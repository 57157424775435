import React, {useState, useRef} from 'react';
import { calcularUtilidad , calcularPrecio } from '../../../../../Core/MatematicasGlobales';



const GananciaEnporcentaje = ({ item, user , accionName , indexx , accionF}) => {


    /*** devido  */
    let PRECIO_ACTUAL = (item.COMPRA && item.COMPRA.precio_actual) ? item.COMPRA.precio_actual : 0;


    let formula = user.CONFIGURACIONES_TABLE.formula_liquidacion ?  user.CONFIGURACIONES_TABLE.formula_liquidacion : 1;

    let {  utilidadPorcentaje } = calcularUtilidad( PRECIO_ACTUAL  ,  item.MATEMATICAS.PRECIO , formula );

    let color;


    const [showinput , setShowInput ] = useState(false);

    const input = useRef();

    const handleChange = (e) => {
        
        setShowInput(false);

        console.log(e.target.value);
        let valor = calcularPrecio(item.MATEMATICAS.PRECIO ,e.target.value  , formula );
        console.log(valor);
        let accion = accionName;
        accionF({indexx,valor, accion});
    }


    const _handleKeyDown =  (e)  => {
        if (e.keyCode === 13) {
            handleChange(e);
            e.preventDefault();
            return false;
        } 
    }

    const clickCampos = () => {
       
        if( user.PERMISOS.editarProducto &&    user.PERMISOS.editarProducto != 0){
            setShowInput(true);
            setTimeout(() => {
                input.current.focus();
                input.current.select(); 
            }, 50);

            return false;
        } 
    }




    if (utilidadPorcentaje < 0) {
        color = '#FF0000'; // Rojo
    } else if (utilidadPorcentaje >= 0 && utilidadPorcentaje <= 5) {
        color = 'rgb(251 206 195)'; // Rojo oscuro
    } else if (utilidadPorcentaje > 5 && utilidadPorcentaje <= 7) {
        color = '#FF6600'; // Rojo medio
    } else if (utilidadPorcentaje > 7 && utilidadPorcentaje <= 10) {
        color = '#FF9900'; // Naranja oscuro
    } else if (utilidadPorcentaje > 10 && utilidadPorcentaje <= 15) {
        color = '#FFCC00'; // Naranja
    } else if (utilidadPorcentaje > 15 && utilidadPorcentaje <= 20) {
        color = '#FFEE00'; // Naranja claro
    } else if (utilidadPorcentaje > 20 && utilidadPorcentaje <= 30) {
        color = '#EEFF00'; // Verde claro
    } else if (utilidadPorcentaje > 30 && utilidadPorcentaje <= 40) {
        color = '#CCFF00'; // Verde medio
    } else if (utilidadPorcentaje > 40 && utilidadPorcentaje <= 50) {
        color = '#99FF00'; // Verde
    } else {
        color = '#66FF00'; // Verde oscuro
    }


    

    return (
        <>
           { !showinput && 
            <span onClick={ clickCampos }>   
                 <span title='Este porcentaje representa la utilidad que te vas a ganar por vender este producto.' style={{ backgroundColor: '#414141', padding: '1.5px', borderRadius: '2px' }}>
                    <span style={{ fontSize: 11, color }}> { Math.round(utilidadPorcentaje)}% </span>
                </span>
            </span> }

            { !(user.PERMISOS.editarProducto &&    user.PERMISOS.editarProducto != 0 ) && <i className="fa-sharp font-s-candado  fa-solid fa-lock"></i>}

           {showinput && <input  ref={input} onKeyDown={_handleKeyDown} className='max-50'  onBlurCapture={ handleChange}  defaultValue={Math.round(utilidadPorcentaje)} />}
        

        </>
    );
};

export default GananciaEnporcentaje;