import React, { useState } from 'react';

const FiltrosCuentasAbiertas = ({ aplicarFiltros }) => {
    // Definimos el estado para cada filtro
    const [vendedor, setVendedor] = useState('');
    const [ruta, setRuta] = useState('');

    // Manejadores de cambio para los filtros
    const handleVendedorChange = (e) => {
        setVendedor(e.target.value);
    };

    const handleRutaChange = (e) => {
        setRuta(e.target.value);
    };

    // Función que se llama al presionar el botón de filtrar
    const handleFiltrar = (e) => {
        e.preventDefault();
        // Llamamos al hook para enviar los filtros
        aplicarFiltros({ vendedor, ruta });
    };

    return (
        <div className="container mt-5 mb-3">
            <h2 className="text-center mb-4">Filtros de Cuentas Abiertas</h2>
            <form onSubmit={handleFiltrar}>
                <div className="row g-3">
                    {/* Filtro Vendedor */}
                    <div className="col-md-4">
                        <label htmlFor="vendedor" className="form-label">Vendedor</label>
                        <select
                            id="vendedor"
                            className="form-select"
                            value={vendedor}
                            onChange={handleVendedorChange}
                        >
                            <option value="">Seleccionar...</option>
                            <option value="vendedor1">Vendedor 1</option>
                            <option value="vendedor2">Vendedor 2</option>
                        </select>
                    </div>

                    {/* Filtro Ruta */}
                    <div className="col-md-4">
                        <label htmlFor="ruta" className="form-label">Ruta</label>
                        <select
                            id="ruta"
                            className="form-select"
                            value={ruta}
                            onChange={handleRutaChange}
                        >
                            <option value="">Seleccionar...</option>
                            <option value="ruta1">Ruta 1</option>
                            <option value="ruta2">Ruta 2</option>
                        </select>
                    </div>

                    {/* Botón Aplicar Filtro */}
                    <div className="col-md-4 d-flex align-items-end">
                        <button type="submit" className="btn btn-primary w-100">
                            Aplicar Filtro
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FiltrosCuentasAbiertas;
