import React from 'react';
import InputValidation from './InputValidation';

const Inventariable = ({formData, btnbmitLoad  , label   , placeholder, type , id ,   className , nameForm , formDataValidations, defaultValue }) => {
    return (
        <div className="form-group mb-3"> 

            <label htmlFor=""><b>Por favor seleccione si este producto es inventariable</b></label>

       
            <InputValidation  
            
            defaultChecked={formData.inventariable == 1 } 
            label={"SI. Inventariable"}  

            listOptions={
                [
                    {
                        defaultChecked : parseInt(formData.inventariable) === 1,
                        label : "SI. Inventariable",
                        id : "Invetariable1",
                        value : 1,
                    },

                    {
                        defaultChecked :  parseInt(formData.inventariable) === 0,
                        label : "No es inventariable",
                        id : "Invetariable2",
                        value : 0,
                    }

                ]
            }


            TipoElementoForm={'radiobutton'} 
            formDataValidations={formDataValidations}  
            disabled={btnbmitLoad} 
            id="inventariable1"
            className="form-check-input" 
            nameForm="inventariable"    />   
                
          
           
            <InputValidation  defaultChecked={formData.inventariable == 0 } TipoElementoForm={'radiobutton'} label={" No es inventariable"}  formDataValidations={formDataValidations}  btnbmitLoad={btnbmitLoad} id="inventariable2" className="form-check-input" nameForm="inventariable"    /> 
        

   

        </div>

    );
};

export default Inventariable;