import React, { useEffect, useState } from 'react';


const VerInventarioVariable = ({ infoInventario }) => {
    const [inventario, setInventario] = useState([]);

    useEffect(() => {
        const parsedInventario = infoInventario.map(item => {
            try {
                return {
                    ...item,
                    variantes: JSON.parse(item.variantes)
                };
            } catch (e) {
                console.error(`Error parsing variantes for item ${item.nombre}:`, e);
                return {
                    ...item,
                    variantes: []
                };
            }
        });

        setInventario(parsedInventario);
    }, [infoInventario]);

    return (
        <div className="container mt-4">
            <h1>Inventario Variable</h1>
            {inventario.map((item, index) => (
                <div key={index} className="mb-4">
                    <h5>Punto de venta: {item.nombre}</h5>
                    {Array.isArray(item.variantes) && item.variantes.length > 0 ? (
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Color</th>
                                    <th>Talla</th>
                                    <th>Cantidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.variantes.map((variante, i) => (
                                    <tr key={i}>
                                        <td>{variante.color}</td>
                                        <td>{variante.talla}</td>
                                        <td>{variante.cantidad}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>Stock : {item.cantidad}</p>
                    )}
                </div>
            ))}
        </div>
    );
};

export default VerInventarioVariable;
