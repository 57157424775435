import React from 'react';

const Thead = () => {
    return (
        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
        <tr>
         
            
            <th className="sort white-space-nowrap align-middle text-uppercase ps-0" scope="col" data-sort="name" style={{ maxWidth: "25%" }}></th>
            
            
            
            <th className="sort align-middle ps-4 pe-5 text-uppercase border-end border-translucent" scope="col" data-sort="email" style={{ width: "15%" }}>
                <div className="d-inline-flex flex-center">
                    <div className="d-flex align-items-center px-1 py-1 bg-success-subtle rounded me-2">
                        <span className="text-success-dark" > <i class="fa-solid fa-envelope"></i> </span></div>
                    <span>Email</span>
                </div>
            </th>
            <th className="sort align-middle ps-4 pe-5 text-uppercase border-end border-translucent" scope="col" data-sort="phone" style={{ width: "15%", minWidth: "180px" }}>
                <div className="d-inline-flex flex-center">
                    <div className="d-flex align-items-center px-1 py-1 bg-primary-subtle rounded me-2"><span className="text-primary-dark" data-feather="phone"><i class="fa-solid fa-phone"></i></span></div>
                    <span>Teléfono</span>
                </div>
            </th>
            <th className="sort align-middle ps-4 pe-5 text-uppercase border-end border-translucent" scope="col" data-sort="contact" style={{ width: "15%" }}>
                <div className="d-inline-flex flex-center">
                    <div className="d-flex align-items-center px-1 py-1 bg-info-subtle rounded me-2"><span className="text-info-dark" data-feather="user"><i class="fa-solid fa-house"></i></span></div>
                    <span>Dirección</span>
                </div>
            </th>

            <th className="sort align-middle ps-4 pe-5 text-uppercase" scope="col" data-sort="date" style={{ width: "15%" }}>Creación</th>
            <th className="sort text-end align-middle pe-0 ps-4" scope="col"></th>
        </tr>
    </thead>
    );
};

export default Thead;