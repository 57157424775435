import React from 'react';

const TrSeparador = () => {
    return (
        <tr>
            <td colSpan={2}><span style={{color:'white'}}>.</span></td>
        </tr>
    );
};

export default TrSeparador;