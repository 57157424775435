

import Variantes from './PartesFormAdd/Inventario/Variantes';
import AjustarDirecto from './PartesFormAdd/Inventario/AjustarDirecto';
import EditarVariantes from './PartesFormAdd/Inventario/EditarVariantes';

const AjustarInventario = ({item , desactivar}) => {

    if(item && item.variantes){


        
      return (<EditarVariantes  item={item}   desactivar={desactivar}  />)
    }




    return (<AjustarDirecto item={item}  desactivar={desactivar}  />)
   

    
};

export default AjustarInventario;