import React from 'react';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';
import TagsIds from '../PartesFormAdd/TagsIds';

const Detalles = ({item}) => {

    const user = fetchUSer();

    const estraerNombreLista = (precio_id) => {

        const resultado = user.DATABASE.ct_lista_precios.find( (lista) => lista.id ==  precio_id  );

        return resultado.nombre
    }  

    const resturnSigno = ( ) => {

        if( user.CONFIGURACIONES_TABLE.switch_modo_precio && user.CONFIGURACIONES_TABLE.switch_modo_precio == 1 ){

            return "%";
        }


        if( user.CONFIGURACIONES_TABLE.switch_modo_precio && user.CONFIGURACIONES_TABLE.switch_modo_precio == 2 ){

            return "$";
        }
        
    }

    return (
        <div>
             <table className='table'>
           <thea>
            
           </thea>

            <body>
            
               <tr>
                    <th>Key</th>
                    <td>{item.id}</td>
               </tr>

               <tr>
                    <th>Codigo</th>
                    

                    { (/,/.test(item.id_2))  && <>  <td>  <TagsIds ids={item.id_2} /> </td> </> || <td>{item.id_2}</td>
                    
                    
                    }

               </tr>

               <tr>
                    <th>Referencia</th>
                    <td>{item.referencia}</td>
               </tr>

               <tr>
                    <th>Nombre</th>
                    <td>{item.nombre}</td>
               </tr>

               <tr>
                    <th>Descripción</th>
                    <td>{item.descripcion}</td>
               </tr>


               <tr>
                    <th>Costo </th>
                    <td>{item.COSTO}</td>
               </tr>

               <tr>
                    <th >Precio de Venta (sin impuestos) </th>
                    <td>{item.PRECIO_PRINCIPAL}</td>
               </tr>



                {
                    (item.LISTA_PRECIOS && item.LISTA_PRECIOS.length >  0) && <>

                        <tr>
                            <th className='text-center' colSpan={2}>Lista de Precios </th>    
                        </tr>

                        {
                            item.LISTA_PRECIOS.map((lista,index) => 
                                <tr key={index}>
                                    <th> { estraerNombreLista(lista.ct_lista_precio_id) } </th>
                                    <td>  {resturnSigno()} {lista.precio}</td>
                                </tr>
                            )
                        }
                    </>
                }



                {
                    (item.IMPUESTOS_RAIZ && item.IMPUESTOS_RAIZ.length >  0) && <>

                        <tr>
                            <th className='text-center' colSpan={2}>Lista de Impuestos </th>    
                        </tr>

                        {
                            item.IMPUESTOS_RAIZ.map((lista,index) => 
                                <tr key={index}>
                                    <th> {lista.nombre} </th>
                                    <td> % {lista.importe}</td>
                                </tr>
                            )
                        }
                    </>
                }

            </body>

            </table>
        </div>
    );
};

export default Detalles;