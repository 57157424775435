import React from 'react';
import {__app , __fecha } from "../../../utils/myApp"
const AcordionConsolidado = ({consolidado, indexx , switchSaldo , saldoFavor }) => {

    if( !consolidado.comprobante ){
        return false;
    }

    
    let idAcordion = "conso" + indexx;
    let titulo = consolidado.nombre;
    
   

    return (
        <div className="accordion" id="accordionPanelsStayOpenExampleCxP">
        <div  className="accordion-item">
               
            <h2 className="accordion-header">
            
                <button  className={'bg-opacity-10  accordion-button collapsed bg-dark' } type="button" data-bs-toggle="collapse" data-bs-target={'#panelsStayOpen-collapse' + idAcordion } aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
            
                    <span className={'fw-bold'}>  {titulo}  </span> 
                    
                    <span className={ 'bg-opacity-75  m-3 badge text-bg-primary '  }> {consolidado.comprobante.length} </span>



                    <small className={"d-inline-flex fw-bold  fs-6 px-2 py-1 fw-semibold  border  rounded-2"}>
                        
                        { saldoFavor  && saldoFavor > 0 && <>  { __app.formato_decimal( {total :saldoFavor })} </>   ||  <>$ { __app.formato_decimal( {total : consolidado.TOTAL})} </>  }

                    </small>

                </button>

            </h2>
            <div id={'panelsStayOpen-collapse' + idAcordion } className="accordion-collapse collapse"  style={{maxHeight: "500px", overflow : "auto"}}>
                <div className="accordion-body">
                    
                       
                <table className='table'>
                   <thead>
                   <tr>
                        <th>CoPago</th>
                        <th>Fecha</th>
                        <th>Cajero</th>
                        <th>Tercero</th>
                        <th>Tipo de Comprobante</th>
                        <th>Numero de Factura</th>
                        <th>Pagado</th>
                    </tr>

                   </thead>
                    
                   <tbody>
                    {consolidado.comprobante.map( (pago, index) =>  
                        
                        <tr key={index}>
                            <td>{pago.idpago}</td>
                            <td>{ __fecha.formatoFecha({$fecha : pago.CREADO } ) }</td>
                            <td>{pago.usernombre}</td>
                            <td>{pago.clienteNombre}</td>
                            <td>  <span className={'badge bg-' + pago.color_documento }> {pago.tipocomprobante} </span> </td>
                            <td>{pago.numero_factura}/{pago.iddocumentos}</td>
                            <td> 
                              
                                {switchSaldo == "+" && 
                                    <span> 
                                        
                                     

                                        <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal( {total : pago.total * -1 , color : true })  }} />


                                    </span> 
                                    
                                    || 
                                    
                                    <span> 
                                 

                                        <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal( {total : pago.total , color : true })  }} />

                                    </span>   
                                }
                                
                            </td>
                        </tr>
                        
                    )}
                    </tbody>
                </table>

                </div>
            </div>
    </div>

     </div>
    );
};

export default AcordionConsolidado;