import React from 'react';
import { useState ,useRef } from 'react';

const  InforServicioOperador = ({ item  , operadores, accionF , indexx}) =>  { 



    if(!operadores){
        operadores = [];
    }else{
        operadores = [ {'id' : 0 , 'razonSocial' : 'Seleccione una Opción'} , ...operadores]
    }
    


    /** 1# Muestra  Todos plano -- otro valor:  Para agregar*/
    const [ switchForm , SetswitchForm ] = useState(true);
    const [ switchDescripcion , SetswitchDescripcion ] = useState(true);
    
    const inputReference = useRef(null);

    const handleChangeClick = () => {
       
        SetswitchForm(!switchForm);
        SetswitchDescripcion(true)
      
    }

    const handleChangeOpe = (e) => {

        SetswitchForm(!switchForm);

        let aux = e.target.value;
        var porciones = aux.split('/');
        let valor = { id : porciones[0] , nombre : porciones[1] };
        let accion = 'cambiar_operador_linea_item';


        accionF({indexx, valor , accion});
    }

    const handleChangeDescripcion = (e) => {
        e.preventDefault();
        SetswitchDescripcion(true);
        let valor = e.target.value;
        let accion = 'cambiar_descripcion_linea_item';
        accionF({indexx, valor , accion});
       
       
        //SetswitchDescripcion(true);
    }

    const handleShowDescription = () => {
        SetswitchDescripcion(false);
        setTimeout( () =>  inputReference.current.focus() , 60 );
    }

    const handleKeypres = (e) => {

        if (e.key === 'Enter' || e.keyCode === 13) {
            SetswitchDescripcion(true);
            let valor = e.target.value;
            let accion = 'cambiar_descripcion_linea_item';
            accionF({indexx, valor , accion});
        }

    }
    return (
        <>

            {  (item && item.tipo_producto == 3 && item.liquidable == 1 ) && 
               
               
                <>
                {   switchForm  &&
                        <div   className=' '>
                            <div  className='d-block d-flex flex-row bd-highlight '>

                                
                                <span onClick={handleChangeClick}  className='d-block ' >
                                    {
                                        item.operador > 0 && 
                                        <>
                                            <span role='button' className='d-inline  badge bg-primary text-white' >{item.nombre_operador}</span>
                                        </>
                                        ||
                                        <>
                                            <span className='d-inline  badge bg-danger text-white' >Por favor seleccione un Operador</span>
                                        </>
                                    }
                                </span>

                                
                                <div className='d-inline  badge bg-success'>

                                    { !(item.switch_liquidacion == 1) && <>$    </> }
                                    {item.liquidacion}
                                    {item.switch_liquidacion == 1 && <>%</> }
                                </div>
                            </div> 
                   

                            { switchDescripcion && 
                                <span onClick={handleShowDescription} role='button' className="mt-2 border d-block border-1 p-2 bg-white">
                                    <span className='d-block fw-light ' style={{"fontSize" : "11px"}} >Descripcion del Servicio</span>
                                    * {item.descripcion}
                                </span>    
                                ||
                                <div className="form-floating mt-3">
                                    <textarea onKeyUpCapture={handleKeypres} ref={inputReference} name='descripcion' onBlur={handleChangeDescripcion} className="form-control" placeholder="Leave a comment here" id="floatingTextarea" defaultValue={item.descripcion}></textarea>
                                    <label htmlFor="floatingTextarea">Escriba detalles del Servicio</label>
                                </div>    
                             }

                           
                              

                        </div>
                    
                    || 
                    <div>
                        <select defaultValue={item.operador + "/" + item.nombre_operador} onChange={handleChangeOpe} className='form-control'>

                            { operadores.map( (ope , index ) => 

                                <option value={ope.id +"/"+ ope.razonSocial } key={index}>
                                    {ope.razonSocial}
                                </option>
                            ) }
                        </select>
                    </div>
                }   
                </>
            }
        </>
    )

}

export default InforServicioOperador;