import React from 'react';

const ListarOperaciones = ({ operaciones, accion }) => {
    return (
        <div>
            <p className='ms-3 mt-5 mb-0 text-body-secondary fs-8' >Seleccionar el tipo de documento que quieres consultar</p>
            <div className="d-flex flex-wrap">
            {operaciones.map((data, index) => (
                <div 
                    key={index} 
                    className="card fs-8 card-hover m-2 p-3 shadow-sm" 
                    style={{ width: '200px', cursor: 'pointer' }}
                    onClick={() => accion(data)}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <i className="fas fa-folder"></i>
                    </div>
                    <p className="mt-3">{data.nombre}</p>
                </div>
            ))}
        </div>
        </div>
    );
};

export default ListarOperaciones;