import Tiempos from "./Tiempos";

export default class MURCIELAGOS extends Tiempos {

    /***
     * Descripción del Cobro
     * 
     * Este cobro inicia cobrando por hora.
     * Cuando el valor de las horas supera el valor de 12 horas,
     * olvida el cobro por hora y aplica un valor fijo por 12 horas.
     * 
     */

   
    constructor({fecha1,fecha2,tarifasbd}) {
        // Llama al constructor de la clase padre (Tiempos)
        super();


        this.explicacion = `
            <p>Cobra por Horas y dias. Horas nocturnas y diurnas con</p>
            <p>Una vez el valor total de las horas supere el costo diario, se dejará de acumular el cobro por hora y se cobrará el costo del día.</p>
            
            <p>Las variables utilizadas son las siguientes:</p>
        
            <ul>
                <li><strong>aux1:</strong> x12 Nocturnas</li>
                <li><strong>aux2:</strong> xHora Nocturna</li>
               
                <li><strong>aux3:</strong> x12 Diurnas</li>
                <li><strong>aux4:</strong> xHora Diurna</li>


               
               
               
                <li><strong>aux5:</strong> Es un tiempo. Escriba aquí después de cuántos minutos el sistema debe contarlo como una hora. 
                    <br>Ejemplo: Si quieres que después del minuto 10 el sistema cobre una hora completa, escribe el número 10.</li>

                <li><strong>aux6:</strong>El el Numero de horas de Gabela. Es decir una vez el cliente cumple 12 horas. Puede decidir no cobrarle las 2 horas siguientes y solo cobrarle 12
                En un ejemplo donde el cliente cumplio 12 horas.</li>



                <li><strong>aux7:</strong>El numero de minutos gratis de entrada sin cobrar.
                 Ejemplo si no queremos cobrar  5 minutos de la primera Hora para que el carro salga en 0. Escribimos el numero 5</li>


                <li><strong>aux8:</strong> Valor Minimo.</li>
 
                </li>
            </ul>
        `;



        this.fecha1 = fecha1;

        this.fecha2 = fecha2;

        // Definir las tarifas
        this.TARIFAS = {

            //aux1
            VALOR_12HORAS_NOCHE: parseFloat(tarifasbd.aux1),


            //aux2
            VALOR_HORA_NOCHE: parseFloat(tarifasbd.aux2),


            
            //aux3
            VALOR_12HORAS_DIA: parseFloat(tarifasbd.aux3),


            //aux4
            VALOR_HORA_DIA: parseFloat(tarifasbd.aux4),



            
            //aux8
            VALOR_MINIMO: parseFloat(tarifasbd.aux8),
            
         

        };

        // Opciones relacionadas con el tiempo
        this.OPCIONES_DE_TIEMPO = {
            /*** Se evalúa esta variable para transformar los minutos en una hora adicional */
            TRANSFORMAR_HORA_DESPUES_DE_X_MINUTOS:  parseFloat(tarifasbd.aux5),


            /**** Son las horas que se van arreglar depus de las 12 horas. El usuario tiene que tener 12 horas para aplicarle el descuneto */
            CANTIDAD_HORAS_DE_GABELA :  parseFloat(tarifasbd.aux6), 




            /**** El numero de Munutos gratis en la primera hora. Ideal cuando queremos que el sistema no cobre inmediatamente  **/
            MINUTOS_GRATIS_PRIMERA_HORA :  parseFloat(tarifasbd.aux7), 
        };

        // Valores de cobros inicializados en 0
        this.COBROS = {
           
           
           
            /**** El numero de horas que no fueron cobradas, Este valor on se suma */
            CANTIDAD_HORAS_DESCONTADAS  :0 ,
           

            VALOR_12H_NOCHE: 0,
           


            VALOR_HORAS_NOCHE: 0,



            VALOR_12H_DIA: 0,
           


            VALOR_HORAS_DIA: 0,


           
            DESCUENTO_X_HORAS_EXTRAS : 0,
           
            VALOR_X_MINUTOS: 0,
           
            VALOR_MINIMO: 0,
           
            VALOR_A_PAGAR : 0
        };

        this.renderizar();


    }

    renderizar ( ){

        console.log(this);
    
        this.addLog("Algoritmo iniciado: Estandar_12h"  )
        
        this.index(this.fecha1 , this.fecha2);

        this.IniciarCobro();

    }

    IniciarCobro(){


        
        ///Valiadando minutos gratis en la primera hora.
        if(this.OPCIONES_DE_TIEMPO.MINUTOS_GRATIS_PRIMERA_HORA > 0 ){
            if(this.TIEMPO.totalHoras == 0 ){
                if(this.TIEMPO.minutos_sobrantes <= this.OPCIONES_DE_TIEMPO.MINUTOS_GRATIS_PRIMERA_HORA){
                    this.addLog("Se cumplio el tiempo de minutos gratis no cobraremos Tiempo"  )
                    this.COBROS.VALOR_A_PAGAR  = 1;
                    return false;
                }
            }
        }



         /*** */
        /// Si los minutos superan los minutos de gabela, entonces cobramos una hora completa por esos minutos
        if( this.TIEMPO.minutos_sobrantes  >   this.OPCIONES_DE_TIEMPO.TRANSFORMAR_HORA_DESPUES_DE_X_MINUTOS ){

           

           
            console.log(this.TIEMPO)
           if(this.TIEMPO.BLOQUE_DE_SALIDA == "dia"){

                this.COBROS.VALOR_X_MINUTOS = this.TARIFAS.VALOR_HORA_DIA;
           }

           if(this.TIEMPO.BLOQUE_DE_SALIDA == "noche"){
                this.COBROS.VALOR_X_MINUTOS = this.TARIFAS.VALOR_HORA_NOCHE;
           }
        }







        this.COBROS.VALOR_12H_NOCHE =  this.TIEMPO.noches_de_12 * this.TARIFAS.VALOR_12HORAS_NOCHE;
           


        this.COBROS.VALOR_HORAS_NOCHE =  this.TIEMPO.horas_sobrante_nocturnas * this.TARIFAS.VALOR_HORA_NOCHE;
   
        if( this.COBROS.VALOR_HORAS_NOCHE >   this.TARIFAS.VALOR_12HORAS_NOCHE ){
            this.COBROS.VALOR_HORAS_NOCHE = this.TARIFAS.VALOR_12HORAS_NOCHE;
        }


        this.COBROS.VALOR_12H_DIA =   this.TIEMPO.dias_de_12 * this.TARIFAS.VALOR_12HORAS_DIA;
    

        this.COBROS.VALOR_HORAS_DIA  =   this.TIEMPO.horas_sobrante_diurnas * this.TARIFAS.VALOR_HORA_DIA;
        if( this.COBROS.VALOR_HORAS_DIA >   this.TARIFAS.VALOR_12HORAS_DIA ){
            this.COBROS.VALOR_HORAS_DIA = this.TARIFAS.VALOR_12HORAS_DIA;
        }



       



         
        //cobramos las horas.
        /**
         * Antes de combrar las horas debemos valifar que el cliente regale una horas estras.
         * PEro esto solo puede pasar de this.TIEMPO.dias_y_noches_12 > 0.
         * 
         */
        if(this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA > 0 ){

            this.addLog("Encontramos Gabela Activa , gabela es de  :" + this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA )
            /** Si hay descuento */
            if(this.TIEMPO.dias_y_noches_12 > 0){

                
                if(this.TIEMPO.horas_sobrante_diurnas  > 0 ){
                    this.addLog("Encontramos horas sobrante de   :" + this.TIEMPO.horas_sobrante_by12H )
                   
                    let horas_sobrantes =   this.TIEMPO.horas_sobrante_diurnas  -  this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA;
                    /*** Entonces si hay horas sobrante */

            
                    this.addLog("El sobrante es de :   :" + horas_sobrantes )


                    //Si entramo aqui, es por que el todal de horas se les hizo el descuento si sobrante se supone que quedara automantentico
                    if(horas_sobrantes >=   0 ){
                        this.addLog("Si Entramos aqui es por que las gabelas se consumieorn" )
                        this.COBROS.DESCUENTO_X_HORAS_EXTRAS = (this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA * this.TARIFAS.VALOR_HORA_DIA) * -1;
                        this.COBROS.CANTIDAD_HORAS_DESCONTADAS = this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA;
                    }


                    if(horas_sobrantes < 0){
                        this.addLog("hay sobrante de Gabela y podriamos anular minutos" )
                        //Sacamos las horas real
                        let aux_horas_de_gabela = this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA - (horas_sobrantes * -1)  ;
                        
                        
                        this.COBROS.DESCUENTO_X_HORAS_EXTRAS = (aux_horas_de_gabela * this.TARIFAS.VALOR_HORA_DIA) * -1;
                        this.COBROS.CANTIDAD_HORAS_DESCONTADAS = aux_horas_de_gabela;
                        
                        
                        //Anulamos los minutos
                        this.COBROS.VALOR_X_MINUTOS = 0;

                    }

           
                }



            }
           
        }



        this.COBROS.VALOR_A_PAGAR =  
        
        parseFloat(this.COBROS.VALOR_12H_DIA) + 
        parseFloat(this.COBROS.VALOR_12H_NOCHE) +  
        
        parseFloat(this.COBROS.VALOR_HORAS_DIA) +  
        parseFloat(this.COBROS.VALOR_HORAS_NOCHE) +

        parseFloat(this.COBROS.VALOR_X_MINUTOS) +


        parseFloat(this.COBROS.DESCUENTO_X_HORAS_EXTRAS) ;     
        
        
        if(this.COBROS.VALOR_A_PAGAR <  this.TARIFAS.VALOR_MINIMO  ){
            
            
            this.COBROS = {
                DESCUENTO_X_HORAS_EXTRAS : 0,
                VALOR_12H_DIA: 0,
                VALOR_12H_NOCHE: 0,
                VALOR_HORAS_DIA: 0,
                VALOR_HORAS_NOCHE: 0,
                VALOR_A_PAGAR : 0
            };

            this.COBROS.VALOR_MINIMO  =   this.TARIFAS.VALOR_MINIMO;
            this.COBROS.VALOR_A_PAGAR  =  this.TARIFAS.VALOR_MINIMO;
        }







    }

}