import React from 'react';
import PedidoByMesa from './PedidoByMesa';
import { useState } from 'react';

const TodasLasMesas = ({user , cargarDocumento , pedidos , eliminarCuentaAbierta , seleccionarMesa , imprimirPreFactura}) => {

    const  [estadoMesa , setEstadoMesa] = useState(0);

    const getEstadoMesa =  (data) => {
        setEstadoMesa(data)
        console.log(data)
    }

    return (
        <>
            
            {user.MESAS.map((mesa, index) => 
                
                <div className={ estadoMesa == 1 ? "border-danger" : "border-success" + " card"}  key={index} style={{overflow:'auto', minHeight:'220px' , overflow : 'auto' ,maxHeight:'150px' , padding:'0px' }}>
                  
                    <div class={ estadoMesa == 1 ? "text-danger" : "text-success" + " card-header"}> 
                        <h5>Mesa {mesa.nombre}</h5> 
                        <span className='badge bg-primary'>{mesa.sessionname}</span> 
                    </div>
                   
                    <div className="" >
                        <PedidoByMesa imprimirPreFactura={imprimirPreFactura} seleccionarMesa={seleccionarMesa} eliminarCuentaAbierta={eliminarCuentaAbierta}   cargarDocumento={cargarDocumento} user={user} getEstadoMesa={getEstadoMesa} mesa_id={mesa.id} pedidos={pedidos} mesanombre={mesa.nombre} />
                    </div>
               
                  
                </div>
            
            )}
        </>
    );
};

export default TodasLasMesas;