const TotalesDisplay = ({ totales }) => {
    return (
        <div>
            <h2>Totales</h2>
            <ul>
                {Object.keys(totales).map((key) => (
                    <li key={key}>
                        <strong>{key}:</strong> {totales[key]}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TotalesDisplay;