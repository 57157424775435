import React from 'react';
import {__app} from "../../../utils/myApp"
import ListaDocumentos from '../Componentes/ListaDocumentos';

function Acordiones({tipoDocumentos , cargarDocumentos , indexx}) {
    return (
        <div className="accordion" id="accordionPanelsStayOpenExample" >
                
          
        {tipoDocumentos.map( (tipodoc , index ) => 
        
            <div key={index} className="accordion-item">
               
                    <h2 className="accordion-header">
                       
                        <button onClick={ () => cargarDocumentos(indexx, index)} className={'bg-opacity-10  accordion-button collapsed' + ' bg-' + tipodoc.color_documento } type="button" data-bs-toggle="collapse" data-bs-target={'#panelsStayOpen-collapse' + indexx + index} aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      
                      
                      
                        <span className={'fw-bold text-' + tipodoc.color_documento}> 
                            {tipodoc.nombre}   
                            
                            { tipodoc.ESTADOHTTP  == 1  &&  <span>  Cargando..</span> }   

                        </span> 
                        
                        <span className={ 'bg-opacity-75  m-3 badge text-bg-primary '  }>{tipodoc.CANTIDAD_DOCUMENTOS}</span>



                        <small className={"d-inline-flex fw-bold  fs-6 px-2 py-1 fw-semibold text-"+tipodoc.color_documento+"-emphasis bg-"+tipodoc.color_documento+"-subtle border border-"+tipodoc.color_documento+"-subtle rounded-2"}>$ { __app.formato_decimal( {total : tipodoc.SUBTOTAL_3})} </small>

                        </button>

      

                    </h2>
                    <div id={'panelsStayOpen-collapse' + indexx + index } className="accordion-collapse collapse"  style={{maxHeight: "500px", overflow : "auto"}}>
                        <div className="accordion-body">

                            { ( tipodoc.ESTADOHTTP &&   tipodoc.ESTADOHTTP == 1)  &&  <p>Cargando...</p> } 
                            
                            <ListaDocumentos tipodoc={tipodoc} /> 
                        </div>
                    </div>
            </div>
         
        ) }
        


        </div>
    );
}

export default Acordiones;


