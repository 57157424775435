import React, {useState , useRef , useEffect} from 'react';
import { ExtraerTabla , reloadDatabases } from '../../../Core/Dixie';

import { NotificationManager} from 'react-notifications';

import {validarFormtercero } from './CoreTercero';
import {fetchEmpresa} from "../../../Core/fetchLocalStoreData";
import {enviarPeticion} from "../../../Core/ActionDatabase";
import StandarModal from '../PartesDocument/StandarModal';

import NuevoTercero from '../../Terceros/NuevoTercero';

import OptionSelect  from '../../../Core/Html/OptionSelect';
import { getLocalstore } from '../../../Core/fetchLocalStoreData';

let tercerosDixie = null;


const FormTercero = ({ handleSubmitPadre , terceroDefaul , accionForm , handleClickCerrar}) => {

    console.log(terceroDefaul);


    const database = getLocalstore("__Ah#d7aWF___");


    const user = getLocalstore("user");

    const initial = ( terceroDefaul) ? terceroDefaul :
    {
        id : null,
        identificacion : "",
        razonSocial : "",
        telefono : "",
    
    }
;
 
    const TituloForm = accionForm == 1  ? "Registrar Contacto" : "Editar Contacto" ;
    const textButton = accionForm == 1  ? "Guardar Contacto" : "Confirmar edición" ;
 
    const [ tercero , setTercero  ] = useState(initial);

    const ConfigEmpresa = fetchEmpresa();

    let auxIgnorarNombre = 2;


    const [ showFormFormulario , SetshowFormFormulario ] = useState(false);

    const handleChange = (e) => {

        setTercero({
          ...tercero,
          [e.target.name]: e.target.value,
        });
    
    }

    const [ estadoAjaxForm ,  setEstadoAjaxForm ] = useState(null);


    const handleSubmit = (e) =>{

        e.preventDefault();
 
        
        if(!tercero.identificacion){
            NotificationManager.warning('No hay identificación en tercero'  , 'Error');
            return false;
        }
        
        
        if(auxIgnorarNombre == 2){
            if ( !tercero.razonSocial ) {
                NotificationManager.warning('Escribe el Nombre'  , 'Error');
                refNombre.current.focus();
                return false;
            }
                
            
        }
         
        
        
        if (tercero.id === null) {

      
            enviarPeticionPhp(tercero , { peticion : "agregar_tercero" }); 
            //handleSubmitPadre(tercero);
            //();
        } else {
          updateData(tercero);
        }
        


       
    }



    const submirFullForm = (tercero) => {

        setEstadoAjaxForm(1);
         
        if ( !tercero.id  || tercero.id === null) {
         
            //handleSubmitPadre(tercero);
            IntentarGuardarEnBD(tercero)
        } else {
          
          updateData(tercero);
        }
    }



    /***
     * Esta funcion intentara guardar el tercero en la base de datos 
     * En caso de que no tenga exito lo almacenara junto con la factura para enviar lo depues
     * Si tiene extio debe guardar el ID en la factura y agregarlo en el DIXE
    */

    const IntentarGuardarEnBD = (tercero) => {
       
        setTercero(tercero);
        enviarPeticionPhp(tercero , { peticion : "agregar_tercero"  } );
    }



    const mystyle = {
        "marginTop": "30px"  
    };

    const refIdentidicacion = useRef(null);
    const refNombre = useRef(null);
    
    useEffect( () => {

        auxIgnorarNombre = 2;

        setTimeout(() => {
            refIdentidicacion.current.focus();
          }, 200);

        const extraerTercerosLocal = async () => {
            tercerosDixie = await   ExtraerTabla('terceros');
        }  

        extraerTercerosLocal();

    } , [] )



    const validarNit = (e) => {
        
        if ( e.target.value.trim().length >= 4 ){
            const resultado = tercerosDixie.find( tercero => tercero.identificacion === e.target.value );
            if(resultado && accionForm == 1){
                NotificationManager.warning('Ya Existe esta identificación'  , 'Error');
                auxIgnorarNombre = 1;
                handleSubmitPadre(resultado);
            }
        }

        
    
    }
    


    const updateData = (tercero) => {
        
        const resultado = tercerosDixie.find( dixtercero =>    dixtercero.identificacion === tercero.identificacion  );
        
        console.log(resultado);
        console.log(tercero);

        if(JSON.stringify(resultado) == JSON.stringify( tercero )){
            handleClickCerrar();
            NotificationManager.warning('No detectamos cambios'  , '');
            return false;
        }


        if(resultado){
            if(resultado.id != tercero.id ){
                NotificationManager.warning('La identificacion ya se encuentra registrada'  , 'Identificacion Repetida');
                //return false;
            }  
        }

       

        enviarPeticionPhp(tercero , { peticion : "editar_tercero" });   
    }



    

    const enviarPeticionPhp  = async (tercero , {peticion}) => {

        let respuesta =  await enviarPeticion( "terceros/" + peticion , ConfigEmpresa.nit  , {
            body : tercero,
            headers :{ 
                
            }
        } );

       if( respuesta.respuesta && respuesta.respuesta == 1 ){
            
            
            setEstadoAjaxForm(null);

            NotificationManager.success('Exito'  , 'Editado');
            
            if(peticion == "editar_tercero" ) {
                actualizarTerceroDixie(tercero , respuesta.data);
                handleSubmitPadre(tercero)

            }


            if(peticion == "agregar_tercero" ) {

                
                agregarTerceroDixie(tercero , respuesta.data.id )
                handleSubmitPadre( { ...tercero , id : respuesta.data.id } );
            }

   


        }else if (respuesta.respuesta && respuesta.respuesta == 2 ) {

            setEstadoAjaxForm(null);
            NotificationManager.warning('La identificacion ya se encuentra registrada'  , 'Identificacion Repetida');

            return false;
        } else{    

            setEstadoAjaxForm(null);
            handleSubmitPadre(tercero) 
            NotificationManager.warning('No tenemos conexión con el Servidor. verifique su conexion a internet'  , 'Sin Conexión');    
        }
    }



    const actualizarTerceroDixie = () => {

        const copiTercero = tercerosDixie.map( terceroDixue => {
                
            if(tercero.id == terceroDixue.id){
                terceroDixue = tercero;
            }
            return tercero;
        }) 
        reloadDatabases(copiTercero , 'terceros');

    }






    const agregarTerceroDixie = (tercero , id ) => {
 
        let copiy = {...tercero , id: id}
        reloadDatabases([copiy]  , 'terceros' )
        
    }
 

    return (
        <div className='contanedor-form shadow p-3 mb-5 bg-light rounded' >
            <form   onSubmit={handleSubmit} onChange={handleChange} className="form-width">

                <br /> 
                <p className='col-md-12 text-center'><b>{TituloForm}</b> </p>
                <div style={mystyle}  >
                <div className="form-group col-md-12">
                        <input onBlur={validarNit} ref={refIdentidicacion} autoComplete="off" defaultValue={tercero.identificacion} name="identificacion" type="text" className="form-control form-control-icons" placeholder="Identificación" />
                        <i className="fa-solid fa-fingerprint iconalign"></i>
                    </div>
            
                   
                    
                    <div className="form-group col-md-12">
                        <input autoComplete="off" ref={refNombre}  defaultValue={tercero.razonSocial} name="razonSocial" type="text" className="form-control form-control-icons" placeholder="Nombre" />
                        <i className="fa fa-user iconalign"></i> 
                    </div>

                    <div className="form-group col-md-12">
                        <input  autoComplete="off"  defaultValue={tercero.telefono} name="telefono" type="text" className="form-control form-control-icons" placeholder="Telefono" />
                        <i className="fa-solid fa-square-phone iconalign"></i>
                    </div>

                
                    <div class="form-group col-md-12">
                        <label htmlFor="">Lista de Precios</label>
                        <select  autoComplete='off'  class="form-control" defaultValue={tercero.ct_lista_precio_id} name="ct_lista_precio_id" >
                            <OptionSelect datos={user.DATABASE.ct_lista_precios} />
                        </select>
                    </div>
                    
                    
                    <button  autoComplete="off"  type='submit' className='mt-3 btn btn-principal d-inline form-button-submit-mini' >{textButton}</button>
                
                    <span className='btn'     onClick={() => SetshowFormFormulario(true)} >Mas Campos</span>        
                </div>
               
               
           

            </form>
            <button  autoComplete="off"  onClick={handleClickCerrar}  className='btn-dark btn d-inline ' > Cancelar </button>
        
        
                 {/***
                * Formulario Lista Compuesto, solo para restaurante.
                */}
                <StandarModal  headerShow={false} size="xl"   title="Formulario Cliente"  desactivar={() => SetshowFormFormulario(!showFormFormulario)}  mostrar={showFormFormulario}  > 
                    <NuevoTercero  estado_ajax={estadoAjaxForm} cerrar={() => SetshowFormFormulario(false) } handleSubmitPadre={submirFullForm} desactivar={() => SetshowFormFormulario(!showFormFormulario)}   /> 
                </StandarModal>
        </div>
    );
}

export default FormTercero;