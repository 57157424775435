import React from 'react';
import Selectt from 'react-select'

const Select = ({filtro  , formData , setFormData}) => {


    if (!Array.isArray(filtro.options) || filtro.options === null || filtro.options.length <= 1) {
        return false;
    }




    const cambiosSelect = (data) => {
        
        setFormData({
            ...formData,
            [filtro.name]: data
        });
    }

    const opciones = filtro.options
    

    const getLabel = (opciones) => {

        if(  filtro.ShowOptions && filtro.ShowOptions.nombre  ){
            return opciones[filtro.ShowOptions.nombre]
        }
        return opciones.nombre 
    }



    const getValue = (opciones) => {   

        return opciones.id  
    }

    return (
        <div className="col">
            <span htmlFor="">{filtro.label}</span>
            <Selectt 
            isMulti 
            name={filtro.name}
            getOptionLabel ={(opciones) => getLabel(opciones)}
            getOptionValue ={(opciones) => getValue(opciones)}
            placeholder={filtro.placeholder} 
            options={opciones} 
            onChange={cambiosSelect}
            value={formData[filtro.name]}
            />
            {/*
          cambiosSelect 
            onChange={handleTaxSelection}
            value={formData.IMPUESTOS_RAIZ} // Establecer las opciones seleccionadas por defecto
            */
        }
        </div>
    );
};

export default Select;