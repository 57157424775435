import React , {useEffect, useState} from 'react';
import { getLocalstore } from '../../Core/fetchLocalStoreData';
import OptionSelect  from '../../Core/Html/OptionSelect';
import { calcularDigitoVerificacion } from './Core';
const NuevoTercero = ({handleSubmitPadre , cerrar , estado_ajax}) => {
    

    
    const database = getLocalstore("__Ah#d7aWF___");

    if(database.datos_precarga ){

        
    }

    const user = getLocalstore("user");

    const [dvnit , setDvnit] = useState(); 

    const [btnbmitLoad  , setBtnbmitLoad ] = useState();

    const [formData, setFormData] = useState({
       
            "fe_tipodocumento_id": "1",
            "fe_tipopersona_id": "",
            "identificacion": "11111",
            "dvnit": "1",
            "razonSocial": "JUAN REYES",
            "nombrecomercial": "ANDRES",
            "direccion": "eqweq",
            "telefono": "3122946723",
            "correoelectronico": "andres@aaa.com",
            "fe_municipio_id": "3",
            "fe_regimenfiscal_id": "49",
            "fe_tipopersona_id": "2"
    });


    useEffect( () => {

        setBtnbmitLoad(estado_ajax);

    } , [estado_ajax])

        
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
        
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked ? [...prevData[name], e.target.value] : prevData[name].filter((item) => item !== e.target.value),
        }));
    };
        
    const handleSubmit = (e) => {
        e.preventDefault();
        //setBtnbmitLoad(1);
        let copy = {...formData , dvnit : dvnit}
        handleSubmitPadre( {...formData , dvnit : dvnit});
    };



    const ocultarForm = ( ) => {

        cerrar();
    }

    
    const calcular = (nit) =>  {
        console.log(nit)
		nit = nit.replace(/ /g, "");
		let isNitValid = nit >>> 0 === parseFloat(nit) ? true : false;

		if (isNitValid) {
		    let difito = calcularDigitoVerificacion(nit);
           
            setDvnit(difito)

            
		}
        console.log(nit)
	}


        return (
            
            <>
            
            <form  onChange={handleChange} onSubmit={handleSubmit} method="POST">
                <h1 className='mb-4'>Formulario de Personas</h1>
                <div className="row ">
                    
                    <div className="col-sm">
                        
                        
             
            
                        <div className="form-group row mb-3">
                            <div className="col-9">
                                <label htmlFor="">Identificación</label>
                                <input onChange={(e) => calcular(e.target.value) }   autoComplete='off' disabled={btnbmitLoad} type="text" id="nit" class="form-control" name="identificacion" placeholder="Cédula o Nit" required />
                            </div>
            
                            <div className="col-3">
                                    <label htmlFor="">Digito Verificación</label>
                                <input  autoComplete='off' disabled={true} value={dvnit} type="text" id="digitoVerificacion" class="form-control" name="dvnit" placeholder="DV" required />
                                <input  value={dvnit} type='hidden'  name="dvnit" />
                            </div>
                        </div>
            
                        <div className="form-group mb-3">
                            <label htmlFor="">Nombre completo o Ranzón Social</label>
                            <input  autoComplete='off' disabled={btnbmitLoad} type="text" class="form-control" name="razonSocial" placeholder="Razon Social" required />
                        </div>
            
                        <div className="form-group mb-3">
                            <label htmlFor="">Nombre Comercial</label>
                            <input  autoComplete='off' disabled={btnbmitLoad} type="text" class="form-control" name="nombrecomercial" placeholder="Nombre Comercial" required />
                        </div>


                        <div className="form-group mb-3">
                            <label htmlFor="">Teléfono</label>
                            <input  autoComplete='off' disabled={btnbmitLoad} type="text" class="form-control" name="telefono" placeholder="Teléfono" required />
                        </div>



                    </div>
            
                    <div className="col-sm">

                        <div className='row'>         
                            <div className="form-group col-6 mb-3">
                                <label>Tipo de Documento</label>
                                <select  autoComplete='off' disabled={btnbmitLoad}  class="form-control" name="fe_tipodocumento_id" required>
                                    <OptionSelect datos={database.datos_precarga.fe_tipodocumentos} />   
                                </select>
                            </div>
                
                            <div className="form-group col-6 ">
                                <label>Tipo de Persona</label>
                                <select  autoComplete='off' disabled={btnbmitLoad} class="form-control" name="fe_tipopersona_id" required>
                                    <OptionSelect datos={database.datos_precarga.fe_tipopersonas} />
                                </select>
                            </div>
                        </div>


                        
                        
            
                        
            
                        <div className="form-group mb-3">
                            <label htmlFor="">Regimén Fiscal</label>
                            <select  autoComplete='off' disabled={btnbmitLoad} className="form-control" name="fe_regimenfiscal_id" required>
                                <OptionSelect datos={database.datos_precarga.fe_regimenfiscals} />
                            </select>
                        </div>
            
                       {/*
                            <div class="form-group mb-3">
                            <label htmlFor="">Responsabilidades Fiscales</label>
                                <select class="Obligaciones form-control" name="obligaciones[]" multiple required>
                                <OptionSelect datos={database.datos_precarga.responsabilidadesFis} />
                                </select>
                            </div> 
                        */}

                        <div class="form-group mb-3">
                            <label htmlFor="">Municipio</label>
                            <select  autoComplete='off' disabled={btnbmitLoad} className="form-control" name="fe_municipio_id" required>
                                <OptionSelect datos={database.datos_precarga.fe_municipios} />
                            </select>
                        </div>

                        
                        
                        <div className="form-group mb-3">
                            <label htmlFor="">Dirección de residencia</label>
                            <input   autoComplete='off'  disabled={btnbmitLoad} type="text" className="form-control" name="direccion" placeholder="Dirección Fisica" required />
                        </div>
            
                        
            
                        <div className="form-group mb-3">
                            <label htmlFor="">Correo electrónico</label>
                            <input   autoComplete='off' disabled={btnbmitLoad} type="email" className="form-control" name="correoelectronico" placeholder="E-mail" required />
                        </div>

                        <div className="form-group  mb-3">
                            <label htmlFor="">Lista de Precios</label>
                            <select  autoComplete='off'  className="form-control"   name="ct_lista_precio_id" >
                                <OptionSelect datos={user.DATABASE.ct_lista_precios} />
                            </select>
                        </div>

                 
                    </div>
                </div>
             



                <span className='btn btn-' onClick={ocultarForm} > Cancelar    </span>

                
                <button disabled={estado_ajax} type="submit" value="" class="btn-lg btn btn-success"  > Guardar  
                
                    {
                        estado_ajax && 

                        <span className="ms-2 spinner-border spinner-border-sm" aria-hidden="true"></span>
                    } 
                
                </button>
            </form>
        
            </>
            
        );
    };

export default NuevoTercero;