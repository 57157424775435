import React from 'react';
import { Numpositivo } from '../../../Core/MatematicasGlobales';
import { __app } from '../../../utils/myApp';


const Pendiente = ({total, abonado}) => {
   
    const saldo_pendiente = Numpositivo(total) -  Numpositivo(abonado);

    if(total === 0){

        return 0;
    }

    return (
        <span>
            
            

            { (saldo_pendiente < 2 && saldo_pendiente !==0 ) && <><span className='badge bg-opacity-50 bg-warning'>Exedente</span>  <br /> <span className='text-success'> &#x26a0;&#xfe0f; ${__app.formato_decimal({total: Numpositivo(saldo_pendiente)}) }</span> </> } 

            {saldo_pendiente > 0  && <span className='text-danger fw-bolder  '> $ { __app.formato_decimal({total: saldo_pendiente}) }</span>  }

            { (saldo_pendiente ===0 ) &&  <span className='text-success text-center'> pagado :) </span>  } 
        </span>
    );
};

export default Pendiente;