import React, { useEffect, useState } from 'react';
import { setLocalStore } from "../../../Core/fetchLocalStoreData";

const FiltroBuscador = ({ filtroBusquedaRipi, SetfiltroBusquedaRipi }) => {
    const opcionesIniciales = ['nombre'];

    const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState(
        filtroBusquedaRipi && filtroBusquedaRipi.length > 0 ? filtroBusquedaRipi : opcionesIniciales
    );

    // Actualizar almacenamiento local cuando opcionesSeleccionadas cambia
    useEffect(() => {
        setLocalStore("ConfiguracionBuscadorRipiPrincipal", opcionesSeleccionadas);
    }, [opcionesSeleccionadas]);

    const toggleOpcionSeleccionada = (opcion) => {
        let copy = [...opcionesSeleccionadas];
        const index = copy.indexOf(opcion);

        if (index === -1) {
            copy.push(opcion);
        } else {
            // Evitar deselección de todas las opciones
            if (copy.length === 1) {
                return; // No se puede deseleccionar la única opción restante
            }
            copy.splice(index, 1);
        }

        setOpcionesSeleccionadas(copy);
        SetfiltroBusquedaRipi(copy);
    };

    return (
        <div className='me-5 text-center'>
            Buscar por:
            {['nombre', 'id_2', 'referencia'].map(opcion => (
                <React.Fragment key={opcion}>
                    <input
                        checked={opcionesSeleccionadas.includes(opcion)}
                        className='ms-2 click'
                        id={`Aias${opcion}`}
                        type="checkbox"
                        onChange={() => toggleOpcionSeleccionada(opcion)}
                    />
                    <label className='click' htmlFor={`Aias${opcion}`}>
                        {opcion === 'id_2' ?
                            'Codigo' :
                            opcion
                        }
                    </label>
                </React.Fragment>
            ))}
        </div>
    );
};

export default FiltroBuscador;
