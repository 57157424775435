import React , {useEffect, useState, forceUpdate} from 'react';
import { getLocalstore } from '../../Core/fetchLocalStoreData';

import { inicial , validaciones , guardarProductosServer , extraerUltimoid , combinarCamposListaPrecio , insertarCambioFirebase, extraerCompuestos } from "./Controlador";




import { validarForm } from '../../Core/Validacion';


import { NotificationManager} from 'react-notifications';
import AddCampos from './AddCampos';
import Composiciones from './PartesFormAdd/Composiciones';
import { __app } from '../../utils/myApp';
import Imagenes from './PartesFormAdd/Imagenes';
import Inventario from './PartesFormAdd/Inventario/Pages';

const Add = ({ cerrar  , item , editar = 0}) => {
    
 
    const database = getLocalstore("__Ah#d7aWF___");

    if(database.datos_precarga ){

        
    }

  




    const user = getLocalstore("user");



    const manejarImpuestos = user.CONFIGURACIONES_TABLE.manejarImpuestos ? user.CONFIGURACIONES_TABLE.manejarImpuestos : 0 ;



    const [dvnit , setDvnit] = useState(); 

    const [btnbmitLoad  , setBtnbmitLoad ] = useState();

    const [estado_ajax , setestado_ajax] = useState(null);

    
    const [formData, setFormData] = useState(null);
    
    const [formDataValidations , SetformDataValidations ] = useState(null);

    const [ error, setError ] = useState(null);


    const [ estadoEdicion , setEstadoEdicion ] = useState(null);

    const caracteresMaximoID =   useState(null);

    const [validacionesFormato , SetvalidacionesFormato] = useState(null);

    const [ ayudas , setAyudas] = useState({
        "composiciones" : 1
    }) 


    useEffect(() => {

  
        NuevoProcedimiento();

        if(user.CONFIGURACIONES_TABLE.validaciones_tabla_productos){
            
            const cleanString = user.CONFIGURACIONES_TABLE.validaciones_tabla_productos.replace(/\\n|\\r/g, '');
            // Parsea la cadena JSON en un objeto
            const parsedObject = JSON.parse(cleanString);
           
           
            if (typeof parsedObject === 'object' && parsedObject !== null) {
                
                let data = validaciones;

                if(parsedObject.id_2){
                    data.id_2.maximoCaracteres.valor = parsedObject.id_2;
                }
                if(parsedObject.nombre){
                    data.nombre.maximoCaracteres.valor = parsedObject.nombre;
                }
                
                
                
                SetvalidacionesFormato(data);
            }
          
        }else{

            SetvalidacionesFormato(validaciones);
        }
        
    } , [])

    
    const NuevoProcedimiento = () => {

        
        SetformDataValidations(null);
        

        let insertar = ( editar ===  1) ? __app.clonarObjeto({...item ,id_2 : convertirCadenasConComas(item.id_2) }) : inicial  ;



        insertar.COMPOSICIONES   = extraerCompuestos( insertar);
       
        let  resutados  = combinarCamposListaPrecio( insertar , user );

        

        if( editar ===  0){
            extraerUltimoidLocal(resutados)
        }else{
            console.log(resutados);
           
            setFormData(resutados);
        }
        
    }


    

    const convertirCadenasConComas = (cadenaOriginal) => {

       

     
        // Función para convertir notación científica a número entero sin comas
        const fromScientificNotation = (scientificNotation) => {
            return scientificNotation; 
            let num = parseFloat(scientificNotation);
            return num.toLocaleString('fullwide', { useGrouping: false });
        };
    

       
        // Dividir la cadena original en un array
        const codigosArray = cadenaOriginal.split(',');
        console.log("ABC" , codigosArray )


        
        // Convertir cada elemento usando la función de conversión
        const codigosConvertidos = codigosArray.map(fromScientificNotation);
        console.log("ABC" , codigosConvertidos );



        // Unir de nuevo los elementos convertidos en una sola cadena separada por comas
        return codigosConvertidos.join(',');
    };



    const extraerUltimoidLocal =  async (copy) => {
     
        if(editar ===  0){
            let res = await extraerUltimoid(user);
            if(res && res !== -1){
                
                console.log({...copy , id_2 :res });
                setFormData( {...copy , id_2 :res } )
                setBtnbmitLoad(null);
            }else{
                setBtnbmitLoad(null);
                setFormData( {...copy , id_2 : "" } ) 
            }   
        }
         
    }



        
    const handleChange = (e) => {

  
        let { name, value  } = e.target;
        
        if(name == "off"){
          
            return false;
            
        }

        if( name === "favoritos" ){

            value = ( value == 1 ) ? 0 : 1;
        }



        setFormData((prevData) => ({ ...prevData, [name]: value }));

        
    };
        

    const handleCheckboxChange = (e) => {
  
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked ? [...prevData[name], e.target.value] : prevData[name].filter((item) => item !== e.target.value),
        }));
    };
        
    const handleSubmit = async (e) => {
       
        e.preventDefault();
     
        setBtnbmitLoad(1);
        setestado_ajax(1);
        setError(null);
    

        let validar = {
            validaciones : validacionesFormato,
            formulario : formData
        }

        console.clear();
        let resultados = validarForm(validar);

       
        console.log(resultados);
        SetformDataValidations(resultados.RESULTADOS)

       
        if(  resultados.estadoGlobalValidarionForm === false){
            setBtnbmitLoad(null);
            setestado_ajax(null);
            NotificationManager.error('El formulario contiene Errores ', 'Error'); 
            return false;
        }

        let response = await guardarProductosServer(formData);
       
        if(response.statushttp){
           console.clear();
            setBtnbmitLoad(null);
            setestado_ajax(null);

            if  (
                
                    (response.responseHttp.respuesta && response.responseHttp.respuesta == 1) && 
                    (response.responseHttp.data      &&  response.responseHttp.data.id && response.responseHttp.data.id >= 1)
                )
                {

                
                NotificationManager.success('Producto guardado con Exito', 'Exito!');

               if(editar === 0){
                    setBtnbmitLoad(1);
                    SetformDataValidations(null);
                    setFormData(null);
                    NuevoProcedimiento()
               }else{

                    /** Si estamos editando */
                    SetformDataValidations(null);
                    setBtnbmitLoad(1);
                    setEstadoEdicion(1);
               }

               insertarCambioFirebase(response.responseHttp.data.datos);

                
            }else{
                console.log(response);
                setError(response.responseHttp.mensaje);
                NotificationManager.error('Hay un error al momento de guardar el producto.', 'Exito!');

            }
        }
        return false;

       // handleSubmitPadre( {...formData , dvnit : dvnit});
    };



    const ocultarForm = ( ) => {
  
        cerrar();
    }

    const asignarMultiCodigo = (value) => {
        const tags = formData.id_2.split(',');
        if (!tags.includes(value)) {
            const dataa = formData.id_2.length > 1 ? formData.id_2 + ',' + value : value;
            setFormData((prevData) => ({ ...prevData, id_2: dataa }));
        } else {
            NotificationManager.warning("Ya existe este Código.")
        }   
    }

    
    const deleteCodigo = (index) => {
        const tags = formData.id_2.split(',');
        tags.splice(index, 1);
        setFormData((prevData) => ({ ...prevData, id_2: tags.join(',') }));
    }
    

    const [ tab , setTab] = useState(1);


    if(!formData){
        return (<p>Cargando...  <button onClick={ () => console.log(formData)} >log</button> </p>);
    }

        return (
            
            <>
            
                <ul className="nav mb-5  nav-pills" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab(1)} className="nav-link active" id="producto-tab" data-bs-toggle="tab" data-bs-target="#producto" type="button" role="tab" aria-controls="producto" aria-selected="true">Producto</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab(2)}   className="nav-link" id="composiciones-tab" data-bs-toggle="tab" data-bs-target="#composiciones" type="button" role="tab" aria-controls="composiciones" aria-selected="false">Composiciones</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setTab(3)}   className="nav-link" id="imagenes-tab" data-bs-toggle="tab" data-bs-target="#imagenes" type="button" role="tab" aria-controls="imagenes" aria-selected="false">Imagenes</button>
                    </li>


                    { !formData.id && <li className="nav-item" role="presentation">
                        <button onClick={() => setTab(4)}   className="nav-link" id="inventario-tab" data-bs-toggle="tab" data-bs-target="#inventario" type="button" role="tab" aria-controls="inventario" aria-selected="false">Inventario</button>
                    </li>}

                </ul>
                
               
               
     
              
  
               
            
            
            
                { formData.id &&  <p className='alert alert-warning'> Edicion de Producto </p> }
                
                <form  onChange={handleChange} onSubmit={handleSubmit} method="POST">



                    {tab === 1 && 
                        <AddCampos 
                        deleteCodigo={deleteCodigo}
                        asignarMultiCodigo={asignarMultiCodigo}
                        formData={formData}
                        formDataValidations={formDataValidations}
                        manejarImpuestos={manejarImpuestos}
                        user={user}
                        setFormData={setFormData}
                        btnbmitLoad={btnbmitLoad}
                        />   
                    }
                    

                    {tab === 2 &&

                        <Composiciones setFormData={setFormData} formData={formData} ayudas={ayudas} setAyudas={setAyudas} />
                    }

                    {tab === 3 &&

                        <Imagenes setFormData={setFormData} formData={formData} ayudas={ayudas} setAyudas={setAyudas} />
                    }



                    {tab === 4 &&

                        <Inventario setFormData={setFormData} formData={formData} ayudas={ayudas} setAyudas={setAyudas} />
                    }




                    {/**<span className='btn btn-' type="button" disabled={estado_ajax} onClick={ocultarForm} > Cancelar    </span> */}

                    { tab === 1 && 
                        <>
                            {  !((estadoEdicion === 1 && editar === 1 )) &&  <button disabled={estado_ajax} type="submit"   className="btn-lg btn btn-success"  > 
                        
                                    { editar === 1 && <> Completar Edición </> ||  <> Guardar Nuevo Producto </>   }  
                            
                                {
                                    estado_ajax && 

                                    <span className="ms-2 spinner-border spinner-border-sm" aria-hidden="true"></span>
                                } 
                            
                            </button>}

                            { error && 

                                <div className='alert alert-danger mt-5'>
                                    <div className='alert alert-danger' dangerouslySetInnerHTML={{ __html: error }} />
                                </div>
                            } 

                        </>
                    }
                </form>
            </>
            
        );
    };

export default Add;
