import Tiempos from "./Tiempos";

export default class Estandar_24h extends Tiempos{


    /***
     *  
     * Descricion del Cobro
     * 
     * Este cobro inicia cobrado por hora.
     * Cuando el valor de las hora supera el valor de 12 horas.
     * Olvida el cobro por hora  
     *
     * 
     */
    constructor({fecha1,fecha2}){

        
      
        super(); 


        this.fecha1 = fecha1;

        this.fecha2 = fecha2;


        this.TARIFAS = {

            VALOR_12HORAS : 8000,

            VALOR_HORA : 1600,

            VALOR_MINIMO : 1600,
            
            VALOR_CUARTO_DE_HORA : 500,

        }


        this.OPCIONES_DE_TIEMPO = {

            /*** Se evalua esta variable para trasformar los minutos en una hora adicional */
            TRANFORMAR_HORA_DEPUES_DE_X_MINUTOS : 30,
        }


        this.COBROS = {

            VALOR12H : 0,
            

            VALOR_X_HORAS  : 0,


            ///En caso de que aplique
            VALOR_X_MINUTOS : 0,

        }
    }


    renderizado(){

        this.index()
    }
}