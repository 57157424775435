
export const    ListaPrecios  = ({id , valores} ) => {
    let data = valores.find((item) => item.id == id);
    return ( 
        <span class="badge ms-1 badge-phoenix badge-phoenix-dark"> 
            {data && data.nombre && data.nombre} 
        </span> )

}

export const    TipoEmpleado  = ({id , valores} ) => {

   

    let  clase = "success";
    let  nombre = "Cliente";
 

    if(id == 2){
        clase = "warning";
        nombre = "Proveedor";
    }

    if(id == 3){
        clase = "info";
        nombre = "Empleado";
    }


    return ( 
        <span class={'badge ms-1 badge-phoenix badge-phoenix-' + clase }> 
            {nombre} 
        </span> )

}