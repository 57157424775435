import React from 'react';
import DetalleTr from './DetalleTr';
import ComprobantesContablesTr from './ComprobantesContablesTr';
import TrSeparador from './TrSeparador';
import CuentasPorCobrar from './CuentasPorCobrar';
import CuentasPorPagar from './CuentasPorPagar';
import RecuperacionCartera from './RecuperacionCartera';
import ConsolidadoCaja from './ConsolidadoCaja';
import ReportedeEfectivo from './ReportedeEfectivo';

const ImprimirTurno = ({turno}) => {

	
    let infoTotales = {
        EFECTIVO_SISTEMA_ACTUAL : 0,
        efectivo_entrega : 0,
        efectivo_recibe : 0,
        TOTAL_CONSULTA_CONSOLIDADO : 0,
    }  
    const {  info , consolidado, cierre_parcial , resumen_comprobantes  } = turno;


	const filaConSeparacionStyle = {
		borderBottom: '1px solid #dee2e6' // Estilo del borde inferior
	  };


    return (
		<div id={'TurnoDetalleImprimir'}>

			<div className=' mt-2 shadow-sm p-3 mb-5 bg-body rounded'>
				<h2>Turno #{info.Turno_id }</h2>
				<table   className="table table-striped table-hover table-bordered table-xs"  >
					
					<DetalleTr info={info}  />
					
					<TrSeparador />

					<ComprobantesContablesTr resumen_comprobantes={resumen_comprobantes} />


					<TrSeparador />

					<CuentasPorCobrar resumen_comprobantes={resumen_comprobantes} /> 

					<TrSeparador />

					<CuentasPorPagar  resumen_comprobantes={resumen_comprobantes} /> 	
					

					<TrSeparador />

					<RecuperacionCartera resumen_comprobantes={resumen_comprobantes} />	

					<TrSeparador />

					<ConsolidadoCaja consolidado={consolidado} />

					<TrSeparador />
				</table>

				<ReportedeEfectivo info={info} />
				</div>	
		</div>
    );
};

export default ImprimirTurno;