import React, { useState } from 'react';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';


export const ListaCategorias = ({extraerCategoria}) => {
    const user = fetchUSer();
   
    const [categorias, setCategorias] = useState(user.DATABASE.objetos_categorias);

    const [descripcion , setDescricion] = useState();

    const handleCategoryClick = (categoria) => {
        extraerCategoria(categoria.id , descripcion)
    };

    return (
        <div className="container mt-4">
            
            <input value={descripcion} placeholder='Descripcion del Carro' onChange={(e) => setDescricion(e.target.value)}  />

            <div className="list-group">
                {categorias.map((categoria) => (
                    <button 
                        key={categoria.id} 
                        onClick={() => handleCategoryClick(categoria)}
                        className="list-group-item list-group-item-action"
                    >
                        {categoria.nombre}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ListaCategorias