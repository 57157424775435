import React, {createContext , useContext , useReducer } from "react";







export const actionTypeDocument = {

    SET_DOCUMENTO : 'SET_DOCUMENTO',
}


export const documentReducer = ( state , action ) => {
   
    // eslint-disable-next-line default-case
    switch(action.type){

       
        case actionTypeDocument.SET_DOCUMENTO:

            console.log("**Se Ejecuto es Despachador**")
            console.log( action.documento)
            if(action.documento.KEYDOCUMENT){
                console.log("ACTUALIZADO LOCAL STORE")
               localStorage.setItem( action.documento.KEYDOCUMENT.keyLocalStogere ,  JSON.stringify(action.documento) ); 
            }
                 
            return {...state , 
                documento : action.documento
            };

        default :
            return state;

    }
}









export const initialDocument = {

    documento : null,
}


export const ContextDocument = createContext();

export const DocumentProvider = ( { reducer , initialState , children }  ) => (
        <ContextDocument.Provider value={  useReducer(reducer, initialState)  } >
            {children}
        </ContextDocument.Provider>

)
export const useContextDocumentValue = () =>  useContext(ContextDocument);