import { __fecha } from "../utils/myApp";

export const __pluma7 = {
    // Componente para mostrar el estado de la factura
    
    EstadoFactura : ({ estado, masterId = 1 }) => {


        if (masterId == 0) {
            return <span>No aplica</span>;
        }

        
    
        let clase;
        let texto;
        let aux = parseInt(estado)
        let icon = "";
        // Asignar clase y texto según el estado
        switch (aux) {
        case 0:
            clase = 'badge-phoenix-danger';
            texto = 'Pendiente';
            icon = <i class="fa-solid fa-ban"></i>;    
            break;
        case 1:
            clase = 'badge-phoenix-success';
            texto = 'Pagado';
           
            icon = <i class="fa-solid fa-check"></i>;  
            break;
        case 3:
            clase = 'badge-phoenix-info';
            texto = 'Cruzado';
            break;
        default:
            clase = 'badge-phoenix-dark';
            texto = 'Desconocido';
        }

        // Devolver el elemento JSX con la clase y texto apropiados
        return <span className={`badge badge-phoenix ${clase}`}>{texto} {icon}</span>;
    },


    /*** Formatea el numero de factura. */
    numeroFactura : ({numero,id}) => {
        if( numero && numero.length > 0){
        return (<span><b>FACT#</b> {numero} </span>)
        }
        return (<span><b>Auto#</b>{id} </span>)
    
    },



     /*** Formatea el numero de factura. */
     NombreMasterID : ({master_id , icono  = true}) => {
        

        let  texto = "";
      

        if(master_id == 1){
            texto =  "INGRESO";
            icono =  <i className="fas fa-arrow-up"></i>;
        }

        if(master_id == 2){
            texto = "EGRESO";
            icono =  <i className="fas fa-arrow-up"></i>;
        }

        if(master_id == 0){
            texto = "NO APLICA";
           
        }
    
        return (
           <>
                {texto}
          
                {master_id == 1  &&  <span className="badge badge-phoenix badge-phoenix-success me-2 fs-8"><i className="fas fa-arrow-up"></i>  </span>}

                {master_id == 2 &&  <span className="badge badge-phoenix badge-phoenix-danger me-2 fs-8"><i className="fas fa-arrow-down"></i> </span>}

          
           </>
        )
    },


    turnoDesdeHasta  : (inicio , fin) =>  {

        if(inicio && fin){
            return (
                <>
                Desde {__fecha.formatoFecha({$fecha : inicio})}  
                <br />
                Hasta {__fecha.formatoFecha({$fecha : fin})}
                </>
            )
        }

       
        return (
            <>
                {__fecha.formatoFecha({$fecha : inicio})} 

                <div title="Turno en curso" className="ms-1  spinner-border" role="status">
                    <span className="visually-hidden"></span>
                </div>
            </>
        )
        
       

    },

    /** Recibe el nit de un cliente  y  el id del producto.*/
    pathfolderImgProductos : function (nit , id ) {
        
        return "app/public/uploads/" + nit + "/img/productos/" + id + "/";
    }



    
}