import React, { useState } from 'react';


function LogItems({indexx , fun}) {
    


    const [ placeholder , setPlaceholder ] = useState(null)
    
    
    function MouseOver(event) {
        if (!placeholder){
            let data = fun();
            let text = "";
            console.log(data)
            data.forEach( (prod, index) => {
                if (prod.index == indexx){
                    text +=  prod.notificacion + " - "
                }
            })
            setPlaceholder(text);
        } else{
            console.log("no consultado")
        }    
    }    




    function MouseOut(event){
        event.target.style.background="";
    }


    return (       
        <i title={placeholder} data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Tooltip on left"   onMouseOver={MouseOver} onMouseOut={MouseOut} style={{fontSize:'10px'}} class="fa-solid fa-lg text-warning fa-triangle-exclamation"></i>
    );
}

export default LogItems;