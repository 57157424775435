import React,{useEffect, useRef } from 'react';

import { useContextDocumentValue , actionTypeDocument } from "../context/ContextDocument.js";

import { DocumentL  } from "../../../components/Document/DocumentCore";
import { cargarInventarioItems, guardarHistoriasItemBorradosCuentaAbierta } from "../../Document/controlador.js";

import { useState } from 'react';
import DescuentoUnitario from './PartesItems/DescuentoUnitario.jsx';
import CambiarPrecioDocumento from './PartesItems/CambiarPrecioDocumento.jsx';
import LineaImpuestos from './PartesItems/LineaImpuestos.jsx';
import ActualizarPrecioDeVenta from "./PartesItems/ActualizarPrecioDeVenta";
import CambiarNomDescripcion from "./PartesItems/CambiarNomDescripcion";
import InforServicioOperador from "./PartesItems/InforServicioOperador";

import { NotificationManager} from 'react-notifications';

import ImpuestoGlobal from './PartesItems/ImpuestoGlobal.jsx';

import {getLocalstore} from '../../../Core/fetchLocalStoreData.js';

import { useStateValue } from '../../../context/StateProvider';

import EditarLinea from "./PartesItems/EditarLinea";
import StandarModal from "./StandarModal";


import {__app} from "../../../utils/myApp";

import {Propoper} from "../../../ComponentsHtml/Elements.jsx";
import AplicarDescuentoGlobal from '../PartesDocument/PartesItems/AplicarDescuentoGlobal';
import Vendedores from './Vendedores.jsx';

import {swtichNombreTr} from "./Controller/Items.js";
import LogItems from './PartesItems/LogItems.jsx';
import MesasSeleccion from './MesasSeleccion.jsx';
import { Button } from 'react-bootstrap';
import ListaComposiciones from './PartesItems/ListaComposiciones.jsx';
import ActualizarPrecioVentaCompra from './PartesItems/SubPartesItems/ActualizarPrecioVentaCompra.jsx';
import GananciaEnporcentaje from './PartesItems/SubPartesItems/GananciaEnporcentaje.jsx';
import UtilidadPrecio from './PartesItems/SubPartesItems/UtilidadPrecio.jsx';
import StockProductos from './PartesItems/SubPartesItems/StockProductos.jsx';
import ThPrecioCompra from './PartesItems/SubPartesItems/ThPrecioCompra.jsx';
import DisplayNumber from './PartesItems/DisplayNumber.jsx';
import VariantesIventario from './PartesItems/SubPartesItems/VariantesIventario.jsx';
import EditarCuentacontable from './PartesItems/SubPartesItems/EditarCuentacontable.jsx';
import CambiarPrecioDocumentoFinal from './PartesItems/CambiarPrecioDocumentoFinal.jsx';
import ClaveEliminarItem from './PartesItems/SubPartesItems/ClaveEliminarItem.jsx';


const  Items = ({activeTab,mostrarFormPagos , OcultarMostrarPanel , focoTeclado, setFocoTeclado})  => {

    const teclaComponente = 'F2';


    /******* USUARIO  ******/
    const [{ user} ] =  useStateValue();          

    const estadoApp =  user.CONFIGURACIONES_TABLE.estado_aplicacion == 1 ? 1 : null;

    /**** DOCUMENTO  */
    const [ {documento}  , dispatch ] = useContextDocumentValue();



    const LogicaDocumento  =  new DocumentL(documento , activeTab.keyLocalStogere , { dispatch, actionTypeDocument } , user  );


    LogicaDocumento.user = user;

  
    const operadores = getLocalstore('operadores');


 

    const [ showmodaleditarlinea ,  setShowmodaleditarlinea ] = useState(false);

   
    const handleBlur = ({indexx,valor, accion}) => {
        LogicaDocumento.despachador( accion , {
            indexx,valor        
        })   
    }


        
    const [ filaItem  , setfilaItem ] = useState(null);


    const botonF2 = useRef();
    const botonF9 = useRef();
    
    const botonmas = useRef();
    const botonmenos = useRef();
    const botonfoco = useRef();

    const botonMasuno = useRef();
    const botonMenosuno = useRef();
    const botonDelete = useRef();

    const $BtnclickFinalizar = useRef();
    

    const [ showLiquidacion , SetshowLiquidacion ] = useState(true);
    
  
    const showCamposContable  =   (documento.TIPO_DOCUMENTO.mostrar_cuenta_contable_directa && documento.TIPO_DOCUMENTO.mostrar_cuenta_contable_directa == 1) ? 1 : null  ;   


    const guardarDocumento = ({indexx,valor, accion}) => {



        //Variable de validacion
        let error_validacion = 0;


        

        /// obligar MEsa
        let obligar_seleccionar_mesa = 0;
        if(obligar_seleccionar_mesa == 1){
            
            
            /*** Si falta mesa colocar el foco en la mesa Directamente. */
            if( documento.FORM &&  documento.FORM.mesa_id &&  Number(documento.FORM.mesa_id) >  0 ){
                
                
                
            }else{
                NotificationManager.error('Selecciona una MESA', 'Mesa');
                OcultarMostrarPanel('venta');
                setFocusMesa(1);
                error_validacion = 1;
            }
        
        }






        if(error_validacion === 0){
            LogicaDocumento.guardarDocumento(user);
        }
    }

    /*** Adiferencia de limpiar los items, este boton genera un nuevo ID, 
     * Sera utilizado cuando tenemso una cuenta abierta cargada y queremos abrir otra
     */
    const limpiarDocumento100Porciento = () => {

        LogicaDocumento.cancelarCuentaActual();
    }




    const [showModalClaveItem , SetshowModalClaveItem ] = useState();


    /** Esta variable guardara los parametros de eliminar linea mientras optenemos la contraseña */
    const [auxDatosEliminarItem , SetauxDatosEliminarItem] = useState();
    const eliminarLinea = (indexx, item = null) => {
        let pasar = 1;
        if(user.CONFIGURACIONES_TABLE.PTV_habilitar_eliminar_item_co && user.CONFIGURACIONES_TABLE.PTV_habilitar_eliminar_item_co == 1 ){
            pasar = 0;
            SetauxDatosEliminarItem({indexx,item})
            SetshowModalClaveItem(true);
        }

        if(pasar === 1 ){
            eliminarLineaPasoFinal(indexx, item = null)
        }
    }



    const ConfirmarEliminarItem  = () =>{
        SetshowModalClaveItem(false);
        console.log(auxDatosEliminarItem);
        eliminarLineaPasoFinal(auxDatosEliminarItem.indexx , auxDatosEliminarItem.item);
        SetshowModalClaveItem(false);
    }


    const eliminarLineaPasoFinal = (indexx, item = null) => {
        //Historias de lineas Borras 
          if( item &&  item.fechaid !==   documento.fechaid ){
            /* En caso de ser un bar o restaurante. Se guardara un hisotrial de los items Sacado
            Esto es por temas de seguridad para identificar si no estan robando **/
            guardarHistoriasItemBorradosCuentaAbierta( item, documento , user );
        }
        LogicaDocumento.despachador( "eliminar_linea" , {
            indexx        
        })

        SetauxDatosEliminarItem(null);
    }

    const eliminarTodo=()=> {
        LogicaDocumento.despachador( "eliminar_todos_productos" )
    }
    

    const [ auxitem , setAuxitem ] = useState(null);

    const [ auxIndex , setAuxIndex] = useState(null);

    const [ focusMesa  , setFocusMesa ] = useState(null);

    const [ showModalVariantesInventario  , SetshowModalVariantesInventario ] = useState(null);
    

    const editarLinea=(item , index)=> {   
        
        setAuxIndex(index)
        setAuxitem(item);
        setShowmodaleditarlinea(true);
    }


    const editarVariantes=(item , index)=> {   

        setAuxIndex(index)
        setAuxitem(item);
        SetshowModalVariantesInventario(true);
    }

  
    
    const cancelarEdicionLinea = () => {
   
        
        setAuxIndex(null)
        setAuxitem(null);
        setShowmodaleditarlinea(false);
        
    }


    const completarEditarLinea = (item, indexx) => {
       
        LogicaDocumento.despachador( "editar_linea_item_completa" , {
            indexx,item        
        })   
        cancelarEdicionLinea();
    }


   
    /*** Alterna si mostramos un campo si o no en la table para desaturar */
    const showTD = (clave) => {
        if(user.CONFIGURACIONES.CAMPOS_A_MOSTRAR_LISTA.findIndex( (data) => data.clave == clave  ) >=  0){
            return true;
        }
        return false;
    }


    /** Maneja los Modelaes del la lista de items */
    const [showPropover , setShowPropover ] = useState();

    const comprobarNotificacion = (index) => {
        
        if(! documento.LOGS) return false;
        if(! documento.LOGS.producto) return false;
        let data =   documento.LOGS.producto.filter( (log) => log.index == index );
        if(data.length > 0){
            return 1;
        }
        return 0;
 
    }


    const finalizado = () => {

        var myElement = document.querySelector('.contenedor-items-only-items');
     
        myElement.scrollTop = 100000
    
    }
    
    useEffect(() => {
        finalizado();
        if(documento.ESTRAS.ultimoIndexDetonar == 1){
            detonarColor();
        }
    }, [documento.PRODUCTOS ])



    useEffect( () => {

        const handleKeyPress = (event) => {
            let data = null;
            console.log(event.key);

          
            
            switch (event.key) {
  
                case 'F2':
                    // Lógica para la tecla F2
                    
                    event.preventDefault();
                    botonF2.current.click();
                    break;

                    
                case 'ArrowUp':
                    botonmenos.current.click();
        
                break;


                case 'ArrowDown':
                   
                    botonmas.current.click();

                break;


                case '+':
                   
                    botonMasuno.current.click();

                break;


                case '-':
                   
                    botonMenosuno.current.click();

                break;


                case 'Delete':
                   
                    botonDelete.current.click();

                break;


                case 'F12':
                   
                    $BtnclickFinalizar.current.click();

                break;

                case 'F9':
                    // Lógica para la tecla F2
                    event.preventDefault();
                    botonF9.current.click();
                    break;

                default:
                break;
            }
          };
      
          
         

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            
            document.removeEventListener('keydown', handleKeyPress);
        };

    } , [] ) 

    const handleF2 = () => {
        // Lógica para la tecla F2
        console.log('Tecla F2 presionadaaa aa a a ');
        botonfoco.current.focus();
        console.log(documento);
        //setFocoTeclado('F2')
   
        let ultimo_id = (documento.PRODUCTOS.length) - 1;
        setfilaItem( ultimo_id );
        //let inputBuscador = document.getElementById("document_item_" + ultimo_id );  
    };

    const handleF9 = () => {

        
        if(focoTeclado === 'F2'){
            if(filaItem >= 0){
                if(user.CONFIGURACIONES_TABLE.switch_modo_precio && user.CONFIGURACIONES_TABLE.switch_modo_precio == 2 ){
                    let item = documento.PRODUCTOS[filaItem];
                    if(item){
                        let valor = 0;
                        if(item.PRECIO_PRINCIPAL == item.MATEMATICAS.PRECIO){
                            if(item.LISTA_PRECIOS &&  item.LISTA_PRECIOS[0] && item.LISTA_PRECIOS[0].precio){
                                valor = item.LISTA_PRECIOS[0].precio;
                            }else{
                                valor = item.PRECIO_PRINCIPAL;
                            }
                        }else{
                            valor = item.PRECIO_PRINCIPAL;
                        }         
                        let accion = "cambiar_precio_documento";
                        let indexx = filaItem;
                        handleBlur({indexx,valor, accion});
                    }
                   
                }
               
            }
        }
      
    };

    


    const detonarColor = () => {
        console.log(documento.PRODUCTOS);
        let id = 'TR_ITEMS_' +  documento.ESTRAS.ultimoIndex;  
        const el = document.getElementById(id);  
        if(documento.PRODUCTOS.length > 0){
            if(el){
                console.log( documento.ESTRAS.ultimoIndexCantidad ,  documento.ESTRAS.ultimoIndex  ,documento.PRODUCTOS[ documento.ESTRAS.ultimoIndex ].MATEMATICAS.CANTIDAD)
                if( documento.ESTRAS.ultimoIndexCantidad !=  documento.PRODUCTOS[ documento.ESTRAS.ultimoIndex ].MATEMATICAS.CANTIDAD){

                    el.classList.add("flash");
                }
            }else{
                console.log(id)
            }
            
        }


        setTimeout( () => {
            if(documento.PRODUCTOS.length > 0){
                let id = 'TR_ITEMS_' +  documento.ESTRAS.ultimoIndex;
                const el = document.getElementById(id);
                if(el){
                    el.classList.remove("flash");
                }          
            }
        },200 )
    }


    const getNotificacion = () =>{
        
        return documento.LOGS.producto
    }



    const TecladoUP = () => {
        if (focoTeclado == teclaComponente){

            if( (filaItem + 1) < documento.PRODUCTOS.length ){
                setfilaItem( prevCount => prevCount + 1 );
            }
        }  
        
    }

    const TecladoDown = () => {

        if (focoTeclado == teclaComponente){

            if( filaItem > 0){
                setfilaItem( prevCount => prevCount - 1);
            }
        }  
    }


    const Teclado_Mas = () => {
        
     
        if (focoTeclado == teclaComponente  ){      
            try {
                let cantidadActual = documento.PRODUCTOS[filaItem].MATEMATICAS.CANTIDAD;
                let valor = cantidadActual + 1;
                let accion = "aplicar_cambio_cantidad";
                let indexx = filaItem;
               handleBlur({ indexx , valor , accion});   
            } catch (error) {
                console.log(error)
            }    
        }else{

            if (focoTeclado == 'F1'  ){      
                try {
                    let cantidadActual = documento.PRODUCTOS[ documento.PRODUCTOS.length - 1 ].MATEMATICAS.CANTIDAD;
                    let valor = cantidadActual + 1;
                    let accion = "aplicar_cambio_cantidad";
                    let indexx = documento.PRODUCTOS.length - 1 ;
                   handleBlur({ indexx , valor , accion});   
                } catch (error) {
                    console.log(error)
                }    
            }  

        }  




    }
    const swtichNombreTrPrecio = key => key === "PRECIO_PRINCIPAL" ? "PrecioVta." : "Costo";



    const Teclado_Menos = () => {

        if (focoTeclado == teclaComponente){

            try {
                let cantidadActual = documento.PRODUCTOS[filaItem].MATEMATICAS.CANTIDAD;
                let valor = cantidadActual - 1;
               if(valor > 0  ){
                    let accion = "aplicar_cambio_cantidad";
                    let indexx = filaItem;
                    handleBlur({ indexx , valor , accion});   
               }

            } catch (error) {
                console.log(error)
            }  
        }else{

            if (focoTeclado == 'F1'  ){      
                try {
                    let cantidadActual = documento.PRODUCTOS[ documento.PRODUCTOS.length - 1 ].MATEMATICAS.CANTIDAD;
                    let valor = cantidadActual - 1;
                   
                    if(valor > 0  ){
                        let accion = "aplicar_cambio_cantidad";
                        let indexx = documento.PRODUCTOS.length - 1;
                        handleBlur({ indexx , valor , accion});   
                   }else{
                    eliminarLinea( documento.PRODUCTOS.length - 1  , documento.PRODUCTOS[documento.PRODUCTOS.length - 1] );
                   }
                } catch (error) {
                    console.log(error)
                }    
            }  

        }  
 
    }

    const [cargando , setCargando] = useState();

    const cargarstockInventario = async () => {
        setCargando(1);
        const productos = documento.PRODUCTOS.map( (data) => data.id );
        let items = await cargarInventarioItems(productos);
        if(items){
            setCargando(null);
        }else{
            setCargando(null);
        }
        LogicaDocumento.despachador('insertarStockItems' , items );
     }


    const TecladoDelete = () => {
        const ITEM_AUX = documento.PRODUCTOS[filaItem];


        if(user.USER.type_user_id  ==  1 
                                            
            || ( user.PERMISOS && user.PERMISOS.EliminarItemDocumento &&  user.PERMISOS.EliminarItemDocumento == 1 )   
            
            || (ITEM_AUX.fechaid === documento.fechaid ) 
        )   {

            if (focoTeclado == teclaComponente){
                try {
                    eliminarLinea( filaItem  , documento.PRODUCTOS[filaItem] );

                    setTimeout( () => {
      
                        //*** Si esta posicianado en el ultimo registro */
                        if ( documento.PRODUCTOS.length - 1  == (filaItem) ){
                            
                            setfilaItem(  prevCount => prevCount -  1 )
                        }
                  


                    } , 200)
    
                } catch (error) {
                    console.log(error)
                }  
            } 
        }else{
            alert("No tienes Permiso")
        }

 
    }

    


    const  [ fontSize , SetfontSize] = useState(12);
    


    ///Extrae las variantes del inventario y las agrega al docuemento *//
    const extraerVariantes = (variantes , cantidad) =>{


        SetshowModalVariantesInventario(false);
        if(cantidad ==  0){
            
            return false;
            
        }

        let indexx = auxIndex;
        
        LogicaDocumento.despachador( "cambiar_variante_item" , {
            indexx,variantes        
        })  



        /// Cambiar Cantidad
        let valor = cantidad;
        let accion = "aplicar_cambio_cantidad";
        handleBlur({ indexx , valor , accion});   

        
        setAuxIndex(null)
        setAuxitem(null);
    }
    
    return (
        <div  >
        
            <div  style={{ 'position': 'absolute',
                    'top': '-500px',
                    'height': '50px'}} >

                <h1>{focoTeclado}</h1>

                <button ref={botonmas} onClick={ TecladoUP   }> Arriba + </button>

                <button ref={botonmenos} onClick={ TecladoDown}> Abajo - </button>


                <button ref={botonMasuno} onClick={  Teclado_Mas   }>  +1 </button>

                <button ref={botonMenosuno} onClick={ Teclado_Menos }>  -1 </button>


                <button ref={botonDelete} onClick={ TecladoDelete}>  Delete </button>


                <button ref={botonF2} onClick={ handleF2}>  f12 </button>

                <button ref={botonF9} onClick={ handleF9 }>  F9 (cambiar precio) </button>


                <input type="text" ref={botonfoco}    />

                <h1> fila: {filaItem}</h1>

                <h1> Cantidad imtes : { documento.PRODUCTOS.length}</h1>

            </div>

            <StandarModal   title="Editar Item" desactivar={cancelarEdicionLinea}  mostrar={showmodaleditarlinea}>   
                <EditarLinea  Accion={completarEditarLinea}  item={ auxitem }  indexx={ auxIndex  } operadores={operadores} />  
            </StandarModal>    


         
       
            <div className=' mt-2   '>
            <div className='table-responsive contenedor-items-only-items '>
            <table className="table table-sm fs-9 mb-0 table-bordered ">
                    <thead>
                        
                        <tr className=''>
                            
                            {
                                (documento.TIPO_DOCUMENTO.LIQUIDABLE && documento.TIPO_DOCUMENTO.LIQUIDABLE == 1) && 
                                <>
                                    <th scope="col" className='table-info bg-primary-subtle' >                                        
                                        <ThPrecioCompra user={user} LogicaDocumento={LogicaDocumento}   documento={documento}  />
                                    </th>
                                    <th scope="col" className='table-success bg-primary-subtle' >Utilidad</th>
                                    <th scope="col" title='Click para Actualizar' className='bg-primary-subtle text-primary' >Stock 
                                
                                        { !cargando && <i onClick={cargarstockInventario}  className="fa-solid fa-arrows-rotate"></i> || <i className="fa-solid fa-arrows-spin fa-spin fa-spin-reverse"></i>}
                    


                                    </th>
                                </>
                            }

                            { showTD("key")  && <th scope="col" className='sort white-space-nowrap align-middle  text-center pe-3'>  {swtichNombreTr('key'  ) } </th>}
                           

                            { showTD("id")  &&  <th scope="col" className='sort white-space-nowrap align-middle  text-center pe-3'>  {swtichNombreTr('id'   ) } </th> }
                            
                          
                            { showTD("nombre")  &&  <th scope="col" className='sort white-space-nowrap align-middle text-center pe-3' style={{maxWidth:"150px"}}>  {swtichNombreTr('nombre'   ) } </th> }
                            

                            { showTD("descripcion")  &&  <th scope="col"  className='sort white-space-nowrap align-middle  text-center pe-3' > {swtichNombreTr( "descripcion"  ) } </th> }



                            { showCamposContable    &&  <th scope="col" className='sort white-space-nowrap align-middle text-center pe-3' style={{maxWidth:"150px"}}>  Cuenta contable  </th> }




                            { showTD("PRECIO")  &&  <th scope="col">  {swtichNombreTrPrecio( documento.TIPO_DOCUMENTO.KEY_PRECIO  ) } </th> }


                            {  showTD("SHOW_CAMBIO_PRECIO")  &&
                                <>
                                    { 
                                        documento.ESTRAS.SHOW_CAMBIO_PRECIO &&
                                        <th scope="col">  {swtichNombreTr(  "SHOW_CAMBIO_PRECIO"   ) }  <br /><span title='Actualizara los precios de venta seleccionados  en la Base de Datos ' className="badge rounded-pill text-bg-primary">Actualizar </span> </th>
                                    } 
                                </> 
                            }     
                            


                            { showTD("DESCUENTO_UNITARIO")  &&  <th scope="col">  {swtichNombreTr(  "DESCUENTO_UNITARIO"  ) } </th> }
                            
                            { showTD("DESCUENTO_UNITARIO_PORCENTUAL")  &&  <th scope="col">  {swtichNombreTr(  "DESCUENTO_UNITARIO_PORCENTUAL"  ) } </th> }
                            

                            { showTD("PRECIO_2")  &&  <th scope="col">  {swtichNombreTr( "PRECIO_2"  ) }</th> }

                            { showTD("IMPUESTOS")  &&  <th scope="col">  {swtichNombreTr("IMPUESTOS"  ) }   </th>    }

                            { showTD("IMPUESTO_UNITARIO")  &&  <th scope="col">  {swtichNombreTr( "IMPUESTO_UNITARIO"  ) } </th> }


                           


                            { showTD("CANTIDAD")  &&  <th scope="col"> {swtichNombreTr( "CANTIDAD"  ) } </th>}

                            { showTD("PRECIO_3")  &&  <th scope="col">  {swtichNombreTr(  "PRECIO_3"  ) }  </th> }

                            
                            { showTD("SUBTOTAL_1")  &&  <th scope="col"> {swtichNombreTr( "SUBTOTAL_1"  ) } </th>}

                            { showTD("DESCUENTO_LINEA")  &&  <th scope="col"> {swtichNombreTr( "DESCUENTO_LINEA"  ) }  </th>}

                          


                            { showTD("SUBTOTAL_2")  &&  <th scope="col"> {swtichNombreTr("SUBTOTAL_2"  ) }  </th>}

                            { showTD("IMPUESTO_LINEA")  &&  <th scope="col"> {swtichNombreTr("IMPUESTO_LINEA"   ) } </th>}

                            { showTD("SUBTOTAL_3")  &&  <th scope="col"> {swtichNombreTr("SUBTOTAL_3" ,    estadoApp ) }  </th>}

                            { showTD("OPCIONES")  &&  <th scope="col"> 
                            <div class="dropdown d-inline-block">
                            <span class="btn btn-link dropdown-toggle" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Opciones</span><span class="caret"> </span>
                            <div class="dropdown-menu dropdown-menu-end py-2" aria-labelledby="dropdownMenuButton">
           
                                <span class="dropdown-item"> 
                                    Tamño de Fuente <span className='btn btn-xs link' onClick={() => SetfontSize(fontSize  + 1 )} > + </span>  <span className='btn btn-xs link' onClick={() => SetfontSize(fontSize  - 1 )}> - </span> 
                                </span>
                              
                            </div>
                            </div>

                            </th>}

                        </tr>
                    </thead>
                    <tbody>
                        { documento.PRODUCTOS.map( (item , index ) =>  
                          
                            <tr className={index == filaItem &&  focoTeclado === 'F2' ?  "active-tr-item" : "hover-actions-trigger btn-reveal-trigger position-static"} id={'document_item_' + index } key={index}>
                                

                                {
                                 (documento.TIPO_DOCUMENTO.LIQUIDABLE && documento.TIPO_DOCUMENTO.LIQUIDABLE == 1)  && 
                                    <>
                                        <th scope="col" title='Este sera el Precio de venta Publico' className='text-success bg-primary-subtle' >                       
                                            <ActualizarPrecioVentaCompra  keyShow={'PRECIO'}  user={user} accionName={'cambiar_precio_documento_from_compra'}  item={item} indexx={index} accionF={handleBlur}  decimal={documento.ESTRAS.DECIMAL} documento={documento}  />
                                        </th>
                                        <th scope="col" className='bg-primary-subtle' > 

                                            <GananciaEnporcentaje fontSize={fontSize} item={item}  user={user} accionName={'cambiar_precio_documento_from_compra'}   indexx={index} accionF={handleBlur}  decimal={documento.ESTRAS.DECIMAL}  />
                                           
                                            <UtilidadPrecio item={item}  fontSize={fontSize} /> 
                                        </th>

                                        <th className={'bg-primary-subtle TR_DOCUMENT_ITEM_' + item.id } > <StockProductos  fontSize={fontSize} user={user} accionName={'set_stock_documento_ajax'}  item={item} indexx={index} accionF={handleBlur}  decimal={documento.ESTRAS.DECIMAL} ct_operacion_id={documento.TIPO_DOCUMENTO.ct_operacion_id} /> </th>
                                    </>
                                }
 

                                { showTD("key")  &&
                                    <td >
                                        <span className='btn' onClick={ () => console.log(item)} >ver</span>
                                        {index}
                                    </td>
                                } 


                                { showTD("id")  && 
                                    <td  className='order align-middle white-space-nowrap py-2' > <span style={{fontSize:fontSize-3}}>{item.id_2}</span> </td>
                                } 





                                  
                                       
                                { showTD("nombre")  && 
                                    <td className='order align-middle text-center white-space-nowrap py-2' style={{maxWidth:"300px"}} >
                                        
                                        <CambiarNomDescripcion  fontSize={fontSize} user={user}  accionName={'cambiar_nombre_linea_item'} keyShow={'nombre'} item={item} indexx={index} accionF={handleBlur}  />

                                        <InforServicioOperador item={item}  indexx={index} operadores={operadores} accionF={handleBlur}  /> 
                                        
                                        <ListaComposiciones item={item} />
                                        
                                    </td>


                                } 
                                
                                { showTD("descripcion")  && 
                                    <td className='order align-middle text-center white-space-nowrap py-2'>
                                        <CambiarNomDescripcion   user={user}  accionName={'cambiar_descripcion_linea_item'} keyShow={'descripcion'} item={item} indexx={index} accionF={handleBlur}  />
                                    </td>
                                } 



                                { showCamposContable  && 
                                    <td className='order align-middle text-center white-space-nowrap py-2'>
                                   
                                       <EditarCuentacontable  accionName={'cambiar_cuenta_contable'}  item={item} keyShow={'cuenta_contable'}  indexx={index} accionF={handleBlur}  />
                                    </td>
                                }



                                { showTD("PRECIO")  && 
                                    <td className='order align-middle white-space-nowrap py-2 text-center' > 
                                       
                       

                                        <CambiarPrecioDocumento  fontSize={fontSize} keyShow={'PRECIO'}  user={user} accionName={'cambiar_precio_documento'}  item={item} indexx={index} accionF={handleBlur}  decimal={documento.ESTRAS.DECIMAL} ct_operacion_id={documento.TIPO_DOCUMENTO.ct_operacion_id} /> 

                                        { !(documento.ESTRAS.SWITCH_LISTA_PRECIO && documento.ESTRAS.SWITCH_LISTA_PRECIO == 2  ) && 
                                           
                                            
                                           <>
                                            {documento.ESTRAS.SET_DEFAULT_PRECIO !== undefined && documento.ESTRAS.SET_DEFAULT_PRECIO == 0  ? 
                                                <>
                                                   
                                                    <ActualizarPrecioDeVenta fontSize={fontSize} item={item} user={user} ct_master_id={documento.TIPO_DOCUMENTO.ct_master_id} /> 
                                                </>
                                                : null
                                            }
                                            </>
                                      
                                        }  
                                    </td> 
                                } 
                                

                                { showTD("SHOW_CAMBIO_PRECIO")  &&  
                                    <>
                                        {  documento.ESTRAS.SHOW_CAMBIO_PRECIO &&             
                                            <td className='order align-middle text-center white-space-nowrap py-2'>
                                                
                                                <ActualizarPrecioDeVenta  item={item}  user={user}  /> 
                                            </td>
                                        }
                                    </>
                                }


                                { showTD("DESCUENTO_UNITARIO")  && 
                                    <td className='order align-middle white-space-nowrap text-center  py-2'>
                                        <DescuentoUnitario  fontSize={fontSize}   clasesDefault="text-danger" user={user} descuento={1} accionName={'aplicar_descuento_linea_precio'} keyShow={'DESCUENTO_UNITARIO'} item={item} indexx={index} accionF={handleBlur} decimal={documento.ESTRAS.DECIMAL}  documento={documento} />   
                                        {/*<DescuentoUnitario  clasesDefault="text-danger" user={user}  descuento={1} accionName={'aplicar_descuento_linea_porcentaje'} keyShow={'DESCUENTO_UNITARIO_PORCENTUAL'} item={item} indexx={index} accionF={handleBlur} /> */} 
                                    </td>
                                } 
                                
                                { showTD("DESCUENTO_UNITARIO_PORCENTUAL")  && 
                                    <td className='order align-middle white-space-nowrap  text-center py-2'>
                                      
                                        <DescuentoUnitario   fontSize={fontSize} clasesDefault="text-danger" user={user}  descuento={1} accionName={'aplicar_descuento_linea_porcentaje'} keyShow={'DESCUENTO_UNITARIO_PORCENTUAL'} item={item} indexx={index} accionF={handleBlur}  documento={documento} decimal={documento.ESTRAS.DECIMAL}  /> 
                                    </td>
                                }     
                         
                                
                                { showTD("PRECIO_2")  && 
                                    <td className='order align-middle white-space-nowrap text-center py-2'>
                                        <DisplayNumber fontSize={fontSize} number={__app.formato_decimal({total: item.MATEMATICAS.PRECIO_2 , decimal : documento.ESTRAS.DECIMAL })} />  
                                    </td>
                                } 

                                { showTD("IMPUESTOS")  && 
                                    <td className='order align-middle white-space-nowrap text-center py-2'>
                                        <LineaImpuestos  fontSize={fontSize}  user={user} impuestos={item.MATEMATICAS.IMPUESTOS} indexx={index} accionF={handleBlur}  />
                                    </td>
                                } 

                                { showTD("IMPUESTO_UNITARIO")  && 
                                    <td className='order align-middle white-space-nowrap  text-center py-2'>
                                        <DisplayNumber fontSize={fontSize} number={ __app.formato_decimal({total: item.MATEMATICAS.IMPUESTO_UNITARIO , decimal : documento.ESTRAS.DECIMAL}) }   /> 
                                    </td>
                                } 



                               

                                {/***Desde aqui */}
                                { showTD("CANTIDAD")  && 
                           
                                    <td  id={'TR_ITEMS_' + index} className='order  text-center align-middle white-space-nowrap py-2'  > 
                                       
                                        { 
                                            (item.fechaid !== documento.fechaid ) &&

                                            <>  <i className="font-s-candado  fa-lock"></i>  <DisplayNumber fontSize={fontSize} number={item.MATEMATICAS.CANTIDAD}  />    </> 
                                            || 
                                           
                                            <>  
                                                
                                                
                                                {/*** En caso del item ser variable no se puede modificar sus cantidades directamente */}
                                                { (item.if_variante && item.if_variante == 1) &&  
                                                    <span className='click link'  onClick={ () => editarVariantes(  item  , index )} >
                                                        Variantes( <i className="font-s-candado  fa-lock"></i>  <DisplayNumber fontSize={fontSize} number={item.MATEMATICAS.CANTIDAD}  />  )
                                                    </span>
                                                    
                                                    ||
                                                    
                                                    <DescuentoUnitario fontSize={fontSize} decimal={false}  user={user} accionName={'aplicar_cambio_cantidad'} keyShow={'CANTIDAD'} item={item} indexx={index} accionF={handleBlur} documento={documento}/> 
                                                } 
                                            </>

                                        }
                                    </td>
                                   
                                } 


                                
                                { showTD("PRECIO_3")  && 
                                    <td className='order align-middle text-center white-space-nowrap py-2' >
                                        
                                        <CambiarPrecioDocumentoFinal  user={user} accionName={'cambiar_precio_documento_subtotal_3'} keyShow={'PRECIO_3'} item={item} indexx={index} accionF={handleBlur}  /> 
                                
                                    </td>
                                } 


                                { showTD("SUBTOTAL_1")  &&
                                    <td className='order align-middle text-center white-space-nowrap py-2'>
                                        <DisplayNumber fontSize={fontSize} number={ __app.formato_decimal({total: item.MATEMATICAS.SUBTOTAL_1 , decimal : documento.ESTRAS.DECIMAL}) }    /> 
                                    </td>
                                }  
                                
                                


                                { showTD("DESCUENTO_LINEA")  &&
                                    <td className='order align-middle text-center  white-space-nowrap py-2'> 
                                        <DescuentoUnitario user={user}  descuento={1} accionName={'aplicar_descuento_total_linea_precio'} keyShow={'DESCUENTO_LINEA'} item={item} indexx={index} accionF={handleBlur} documento={documento} />           
                                    </td>
                                } 




                                { showTD("SUBTOTAL_2")  &&
                                    <td className='order align-middle text-center white-space-nowrap py-2' >
                                        <DisplayNumber fontSize={fontSize} number={ __app.formato_decimal({total: item.MATEMATICAS.SUBTOTAL_2 , decimal : documento.ESTRAS.DECIMAL}) } />
                                    </td>
                                } 



                                { showTD("IMPUESTO_LINEA")  &&
                                    <td className='order align-middle text-center white-space-nowrap py-2'>
                                        <DisplayNumber fontSize={fontSize} number={ __app.formato_decimal({total: item.MATEMATICAS.IMPUESTO_LINEA , decimal : documento.ESTRAS.DECIMAL}) } />
                                    </td> 
                                } 

                                {showTD("SUBTOTAL_3")  &&
                                    <td className='order align-middle text-center  white-space-nowrap py-2'>
                                       <DisplayNumber fontSize={fontSize} number= { __app.formato_decimal({"total" : item.MATEMATICAS.SUBTOTAL_3 , decimal : documento.ESTRAS.DECIMAL })}  />   
                                    </td> 
                                } 
                                {/*** HAsta aqui */}


                                {showTD("OPCIONES")  &&    
                                    <td className='order align-middle white-space-nowrap py-2'>
                                      
                                      
                              

                                        { 
                                         (user.USER.type_user_id  ==  1 
                                            
                                            || ( user.PERMISOS && user.PERMISOS.EliminarItemDocumento &&  user.PERMISOS.EliminarItemDocumento == 1 )   
                                            
                                            || (item.fechaid === documento.fechaid ) 
                                        )        
                                            &&  
                                            
                                            

                                            <span onClick={() => eliminarLinea( index  , item ) } className='btn btn-principal btn-xs m-2'> <i title="Eliminar Producto " className="fa fa-trash" aria-hidden="true"></i> </span>
                                        }
                                        
                                        { ( user.USER.type_user_id  ==  1 &&  (item.fechaid === documento.fechaid )  ) && <span onClick={() => editarLinea(  item  , index ) } className='btn btn-principal btn-xs'>  <i className="fa-solid fa-pen-to-square"></i> </span> }
                                       
                                        
                                        
                                        { comprobarNotificacion(index) &&  <span> <LogItems fun={getNotificacion} indexx={index} /> </span> || <span></span> }

                                    </td>
                                } 
                            </tr>
                        ) }
                    </tbody>
                    <tfoot>

                    </tfoot>
                </table>

                <div className='d-flex d-flex justify-content-end'>
                {
                    (user.PERMISOS.verBotonFacturarDocumento == 1 || user.USER.type_user_id == 1) && 
                    <>   
                         {focoTeclado != 'F2' && <button className={`kbc-button  ${focoTeclado === 'F2' ? 'active' : ''}`}>F2</button> }

                        
                        {focoTeclado == 'F2' && <>

                            <button className={`kbc-button kbc-button-xs` }><i className="fa fa-arrow-up" aria-hidden="true"></i></button>
                            <button className={`me-5  kbc-button kbc-button-xs` }><i className="fa fa-arrow-down" aria-hidden="true"></i></button>

                            <button className={`kbc-button kbc-button-xs` }><i className="fa fa-plus" aria-hidden="true"></i></button>
                            <button className={`kbc-button kbc-button-xs` }>Supr</button>
                            <button className={`kbc-button kbc-button-xs` }><i className="fa fa-minus" aria-hidden="true"></i></button>

                        </>}







                        <span className='d-none d-sm-block'></span>  
                        <span className='ms-5 mt-3 fw-medium d-none d-sm-block'>  Subtotal $<DisplayNumber fontSize={fontSize + 2} number= { __app.formato_decimal({total :  documento.MATEMATICAS.SUBTOTAL_1 , decimal : documento.ESTRAS.DECIMAL })} />  </span>
                        <span className='ms-5 mt-3 fw-medium '>  Descuento $<DisplayNumber fontSize={fontSize + 2} number={ __app.formato_decimal({total :  documento.MATEMATICAS.DESCUENTO_LINEA , decimal : documento.ESTRAS.DECIMAL })} />  </span>
                        <span className='ms-5 mt-3 fw-medium '>Neto$  <DisplayNumber fontSize={fontSize + 2} number={ __app.formato_decimal({total : documento.MATEMATICAS.SUBTOTAL_2 , decimal : documento.ESTRAS.DECIMAL })} /></span>                           
                        <span className='ms-5 mt-3 fw-medium d-none d-sm-block'> Impuestos $ <DisplayNumber fontSize={fontSize + 2}  number={ __app.formato_decimal({total : documento.MATEMATICAS.IMPUESTO , decimal : documento.ESTRAS.DECIMAL })}  /> </span>  
                        <span className='ms-5 mt-3 fw-medium '> Total   $ <DisplayNumber fontSize={fontSize + 2} number={ __app.formato_decimal({total :  documento.MATEMATICAS.SUBTOTAL_3 , decimal : documento.ESTRAS.DECIMAL })}   /> </span>    
                                        
                    </>
                }      
                </div>              
            
            </div> 
                
                
            
            <div className="input-group  d-block d-sm-none estiloPrecios">
                
                <button  data-bs-toggle="offcanvas" data-bs-target="#offCanvasMenuMovil" aria-controls="offCanvasMenuMovil" style={{width:'20%'} } className="btn-movil btn btn-primary" type="button"> <i className="fa-solid fa-bars"></i> </button>
               
                <button  onClick={eliminarTodo}  style={{width:'15%'} } className="btn-movil btn btn-danger" type="button"><i className="fa-solid fa-trash"></i></button>
                

                <button onClick={guardarDocumento} style={{width:'35%'} } className="btn-movil btn btn-principal" type="button">   $ { __app.formato_decimal({total :  documento.MATEMATICAS.SUBTOTAL_3,  decimal : documento.ESTRAS.DECIMAL})} </button>
                
                <button  style={{width:'10%'} } type="button" className="btn-movil btn btn-principal dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu">
                    <li>hola</li>
                </ul>
                    


                <button onClick={OcultarMostrarPanel} style={{width:'20%'} } className="btn-movil btn btn-dark" type="button"><i className="fa-solid fa-arrow-left"></i></button>
            </div> 
    
     
            
            <div className='d-none d-sm-block'>

            <div className='row fixed-bottom   pt-2 pb-2  bottom-0' style={{'marginBottom': '20px'}}> 
        
                <div  className={ 'col-sm-12 col-md-12 	col-lg-10	col-xl-8	col-xxl-8 col-0 bg-'  + documento.TIPO_DOCUMENTO.color_documento}   >

                    <div className='row'>
                        <div className='col-6 d-flex justify-content-between'>
                            <span className='mt-2'> {
                                (documento.PRODUCTOS.length > 0 && !documento.cuentAbiertaFirebaseId  ) &&  <span onClick={eliminarTodo} className='text-white me-2 mt-4  ms-2 link' >Limpiar </span>
                            }</span> 


                            <span className='mt-2'> {
                                documento.cuentAbiertaFirebaseId     &&  <span onClick={limpiarDocumento100Porciento} className='text-white me-2 mt-4  ms-2 link' > Nueva Cuenta </span>
                            }</span> 


                            { !(( user.PERMISOS.mesero  && user.PERMISOS.mesero   == 1  )) &&  <Propoper className="me-2 text-white" texto_boton="Descuento" showPropover={showPropover}  keyChange={activeTab} >
                                <AplicarDescuentoGlobal   accionCerrar={()=>setShowPropover(!showPropover)}  accionF={handleBlur}  />
                            </Propoper>}

                            {user.CONFIGURACIONES_TABLE.obligar_vendedor == 1 && <Vendedores guardarVendedor={handleBlur} formDocument={documento.FORM} />  }

                            {user.CONFIGURACIONES_TABLE.restaurante == 1  &&  
                                <>
                                    <MesasSeleccion  focusMesa={focusMesa} user={user} guardarMesa={handleBlur} mesaActual={documento.FORM} />

                                    { (user.USER.vendedor != 1 &&   user.CONFIGURACIONES_TABLE.habilitar_vendedor_x_pin &&  user.CONFIGURACIONES_TABLE.habilitar_vendedor_x_pin == 0 )  &&  <Vendedores  guardarVendedor={handleBlur} formDocument={documento.FORM} /> }
                                </>
                            }
                        </div>

                            
                        
                        <div className='col-6 d-flex justify-content-end'>
                        
                            {user.CONFIGURACIONES_TABLE.restaurante == 1 && 
                                <button 
                                className={ (!documento.cuentAbiertaFirebaseId) ? ' btn-principal me-2 btn ': ' btn-warning me-2 btn' }
                                onClick={guardarDocumento} > 
                                    {
                                        (!documento.cuentAbiertaFirebaseId) && <>Comandar</> || <>Editar+Comandar</>
                                    }  
                                </button> 
                            }

                            {(user.PERMISOS.verBotonFacturarDocumento == 1 || user.USER.type_user_id == 1) &&

                                
                                <div>
                                     { documento.MATEMATICAS.TOTAL_SALDO_FAVOR && documento.MATEMATICAS.TOTAL_SALDO_FAVOR > 0 &&   <div className='saldoFavorFlotante'>Saldo a favor :  $ { __app.formato_decimal({total :  documento.MATEMATICAS.TOTAL_SALDO_FAVOR , decimal : documento.ESTRAS.DECIMAL })}   </div> }
                                     <button ref={$BtnclickFinalizar} onClick={mostrarFormPagos}  className='me-2 btn btn-lg btn-principal  '>Continuar    $ <DisplayNumber fontSize={fontSize + 4 }  number={ __app.formato_decimal({total :  documento.MATEMATICAS.SUBTOTAL_3 , decimal : 1})}  />    
                                        <i className={`kbc-button kbc-button-xs` }>F12</i>
                                     </button>  
                                </div>
                                
                            } 
                                        
                        </div>
                    </div>

                </div>

            </div>
            </div>               


                   
           
        </div>



            <StandarModal  desactivar={() =>SetshowModalVariantesInventario(false)}   mostrar={showModalVariantesInventario}>   
                <VariantesIventario auxitem={auxitem}  extraerVariantes={extraerVariantes}  />  
            </StandarModal>


            <StandarModal  desactivar={() =>SetshowModalClaveItem(false)}   mostrar={showModalClaveItem}>   
                <ClaveEliminarItem  ConfirmarEliminarItem={ConfirmarEliminarItem}  />             
            </StandarModal>



        </div>
    );
}

 

export default Items;
