import { nanoid } from 'nanoid'


export const fetchUSer = () => {
    let  userInfo = localStorage.getItem('user') !== "undefined" ? 
    /* SI Esta Diferente de undefined, significa que esta lleno */
    JSON.parse(localStorage.getItem('user'))
    :
    null;
    
    return userInfo;
} 



export const fetchLocalconfig = () => {

    let  userInfo = localStorage.getItem('Df45G') !== "undefined" ? 
    /* SI Esta Diferente de undefined, significa que esta lleno */
    JSON.parse(localStorage.getItem('Df45G'))
    :
    null;
 
    if(!userInfo){
       
        let data = {

            global : {

                servidor : 'https://apipuntoventav1.nubep7.com/',

                dispositivo_id : nanoid(10),

            }
        }
        
        localStorage.setItem('Df45G' , JSON.stringify(data) )
        
        return data;
    }
 
    return userInfo;
} 



export const fetchNombreDispositivo = () => {
    // Obtiene el contenido de localStorage para la clave 'Df45G'
    let userInfo = localStorage.getItem('Df45G') !== "undefined" ? 
        JSON.parse(localStorage.getItem('Df45G')) : 
        null;

    // Si userInfo no existe, crea un nuevo objeto
    if (!userInfo) {
        userInfo = { global: {} };
    }

    // Si dispositivo_id no existe en userInfo.global, genera uno nuevo
    if (!userInfo.global.dispositivo_id) {
        
        userInfo.global.dispositivo_id = nanoid(10);

        // Guarda el nuevo contenido actualizado en localStorage
        localStorage.setItem('Df45G', JSON.stringify(userInfo));
    }

    // Retorna el dispositivo_id existente o recién creado
    return userInfo.global.dispositivo_id;
};



/** Importa los datos de configuracion basica */
export const getConfigBasicas = () => {
    
    let  Localconfig = localStorage.getItem('Df45G') !== "undefined" ?  JSON.parse(localStorage.getItem('Df45G')) : null;
    return Localconfig;
}

/*** Exporta los Datos de la Empresa de Local Store */
export const fetchEmpresa = () => {
    let  empresaInfo = localStorage.getItem('__Ah#d7aWF___') !== "undefined" ?  JSON.parse(localStorage.getItem('__Ah#d7aWF___')) : null;
    return empresaInfo;
}


export const getLocalstore = (store) => {
    
    let  Localconfig = localStorage.getItem(store) !== "undefined" ?  JSON.parse(localStorage.getItem(store)) : null;
    return Localconfig;
}

export const setLocalStore = (store , data ) => {
    localStorage.setItem(store , JSON.stringify(data) )
}




export const getNit = () => {
    let infoUser = JSON.parse( localStorage.getItem("__Ah#d7aWF___") );
    return infoUser.nit;
}

/*** Utiliza el servidor nombralo 
 * 
 *  oBligarURLConfig : Si esta variable esta positiva ignora 
 *  la configuracion de base de datos y toma la configuracion local
*/
export const getHostHttp = (oBligarURLConfig = 0) => {
    console.log(oBligarURLConfig)
    if(oBligarURLConfig == 0 ){
        let infoUser = JSON.parse( localStorage.getItem("__Ah#d7aWF___") );
        console.log(infoUser);
        if(infoUser && infoUser.httpserver && infoUser.httpserver.length > 5 ){
            console.log(infoUser.httpserver)
            return infoUser.httpserver;
        }
    }
    const localconfig   = fetchLocalconfig();
    return localconfig.global.servidor;
    
}











