import React, { useEffect, useState } from 'react';
import { __app } from '../../../../../utils/myApp';

const ItemCompuesto = ({ filtroPlato, filtroGrupoid, itemAll, itemActuales, agregardatos }) => {
    
    const agregardatosdes = (e, com) => {
        agregardatos(e, com);
    };

    const [itemAllCopia, setItemAllCopia] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if(itemAll && typeof itemAll === 'object' ){
            let CopyitemAll = itemAll.map((item) => {
                if (typeof item === 'object' ) {
                    item.CANTIDAD_DEFECTO = parseFloat(item.cantidad_composicion);
                    item.CANTIDAD_AGREGADA = parseFloat(item.CANTIDAD_AGREGADA) ? parseFloat(item.CANTIDAD_AGREGADA) : 1;
                    item.PRECIO_COMPUESTO_TOTAL = parseFloat(item.PRECIO_COMPUESTO_TOTAL) ? parseFloat(item.PRECIO_COMPUESTO_TOTAL) : item.PRECIO_COMPUESTO;
                } else {
                    console.log("no encontrado");
                }
                return item;
            });
            
            setItemAllCopia(CopyitemAll);
        }
    }, [itemAll]);

    const incrementQuantity = (productos_composicione_id) => {
        let index = itemAllCopia.findIndex(data => data.productos_composicione_id == productos_composicione_id  ) 
        const newItems = [...itemAllCopia];
        newItems[index].CANTIDAD_AGREGADA = parseFloat(newItems[index].CANTIDAD_AGREGADA) + 1;
        Totalizar(newItems, index);
    };

    const decrementQuantity = (productos_composicione_id) => {

     
     
        let index = itemAllCopia.findIndex(data => data.productos_composicione_id == productos_composicione_id  ) 
  
      
        const newItems = [...itemAllCopia];
        if (newItems[index].CANTIDAD_AGREGADA && newItems[index].CANTIDAD_AGREGADA > 1) {
            newItems[index].CANTIDAD_AGREGADA = parseFloat(newItems[index].CANTIDAD_AGREGADA) - 1;
            Totalizar(newItems, index);
        }
           
    };

    const Totalizar = (newItems, index) => {
        newItems[index].PRECIO_COMPUESTO_TOTAL = parseFloat(newItems[index].PRECIO_COMPUESTO) * parseFloat(newItems[index].CANTIDAD_AGREGADA);
        newItems[index].cantidad_composicion = parseFloat(newItems[index].CANTIDAD_DEFECTO) * parseFloat(newItems[index].CANTIDAD_AGREGADA);
        setItemAllCopia(newItems);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredItems = itemAllCopia.filter(item => 
        item.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <input
                type="text"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={handleSearch}
                className="form-control mb-3"
            />
            {itemAllCopia && (
                <ul className="list-group">
                    {filteredItems.map((com, index) => (
                        filtroPlato === com.auxRestauranteTipoMenu && (
                            (filtroGrupoid === com.productogrupo_id || !filtroGrupoid) && (
                                <li className="p-4 list-group-item" key={index}>
                                    <div>
                                        {index}
                                        <input
                                            onChange={(e) => agregardatosdes(e, com)}
                                            checked={itemActuales.findIndex((data) => data.id === com.id) >= 0}
                                            type="checkbox"
                                            role="switch"
                                            className="form-check-input me-1"
                                            id={`${com.id}_RTafirstCheckboxStretched${index}`}
                                        />
                                        <label className="ms-3 cursorPointer form-check-label" htmlFor={`${com.id}_RTafirstCheckboxStretched${index}`}>
                                            {com.nombre} - {com.productos_composicione_id}
                                        </label>
                                    </div>
                                    <span className='fs-7 float-end text-principal'>${__app.formato_decimal({ total: com.PRECIO_COMPUESTO_TOTAL })}</span>
                                    <span className='me-2'>descontar inventario: {com.cantidad_composicion}</span>

                                    {itemActuales.findIndex((data) => data.id === com.id) < 0 && (
                                        <div className='float-end'>
                                            <button 
                                                className='btn btn-outline-secondary me-2' 
                                                onClick={() => decrementQuantity(com.productos_composicione_id)}
                                            >
                                                <i className='fa fa-minus'></i>
                                            </button>
                                            <span className='me-2'>{com.CANTIDAD_AGREGADA}</span>
                                            <button 
                                                className='btn btn-outline-secondary' 
                                                onClick={() => incrementQuantity(com.productos_composicione_id)}
                                            >
                                                <i className='fa fa-plus'></i>
                                            </button>
                                        </div>
                                    )}
                                </li>
                            )
                        )
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ItemCompuesto;