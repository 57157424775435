import React from 'react';

const ListaComposiciones = ({item}) => {

    

    if (!item.COMPUESTO) return false;

    return (
        <div>
             <div >
                {item.COMPUESTO.map((pro,index) => 
            
                    <span key={index} className="badge bg-primary">{pro.nombre}
                    </span>
                                        
                )}
            </div>
        </div>
    );
};

export default ListaComposiciones;