import React from 'react';
import { __app } from '../../../../utils/myApp';

const ProductosCarta = ({documento , estilos , fontSize }) => {



    return (

        <div>              
            <table   cellSpacing="0" style={{ width: '100%', border: 'solid 1px black', background: '#F7F7F7', fontSize: fontSize.md + 'pt' }}>
                <colgroup>
                    <col style={{ width: '7%', textAlign: 'left' }} /> {/*Codigo */}
                    <col style={{ width: '30%', textAlign: 'left' }} /> {/*Descripción */}
                    <col style={{ width: '10%', textAlign: 'right' }} /> {/*Precio */} 
                    <col style={{ width: '5%', textAlign: 'center' }} /> {/*Ctd */} 
                    <col style={{ width: '10%', textAlign: 'center' }} /> {/*SubTotal */} 
                    <col style={{ width: '10%', textAlign: 'center' }} /> {/*Dcto */} 
                    <col style={{ width: '10%', textAlign: 'right' }} /> {/*Impuestos */}
                    <col style={{ width: '15%', textAlign: 'right' }} /> {/*Precio */}
                </colgroup>
                <thead>
                    <tr style={{ background: '#E7E7E7' }}>
                        <th style={{ borderBottom: 'solid 1px black', textAlign: 'left' }}>Codigo</th>
                        <th style={{ borderBottom: 'solid 1px black', textAlign: 'left' }}>Descripción</th>
                        <th style={{ borderBottom: 'solid 1px black', textAlign: 'right' }}>Precio Unitario</th>
                        <th style={{ borderBottom: 'solid 1px black', textAlign: 'center' }}>Ctd</th>
                        <th style={{ borderBottom: 'solid 1px black', textAlign: 'center' }}>SubTotal</th>
                        <th style={{ borderBottom: 'solid 1px black', textAlign: 'center' }}>Dcto</th>
                        <th style={{ borderBottom: 'solid 1px black', textAlign: 'center' }}>Impuestos</th>
                        <th style={{ borderBottom: 'solid 1px black', textAlign: 'right' }}>Precio Neto</th>
                    </tr>
                </thead>
                <tbody>
                    {documento.PRODUCTOS.map((pro, index) => (
                        <tr key={index} >


                            { /*** Codigo  */}
                            <td style={{  borderTop: 'solid 1px black' , textAlign: 'left' }}>{pro.id_2}</td>
                            
                            { /*** Nombre + Descripcion  */}
                            <td style={{borderTop: 'solid 1px black' ,  textAlign: 'left' }}> 
                                
                                <div style={{marginTop : '5px' , marginBottom : '2px'}}>
                                {pro.nombre}
                                <span style={{ fontSize: fontSize.xs + 'pt' ,  display: 'block', marginLeft: '15px', fontFamily: 'auto' }}>{pro.descripcion}</span>
                                </div>

                            </td>


                            { /*** Precio Unitario  */}
                            <td style={{borderTop: 'solid 1px black' , textAlign: 'right' }}>&#36; {__app.formato_decimal({total :pro.MATEMATICAS.PRECIO})} </td>
                            

                            { /*** Total Cantidad */} 
                            <td style={{borderTop: 'solid 1px black' , textAlign: 'center' }}>{pro.MATEMATICAS.CANTIDAD}</td>


                            { /*** Total sin Descuntos */} 
                            <td style={{borderTop: 'solid 1px black' , textAlign: 'center' }}>{__app.formato_decimal({total :pro.MATEMATICAS.SUBTOTAL_1})}</td>


                            { /*** Descuento */} 
                            <td style={{ borderTop: 'solid 1px black' , textAlign: 'center' , color : 'red' }}>  

                                <span style={{fontSize: fontSize.xs + 'pt' , display : 'block'}} > ({pro.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL.toFixed(1)}%)</span>
                                &#36;-{__app.formato_decimal({total :pro.MATEMATICAS.DESCUENTO_LINEA})} 

                            </td>

                            { /*** impuestos  */}
                            <td style={{ borderTop: 'solid 1px black' ,textAlign: 'center' }}>

                                {
                                    (pro.MATEMATICAS.IMPUESTOS && pro.MATEMATICAS.IMPUESTOS.length > 0) &&
                                    <> 
                                        {pro.MATEMATICAS.IMPUESTO && pro.MATEMATICAS.IMPUESTOS.map((impuesto, i) => (
                                            <span key={i}>
                                                {impuesto.nombre} {impuesto.importe}%
                                            </span>
                                        ))}
                                    </>
                                    ||
                                    <>
                                        0%
                                    </>
                                }
                            </td>
                            
                            { /*** TOTAL A PAGAR  */}
                            <td style={{ borderTop: 'solid 1px black' , textAlign: 'right' }}>&#36; {__app.formato_decimal({total :pro.MATEMATICAS.SUBTOTAL_3})}</td>
                       
                        </tr>
                    ))}





                    <tr>
                        <td style={{ borderTop: 'solid 1px black' , borderRight: '1px solid'  }} colSpan={6} rowSpan={5}  >
                            <b>Observaciones:  </b> <br/>{documento.FORM.observacion}
                        </td>
                        <td>
                            <tr style={{ background: '#E7E7E7' }}>
                                <th  style={{ borderTop: 'solid 0px black', textAlign: 'right' }}>Sub Total: </th>
                                <th  style={{ borderTop: 'solid 0px black', textAlign: 'right' }}>&#36; { __app.formato_decimal(  {total : documento.MATEMATICAS.SUBTOTAL_1 } )} </th>
                            </tr>
                            
                            <tr>
                                <th  style={{ borderTop: 'solid 0px black', textAlign: 'right',  }}>Descuento : </th>
                                <th  style={{ borderTop: 'solid 0px black', textAlign: 'right' ,  color : 'red'  }}>&#36; -{  __app.formato_decimal( {total :  documento.MATEMATICAS.DESCUENTO_LINEA }  )} </th>
                            </tr>

                            
                            <tr style={{ background: '#E7E7E7' }}>
                                <th  style={{ borderTop: 'solid 0px black', textAlign: 'right' }}>Impuestos : </th>
                                <th  style={{ borderTop: 'solid 0px black', textAlign: 'right' }}>&#36; {  __app.formato_decimal( {total :  documento.MATEMATICAS.IMPUESTO }  )} </th>
                            </tr>

                            <tr>
                                <th  style={{ borderTop: 'solid 0px black', textAlign: 'right' }}>Total  : </th>
                                <th  style={{ borderTop: 'solid 0px black', textAlign: 'right' }}>&#36;  { __app.formato_decimal({total : documento.MATEMATICAS.SUBTOTAL_3 + documento.MATEMATICAS.PROPINA })  }</th>
                            </tr>
                        </td>
                    </tr>

                   
                </tbody>
            </table>
        </div>
    );
};

export default ProductosCarta;