import React, { useEffect, useState } from 'react';
import { fetchEmpresa, fetchUSer } from '../../../../../Core/fetchLocalStoreData';
import { HttpGetEmpresa } from '../../../../user/css/Core/Core';
import ListarTipos from './ListarTipos';
import ListarOpereaciones from './ListarOpereaciones';
import { paginacionDocumentos } from '../../../controlador';
import TablaDocumentos from '../../../../Terceros/TerceroView/TablaDocumentos';
import ListarDocumentos from '../ListarDocumentos';
import ListarItemsSum from '../../../ListarItems/Pages';



const Pages = ({datosFiltro}) => {

    const user = fetchUSer();


    const [ empresa , SetEmpresa ] = useState(fetchEmpresa())

    



    useEffect(() => {
        
        if(!empresa.datos_precarga.comprobantes){
            getDatos();
        }else{
    
        }

   
    }, [])




    const  getDatos =  async () => {
       
        let hppresponse = await HttpGetEmpresa();
       
        if(hppresponse){
            SetEmpresa( fetchEmpresa() );
        }
    }
    

    const [modulo , setModulos] = useState();

    const [documentos , setDocumentos] = useState(null);

    const [itemssum , setItemssum] = useState(null);
    
    const handleClickSetMoudulo = (data) => {
        
        setModulos(data)
      
        console.log(data);
        if(data && data.id){
          
          // listarDocumentos(data.id);
        }else{

            setDocumentos(null);
        }
    }

    

    const ResultadoDatosfiltro = (data) => {
        console.log(data)
        setDocumentos(data);
    }

    const ResultadoDatosfiltroItems = (data) => {
        console.log(data)
        setItemssum(data);
    }

    


    return (
        <div>
            
            

            { !empresa.datos_precarga.comprobantes && <p className='alert alert-warning'>No hay comprobantes </p> }
         


            
            { (empresa.datos_precarga.comprobantes && !modulo) &&  <ListarOpereaciones accion={handleClickSetMoudulo}   operaciones={empresa.datos_precarga.comprobantes.operaciones} /> }


            { modulo && 
                <h1 className='fs-6 mt-3'> 
                    <span onClick={()=>handleClickSetMoudulo(null)} className='btn btn-link px-2 text-body copy-code-btn '> 
                        <i class="fa-solid fa-arrow-left"></i> Atras 
                    </span> 
                    MODULO DE  {modulo.nombre}  
                </h1>
            }

            { modulo &&  <ListarTipos empresa={empresa} modulo={modulo} datosfiltro={datosFiltro}  ResultadoDatosfiltro={ResultadoDatosfiltro}  ResultadoDatosfiltroItems={ResultadoDatosfiltroItems}  /> }

            
            

            { modulo &&<div className='card p-4'>
                <ListarDocumentos empresa={empresa} modulo={modulo} datosfiltro={datosFiltro} documentosaux={documentos}  /> 
            </div>}


            { modulo &&<div className='card p-4'>
              
                
                <ListarItemsSum  itemssum={itemssum}  /> 
            </div>}


            

                            
        </div>
    );
};

export default Pages;