import { NotificationManager} from 'react-notifications';
import { __app } from '../../utils/myApp';

const LINIA_INICIAL_MATEMATICAS = {

    
    /* El precio principal desnuedo de donde parte todo */
    PRECIO : 0,


    /**** Cantidad */
    CANTIDAD : 1,


    

    /* Descuento por cada unida del producto __POR EL USUARIO__ */ 
    DESCUENTO_UNITARIO : 0,


    /***  Descuento por cada imodad representado en porcentaje __ Por el usuario__  */
    DESCUENTO_UNITARIO_PORCENTUAL : 0,



    /**** Desucneto directo. tiene que estar en uno para tomar un descuento que no es porcentaje */
    DESCUENTO_DIRECTO  : 0,


    /** deido a que cada vez que nosotros aplicamos un descuento Manual, el sistema olvida el precio manual lo combierte a porcentaje
    * y apartir del porcentaje vuelve y saca el descuento maunal
    */
    IGNORAR_DESCUENTO_PORCENTAJE : 0,






    /**** impuesto por cada linea del producto   */
    IMPUESTO_UNITARIO  : 0,


    IMPUESTOS : [],


    /** Gran de la total de la linea   */
    SUBTOTAL_3 : 0

}


const LINIA_INICIAL_DOCUMENTO = {
    CAMBIO_DE_PRECIO : false
}





export default class Matematicas {

    constructor( extraDocuemto = null ){
        
        console.log("CLASE Matemariacas Instanciado");

        /* Esta Variable se encargara de guardara los comportamientos del documento a medida que se le aplican
        Cambios, descuentos , etc */
        this.logs = {

            /* guardara las notificaciones de items **/
            producto : [],

            /* Guardara las notificaciones del docuemento +*/
            documento : []
        } 

        this.producto  = {};


        this.productoIndex = null;


        

        this.MATEMATICAS = {


            /* El precio principal desnuedo de donde parte todo */
            PRECIO : 0,


            /**** Cantidad */
            CANTIDAD : 1,

            /* Descuento por cada unida del producto __POR EL USUARIO__ */ 
            DESCUENTO_UNITARIO : 0,


            /** deido a que cada vez que nosotros aplicamos un descuento Manual, el sistema olvida el precio manual lo combierte a porcentaje
            * y apartir del porcentaje vuelve y saca el descuento maunal
            */
            IGNORAR_DESCUENTO_PORCENTAJE : null,


            /**** impuesto por cada linea del producto   */
            IMPUESTO_UNITARIO  : 0,

        
            PROPINA : 0, 


            /** Gran de la total de la linea   */
            SUBTOTAL_3 : 0
        }


    }


    



    /**** Primer formato donde se asigna las variables principales */
    primerFormato = (items , {cantidad, PRECIO }) => {
        
        LINIA_INICIAL_MATEMATICAS.CANTIDAD = this.FN(cantidad);
        const matematicas  =   this.clonarObjeto(LINIA_INICIAL_MATEMATICAS);
        

        if  ( PRECIO === undefined){

            matematicas.PRECIO =   this.extraerPrecio(items);
        }else{
   
            matematicas.PRECIO = PRECIO;
            
        }
        const documento    =   LINIA_INICIAL_DOCUMENTO;
        return {matematicas ,documento };
    }


    /*** Extrae el PRECIO. YA se el COSTO o PRECIO DE VENTA */
    /*** Tambien Alterna entre Lista de Precio porcentaje  y Precio directo */
    extraerPrecio = (items) => {
        return items[ this.KEY_PRECIO ];
    }



    /** Es utilizada para crear el precio desde Matemaricas  */
    devolverDocumento = () => {
        
        return this.DOCUMENT;
    }




    /** Es utilizada para crear el precio desde Matemaricas  */
    crearUSUARIO = (USUARIO ) => {

        this.DOCUMENT = null;

        this.USUARIO = USUARIO;
    }
    




    compraPreciosSwitch = (infoPrecioid) => {

 

        this.DOCUMENT.PRODUCTOS = this.DOCUMENT.PRODUCTOS.map((producto, productoIndex) => {
          
            producto.COMPRA =  this.compraPreciosSwitch2(producto, infoPrecioid)
            return producto;
        })

    }


    compraPreciosSwitch2 = (producto, infoPrecioid) => {
        
        let nuevaLista =  (producto.COMPRA && producto.COMPRA.lista_precio) ? producto.COMPRA.lista_precio : [];    
            
        /*** Este sera el precio Actual que usuario vera.  Mientras se liquida */
        let precio_actual = 0;
     

        /**  */
       if(infoPrecioid != 0){
         
            console.log(infoPrecioid)
            console.log(producto.LISTA_PRECIOS)


            let listaProducto = producto.LISTA_PRECIOS.find(itemm => itemm.ct_lista_precio_id == infoPrecioid);


            if (listaProducto) {

                /** Evita que se repita la lista. Verifica que ya existe antes de agregar. */
                let actual = nuevaLista.find(item => item.ct_lista_precio_id === listaProducto.ct_lista_precio_id)
                if (!actual) {

                    nuevaLista = [...nuevaLista, listaProducto];
                    precio_actual = listaProducto.precio;
                }else{

                    precio_actual = actual.precio;
                }    
            }else{

                precio_actual = producto.PRECIO_PRINCIPAL;
            }
        }else{
       
            let actual = nuevaLista.find(item => item.ct_lista_precio_id == 0)
            if(!actual){           
                
                let  aux  = {

                    "ct_lista_precio_id": "0",
                    "precio":  producto.PRECIO_PRINCIPAL,
                }

                nuevaLista = [...nuevaLista, aux];
                precio_actual = producto.PRECIO_PRINCIPAL;
            }else{
                precio_actual = actual.precio;
            }
            
                
            
        }
      
        return  {
            
            precio_actual : precio_actual,
            lista_precio : nuevaLista
        }

        
    }

    

    /*** Extraer TOTAL */
    renderizarTotal = (nombre_funcion = null) => {

        this.log("---Iniciando la Funcion --- : renderizarTotal ------");

        /// Solo para controlar el color de la cantidad
       if(nombre_funcion == 'insertarItem'){
            this.DOCUMENT.ESTRAS.ultimoIndexDetonar =  1 ;
       }else{
        this.DOCUMENT.ESTRAS.ultimoIndexDetonar =  0 ;
       }

       this.DOCUMENT.FORMAS_DE_PAGO = null;

       this.DOCUMENT.ESTRAS.DECIMAL =  this.DOCUMENT.TIPO_DOCUMENTO.DECIMAL ? this.DOCUMENT.TIPO_DOCUMENTO.DECIMAL : 0;



        this.DOCUMENT.MATEMATICAS.SUBTOTAL_1         =  0;
        this.DOCUMENT.MATEMATICAS.SUBTOTAL_2         =  0;

        
        this.DOCUMENT.MATEMATICAS.DESCUENTO_LINEA     =  0; 
        this.DOCUMENT.MATEMATICAS.IMPUESTO            =  0; 
        this.DOCUMENT.MATEMATICAS.IMPUESTOS           =  []; 

        this.DOCUMENT.MATEMATICAS.SUBTOTAL_3           =  0;

 



        
        this.DOCUMENT.ESTRAS.SHOW_CAMBIO_PRECIO = false;

        this.log("Documento de entrada");
        this.log(this.DOCUMENT);
        
        this.DOCUMENT.PRODUCTOS = this.DOCUMENT.PRODUCTOS.map((producto, productoIndex) => {




            producto.MATEMATICAS.VALOR  = producto.MATEMATICAS.PRECIO;

            
            /**  Si existe un descuento GLOBLA: lo guardaremos como unitrario. De lo contrario matenemos el de la linea */
            producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL = this._____comprobarDescuentoGlobal_O_desuentoLista( producto , productoIndex );
          
            
    
            /*** Formateando el Descuento  */
            if (  this.___validarSiPermiteDescuento(producto , productoIndex)  ){
                
                
                /**  Si estamos ignorando el descuneto O hay un descuento global, debemos aplicar descuento */
                if ( !producto.MATEMATICAS.IGNORAR_DESCUENTO_PORCENTAJE || this.DOCUMENT.ESTRAS.OBLIGAR_DESCUENTO_GLOBAL  == 1){
                    const descuento_precio =   this.transformarPorcentaje(  producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL ,  producto.MATEMATICAS.PRECIO );
                    producto.MATEMATICAS.DESCUENTO_UNITARIO =  descuento_precio;
                }

            


                producto.MATEMATICAS.DESCUENTO_LINEA    =  producto.MATEMATICAS.DESCUENTO_UNITARIO *  producto.MATEMATICAS.CANTIDAD;
               

            }else{
                
                producto.MATEMATICAS.DESCUENTO_UNITARIO = 0;
                producto.MATEMATICAS.DESCUENTO_LINEA    = 0;
                producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL = 0;
            }
            
            






            /*** El Subtotal 1 es El precio por la cantidad sin ningun tipo de descuento  */
            producto.MATEMATICAS.SUBTOTAL_1 = (  producto.MATEMATICAS.CANTIDAD *  producto.MATEMATICAS.PRECIO );
            



            /** El precio 2 del produucto es basucamente el precio real, 
             *  Este precio es unitario
             * Precio real de venta sin impuesto por unidad
             */
            producto.MATEMATICAS.PRECIO_2  = producto.MATEMATICAS.PRECIO  - producto.MATEMATICAS.DESCUENTO_UNITARIO;






            /***** SUBTOTAL_2: Hace referencia al total aplicando el descuento ****/
            producto.MATEMATICAS.SUBTOTAL_2  = (  producto.MATEMATICAS.CANTIDAD *  producto.MATEMATICAS.PRECIO_2 );



            /*** El Array de todos los Impuestos */
            producto.MATEMATICAS.IMPUESTOS = this._____sumarImpuestoLineal(producto);



            /*** Impuesto Por Linea + Impuesto total   */
            let infoImpuesto   = this._____sumarImpuestoLineal_2(producto.MATEMATICAS.IMPUESTOS);
            producto.MATEMATICAS.IMPUESTO_UNITARIO  =  infoImpuesto.impuesto_unitario;
            producto.MATEMATICAS.IMPUESTO_LINEA     =  infoImpuesto.impuesto_total





            /** El precio 2 del produucto es basucamente el precio real ya con el impuesto, 
             *   PRECIO - DESCUENTO + IMPUESTO 
             */
            producto.MATEMATICAS.PRECIO_3  =   producto.MATEMATICAS.PRECIO_2 +  producto.MATEMATICAS.IMPUESTO_UNITARIO;
            producto.MATEMATICAS.PRECIO_3  =   this.FN( producto.MATEMATICAS.PRECIO_3 , this.DOCUMENT.ESTRAS.DECIMAL );
           
            
            /*** El TOTAL A PAGAR POR LINEA  */    
            producto.MATEMATICAS.SUBTOTAL_3  =   producto.MATEMATICAS.SUBTOTAL_2 +  producto.MATEMATICAS.IMPUESTO_LINEA


            producto.MATEMATICAS.SUBTOTAL_3  =  this.FN( producto.MATEMATICAS.SUBTOTAL_3 , this.DOCUMENT.ESTRAS.DECIMAL );

            this.SUMAR_TOTAL();




















            /*******************  DATOS EXTRAS PARA LA MANIPULACON DE LA VISTA   */
            producto.DOCUMENTO.CAMBIO_DE_PRECIO  = this._____switchCambioPrecio(producto); 

            /** Indicamos generalmente de que hay un producto que tiene el precio cambiado */
            this.DOCUMENT.ESTRAS.SHOW_CAMBIO_PRECIO = (  producto.DOCUMENTO.CAMBIO_DE_PRECIO ) ?  producto.DOCUMENTO.CAMBIO_DE_PRECIO : this.DOCUMENT.ESTRAS.SHOW_CAMBIO_PRECIO;
            




            /******** SUMAR TOTAL GLOBAL  */

            this.DOCUMENT.MATEMATICAS.SUBTOTAL_1         +=  producto.MATEMATICAS.SUBTOTAL_1;
            this.DOCUMENT.MATEMATICAS.SUBTOTAL_2         +=  producto.MATEMATICAS.SUBTOTAL_2;
            this.DOCUMENT.MATEMATICAS.DESCUENTO_LINEA    +=  producto.MATEMATICAS.DESCUENTO_LINEA;
            this.DOCUMENT.MATEMATICAS.IMPUESTO           +=  producto.MATEMATICAS.IMPUESTO_LINEA;     
            this.DOCUMENT.MATEMATICAS.SUBTOTAL_3         +=  producto.MATEMATICAS.SUBTOTAL_3;

            
            this._____calcularImpuestos(producto);

            return  producto;
       })    
       

       if   ( !this.DOCUMENT.MATEMATICAS.TOTAL_SALDO_FAVOR   ){

            this.DOCUMENT.MATEMATICAS.TOTAL_SALDO_FAVOR = 0;
       }



       this.DOCUMENT.MATEMATICAS.SUBTOTAL_1         = this.FN(this.DOCUMENT.MATEMATICAS.SUBTOTAL_1);
       this.DOCUMENT.MATEMATICAS.SUBTOTAL_2         = this.FN(this.DOCUMENT.MATEMATICAS.SUBTOTAL_2);
       this.DOCUMENT.MATEMATICAS.DESCUENTO_LINEA    = this.FN(this.DOCUMENT.MATEMATICAS.DESCUENTO_LINEA);
       this.DOCUMENT.MATEMATICAS.IMPUESTO           = this.FN(this.DOCUMENT.MATEMATICAS.IMPUESTO);
       this.DOCUMENT.MATEMATICAS.SUBTOTAL_3         = __app.redondearAlMultiploDe100FINAL_SUBTOTAL_3(this.DOCUMENT.MATEMATICAS.SUBTOTAL_3);
       
       


       this.DOCUMENT = {...this.DOCUMENT, LOGS : this.logs }
       
       this.log("Documento de salida")
       this.log( this.DOCUMENT);
       this.log("---Finalizando  la Funcion --- : renderizarTotal ------");
    }





    /*** Valida un producto si permite el descuento 
     *  
     * 1) Si el producto esta permitido el descuento Directamente.
     * 
     * 2) Si no hay una lista de precio activada
     * 
     * 3) El precio es el minimo permitido
     * 
     * 4) No supera el descuento establecido por el usuario  
     * 
     ******/    
    ___validarSiPermiteDescuento(producto , index){
        
        let  notificar = true;
        
  
        console.log(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL);
        console.log(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL);
        console.log(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL);
        console.log(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL);
        console.log(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL);


        if(Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) < 0 ){

            producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL = Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) * -1;
        }



        /* Si no hay descuento evitamos toda esta logica **/
        if ( !Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) > 0 ){
            

            let a =  ["El descuento no es  > que  0 " ];
            this.logs.documento = [...this.logs.documento , a];

            return false;
        }


         /* Si no hay descuento evitamos toda esta logica **/
         if ( Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL)  >= 100 ){

            let x = {index : index , notificacion : 'El descuento supera al limite permitido que es 100% por lo tanto no se aplico'  };
            this.logs.producto = [...this.logs.producto , x];  
            return false;
        }


        /*Se puede aplicar todo el descuento this.USUARIO.USER.type_user_id == 1 **/
        if(   Number(this.USUARIO.USER.type_user_id) == 1  ) {
            let a =  ["Se aplico el descuento al producto " + producto.id + "   / Motivo   Administrador" ];
            this.logs.documento = [...this.logs.documento , a];
            return true;
        }

        
        /* Si no hay descuento evitamos toda esta logica **/
        if (   this.USUARIO.PERMISOS.BloquearDescuentos  && this.USUARIO.PERMISOS.BloquearDescuentos  ==  1  ){
            let x = {index : index , notificacion : 'Este usuario en especifico tiene los descuentos bloqueados para todo.'  };
            this.logs.producto = [...this.logs.producto , x];  
            return false;
        }




        /*  Que el producto se le permite descuento */ 
        if(  Number(producto.permitir_descuento) == 0  ) {             
           let x = {index : index , notificacion : 'El Producto  #'+  producto.id + ' Se Encuentra bloqueado para realizar descuento. Si desea aplicar descuento a este producto debe desbloquarlo desde su Perfil del producto.'};
           this.logs.producto = [...this.logs.producto , x];
           return false;
        }

        if(  Number(producto.MATEMATICAS.PRECIO) <  Number(this.USUARIO.CONFIGURACIONES_TABLE.limite_descuento) ) {
           
            let x = {index : index , notificacion : 'El Producto  #'+  producto.id + ' No se le puede hacer descuento debido a que no supera el valor Permitido..El valor permitido para los descuentos es de : Mayor a $' + this.USUARIO.CONFIGURACIONES_TABLE.limite_descuento };
            this.logs.producto = [...this.logs.producto , x];


            let y = {index : index , notificacion : producto.MATEMATICAS.PRECIO  + "<" + this.USUARIO.CONFIGURACIONES_TABLE.limite_descuento  + "Verdad"};
            this.logs.producto = [...this.logs.producto , y ];
            return false;
        }
      
   

        if ( Number(this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo)   <  Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL ) ){
              
           

            if(  this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA == 0) {
                let x = {index : index , notificacion :' El descuento minimo Autorizado es de :' + this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo + '% '  };
                this.logs.producto = [...this.logs.producto , x];
            }

            /** Guarda el porcentaje Maximo de la lista */
            if(  Number(this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA) >  0) {
                let porcentajeMaximo = Number(this._____extraerPorcentajeListaPrecio( producto));

                if( porcentajeMaximo < 0 ) porcentajeMaximo = porcentajeMaximo * -1;
                
               
                if ( Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) >  porcentajeMaximo ){
                    let x = {index : index , notificacion : 'No puedes Superar el descuento para Esta lista de precio Activa :' + porcentajeMaximo  + '% '  };
                    this.logs.producto = [...this.logs.producto , x];  
                    return false;
                }
            }
        }




        console.log( Number(this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo),">" ,   Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) )
        console.log( Number(this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo),">" ,   Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) )
        console.log( Number(this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo),">" ,   Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) )
        console.log( Number(this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo),">" ,   Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) )
       
        /*
        if( Number(this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo) < Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) ){
            console.log(this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA)
            
            
            if ( this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA || this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA == 0){
                let x = {index : index , notificacion : 'No puedes descontar' };
                this.logs.producto = [...this.logs.producto , x];
                return false;
            }
        }else{
            this.log("no")
        }*/


        if(Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) > Number(this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo) ){
            
            if(this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA &&  this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA > 0){

                if(Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) > Number(this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo) ){ 


                    let porcentajeMaximo = Number(this._____extraerPorcentajeListaPrecio( producto));

                    if( porcentajeMaximo < 0 ) porcentajeMaximo = porcentajeMaximo * -1;
                    
                   
                    if ( Number(producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL) >  porcentajeMaximo ){
                        let x = {index : index , notificacion : 'No puedes Superar el descuento para Esta lista de precio Activa :' + porcentajeMaximo  + '% '  };
                        this.logs.producto = [...this.logs.producto , x];  
                        return false;
                    }

                }

            }else{
                let x = {index : index , notificacion : "Es descuento de este producto Supera el minimo permitido"}; 
                this.logs.producto = [...this.logs.producto , x];
                return false;
            }
            
        }
     

        if  (Number(this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA)  > 0 && Number(this.DOCUMENT.ESTRAS.SWITCH_LISTA_PRECIO) == 2){
            let x = {index : index , notificacion : "No puedes aplicar descuento  mientras hay una lista de precios este activa"};  
            this.logs.producto = [...this.logs.producto , x];    
            return false;
        }


        return true;


    }

    _____extraerPorcentajeListaPrecio(  producto){
        //LISTA_PRECIOS
        if (producto.LISTA_PRECIOS){
            const listaprecio  = producto.LISTA_PRECIOS.find(lista => (lista.ct_lista_precio_id  ==  this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA  ) );
            return listaprecio.precio;
        }

        return this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo;
    }



    /***** Agrega los impuestos de un producto a las matematicas */
    _____sumarImpuestoLineal = (producto) => {
        console.log(this.DOCUMENT.TIPO_DOCUMENTO);
        
        if( this.DOCUMENT.TIPO_DOCUMENTO.mover_impuestos == 0  ) { 
            return null;
        }   

        try {
            if( !(producto.IMPUESTOS_RAIZ.length  >  0)  ){
                return null;
            }  
        } catch (error) {
            return null;
        }

        let copiaImpuesto = this.clonarObjeto(producto.IMPUESTOS_RAIZ);

		copiaImpuesto  =  copiaImpuesto.map( (im , index ) => {

			im.IMPUESTO_UNITARIO            =      (producto.MATEMATICAS.PRECIO_2 * im.importe) / 100 ;
			im.TOTAL_IMPUESTO               =      im.IMPUESTO_UNITARIO * producto.MATEMATICAS.CANTIDAD;
            im.BASE_IMPUESTOS               =      producto.MATEMATICAS.SUBTOTAL_2 ;	
            return im;
        })
        return copiaImpuesto;
    }

    /** Suma los impuestos de una linea,  */
    _____sumarImpuestoLineal_2(impuestos){
        
        let  impuesto_unitario = 0;
        let impuesto_total = 0;

        if(impuestos){
            impuestos.map( (impuesto,index) => {
                impuesto_unitario += impuesto.IMPUESTO_UNITARIO;
                impuesto_total += impuesto.TOTAL_IMPUESTO;
            });
        }
        
        return { impuesto_total , impuesto_unitario };
    }




    /*** Comprubea Si hay un descuento porcentual Activado. Y trata de Asignarlo 
     * a un producto. SIEMPRE Y CUANDO ESTE NO TENGA UN Descuento Predefinido
     */
    
    /*** Hay que mirar Si hay que obligar o no */
    _____comprobarDescuentoGlobal_O_desuentoLista(pro , productoIndex ) {
        
        this.log(this.DOCUMENT.ESTRAS.OBLIGAR_DESCUENTO_GLOBAL)

        /*** Damos prioridad si existe un descuento directo */
        if( pro.MATEMATICAS.DESCUENTO_DIRECTO &&  pro.MATEMATICAS.DESCUENTO_DIRECTO === 1  &&  this.DOCUMENT.ESTRAS.OBLIGAR_DESCUENTO_GLOBAL == 0 ){
        
            let a =  [" Index : "+productoIndex+" El Descuento global se encuentra inactivo. Por lo tanto  podemos aplicar descuento unitario a el producto."];
            this.logs.documento = [...this.logs.documento , a];


            return  pro.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL;
        }



        /** Combrueba si existe un Porcentaje Global */
        if (  Number(this.DOCUMENT.ESTRAS.PORCENTAJE_GLOBAL) >=   0 &&  this.DOCUMENT.ESTRAS.OBLIGAR_DESCUENTO_GLOBAL == 1  ){
            this.log()
            let a =  [" Index : "+productoIndex+" HAY UN DESCUENTO GLOBAL EN PORCENTAJE DE : " + this.DOCUMENT.ESTRAS.PORCENTAJE_GLOBAL];
            this.logs.documento = [...this.logs.documento , a];
            return this.DOCUMENT.ESTRAS.PORCENTAJE_GLOBAL ;
        }   




        if( Number(this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA) > 0){
            this.log("Ingrese a descuento lista, devolveremos es descuento de la lista:")
            
           

            
            let { descuento}  =  this.extraerPreCiobyLista(this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA, this.DOCUMENT.ESTRAS.SWITCH_LISTA_PRECIO ,pro);
            
            let a =  [" Index : "+productoIndex+" Ingrese a descuento lista, devolveremos en descuento de la lista:" + descuento ];
            this.logs.documento = [...this.logs.documento , a];


            return descuento
        }
        
        
        return  pro.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL;
    }




    /*** Si el precio de venta a cambiado  */
    _____switchCambioPrecio (producto){

        if ( producto.MATEMATICAS.PRECIO != producto[ this.KEY_PRECIO ]   ) {

            return true;
        }
        return false;
    }




    _____calcularImpuestos(producto){
        /*
        if (!$this->TipoDocumentoFiscal['mover_impuestos']) {	
			return false;
		}
        */

        let Mutandoproducto = this.clonarObjeto(producto);

        if( Mutandoproducto.MATEMATICAS.IMPUESTOS){
 
             Mutandoproducto.MATEMATICAS.IMPUESTOS.map( (impuesto , index) => {
             
                 if (this.____ComprobarSiExisteImpuesto( impuesto)) {
                     console.log("El impuesto Si Existe")
                 }else{
             
                     this.DOCUMENT.MATEMATICAS.IMPUESTOS = [ ...this.DOCUMENT.MATEMATICAS.IMPUESTOS , impuesto  ] 
                 }
 
             } ) 
        }    
    }


	____ComprobarSiExisteImpuesto( impuesto_local){

        let respuesta = false;
        this.DOCUMENT.MATEMATICAS.IMPUESTOS = this.DOCUMENT.MATEMATICAS.IMPUESTOS.map( (impuesto_global) => {
            
            if (impuesto_global.id  === impuesto_local.id ) {
		
                impuesto_global.TOTAL_IMPUESTO += ( impuesto_local.TOTAL_IMPUESTO);
				impuesto_global.BASE_IMPUESTOS += ( impuesto_local.BASE_IMPUESTOS);
				respuesta = true;
			}
            return impuesto_global;
        });

		return respuesta;	
	}















    /*** Data */
    aplicardescuentolineaprecio = ({indexx, valor}) => {
        
        


        let  COPIA =  this.clonarObjeto(this.DOCUMENT.PRODUCTOS);
        
        let descuento = this.FN(valor);


        
        if(descuento < 0 ){

            descuento = descuento * -1;
        }

        
        COPIA[parseInt(indexx)].MATEMATICAS.DESCUENTO_UNITARIO = descuento;
        
        COPIA[parseInt(indexx)].MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL = this.extraerPorcentaje( descuento ,   COPIA[parseInt(indexx)].MATEMATICAS.PRECIO );   
        COPIA[parseInt(indexx)].MATEMATICAS.IGNORAR_DESCUENTO_PORCENTAJE = 1;
        COPIA[parseInt(indexx)].MATEMATICAS.DESCUENTO_DIRECTO = 1;

        this.DOCUMENT = { ...this.DOCUMENT , 'PRODUCTOS' : COPIA }
    }


    aplicardescuentolineaporcentaje = ( {indexx, valor} ) => {
        
        
        let  COPIA      =   this.clonarObjeto(this.DOCUMENT.PRODUCTOS);
        let  porcentaje =   this.FN(valor);
        

        if(porcentaje < 0 ){

            porcentaje = porcentaje * -1;
        }

        console.log(porcentaje);

        COPIA[parseInt(indexx)].MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL = porcentaje;
        COPIA[parseInt(indexx)].MATEMATICAS.DESCUENTO_UNITARIO = this.transformarPorcentaje( porcentaje ,   COPIA[parseInt(indexx)].MATEMATICAS.PRECIO );
       
        COPIA[parseInt(indexx)].MATEMATICAS.IGNORAR_DESCUENTO_PORCENTAJE = null;

        /** Si este interruptor esta en uno, significa de que el software dee darle prioridad a este descuento.
         *  
         *  Ya que en una lista de porcentaje. donde a todo se le aplica el 10% 
         *  hay un producto que quisiera dejarlo en 5% 
         * 
         */
        COPIA[parseInt(indexx)].MATEMATICAS.DESCUENTO_DIRECTO = 1;
        

       
        this.DOCUMENT = { ...this.DOCUMENT , 'PRODUCTOS' : COPIA };

        console.log("El documento")
        console.log(this.DOCUMENT);
    }


    aplicarDescuentoTotalLineaPrecio = ( {indexx, valor} ) => {
        valor = valor / this.DOCUMENT.PRODUCTOS[indexx].MATEMATICAS.CANTIDAD;
        this.aplicardescuentolineaprecio( {indexx, valor} );
    }
    




    AplicarCambioCantidad = ( {indexx, valor} ) => { 
        
        let  COPIA =  this.clonarObjeto(this.DOCUMENT.PRODUCTOS);
        let cantidad = this.FN(valor);
        COPIA[parseInt(indexx)].MATEMATICAS.CANTIDAD = cantidad;
        this.DOCUMENT = { ...this.DOCUMENT , 'PRODUCTOS' : COPIA };
    }



    cambiarPrecioDocumento = ({indexx, valor}) => {
    
        let  COPIA =  this.clonarObjeto(this.DOCUMENT.PRODUCTOS);
        let precio = this.FN(valor);
        COPIA[parseInt(indexx)].MATEMATICAS.PRECIO = precio;
        this.DOCUMENT = { ...this.DOCUMENT , 'PRODUCTOS' : COPIA };
    } 

    cambiarPrecioDocumentoFromCompra = ({indexx, valor}) => {
      
        let  COPIA =  this.clonarObjeto(this.DOCUMENT.PRODUCTOS);
        let precio = this.FN(valor);
        
        let COPIA_ITEM  =  this.clonarObjeto(COPIA[parseInt(indexx)]);

        if (!this.DOCUMENT.ESTRAS.COMPRA || !this.DOCUMENT.ESTRAS.COMPRA.lista_precio_activa_compra) {
            
            this.DOCUMENT.ESTRAS.COMPRA = {
                lista_precio_activa_compra :0
            }
        } 

        if (this.DOCUMENT.ESTRAS.COMPRA && this.DOCUMENT.ESTRAS.COMPRA.lista_precio_activa_compra !== undefined) {

            let listaprecioid = this.DOCUMENT.ESTRAS.COMPRA.lista_precio_activa_compra;
            
            if (COPIA_ITEM.COMPRA) {
                let listaPreciobuscada = COPIA_ITEM.COMPRA.lista_precio.find(abc => abc.ct_lista_precio_id == listaprecioid);
                
                if (listaPreciobuscada) {
                    console.log("Se encontro la lista de precio y se cambiara")
                    let sinBuscada = COPIA_ITEM.COMPRA.lista_precio.filter(abc => abc.ct_lista_precio_id != listaprecioid);
                    listaPreciobuscada.precio = precio;
                    let resultadoFinal = [...sinBuscada, listaPreciobuscada];
                    
                    
                    COPIA_ITEM.COMPRA.lista_precio = resultadoFinal;
                    COPIA_ITEM.COMPRA.precio_actual = precio;


                } else {
                    console.error("El objeto no tiene una lista de precios con el id proporcionado.");
                }
            } else {
                console.error("El objeto no tiene la propiedad 'COMPRA'.");
            }
        } else {
            console.error("No hay una lista de precios activa para la compra.");
        }
           
   
        COPIA[indexx] = COPIA_ITEM;
        this.DOCUMENT = { ...this.DOCUMENT , 'PRODUCTOS' : COPIA };
    } 




    eliminarImpuestoLinea = ( { indexx , valor } ) => {
        let COPIA =  this.clonarObjeto(this.DOCUMENT.PRODUCTOS);
        let index = parseInt(indexx);
        COPIA[index].IMPUESTOS_RAIZ  =  COPIA[index].IMPUESTOS_RAIZ.filter(impuesto => impuesto.id !=  valor );
        this.DOCUMENT = { ...this.DOCUMENT , 'PRODUCTOS' : COPIA };    
    }   



    eliminarLinea = ({ indexx }) => {           
        this.DOCUMENT.PRODUCTOS.splice( indexx , 1 );
    }

    eliminartodosproductos = () => {
        this.DOCUMENT.PRODUCTOS = [];
    }





    

    aplicarDescuentoGlobla = ({   valor  }) => {
        
     
        const   {descuento , tipodescuento } = valor  ;
        let porcentaje = descuento;
     
        /* Si es manual se convierte a porcentaje */
        if (tipodescuento === 2){       
            porcentaje = this.extraerPorcentaje( descuento , this.DOCUMENT.MATEMATICAS.SUBTOTAL_1    );    
        }

 
       
      
        if  (this.DOCUMENT.ESTRAS.LISTA_PRECIO_ACTIVA  > 0 && this.DOCUMENT.ESTRAS.SWITCH_LISTA_PRECIO == 1){
            NotificationManager.error('  No puedes hacer uso del Descuento global mientras exista una Lista de Precios por Porcentaje Activa', 'Bloqueado');      
            this.DOCUMENT.ESTRAS.PORCENTAJE_GLOBAL = 0;
            return false;
        }

        
        if( porcentaje > Number(this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo) && Number(this.USUARIO.USER.type_user_id) != 1  ){

            NotificationManager.error('No tienes Permiso para realizar este descuento', 'Bloqueado');
            let a =  ["No tienes Permiso para realizar este descuento. Tu  Descuento en Porcentaje Representa: " + porcentaje + "%" + "Y solo puedes aplicar un Maximo de " +  this.USUARIO.CONFIGURACIONES_TABLE.descuento_minimo + "%" ];
            this.logs.documento = [...this.logs.documento , a];
            return false; 
        }


        this.DOCUMENT.ESTRAS.PORCENTAJE_GLOBAL = porcentaje;
        this.DOCUMENT.ESTRAS.OBLIGAR_DESCUENTO_GLOBAL = 1;
        console.log("porcentaje establecido es: " +  this.DOCUMENT.ESTRAS.PORCENTAJE_GLOBAL);
 
        return false;
    }







    cambiarTipoDocumento = ({valor}) => {

        if ( this.DOCUMENT.TIPO_DOCUMENTO.KEY_PRECIO   !=   valor.KEY_PRECIO ) {
            
            this.KEY_PRECIO =  valor.KEY_PRECIO;
            this.resetearPreciosYDescuentos();
        }

        this.DOCUMENT.TIPO_DOCUMENTO = valor;


    }




    /*  Detecta si hay un cambio de documento y resetear los precios y descuentos  OJO pendiente
        La lista de precios 
    */
    resetearPreciosYDescuentos = () => { 
        this.DOCUMENT.PRODUCTOS.map( (producto) => {
            
            producto.MATEMATICAS.PRECIO =  producto[ this.KEY_PRECIO ];
            producto.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL = 0;
            producto.MATEMATICAS.IGNORAR_DESCUENTO_PORCENTAJE  = null;
            return producto;
  
        })

        this.DOCUMENT.ESTRAS.PORCENTAJE_GLOBAL = 0;
    }




    SUMAR_TOTAL = () => {

        this.MATEMATICAS.SUBTOTAL_3 +=  this.producto.precio;

    }



    FN = (num) => {
   
        let num2 =  Number.parseFloat(num).toFixed(3);
        return parseFloat(num2);
    }







    /*** Tranformar Porcentaje */
    extraerPorcentaje = ( total_1 , total100  ) => {       
        const resultado =  (total_1 * 100) /  total100;
        return this.FN(resultado);
    }


    /*** Tranformar Porcentaje en PRecio 
     * 
     *  Recibe un porcentaje y el valor de 100% de comparacion 
     */
    transformarPorcentaje = ( porcentaje , total100  ) => {   
        
        
        console.log(porcentaje)


        if(porcentaje > 0){

            console.log("positivo")
        }else{

            porcentaje = porcentaje * -1;
            console.log("negativo")
        }

        const resultado =  (total100 * porcentaje) /  100;
       
       
        console.log(resultado);
        console.log(resultado);
        console.log(resultado);
        return this.FN(resultado);
    }
    

    





    /********************* No se Si esto son buenas Practicas   */
    setTitleHtml = () => {

        let button = document.querySelector("#" + this.KEY_LOCAL_STORAGE ); // Match the first div
        button.innerHTML =   "$ " + this.MATEMATICAS.SUBTOTAL_3;   
    
    }


    
}