import React from 'react';

const TipoDocumento = ({tipoComprobantesbyModulo , modulo , formData , setFormData}) => {

    const handleGrupoDocumentoChange = (grupo, isChecked) => {
       
        setFormData(prev => {
           
            const newGrupoDocumentos = isChecked
                ? [...prev.grupoDocumentos, grupo]
                : prev.grupoDocumentos.filter(item => item.id !== grupo.id);
    
            return {
                ...prev,
                grupoDocumentos: newGrupoDocumentos
            };
        });
    };




    return (
        <div>
            <h5 className='mb-4'>Filtrar por Tipo de Documento</h5>
           
            {tipoComprobantesbyModulo.map((grupo, index) => (
             
                <div key={index} className='form-check form-check-inline me-4'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id={`grupo-${index}`}
                        checked={ formData.grupoDocumentos.some(item => item.id == grupo.id)   } // Estado del checkbox basado en el estado formData
                        onChange={(e) => handleGrupoDocumentoChange(grupo, e.target.checked)} // Llamada a la función handleGrupoDocumentoChange
                    />
                    <label className='form-check-label fs-8' htmlFor={`grupo-${index}`}>
                        <i className='fa-regular fa-file-lines'></i> {grupo.nombre}
                    </label>
                </div>
                
            ))}
        </div>
    );
};

export default TipoDocumento;