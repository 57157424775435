import React from 'react';

const EstadoPagoDocumento = ({formData}) => {
    return (
        <div className="mt-5">
            <div><b>Estado del Documento</b></div>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name="ct_documento_estado_id"
                    id="flexRadioDefault0"
                    value="-1"
                    checked={formData.ct_documento_estado_id === "-1"} // Marcado por defecto
                    onChange={() => {}}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault0">
                    Todos los estados
                </label>
            </div>

            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name="ct_documento_estado_id"
                    id="flexRadioDefault1"
                    value="1"
                    checked={formData.ct_documento_estado_id === "1"}
                    onChange={() => {}}
                />
                <label className="form-check-label-success" htmlFor="flexRadioDefault1">
                    Pagado
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name="ct_documento_estado_id"
                    id="flexRadioDefault2"
                    value="0"
                    checked={formData.ct_documento_estado_id === "0"}
                    onChange={() => {}}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Pendiente de pago
                </label>
            </div>
        </div>
    );
};

export default EstadoPagoDocumento;