import React, { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { __app } from '../../../utils/myApp';



const  StandarModal = ({keyboard = false, children , titulo , mostrar , desactivar , size , fullscreen = 0 , headerShow = true , colorHeader, backdrop="static"}) => {

  let sizee = size;

  if(!size){
    sizee = ""; 
  }

  if ( __app.TipoMovil() ) fullscreen = 1;

  const [show, setShow] = useState(mostrar);

  useEffect( () => {

    setShow(mostrar);

  } , [mostrar])
  
  const handleClose = () => {
    desactivar();
    setShow(false);
  } 
  const handleShow = () => setShow(true);


  return (
    <>
     

      <Modal
        size={sizee}
        show={show}
        onHide={handleClose}
        backdrop={backdrop}
        keyboard={keyboard}
        fullscreen={Number(fullscreen)}
      >
        

        {
          headerShow == true  &&

          <Modal.Header closeButton className={colorHeader}>
            <Modal.Title  dangerouslySetInnerHTML={{ __html: titulo}}  />
          </Modal.Header>
        

        }
        <Modal.Body>
         
          {children}
        </Modal.Body>

      </Modal>
    </>
  );
}

export default StandarModal;
