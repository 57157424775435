
export default  class Buscaripi {
   
    constructor (arrelo = [] ) {

       
        /** Variable para el manejo de la Busqueda  */
        this.debug = 0;
        

        /***** Variable par Controlar los Mensaje en pantalla  */
        this.ArrayPrincipal  = arrelo;
      


        /*** REsultados cuando la palabra se encuentre */
        this.ArrayResultado = [];




        this.busqueda_explotada = null;


        /**** Opciones de Busqueda */
        this.opciones = {

            keysBuscar : ['nombre']
        }

        /** Si esta varibale es Verdadera, esto sifnifica que cuando envien una mapalabra vacia el buscador devolvera 
         * todos lso productos
         */
        this.fullResultado = false;


        this.log("Se ha instancioado la Clase Busca Ripi")
        this.log("Productos Diponible para la Busqueda");
        this.log(this.ArrayPrincipal);

      
     
    }   

    

    addNewObjets = (newArray) => {


        
        //this.ArrayPrincipal = [  ...this.ArrayPrincipal,  ...newArray];

        const IDProperty = 'id';

        // Filtrar elementos del array principal que coinciden con el nuevo array
        this.ArrayPrincipal = this.ArrayPrincipal.filter(item => {
        return !newArray.some(newItem => newItem[IDProperty] === item[IDProperty]);
        });
     

        // Agregar los elementos de newArray al array principal
        this.ArrayPrincipal = [...this.ArrayPrincipal, ...newArray];
    }



    

    eliminarIds = (ids) => {


        const IDProperty = 'id';

     

        // Filtrar elementos del array principal que coinciden con el nuevo array
        this.ArrayPrincipal = this.ArrayPrincipal.filter(item => {

            return !ids.some(newItem => newItem.ref_id === item.id);
        });
        

    
   

    }


    /*** Funcoion LOG */
    log = (data) => {
        if (this.debug ){
            console.log( data );
        }
    }



    startDirecto = (palabra_buscada) => {
        
        palabra_buscada = palabra_buscada.trim().replace(/\s+/g, " ").toUpperCase();

        let item = null;
        let BreakException = {};
       
      
        this.ArrayPrincipal.forEach((data, index1) => { 
        
            if (data.id_2){
                
                let codigos = data.id_2.split(",");
                try {
                codigos.forEach( (data1,index2) => {
                    if(data1.toUpperCase().trim() == palabra_buscada ){
                        item = data;
                        throw BreakException;
                    }
                })
                } catch (e) {
                    if (e !== BreakException) throw e;
                } 
            }
              
        });
        
        return item;
    }


    /**** Esta Funcion Inicio la Busqueda */
    start =  (palabra_buscada , opciones = null )  => {

        if(opciones){
            
            this.opciones.keysBuscar  = opciones;
        }

       

        palabra_buscada = palabra_buscada.trim().replace(/\s+/g, " ").toUpperCase();

   

        this.ArrayResultado = [];



        this.busqueda_explotada = palabra_buscada.split(" ");

        this.log("Iniciando Nueva Busqueda para la Palabra: " + palabra_buscada  )


     

        if(  palabra_buscada.length == 0  ){
            
            if( this.fullResultado  ) this.ArrayResultado =   this.ArrayPrincipal.slice();
         
        }else{
            
            this.ArrayPrincipal.forEach((data, index) => {
               
                let  encontrado = this.start_2(data);
                if ( encontrado ){  
                    this.ArrayResultado = [...this.ArrayResultado , data];
                }
    
            });   
        }
       
        

        return this.ArrayResultado;
     
        
    }














    start_2 = (data) => {

        let allTextokeys = this.adjuntarKeys(data);
		this.encontrado = 0;



		for (var i = 0; i < this.busqueda_explotada.length; i++) {
			if ( this.busqueda_explotada[i].length > 0) {
				this.consultarsubPalabra(this.busqueda_explotada[i] , allTextokeys);
			}	
		}



		if ( this.encontrado ===  this.busqueda_explotada.length  ) {
			return true;
		}
		return false;
	
   
    }


    consultarsubPalabra = (subpalabra , allTextokeys ) => {
        this.log( "COMPARANDO "  + subpalabra + " Con la palabra " +  allTextokeys );
        if (allTextokeys.indexOf(subpalabra) >= 0 ) {
           
			this.encontrado  ++;
		}else{

			this.encontrado  --;
		}
    }


    adjuntarKeys  =  (objetoJson) =>  {
		
		let arraykeys = [];

		/**** Si no se define las keys a buscar, entonces se tomaran todas las keys del objeto, No recomendable **/
		if (this.opciones.keysBuscar === undefined ) {	
			arraykeys = Object.keys(objetoJson);
		}else{	
			arraykeys = this.opciones.keysBuscar;
		}

		let  textoCompleto = "";
		let auxKey;

        
		for (var i = 0; i < arraykeys.length; i++) {
			auxKey = arraykeys[i];
			textoCompleto += objetoJson[  auxKey   ] +  " ";
		}

		return  textoCompleto.toUpperCase();
        
	}

}

  