import React, { useEffect, useState } from 'react';
import { listarPagosHttpByTercero } from '../Core/Controlador';
import Table from './TablePago/Table';

const Pages = ({terceroId}) => {

    const [pagos, setPagos]  =  useState(null)

    useEffect(() => {
        
       listarPago();

    },[])

    const listarPago = async () => { 
        let data =  await listarPagosHttpByTercero(terceroId);
        
        if(data !== -1){
           
            setPagos(data)
        }else{
            alert("Error sin red.")
        }
    }

    return (

        <div className='card table-responsive'>      
            { ( pagos  && pagos.length > 0) && <Table PAGOS={pagos} />}
        </div>
    );
};

export default Pages;