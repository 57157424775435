import { __app, __fecha } from '../../utils/myApp';
import React, { useState } from 'react';
import CartonPedidos from './Distribuidora/CartonPedidos';
import CerrarVentaEnMasa from './Distribuidora/CerrarVentaEnMasa';
import FiltrosCuentasAbiertas from './FiltrosCuentasAbiertas';

const ModoLista = ({ pedidos, cargarDocumento, eliminarCuentaAbierta, user, imprimirPreFactura }) => {
    // Estado para el checkbox "Todo" y los checkboxes de cada fila
    const [todosSeleccionados, setTodosSeleccionados] = useState(false);
    const [seleccionados, setSeleccionados] = useState({});
    const [pedidosSeleccionados , SetpedidosSeleccionados ] = useState(null);
  
  
    // Función para manejar el cambio del checkbox "Todo"
    const handleCheckTodo = () => {
        setTodosSeleccionados(!todosSeleccionados);
        const nuevosSeleccionados = {};
        pedidos.forEach((duc) => {
            nuevosSeleccionados[duc.id] = !todosSeleccionados;
        });
        setSeleccionados(nuevosSeleccionados);
    };

    // Función para manejar el cambio de un checkbox individual
    const handleCheckFila = (id) => {
        setSeleccionados((prevState) => {
            const nuevosSeleccionados = { ...prevState, [id]: !prevState[id] };
            // Si todos los checkboxes son seleccionados, el checkbox "Todo" se marca
            setTodosSeleccionados(Object.values(nuevosSeleccionados).every((val) => val));
            return nuevosSeleccionados;
        });
    };

    const combinarPedidos = () => {
        // Filtrar los pedidos seleccionados
        const pedidosSeleccionados = pedidos.filter((duc) => seleccionados[duc.id]);
        
        // Imprimir los pedidos seleccionados en la consola
        SetpedidosSeleccionados(pedidosSeleccionados);
        console.log(pedidosSeleccionados)
        reset();
    }
    
    // Función para manejar el click en el botón "Continuar"
    const [showImprimir , SetShowImprimir ] = useState();
    const handleContinuar = () => {
        
        
        combinarPedidos();
        SetShowImprimir(true);

    };

    const reset = () => {
        SetShowImprimir(null);
        SetshowFacturarMasa(null);
    }

    // Función para manejar el click en el botón "Imprimir"
    const handleImprimir = () => {
        combinarPedidos();
        SetShowImprimir(1);
    };

    // Función para manejar el click en el botón "Imprimir"
    const [showFacturarMasa , SetshowFacturarMasa ] = useState();
    const handleFactura = () => {
        combinarPedidos();
        SetshowFacturarMasa(1);
    };

    


    // Verifica si hay algún pedido seleccionado
    const haySeleccionados = Object.values(seleccionados).some((val) => val);

    return (
        <div className=' m-2 p-2'>
           
            <FiltrosCuentasAbiertas />

            {/* Modo lista */}
            <div className='card'>
            <table className=' table'>
                <thead>
                    <tr>
                        {/* Checkbox Todo */}
                        <th>
                            <input 
                                type="checkbox" 
                                checked={todosSeleccionados}
                                onChange={handleCheckTodo} 
                            /> Todo
                        </th>
                        <th>Codigo</th>
                        <th>Fecha</th>
                        <th>Vendedor</th>
                        <th>Cliente</th>
                        <th>Total</th>
                        <th>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {pedidos
                        .sort((a, b) => a.fechaid - b.fechaid)
                        .map((duc) => (
                            <tr key={duc.id}>
                                <td>
                                    {/* Checkbox individual */}
                                    <input
                                        type="checkbox"
                                        checked={seleccionados[duc.id] || false}
                                        onChange={() => handleCheckFila(duc.id)}
                                    />
                                </td>
                                <td>{duc.fechaid}</td>
                                <td>{__fecha.formatoFecha({ $fecha: duc.FORM.FechaHoraLocal })}</td>
                                <td>{duc.FORM.nombre_vendedor}</td>
                                <td>{duc?.TERCERO?.razonSocial}</td>
                                <td>${__app.formato_decimal({ total: duc.MATEMATICAS.SUBTOTAL_3 })}</td>
                                <td>
                                   <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                                        Opciones <span class="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <ul class="dropdown-menu bg-light">
                                    <li><button  onClick={() => imprimirPreFactura(duc , 5 )}  class="dropdown-item" href="#">Seguir Cuenta</button></li>
                                    <li><button  onClick={() => imprimirPreFactura(duc , 5 )}  class="dropdown-item" href="#">Imprimir Cuenta</button></li>
                                    <li><button  onClick={() => imprimirPreFactura(duc , 10 )}  class="dropdown-item" href="#">Otra Opciones</button></li>
                                    <li><hr class="dropdown-divider" /></li>
                                    <li>{(user.PERMISOS.verBotonFacturarDocumento == 1 || user.USER.type_user_id == 1) && <a onClick={() => eliminarCuentaAbierta(duc)} className="card-link link">Eliminar</a>}</li>
                                    </ul>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            </div>

            {/* Mostrar los botones solo si hay pedidos seleccionados */}
            {haySeleccionados && (
                <div className="btn-group mt-3" role="group">
                    <button className="btn btn-primary" onClick={handleImprimir}>
                        Imprimir
                    </button>
                    <button className="btn btn-secondary" onClick={handleFactura}>
                        Factura
                    </button>
                    <button className="btn btn-success" onClick={handleContinuar}>
                        Continuar
                    </button>
                </div>
            )}

            {showImprimir && <CartonPedidos    pedidosSeleccionados={pedidosSeleccionados} />}

            {showFacturarMasa  && <CerrarVentaEnMasa    pedidosSeleccionados={pedidosSeleccionados} />}
        </div>
    );
};

export default ModoLista;
