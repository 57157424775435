import React, { useState, useEffect } from 'react';
import './estilos.css'; // Asegúrate de que la ruta sea correcta

import { consultarAgotados } from '../Controlador';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';
import StandarModal from '../../Document/PartesDocument/StandarModal';
import MaximosMinimos from '../MaximosMinimos.jsx';
import { useStateValue } from '../../../context/StateProvider.js';


const Pages = () => {

    const [ { user  } ] = useStateValue();

    

    const [mostrarModal, setMostrarModal] = useState(false);


    const [ alertaMaximoMinimos, SetalertaMaximoMinimos] = useState();

    const cerrarModalAlertaInventario = () => {
        setMostrarModal(false)
       // SetalertaMaximoMinimos([]);
    }



    useEffect(() => {


        vertificadorDeInventario();


        // Función que se ejecutará cada 5 segundos
        const intervalId = setInterval(() => {
            vertificadorDeInventario();
        }, 500000);

        // Limpia el intervalo al desmontar el componente
        return () => clearInterval(intervalId);


    }, [user]);

    const abrirModal = () => {
        setMostrarModal(true); // Abre el modal
    };

    const cerrarModal = () => {
        setMostrarModal(false); // Cierra el modal
    };

    /**
     * Verificador de inventario
     */
    const vertificadorDeInventario = async () => {

        if(!user){
            SetalertaMaximoMinimos(null)
            return false;
        }   
        let data = await consultarAgotados(user.ESTACION.id);
        if (data && data.infoInventario &&  data.infoInventario.length > 0) {
         
            console.log(data.infoInventario);
            SetalertaMaximoMinimos(data.infoInventario)
        }
    };


    const volverAConsultarMAximosYminimos = () => {

        vertificadorDeInventario();
        setMostrarModal(false);
        SetalertaMaximoMinimos(null);
    }



    return (
        <div>
           

            {/* Botón flotante */}
            {alertaMaximoMinimos && <div className="boton-flotante" onClick={abrirModal}>
                Inventario Agotado
            </div>}





                


                 {/***
                * Alerta de Inventarios  <button onClick={() => vertificadorDeInventario()}>adas</button>
                */}
                <StandarModal   keyboard={true}  headerShow={true} size="lg"   titulo="Alerta de Inventario"  desactivar={() => cerrarModalAlertaInventario()}  mostrar={mostrarModal}  > 
                    <MaximosMinimos  volverAConsultarMAximosYminimos={volverAConsultarMAximosYminimos} alertaMaximoMinimos={alertaMaximoMinimos} desactivar={cerrarModalAlertaInventario}    />
                </StandarModal>
        
        </div>
    );
};

// Estilos básicos para el modal
const estiloModal = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    border: '1px solid #ccc',
    zIndex: 1000,
};

export default Pages;
