import React from 'react';

const FooterCarta = ({fontSize , user }) => {
    return (
        <>
            <div class="footer" style={{marginTop: 15}} >
                
               {/**  <p style={{fontSize: fontSize.md + 'pt'}} ><b>Resolución</b>   resolucion </p> */}

                <p style={{fontSize: fontSize.xs + 'pt'}} > Factura Generada por  <a target="_BLANK" href="#document_reprise">www.pluma7.com</a> Contacto +57 312 294 6723 - Oficina en (Medellín - Apartadó)</p>
            </div>
        </>
    );
};

export default FooterCarta;