import React from 'react';
import {   configuracionesLocales } from '../../../Core/VariablesGlobales'; 
import { useStateValue } from '../../../context/StateProvider';
import { setLocalStore } from '../../../Core/fetchLocalStoreData';
const CamposConfiguracionLocal = () => {
    
    const [ {user , localconfig }  , dispatch ] = useStateValue();



    
    const agregardatos = (event,infoTr) => {

        console.log(infoTr)

        let gene = user.CONFIGURACIONES.GENERALES;
        let newlist = null;


        if (event.target.checked) {
            newlist  = {...gene , [infoTr.clave] : 1 }
        } else {
            
            newlist  = {...gene , [infoTr.clave] : 0 }
    
        }
        

        let copy = { ...user , CONFIGURACIONES : { ...user.CONFIGURACIONES , GENERALES : newlist} }
    

        dispatch ({
            type : 'SET_USER',
            user: copy ,
        })    
        setLocalStore("user" , copy );
        setLocalStore("user_localConfig" , copy.CONFIGURACIONES  );
       
        
    }

    const comprobarCheked = (campo) => {
        console.log(user.CONFIGURACIONES.GENERALES[campo.clave])
      
        if(user.CONFIGURACIONES.GENERALES[campo.clave] && user.CONFIGURACIONES.GENERALES[campo.clave]  == 1 ){
            console.log("chekeed")
            return true;
        }
        return false;
        //user.CONFIGURACIONES.CAMPOS_A_MOSTRAR_LISTA.findIndex( (data) => data.clave == campo.clave  ) >= 0
    }

    return (
        <div>
            
            <h3 className='mt-5'>Otras Configuraciones</h3>
                {configuracionesLocales.map(( campo , index ) => 
                    <div key={index} className="form-check form-switch">
                        <input onChange={(e) => agregardatos(e,campo)} defaultChecked={ comprobarCheked(campo) } className="form-check-input" type="checkbox" role="switch" id={campo.clave + "flexSwitchCheckDefault"} />
                       
                        <label className="form-check-label" htmlFor={campo.clave +"flexSwitchCheckDefault"}>
                            <p>{campo.label}  <span className='text-helper'>{campo.ayuda}</span> </p> 
                        </label>
                    </div>
                )}


                
        </div>
    );
};

export default CamposConfiguracionLocal;