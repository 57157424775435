import React, { useEffect } from 'react';
import { __app } from '../../../../utils/myApp';

const FormaPagoPaso3 = ({ documento_actual, estadoServer, totalpagado, user }) => {
  
    // Verificación: Si 'totalpagado' no es un número válido, mostramos un mensaje de error.
    useEffect(() => {
        if (isNaN(totalpagado)) {
            alert("Error: total pagado no es un número válido. Por favor, vuelva a intentarlo.");
        }
    }, [totalpagado]);

    // Cálculo del total pagado incluyendo saldo a favor.
    const saldoFavor = parseFloat(documento_actual.MATEMATICAS?.TOTAL_SALDO_FAVOR || 0);
    const totalPagado = parseFloat(totalpagado || 0) + saldoFavor;

    // Total real de la factura (subtotal + propina)
    const totalFactura = documento_actual.MATEMATICAS.SUBTOTAL_3 + documento_actual.MATEMATICAS.PROPINA;

    // Cálculo del crédito
    const credito = totalFactura - totalPagado;

    // Función para formatear números a un formato decimal.
    const formatDecimal = (valor) => __app.formato_decimal({ total: valor });

    return (
        <div>
            {/* Verificamos si hay formas de pago disponibles en el documento */}
            {documento_actual.FORMAS_DE_PAGO && documento_actual.FORMAS_DE_PAGO.length > 0 && (
                <>
                    <h1>FORMA DE PAGO</h1>

                    {/* Botón de Log para ventas si el estado de la aplicación es 2 */}
                    {user.CONFIGURACIONES_TABLE?.estado_aplicacion === 2 && (
                        <button onClick={() => console.log(documento_actual, totalpagado)}>
                            Log venta
                        </button>
                    )}

                    <table className='table table-responsive'>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Listamos las formas de pago donde el total sea mayor que 0 */}
                            {documento_actual.FORMAS_DE_PAGO.map((pago, index) =>
                                pago.total > 0 && (
                                    <tr key={index}>
                                        <td>{pago.nombre}</td>
                                        <td>{formatDecimal(pago.total)}</td>
                                    </tr>
                                )
                            )}

                            {/* Si existe saldo a favor, lo mostramos */}
                            {saldoFavor > 0 && (
                                <tr>
                                    <td>SALDO A FAVOR</td>
                                    <td>{formatDecimal(saldoFavor)}</td>
                                </tr>
                            )}
                        </tbody>

                        <tfoot>
                            <tr>
                                <th>TOTAL PAGADO</th>
                                <th>
                                    <h2>$ {formatDecimal(totalPagado)}</h2>
                                </th>
                            </tr>
                        </tfoot>
                    </table>





                    {/* Indicamos si el sistema está funcionando sin conexión a la red */}
                    {!estadoServer ? (
                        <p className="text-primary mb-3">Trabajando sin Red</p>
                    ) : (
                        <span className="text-principal"></span>
                    )}


                    {/* Cálculo del crédito o saldo restante */}
                    {credito > 0 && (
                        <p className='badge badge-phoenix badge-phoenix-danger fs-5'>
                            Crédito: {formatDecimal(credito)}
                        </p>
                    )}

                    {/* Cálculo del saldo a favor */}
                    {credito < 0 && (
                        <p className='text-success'>
                            Saldo a Favor: {formatDecimal(-credito)}
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

export default FormaPagoPaso3;
