import React from 'react';

const Header = ({cantidad}) => {
    return (
        <div class="row align-items-center justify-content-between g-3 mb-4">
            <div class="col-12 col-md-auto">
                <h2 class="mb-0">
                    Contactos 
                    <span class="fw-normal text-body-tertiary ms-3">({cantidad})</span> 
                    
                </h2>
                { false && <span style={{fontSize:'10px'}} class="d-none d-md-block text-body-tertiary text-muted  ms-1">Version: 4545</span>}
            </div>
                <div class="col-12 col-md-auto d-flex">
                    
                    {
                        /* <button class="btn btn-link text-body px-0 me-2 me-md-4">
                        <span class="fa-solid fa-sync fs-10 me-2"></span> 
                        <span class="d-none d-md-inline">Sincronizar Ahora</span>
                    </button>**/
                    }

                    <span class="  text-body px-0 me-2 me-md-4">
                
                    
                    </span> 
                </div>
        </div>
    );
};

export default Header;