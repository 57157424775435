import React from 'react';
import { __app, __fecha } from '../../../utils/myApp';
const ComprobantesContablesTr = ({resumen_comprobantes}) => {


    const comprobantes_validos = resumen_comprobantes.tipo_comprobante.filter( (data, index ) => data.ct_master_id !== 0 );

    return (
        <>
            
     

            <tr  style={{marginTop:10}} >
                <th colspan="2" style={{textAlign:'center'}} >  Comprobantes Registrados </th>
            </tr>
            
            { (comprobantes_validos.length > 0 ) && 
                <>
                    {comprobantes_validos.map((value, key) => 
                    
                        <tr key={key}>
                            <td>{value['nombre']}</td>                  
                            <td>
                                <span dangerouslySetInnerHTML={{ __html :  __app.formato_decimal( { total :  value.TOTAL , color : true } )   }} /> 
                            </td>
                        </tr>
                    )}
                </> || 

                <tr >
                    <td  style={{textAlign:'center'}} colSpan={2}> No encontramos documentos   </td>                  
                </tr>

            }
            
        </>
    );
};

export default ComprobantesContablesTr;

