import React from 'react';

const InfoTercero = ({DOCUMENTO}) => {

    if(!DOCUMENTO) return false 
    
    
    return (
        <div>

            <table className='table'>
                <tr>
                    <th className="text-center" colSpan="2">
                        <p>Informacion del Tercero</p>
                    </th>
                </tr>

                <tr>
                    <th>Nombre</th>
                    <td className="text-">
                        {DOCUMENTO.razonSocial}
                    </td>
                </tr>

                <tr>
                    <th>Nit/Identificación</th>
                    <td className="text-">
                        {DOCUMENTO.identificacion}
                    </td>
                </tr>
                
                <tr>
                    <th>Teléfono</th>
                    <td className="text-">
                        {DOCUMENTO.telefono}
                    </td>
                </tr>

                <tr>
                    <th>E-mail</th>
                    <td className="text-">
                        {DOCUMENTO.correoelectronico}
                    </td>
                </tr>

            </table>
        </div>
    );
};

export default InfoTercero;