import React from 'react';
import { __fecha } from '../../../../utils/myApp';

const HeaderCarta = ({ documento ,  user , empresa , fontSize , SaldoPendiente }) => {


    const estiloEstado = {
        PENDIENTE: {
          background: '#f13224',
          color: 'white',
          marginLeft: '-13px',
          borderRadius: '10px',
          width: '140px',
          textAlign: 'center',
          display: 'inline-table',
          margin: '2px',
        },
        PAGADO: {
          background: 'green',
          color: 'white',
          marginLeft: '-13px',
          borderRadius: '10px',
          width: '140px',
          textAlign: 'center',
          display: 'inline-table',
          margin: '2px',

        }
    };

    const estado =   ( SaldoPendiente != 0 ) ? false :  true  ;
 
    return (
        <table cellspacing="0" style={{ width: '100%', fontSize: fontSize.md + 'pt' }}>
            <colgroup>
                <col style={{ width: '20%', textAlign: 'left' }} />
                <col style={{ width: '45%', textAlign: 'center' }} />
                <col style={{ width: '35%', textAlign: 'center' }} />
            </colgroup>
            <thead>
                <tr style={{ background: '#E7E7E7' }}>
                    <th style={{ borderBottom: 'solid 1px black' }}></th>
                    <th style={{ borderBottom: 'solid 1px black' }}></th>
                    <th style={{ borderBottom: 'solid 1px black' }}></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ width: '20%' }}>
                        <div style={{ textAlign: 'center', fontWeight: 700 }}>
                            <br />
                                { (user.CONFIGURACIONES_TABLE.mostrar_logo_factura && user.CONFIGURACIONES_TABLE.mostrar_logo_factura == 1)  && 
                                <>
                                    <img width="160" src={empresa.LOGO} className="img-fluid rounded-start" alt="..." />
                                </>                           
                                }
                            <span style={{ fontSize: fontSize.xl , textAlign: 'center' }}>
                                <b>NIT: {user.ESTACION.nit} </b>
                            </span>
                        </div>
                    </td>
                    <td style={{ width: '30%' }}>
                        <address style={{ textAlign: 'center', fontSize: fontSize.xl + 'pt' }}>
                           
                            <b>

                                <span style={{ textAlign: 'center', fontSize: fontSize.xl + 2 +  'pt' }}>{user.ESTACION.nombre}</span> <br />
                                               
                                <span>{user.ESTACION.municipio} </span>   <br />
                            
                                <span >Dir: {user.ESTACION.direccion}</span> <br />
                                
                                <span >Tel: {user.ESTACION.telefono}</span>  <br /> 
                               
                                <span >E-mail: {user.ESTACION.correo_electronico}</span> <br />
                                
                            </b>
                        </address>
                    </td>




                   
                    <td style={{ width: '40%'}}>
                        <table  style={{ width: '100%' ,  fontSize: fontSize.md + 'pt'  }}>
                            <tr>
                                <td colSpan="2" style={{ textAlign:'center' ,  border : 'solid 1px'}}>
                                    
                                    Representación Gráfica De 
                                    <br />                                   
                                        <span style={{fontSize: fontSize.xl +1  + 'pt' , textAlign : 'center'}}  >{ documento.id_server && <b>{documento.TIPO_DOCUMENTO.descripcion}</b> || <b> PRE-FACTURA.  </b> }</span> 
                                    <br />


                                    <div>
                                        {  documento.EstadoServidor == 1 && 
                                            <div > 
                                                Factura#  { ( documento.NUMERACION &&  documento.NUMERACION.numero_completo && documento.NUMERACION.numero_completo.length > 1 ) && documento.NUMERACION.numero_completo  ||   documento.id_server  }         
                                            </div> 
                                            ||
                                            <div> 
                                                Cod.Temporal :  <b>{documento.id }  </b>
                                            </div> 
                                        }
                                        { (documento.NUMERACION && documento.NUMERACION.texto_factura ) && <div style={{fontSize: '9px'}}> { documento.NUMERACION.texto_factura } </div> }
                                    </div>
                                    
                                </td>
                            </tr>

                            <tr style={{ textAlign:'center'}}>
                                <td colSpan="2">Fecha Factura:   {documento.FORM && <> {__fecha.formatoFecha({$fecha : documento.fechaFinal})} </> }  </td>
                            </tr>
                         
                            <tr>
                                <td colSpan="2" style={{ textAlign:'center'}} >
                                    
                                    <span style={{ display: 'block', marginLeft: '10px', fontSize: '11px' }}>
                                        Estado de pago
                                    </span>

                                    <div style={estado ? estiloEstado.PAGADO : estiloEstado.PENDIENTE}>
                                        {estado ? "PAGADO" : "PENDIENTE DE PAGO"}
                                    </div>
                                </td>
                            </tr>



                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default HeaderCarta;