import React from 'react';

const HorasTabla = ({resultado}) => {
   
    if( !(resultado && resultado.TIEMPO_EN_BLOQUE)){
        return false
    }

    return (
        <table border="1" cellPadding="5" style={{ borderCollapse: 'collapse', margin: '20px auto', textAlign: 'center' }}>
            <thead>
                <tr>
                    <th colSpan={9}>Horas</th>
                </tr>
            </thead>
            <tbody>
                { resultado.TIEMPO_EN_BLOQUE.map((fila, index) => (
                    <tr key={index}>
                        {fila.map((hora, idx) => (
                            <td key={idx}>{hora}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default HorasTabla;