import React, { useEffect, useState } from 'react';

const Seleccion = ({ Auxvariantes  , getcambios}) => {
    
    
    
    const [variantes , setVariantes] = useState(Auxvariantes)
    const [totalStock , SettotalStock] = useState(0)



    useEffect(() => {
       
        SettotalStock(  variantes.reduce((acc, obj) => acc + parseInt(obj.cantidad) , 0)   )
    }, [variantes])


    const incrementarCantidad = (index) => {
        const nuevasVariantes = [...variantes];
        nuevasVariantes[index].cantidad += 1;
        setVariantes(nuevasVariantes);
    };

    const decrementarCantidad = (index) => {
        const nuevasVariantes = [...variantes];
        if (nuevasVariantes[index].cantidad > 0) {
            nuevasVariantes[index].cantidad -= 1;
            setVariantes(nuevasVariantes);
        }
    };


    const aplicarCambios = () => {
        
        getcambios(variantes , totalStock )

    }

    if (!variantes) {
        return <p>sin datos</p>;
    }

    return (
        <div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Talla</th>
                        <th scope="col">Color</th>
                        <th scope="col">Cantidad</th>

                    </tr>
                </thead>
                <tbody>
                    {variantes.map((variante, index) => (
                        <tr key={index}>
                            <td>{variante.talla}</td>
                            <td>{variante.color}</td>
                            <td>
                                <button className="btn btn-secondary btn-sm" onClick={() => decrementarCantidad(index)}>-</button>
                                {' '}
                                {variante.cantidad}
                                {' '}
                                <button className="btn btn-secondary btn-sm" onClick={() => incrementarCantidad(index)}>+</button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">{totalStock}</th>
                        <th scope="col"></th>
                    </tr>
                </tbody>
            </table>

            <button className='btn btn-primary' onClick={aplicarCambios } >Aplicar cambios </button>
        </div>
    );
};

export default Seleccion;
