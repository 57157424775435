import { useEffect, useState } from "react";


import {getLocalstore} from '../../../../Core/fetchLocalStoreData'

import { __app , __fecha } from '../../../../utils/myApp';
import { disenoFactura } from "../../../../Core/VariablesGlobales";
import InfoPago from "../../VerPago/InfoPago";


/** imprimirProductosTipo : Esta variable indica que solo vamos a imprimir lso productos
 * en el campo "cocina" que sean igual a esta variable.
 * 
 * ejemplo : cocina : 1 
 *           Barra : 2
 * 
 *  Asi poddemos abrir 2 pestañas en cada sitio sin problemas.
 */
const PlantillaPago = ( {pago  } ) => {


 
    



    return (
        <div id="EasyPrintAnd">
    
           <InfoPago pago={pago}  boton={false} />
        </div>
    )};

export default PlantillaPago