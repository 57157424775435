import React, { useEffect, useState } from 'react';
import {ExtraerComponentedeProductos , extraerCantidadDeProductosBD , cargarProductosByPagina , combinarProductos , cargarUltimaKey} from "./Controlador"
import { ExtraerTabla , reloadDatabases } from '../../Core/Dixie';
import { NotificationManager} from 'react-notifications';

const SincronizarProductos = ({desactivar, getDatos}) => {

    const [ confirmacion , SetConfimacion ] = useState();
    
    const iniciarProceso = () => {
        SetConfimacion(1);
        EjecutarPaso1();
    }


    let COMPONENTEEEE = null; 

    const [componentes , SetComponente ] = useState();

    const [cantidadProductos , SetcantidadProductos ] = useState(0);
    const [ NumeroDePeticiones ,  SetNumeroDePeticiones  ] = useState(0);
    const [ todosLosProductos , SettodosLosProductos ] = useState([]);


    const [ pasos , SetPasos ] = useState({
        paso1  : null,
        paso2  : null,
        paso3  : null,
        paso4  : null,
    })
    const [ pruebas , setPruebas ] = useState(null);
    
    const EjecutarPaso1 = async () => {

        SetPasos({...pasos , paso1 : "load"});

        let data = await ExtraerComponentedeProductos();

        if(data){
            SetComponente(data);
            COMPONENTEEEE = data;
            SetPasos({ paso1 : "success" , paso2 : "load"});
            
            setTimeout( () => {
                EjecutarPaso2();
            }, 1000)
        }
        
    }



    const EjecutarPaso2 = async () => {
        
        let data = await extraerCantidadDeProductosBD();
        if (data){
            SetPasos({ paso1 : "success" , paso2 : "success" , paso3 : "load"})
            SetcantidadProductos(data);
            let x =  Math.ceil(parseFloat( data ) / 2000);
            SetNumeroDePeticiones(x);
            setTimeout( () => {
                EjecutarPaso3(data);
            }, 2000)
        }
       


 
    }

    
    const [progreso, setProgreso] = useState(null);

    // Función para cargar productos en bloques
    const  EjecutarPaso3  = async (data) =>  {
       
        const totalProductos = parseFloat(data);

        
        const productosPorBloque = 2000;
        
        const totalBloques = Math.ceil(totalProductos / productosPorBloque);
      
        let todos = [];


        if( !(totalBloques > 1) ){
            console.log("no hay blosques!!!! totalBloques: "  + totalBloques )
        }
        for (let i = 0; i < totalBloques; i++) {
            
            const inicio = i * productosPorBloque;
            const fin = Math.min((i + 1) * productosPorBloque, totalProductos);


            const porcentaje = ((i + 1) / totalBloques ) * 100;
            setProgreso(Math.round(porcentaje) );


            try {
                const respuesta = await cargarProductosByPagina(inicio, 2000);
                todos = todos.concat(respuesta);
            } catch (error) {
                console.error(`Error al cargar el bloque ${i + 1}: ${error.message}`);
            }

            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        console.log("Productos en bloque..");
        console.log(todos);
        SettodosLosProductos(todos);

        setTimeout(  () => {
            SetPasos({ paso1 : "success" , paso2 : "success" , paso3 : "success" , paso4 : "load" })
            EjecutarPaso4(todos); 
        }  , 3000)
       
       
    }




    const  EjecutarPaso4 = async (todos) => {



        let productos = combinarProductos( COMPONENTEEEE ,todos )

        cargarUltimaKey();
        
        reloadDatabases(productos , 'productos' , 1 ) .then( (respuestaProductos) => {

           if(respuestaProductos == "ok"){
                getDatos();
                SetPasos({ paso1 : "success" , paso2 : "success" , paso3 : "success" , paso4 : "success" });
                NotificationManager.success("Sincronizados con Exito");
                setTimeout( () => {

                    desactivar();

                }  , 1000)
           }
    
        })
        
        

       // reloadDatabases([] , 'actualizaciones' , 1 );
    }




    return (
        <div>
            {   
                !confirmacion &&
            
                <div>
                    <h4>La Sincronizacion Full puede tardar varios minutos.</h4>
                    <span>¿Desea Continuar?</span>
                    <br></br>
                    <button onClick={ iniciarProceso } className='btn btn-principal' >Si</button>
                    <button onClick={desactivar} className='btn-default btn' > No</button>
                </div>
            }

            

            {   
                confirmacion &&
            
                <div>
                    <h4>Iniciando sincronización de productos</h4>
                    
                    
                 
                    <p>Catidad de Productos : { cantidadProductos }</p>


                    <ul>
                        <li> <span className={ pasos.paso1 == "success" ? 'text-success' : '' } >Cargar Componentes  </span>
                            { pasos.paso1 == "load" &&  <span> ... <i className="fa-solid fa-arrows-spin fa-spin fa-spin-reverse"></i></span> }
                            
                            { pasos.paso1 == "success" &&  <i className=" text-success fa-solid fa-check"></i> }

                            { pruebas && <> <button onClick={EjecutarPaso1}>accion</button> <button onClick={() => console.log(componentes) }>mostra</button></> }
                        </li>
                        
                        <li> <span className={ pasos.paso2 == "success" ? 'text-success' : '' } > Contando Productos </span>
                            { pasos.paso2 == "load" &&  <span> ... <i className="fa-solid fa-arrows-spin fa-spin fa-spin-reverse"></i></span> } 
                            { pasos.paso2 == "success" &&  <i className=" text-success fa-solid fa-check"></i> }
                            { pruebas &&  <><button onClick={EjecutarPaso2}>accion</button> <button onClick={() => console.log(cantidadProductos) }>mostra</button></> } 
                        </li>
                        
                        <li> <span className={ pasos.paso3 == "success" ? 'text-success' : '' } > Extraer Productos</span>  
                            { pasos.paso3 == "load" &&  <span> ... <i className="fa-solid fa-arrows-spin fa-spin fa-spin-reverse"></i></span> }
                             { pasos.paso3 == "success" &&  <i className=" text-success fa-solid fa-check"></i> }
                             { pruebas && <> <button onClick={EjecutarPaso3}>accion</button> <button onClick={() => console.log(todosLosProductos) }>mostra</button> </> } 
                       


                            { ( progreso > 0 &&  progreso < 100 )&&  
                                <div className="progress">
                                <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${progreso}%` }}
                                aria-valuenow={progreso}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                >
                                {progreso}%
                                </div>
                                </div>
                            }

                       
                       
                        </li>
                       
                        <li> <span className={ pasos.paso4 == "success" ? 'text-success' : '' } > Finalizar </span>
                            { pasos.paso4 == "load"    &&  <span> ... <i className="fa-solid fa-arrows-spin fa-spin fa-spin-reverse"></i></span> }
                            { pasos.paso4 == "success" &&  <i className=" text-success fa-solid fa-check"></i> }
                            { pruebas                  && <> <button onClick={() => EjecutarPaso4(todosLosProductos)}>accion</button> <button onClick={() => console.log(todosLosProductos) }>mostra</button>  </> }  </li>
                    </ul>

                </div>
            }

        </div>
    );
};

export default SincronizarProductos;