import React from 'react';
import Thead from './Thead';
import Tbody from './Tbody';

const Table = ({dataList , user}) => {

    const {DATABASE } = user

 
    return (
        <div class="mt-5 table-responsive scrollbar mx-n1 px-1 table-container text-wrap">
            <table class="table fs-9 mb-0 leads-table border-top border-translucent text-wrap">
                <Thead /> 

                <Tbody dataList={dataList} DATABASE={DATABASE}/>
            </table>
        </div>
    );
};

export default Table;