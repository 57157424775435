import { NotificationManager } from "react-notifications";
import { enviarPeticion } from "../../Core/ActionDatabase";
import { ExtraerTabla, reloadDatabases } from "../../Core/Dixie";

export const calcularDigitoVerificacion = (myNit)=>  {
    var vpri, x, y, z;

    // Se limpia el Nit
    myNit = myNit.replace(/\s/g, "");
    // Espacios
    myNit = myNit.replace(/,/g, "");
    // Comas
    myNit = myNit.replace(/\./g, "");
    // Puntos
    myNit = myNit.replace(/-/g, "");
    // Guiones

    // Se valida el nit
    if (isNaN(myNit)) {
        console.log("El nit/cédula '" + myNit + "' no es válido(a).");
        return "";
    }
    ;
    // Procedimiento
    vpri = new Array(16);
    z = myNit.length;

    vpri[1] = 3;
    vpri[2] = 7;
    vpri[3] = 13;
    vpri[4] = 17;
    vpri[5] = 19;
    vpri[6] = 23;
    vpri[7] = 29;
    vpri[8] = 37;
    vpri[9] = 41;
    vpri[10] = 43;
    vpri[11] = 47;
    vpri[12] = 53;
    vpri[13] = 59;
    vpri[14] = 67;
    vpri[15] = 71;

    x = 0;
    y = 0;
    for (var i = 0; i < z; i++) {
        y = (myNit.substr(i, 1));
        // console.log ( y + "x" + vpri[z-i] + ":" ) ;

        x += (y * vpri[z - i]);
        // console.log ( x ) ;    
    }

    y = x % 11;
    // console.log ( y ) ;

    return (y > 1) ? 11 - y : y;
}

export const getDocumentosbyTerceroid = async (id) => {
    let responseHttp = await enviarPeticion('terceros/documentos_personas' , id );
    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }
}


export const getDocumentosbyTerceroidPendiente = async (id , ct_operacion_id) => {
    let responseHttp = await enviarPeticion('terceros/documentos_personas_pendientes' , id + '/' + ct_operacion_id );
    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }
}


export const extraerInfoCliente = async (id , accion ) => {
    let responseHttp = await enviarPeticion('terceros/' + accion  , id  );
    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }
}



export const HttplistarClientes = async () => {
    let responseHttp = await enviarPeticion( 'terceros/listar' );
    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }
}

/** Esta funcion intenta extraer los clientes  desde indexDB, en caso tal no existe los trae directamente de la base de datos*/
export const listarCientesv2 = async () => {
    let tercerosDixie = await   ExtraerTabla('terceros');
    if(tercerosDixie.length > 0){
        console.log("...loading from localstore");
        return tercerosDixie;
    }else{   
        let httpresponse = HttplistarClientes();
        if(httpresponse == -1){
            NotificationManager.error('Error'  , 'Error de internet');
            return -1;
        }else{
            reloadDatabases(httpresponse.data , 'terceros');
        }
    }

}



/** Esta funcion intenta extraer los clientes  desde indexDB, en caso tal no existe los trae directamente de la base de datos*/
export const extraerVendedores = async () => {
   
    let httpresponse =   await enviarPeticion( "terceros/extraer_vendedores" );
    if(httpresponse == -1){
        NotificationManager.error('Error'  , 'Error de internet');
        return -1;
    }else{
        return httpresponse.data
    }
    

}


