
import React, { useState } from 'react';
import { useStateValue } from '../../context/StateProvider';
import {fetchEmpresa, setLocalStore , getLocalstore} from "../../Core/fetchLocalStoreData";
import {enviarPeticion} from "../../Core/ActionDatabase";
import { NotificationManager} from 'react-notifications';
import { __app } from "../../utils/myApp";
import { useEffect } from 'react';






const  OperadoresServicio = (props) =>  {

    const [operadores , setOperadores ] = useState([]);
    const ConfigEmpresa = fetchEmpresa();

    const [ {user , localconfig } ] = useStateValue();

  

    const UpddareExtraerOperadoresServicio = async () => {
        let respuesta =  await enviarPeticion( "terceros/extraer_vendedores" ,  user.TURNO.estacione_id );

        if(respuesta){
            __app.CARGANDO(2);
            
            console.log(respuesta.data);
            
            
            if (respuesta.respuesta == 1 ){
                setLocalStore('vendedores' , respuesta.data);
                NotificationManager.success('Exito'  , 'Los Vendedores del sistema se cargaron con Exito');
                setOperadores( respuesta.data);
            }
        }else{
            __app.CARGANDO(2);
        }
    }

    useEffect( () => {
        let data = getLocalstore('vendedores');

        console.log(data);
        
        if(data){
            setOperadores(data);
        }else{
            UpddareExtraerOperadoresServicio(); 
        }
        
    },  [])

    return (
        <div className='mt-3 mb-3 container'>
           
            <div className=''>
                <h1>Vendedores  Activos</h1>
            </div>
            <hr />
            <br />

            <button onClick={UpddareExtraerOperadoresServicio} className='btn btn-principal'>Sincronizar</button>
            <br/>
            <table className="table">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Codigo</th>
                <th scope="col">Nombre</th>
             
                </tr>
            </thead>
            <tbody>
                { 
                
                operadores.map( (ope , index) => 
                    <tr key={index}>
                        <th scope="row">{index+1}</th>
                        <td>{ope.id}</td>
                        <td>{ope.nombre}</td>
                   
                    </tr>
                 ) 

                }
               
            </tbody>
            </table>
        </div>
    );
}

export default OperadoresServicio;







