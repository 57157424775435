import React, { useState } from 'react';
import { fetchEmpresa, fetchLocalconfig, getNit , fetchUSer } from '../../../Core/fetchLocalStoreData';
import { __pluma7 } from '../../../Core/pluma7';

const Imagenes = ({ item }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);


    const user = fetchUSer();

    const localconfig = fetchLocalconfig();
    
    console.log( user.CONFIGURACIONES_TABLE.URL_servidor_de_imagenes);

    const server = user.CONFIGURACIONES_TABLE.URL_servidor_de_imagenes ??  localconfig.global.servidor;
    

    
    
    
    
    const pathFotos = server + __pluma7.pathfolderImgProductos(getNit() , item.id);

    if (!item.foto) {
        return <p>Sin fotos</p>;
    }

    let arrayImagenes = [];

    try {
        arrayImagenes = JSON.parse(item.foto);
    } catch (error) {
        console.error('Error al analizar JSON:', error);
        return <p>No hay un array de fotos válido.</p>;
    }

    if (!Array.isArray(arrayImagenes)) {
        return <p>No hay un array de fotos válido.</p>;
    }

    const openModal = (image) => {
        setSelectedImage(image);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setModalOpen(false);
    };

    return (
        <div>
            <h4 className='mb-5 mt-5'>Lista de imágenes</h4>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {arrayImagenes.map((img, index) => (
                    <div key={index} style={{ margin: '5px', cursor: 'pointer' }} onClick={() => openModal(img)}>
                        <p>{img}</p>
                        <img src={pathFotos + img} alt={`Imagen ${index}`} style={{ width: '150px' }} />
                    </div>
                ))}
            </div>
            {modalOpen && (
                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '9999' }}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>
                        <button style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', border: 'none', background: 'transparent', fontSize: '20px', color: '#666' }} onClick={closeModal}>×</button>
                        <img src={pathFotos + selectedImage} alt="Imagen seleccionada" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Imagenes;