import React, { useEffect, useState } from 'react';
import {ExtraerTabla} from "../../Core/Dixie";
import { __app } from '../../utils/myApp';
import Moment from 'react-moment'
import 'moment/locale/es'  // without this line it didn't work
const HistorialSincronizados = ({getLastUpdateProductos}) => {

    const lastKey = (localStorage.getItem("firebaseLastKeyProducto") ) ? localStorage.getItem("firebaseLastKeyProducto") :  undefined ; 

    const [ actualizados , setActualizados ] = useState([]);

    useEffect( () => {

        extraerDatos();

    }, [] )


    const extraerDatos = async () => {

        let data = await  ExtraerTabla('actualizaciones');
        if(data){
            setActualizados(data);
        }
        
    }
    
    return (
        <div>
            <h3>Historial de Productos sincronizados Automaticamente.</h3>
            <p>Ultima Key : {lastKey}</p>


           {/* <button onClick={ getLastUpdateProductos }>  </button>*/}

            

            <table className='table'>
                <thead>
                    <tr>
                    
                        <th>Fecha</th>
                        <th>Codigo</th>
                        <th>Referencia</th>
                        <th>Nombre</th>
                        <th>Precio + Imp</th>
                    </tr>
                </thead>
                
                <tbody>
                { actualizados.sort((a, b) => b.fechanumero - a.fechanumero).map( (data, index) =>                   
                    <tr>
                     
                        <td> <Moment fromNow interval={3000}>{__app.formatoFechaGTM5(data.fechaSincronizacion)}</Moment>  </td>
                        <td>{data.id_2}</td>
                        <td>{data.referencia}</td>
                        <td>{data.nombre}</td>
                        <th className='fs-9'> $ { __app.formato_decimal_2( {total: data.PRECIO_PRINCIPAL}) }</th>
                    </tr>         
                 )}
                </tbody> 
            </table>
            
        </div>
    );
};

export default HistorialSincronizados;