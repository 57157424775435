


const OptionSelect  = ({ datos  }) => {


    if ( !Array.isArray(datos)) return (<option>Error! No tenemos un elemento valido para mostrar.</option>)
    

    return (

        <>
            <option>Seleccione una Opción</option>
            {
                datos.map( (item , index) => 

                    <>
                        {item.id && <option key={index} value={item.id} > { item.nombre } </option> }

                        {!item.id && <option key={index} value={item} > { item } </option> }

                    </>

                )  
            }
        </>

    );

    
}

export default OptionSelect;