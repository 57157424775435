import React, {  useState } from 'react';
import { __app } from '../../../utils/myApp';
import {  getCuentasEliminadas } from '../Controlador';

import StandarModal from '../../Document/PartesDocument/StandarModal';


const CuentasAbiertasEliminadas = ({turno_id}) => {
    const [ itemsVendios , setItemVendios ] = useState([]);
    const [ carga , setCarga ] = useState(null);


    const [ totales , setTotales ] = useState({
        total : 0,
    });


    const [ showViewForm , SetshowViewForm] = useState();


    const cargarVentasItemsbyTurno = async () => {

        if( itemsVendios.length > 0  ){
            return false;
        }

        if ( carga == 1 ){
            return false;
        }

        setCarga(1);
        
        let data = await  getCuentasEliminadas(turno_id  );

        if  (data.length > 0){


            console.log(data);

            setCarga(null);
            setItemVendios(data);


            data.map((item, index) => {
                
                // Convierte las propiedades a números antes de sumarlas
                const total = parseFloat(item.total);

              
                // Acumula los valores en totales
                setTotales(prevTotales => ({
                    total: prevTotales.total + total,
                }));
            })

        } 

    }





    const index = 4854547545454;

    const [ documentoo , SetDocumento  ] = useState(null);

    const verDetalles = (documento) => {
        SetDocumento(documento)
        SetshowViewForm(true);
    }


    const fechaEliminacion =  ( inicio , fin  ) => {

        var fechaInicio = new Date(inicio);
        var fechaEliminacion = new Date(fin);


        // Calcular la diferencia en milisegundos
        var diferenciaEnMilisegundos = fechaEliminacion - fechaInicio;

        // Convertir la diferencia a segundos
        var diferenciaEnSegundos = diferenciaEnMilisegundos / (1000 * 60);

        // Mostrar el resultado
        diferenciaEnSegundos = diferenciaEnSegundos * -1;
        return  parseInt(diferenciaEnSegundos);
    }

    return (
        <div className="accordion" id="accordionPanelsStayOpenExample2" >
                
        
            <div key={index} className="accordion-item">
               
                    <h2 className="accordion-header">
                       
                        <button onClick={cargarVentasItemsbyTurno} className={'bg-opacity-10  accordion-button collapsed' + ' bg-danger'  } type="button" data-bs-toggle="collapse" data-bs-target={'#panelsStayOpen-collapse' + index } aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      
                        <span className={'fw-bold '}>  CUENTAS ABIERTAS ELIMINADAS 
                        
                        { carga  == 1  &&  <span> Cargando..</span> }     </span> 
                        
    
                        </button>

      

                    </h2>
                    <div id={'panelsStayOpen-collapse'  + index } className="accordion-collapse collapse"  style={{maxHeight: "500px", overflow : "auto"}}>
                        <div className="accordion-body">


                            { ( carga &&   carga == 1)  &&  <p>Cargando...</p> || 
                            
                                <table className='table '>
                                    <thead>
                                        <tr>
                               
                                            <th>Fecha </th>
                                            <th>Eliminacion </th>
                                            <th>Tiempo </th>
                                            <th>responsable</th>
                                            <th>Vendedor</th>
                                            <th>Mesa</th>
                                            <th>Total</th>
                                            <th>Detalles</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {( itemsVendios.map( (item, index) => 

                                        <tr>    
                                     
                                            <td>{item.fecha}</td>
                                            <td>{item.documentojson.FORM.FechaHoraLocal}</td>
                                            <td> { fechaEliminacion(item.fecha ,item.documentojson.FORM.FechaHoraLocal )} minutos</td>
                                            <td>{item.usuario_id}</td>
                                            <td>{item.documentojson.FORM.nombre_vendedor}</td>
                                            <td>{item.documentojson.FORM.mesanombre}</td>
                                            <td> $  { __app.formato_decimal( {total :item.documentojson.MATEMATICAS.SUBTOTAL_3})} </td>
                                            <td> <button  onClick={ () => verDetalles(item) } className='btn btn-principal btn-xs'> Ver detalles </button> </td>  
                                            
                                           
                                            
                                        </tr>   

                                    ))}
                                    </tbody>
                                
                                </table>
                            }
                        </div>
                    </div>
            </div>
         
          {/***
                 * Formulario Guardad Documento
            */}
            <StandarModal size="xl"  titulo="Guardar Documento"  desactivar={() => SetshowViewForm(!showViewForm) } mostrar={showViewForm}  > 
               

               <div className='row'>
                    {
                        console.log(documentoo)
                    }
                    <div className='col-6'>
                        <h3>Items de cuenta </h3>
                        <table className='table'>
                            <tr>
                                <th>Codigo</th>
                                <th>Nombre</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                            </tr>
                            {   
                                ( documentoo ) && 
                                <>
                                    { documentoo.documentojson.PRODUCTOS.map((pro) => 
                                        <tr>
                                            <td>{pro.id_2}</td>
                                            <td>{pro.nombre}</td>
                                            <td>{pro.MATEMATICAS.CANTIDAD}</td>
                                            <td>{pro.MATEMATICAS.CANTIDAD}</td>
                                            <td>{pro.MATEMATICAS.SUBTOTAL_3}</td>
                                        </tr>
                                    ) }
                                </>
                            }
                        </table>
                    </div>
                    
                    
                    <div className='col-6'>
                        <h3>Items Borrados</h3>
                        <table className='table'>
                            <tr>
                                <th>Borrado</th>
                                <th>Codigo</th>
                                <th>Nombre</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                            </tr>
                            {   
                                ( documentoo ) && 
                                <>
                                    {  documentoo.itemsBorrados.map((pro2) => 
                                        <tr>
                                            <td>{pro2.item.fecha_eliminacion} </td>
                                            <td>{pro2.item.id_2}</td>
                                            <td>{pro2.item.nombre}</td>
                                            <td>{pro2.item.MATEMATICAS.CANTIDAD}</td>
                                            <td>{pro2.item.MATEMATICAS.CANTIDAD}</td>
                                            <td>{pro2.item.MATEMATICAS.SUBTOTAL_3}</td>
                                        </tr>
                                    ) }
                                </>
                            }
                        </table>

                    </div>
               </div>
                
            </StandarModal> 

        </div>
    );
};


export default CuentasAbiertasEliminadas;