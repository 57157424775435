import React, { useState } from 'react';
import Detalles from './ViewProducto/Detalles';
import Imagenes from './ViewProducto/Imagenes';

const LoadView = ({item}) => {

    

    const [menu , Setmenu ] = useState(1);


    return (
        <div>

            <nav class="navbar pb-4 px-0 sticky-top bg-body nav-underline-scrollspy" >
                  <ul class="nav nav-underline fs-9">
                    <li class="nav-item"><a  onClick={() => Setmenu(1)} class="nav-link me-2" href="#scrollspyTask"> Detalle </a></li>
                    <li class="nav-item"><a onClick={() => Setmenu(2)} class="nav-link me-2" href="#scrollspyDeals">Fotos</a></li>
                  </ul>
            </nav>

           
           {menu === 1 && <Detalles item={item} />}


           {menu === 2 && <Imagenes item={item} />}
        </div>
    );
};

export default LoadView;