import { __app } from "../utils/myApp";

export const  calcularUtilidad = (precio, costo, formula = 1 ) =>  {

    precio = parseInt(precio);
    costo = parseInt(costo);
    if(formula === 1){
        
        // Calcular la ganancia
        let ganancia = precio - costo;

        // Calcular la utilidad en porcentaje
        let utilidadPorcentaje = (ganancia / costo) * 100;
        if (utilidadPorcentaje === Infinity){
            utilidadPorcentaje = 100;
        }
        return { ganancia, utilidadPorcentaje };

    }
    
    let utilidadPorcentaje = ((precio - costo) / precio) * 100;
    let ganancia = precio - costo; 
    return { ganancia, utilidadPorcentaje };
   
}

export const calcularPrecio = (costo, utilidadPorcentaje , formula = 1 ) => {
    
    costo = parseInt(costo);
    utilidadPorcentaje = parseInt(utilidadPorcentaje);

    
    if(formula===1){
        const precio =  ((costo * utilidadPorcentaje) /  100) + costo;
      
        return Math.round(precio,2);
    }

    let precio = costo / (1 - (utilidadPorcentaje / 100));
    return Math.round(precio,2);;
}


export const SumarImpuestosPrecio = ( producto , precio_entrada = null ) => {

    if(producto.IMPUESTOS_RAIZ && producto.IMPUESTOS_RAIZ.length > 0){
        // Suponiendo que tienes el objeto JSON en una variable llamada 'producto'
        if( precio_entrada === null){
            precio_entrada = producto.PRECIO_PRINCIPAL;
        }

        let precioFinal = parseFloat(precio_entrada);
        let porcentajeTotalImpuestos = 0;
        producto.IMPUESTOS_RAIZ.forEach(impuesto => {
            if( impuesto.tipo_tributo_id == 1){
                const porcentajeAumento = parseFloat(impuesto.importe);
                porcentajeTotalImpuestos += porcentajeAumento;
            }
        
        });
        precioFinal += precioFinal * (porcentajeTotalImpuestos / 100);
        return  __app.redondearAlMultiploDe100(precioFinal);

    }
    return  __app.redondearAlMultiploDe100(precio_entrada); 
}


export const obtenerValorAntesDeImpuestos = (precioBruto, impuestos) => {
    // Verificar si hay impuestos definidos

    precioBruto = parseFloat(precioBruto);
    if (impuestos && impuestos.length > 0) {

        let impuesto_descontar = 0;
        // Iterar sobre cada impuesto en la lista
        for (let impuesto of impuestos) {
            // Verificar si el impuesto es del tipo porcentaje
            if (impuesto.tipo_tributo_id == 1) {
                // Convertir el porcentaje de impuesto a un decimal
                const decimalImpuesto = parseFloat(impuesto.importe) / 100;
                // Sumar 1 al decimal obtenido
                const factor = 1 + decimalImpuesto;
                // Dividir el precio bruto por el factor obtenido
               console.log(factor);
               impuesto_descontar += precioBruto/ factor
            }
        }


        return  parseFloat(impuesto_descontar.toFixed(2)); ;
    }
    // Devolver el valor antes de impuestos
    return precioBruto;
}


export const extraerSoloImpuestoTotal = ( producto) => {
    
    if( (parseFloat( producto.PRECIO_PRINCIPAL ) > 0 )  ){
        
    }else{
        return 0;
    }

    if(producto.IMPUESTOS_RAIZ && producto.IMPUESTOS_RAIZ.length > 0){ 
        let porcentajeTotalImpuestos = 0;
        producto.IMPUESTOS_RAIZ.forEach(impuesto => {
            if( impuesto.tipo_tributo_id == 1){
                const porcentajeAumento = parseFloat(impuesto.importe);
                porcentajeTotalImpuestos += porcentajeAumento;
            }
        });    
      
        let data = (parseFloat(producto.PRECIO_PRINCIPAL) *  porcentajeTotalImpuestos ) / 100 ;
        return  __app.redondearAlMultiploDe100(data);
    }
    return  0; 
}

/**
 * Calcula el porcentaje basado en un precio del 100% y otro valor (puede ser el descuento).
 */
export const CalcularPorcentaje = (descuento , precio100 ) => {
    // Asegúrate de que ambos valores se conviertan a número
    const precioOriginal = Numpositivo(precio100);
    const descuentoValue = Numpositivo(descuento);
  
    // Calcula el porcentaje de descuento
   
    const porcentajeDescuento = (descuentoValue / precioOriginal) * 100;
    

    // Devuelve el porcentaje con dos decimales
    return porcentajeDescuento.toFixed(2);
  };

  export const Numpositivo = (num) => {
        if(!num) return 0;
        return  Math.abs(parseFloat(num)); // Devuelve 10
  }