

import { useStateValue } from "../../context/StateProvider";
import VerticalNav from './VerticalNav/VerticalNav';
import TopNav from './NavTop/TopNav';
import Main from './Main';
import { useLocation } from 'react-router-dom';


import '../../estilos/customBoostrap.css';
import '../../estilos/index.css';
import '../../estilos/teclado.css';
import '../../estilos/bootstrapMobil.css';



function LayautDefault(props) {

    const [ {user}   ] = useStateValue();

    const location = useLocation();
    
    console.log(location.pathname)
    return (
        <main className="main" id="top">
            
            { '/crearDocumento' != location.pathname &&  
                <>
                    <VerticalNav />
                    <TopNav />

                    <div  className="content">
                    
                        <div  className="pb-5">
                          
                            <Main user={user} /> 
                        </div>
        
        
                        <footer  className="footer position-absolute">
                            <div  className="row g-0 justify-content-between align-items-center h-100">
                                
                            </div>
                        </footer>
                    </div>

                </> 
            }

            { '/crearDocumento' == location.pathname &&   <Main user={user} />  }

           

        </main>
    );
}

export default LayautDefault;