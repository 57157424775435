import userEvent from '@testing-library/user-event';
import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { __app } from '../../../../utils/myApp';
import DisplayNumber from './DisplayNumber';

const DescuentoUnitario = ({fontSize , decimal = true , item,indexx,accionF,keyShow,accionName , descuento , user , clasesDefault, documento }) => {
    
    const [showinput , setShowInput ] = useState(false);


    const handleChange = (e) => {
        setShowInput(false);
        let valor = e.target.value;
        let accion = accionName;
        accionF({indexx,valor, accion});
    }


    const _handleKeyDown =  (e)  => {
        if (e.keyCode === 13) {
            handleChange(e);
            e.preventDefault();
            return false;
        } 
    }


    /*** Bloqueamos el Descuento */
    if(user.USER.type_user_id  > 1  && item.permitir_descuento == 0){

        if(descuento){
            if(item.permitir_descuento == 0){
                return (
                    <>
                        <DisplayNumber fontSize={fontSize}  number={  __app.formato_decimal({total: item.MATEMATICAS[keyShow]}) }  />   <i className="fa-sharp font-s-candado  fa-solid fa-lock"></i>
                    </>
                )
            }
        }
    }


    if ( keyShow === "DESCUENTO_UNITARIO_PORCENTUAL"){
        return (
            <>        
                { !showinput && 
                    <div onClick={ () =>   setShowInput(true) } className={clasesDefault} >  
                        (<DisplayNumber fontSize={fontSize}  number={  __app.formato_decimal({total: item.MATEMATICAS[keyShow] , decimal : documento.ESTRAS.DECIMAL }) }  />%)
                    </div> }  

                {showinput && <input    style={{fontSize:fontSize+2}} onKeyDown={_handleKeyDown}  className='max-50' autoFocus onBlurCapture={ handleChange}  defaultValue={item.MATEMATICAS[keyShow]} />}
            </>
        );
    }

     

    if ( keyShow === "CANTIDAD" && user.USER.type_user_id  !=  1  &&  (item.fechaid !== documento.fechaid )  ){
        return (
            <>    
                <DisplayNumber fontSize={12} number={__app.formato_decimal({total: item.MATEMATICAS[keyShow] ,  decimal : documento.ESTRAS.DECIMAL })} >
                 </DisplayNumber> <i className="fa-sharp font-s-candado  fa-solid fa-lock"></i>
            </>
        );
    }





    return (
        <>        
            { !showinput && <div onClick={ () =>   setShowInput(true) } className={clasesDefault} >  
                {
                    (keyShow === "DESCUENTO_UNITARIO" && item.MATEMATICAS[keyShow] > 0 ) && <>-</>

                }  
                 
                
                {
                    decimal && <> <DisplayNumber fontSize={fontSize} number={__app.formato_decimal({total: item.MATEMATICAS[keyShow] , decimal : documento.ESTRAS.DECIMAL })} />  </> ||  <b> <DisplayNumber fontSize={fontSize+3} number={item.MATEMATICAS[keyShow]}  /> </b>
                }

               
             </div> }
                
            {showinput && <input  style={{fontSize:fontSize+2}} onKeyDown={_handleKeyDown}  className='max-50' autoFocus onBlurCapture={ handleChange}  defaultValue={item.MATEMATICAS[keyShow]} />}
        </>
    );
}

export default DescuentoUnitario;