import React from 'react';
import MyLogin from './user/Login.jsx';



import { useStateValue } from "../context/StateProvider";
import HomeContainer from './HomeContainer.jsx';



const MainContainer = (props) =>  {

    const [ {user}   ] = useStateValue();

    return (
        <>   
            { !user && <MyLogin /> }
            { user && <HomeContainer /> }
        </>
    );
}

export default MainContainer;