import React from 'react';

const ErrorPeticionHttp = () => {
    return (
        <div  className='alert alert-danger'>
            <p className='fs-5'>Error de conexión </p>
            <b>Por favor verifique que tenga conexión a internet. </b>
        </div>
    );
};

export default ErrorPeticionHttp;