import React, { useEffect, useRef } from 'react';
import { useState } from 'react';

const MesasSeleccion = ({focusMesa , user, guardarMesa , mesaActual }) => {


    if(focusMesa == 1 ){
        setTimeout(() => {
            input.current.focus();
            console.log("MESERO")
        }, 300)
    }

    const [mesa_id , setMesaid] = useState();
    
    const input = useRef();

    useEffect(() => {
        let mesa_idayx = (mesaActual && mesaActual.mesa_id ) ? mesaActual.mesa_id +"/"+  mesaActual.mesanombre : "0";
        setMesaid(mesa_idayx);

       
    }, [mesaActual])
    
    const handleChange = (e) => {
        
        let aux = e.target.value;
        var porciones = aux.split('/');
        let data  =  {
            renderizar_total : false,
            mesa_id :  porciones[0],
            mesanombre : porciones[1],

        };
       guardarMesa ({ valor :  data , accion : "actualizar_mesa" });
    }
    
    return (
        <div className="form-group  form-online " style={{minWidth:'180px'}}> 
           
            <select ref={input} value={mesa_id} onChange={ handleChange }  name="vendedor" className="form-select form-control" aria-label="Default select example">
               <option value="" >Seleccione una Mesa</option>
               {user.MESAS.map((mesa, index) => 
                    <option key={index} value={mesa.id + "/" + mesa.nombre }> 
                        {mesa.nombre + "/" + mesa.sessionname }
                    </option>
               ) }
            </select> 
        </div>
    );
};

export default MesasSeleccion;