import React, { useEffect } from 'react';
import { useState } from 'react';
import { __app } from '../../../../utils/myApp';
import { getLocalstore } from  '../../../../Core/fetchLocalStoreData'
import ItemCompuesto from './SubPartesItems/ItemCompuesto';
import TagsCocina from './SubPartesItems/TagsCocina';


/**** Esta Opcion nos Permite sacar una lista de los propductos compuestos y debemos seleccionar Cada uno de ellos, aplica para el restaurante, ya que 
 * el mimo plato, puede tener diferente carne, dirente jugo, etc.
 */
const SeleccionProductosCompuesto = (   {auxitem , accion , desactivar}  ) => {


        
    
    const categorias = getLocalstore('Listcategorias');

    
    
    
    const [ item, setItem ] = useState(  auxitem.COMPUESTO ? auxitem.COMPUESTO : null  );
  


    const [itemActuales , SetItemActuales] = useState(   []  );



    const [nuevoPrecio, setNewPrecio ] = useState(auxitem.PRECIO_PRINCIPAL)
    

    const [ categoriasPlato , SetcateriasPlato ]  = useState();
   
    const [totalPaso , setTotalpasos] = useState();
    
    
    const [pasoActual , setPasoActual ] = useState(0);

    const [SubPasopasoActual , setSubPasopasoActual ] = useState(0);

    const [ grupoCategorias , setGrupoCategorias] = useState();


    /** Contador: Esta Variable almacena el numero de platos, si los platos son muchos es mejor clasificarla. pero si son pocas, es mejor omiter un paso  */
    const  [CantidadproductosTipoplato , setCantidadproductosTipoplato ] = useState();


    const [ switchComentario , setswitchComentario] = useState(null);

    const [ comentario , SetComentario ] = useState();



    
    useEffect(() => {
        
        
        
        if ( auxitem.COMPUESTO && auxitem.COMPUESTO.length > 0  ){

            SetItemActuales ( auxitem.COMPUESTO.filter( (compuesto) => compuesto.autocheck == 1  ))
        } 
        getCategorias();
        
    } , [])
    

    /**** recoore el arra, intenta clasificar las categorias por medio del campo auxRestauranteTipoMenu */
    const getCategorias = () => {

      
        if(item && typeof item === 'object'){
            alert("so")
            const categorias = [];

            for (let i = 0; i <  item.length; i++) {
                categorias.push(item[i].auxRestauranteTipoMenu);
            }
            const dataArr = new Set(categorias);
            let aux = [...dataArr];

            
            SetcateriasPlato(aux);
            setTotalpasos(aux.length);  
            getGrupos(aux);
            SetfiltroPlato(aux[0])
        }else{
            alert("no")
        }
    }



    /** genera Categorias */
    const getGrupos = (aux) => {
        const clasificacion = clasificarPorProductogrupoId(aux);

    }

    // Función para clasificar por "productogrupo_id"
    function clasificarPorProductogrupoId(aux) {
        const categorias = [];

        for (let i = 0; i <  auxitem.COMPUESTO.length; i++) {

            if( aux[pasoActual] ==   auxitem.COMPUESTO[i].auxRestauranteTipoMenu ){
                categorias.push( auxitem.COMPUESTO[i].productogrupo_id);
            }
           
        }

        /*** El numero de total de platos : todos los de cerdos, todos los de pollo etc */
        setCantidadproductosTipoplato(categorias.length);


        const dataArr = new Set(categorias);
        let aux2 = [...dataArr];


        /**** Numero total de Categorias : POLLO, PESCADO , CERDO */
        const numerototalDeTipos  = aux2.length;

        setGrupoCategorias(aux2)
        
    }
            


    /** Funcion de apoyo. */
    const devolverNombreReal = (id) => {

        if (categorias){
            let data =  categorias.filter( (data, index) => data.id == id );

            if(data && data.length > 0 ){

                return data[0].nombre;
            } 
        }
       
        return "Sin Categoria."      
    }




    /*** Observa el cambio del Paso para reclasificar */

    const [ filtroPlato , SetfiltroPlato ] = useState();

    const [ filtroGrupoid , SetFiltroGrupoid ] = useState();

    useEffect(() => {

        /** Llama nuvamente a clasificar por grupo ID los correspondiente a el Menu : Proteinas, Platos , Sopa , etc */
        
        if(categoriasPlato && categoriasPlato.length > 0){

            clasificarPorProductogrupoId(categoriasPlato);

            console.log(categoriasPlato[pasoActual])
            let filtro =   auxitem.COMPUESTO.filter( item => item.auxRestauranteTipoMenu == categoriasPlato[pasoActual] );
            SetfiltroPlato( categoriasPlato[pasoActual] );
        }

        setSubPasopasoActual(0);
        SetFiltroGrupoid(null)

    }, [pasoActual])








    const agregardatos = (event,itemCompuesto) => {

        let newlist = null;
        let config = {...itemActuales}; 
        console.log(itemCompuesto);
        if (event.target.checked) {
            newlist =  [...itemActuales , itemCompuesto];
        } else {
            newlist = itemActuales.filter((lista) => lista.id !=  itemCompuesto.id  );
        }

        sumarTotal(newlist)
        SetItemActuales(newlist)
        
    }


    const sumarTotal  =(newlist) => {

        let total = newlist.reduce(function(sum, current) {
            return sum + Number(current.PRECIO_COMPUESTO_TOTAL);
        }, 0); // 43 + 32 + 21 = 96     
        setNewPrecio( Number(total) + Number(auxitem.PRECIO_PRINCIPAL) )
    } 



    /*** Filtra los productos compuesto de la categoria Seleccionada */
    const MostrarProductosByCategoria = (categoria_id) => {

        setSubPasopasoActual(1);
        let filtro =  item.filter( item => item.productogrupo_id == categoria_id );
        //setItem (filtro)
        SetFiltroGrupoid(categoria_id);
        console.log(filtro);


    }

    /** Esta Funcion devuleve las categorias hacia atras */
    const devolverPasoSubCategoria = () => {

        setSubPasopasoActual(0);
        //setItem ( auxitem.COMPUESTO)
    }




    const handleClick =  () => {

        accion({ ...auxitem , COMPUESTO : itemActuales , PRECIO_PRINCIPAL : nuevoPrecio , descripcion : comentario   })
    }

    const eliminarseleccion = (id) => {
       
        let copy = itemActuales.filter((data, index) => id != data.id);
        SetItemActuales(copy);
        sumarTotal(copy)

    }

    const Cargarcomentario = (comen) => {

        setswitchComentario(null);
        SetComentario(comen)
        
    }

    return (

        
        <div className='container-fluid'>
            
       
           <h1>Hola mundo   :{SubPasopasoActual} </h1>
            <button onClick={() => console.log(categoriasPlato)}>categoriasPlato</button>
            
            { !switchComentario && 
                <> 
            
                    { ( categoriasPlato  && categoriasPlato[pasoActual] ) && <h3>{categoriasPlato[pasoActual]}</h3>}

                    { (categoriasPlato && categoriasPlato.length >= 0 )  &&
            
                        <div className='row'>
                        
                        
                            <div className='mb-3 col'>                
                                    
                                    { 
                                        (SubPasopasoActual == 1) && 
                                        (grupoCategorias.length >= 2 && CantidadproductosTipoplato > 4 ) 
                                        &&  
                                        <button   onClick={devolverPasoSubCategoria} className=' btn  btn-xs'> Atrás <i class="fa-solid fa-angles-left"></i>  </button> 
                                        
                                    } 


                                    { (pasoActual > 0 || SubPasopasoActual == 0) &&  <button disabled={!pasoActual > 0}  onClick={() => setPasoActual( pasoActual - 1 )} className='btn-xs  btn '>  <i class="fa-solid fa-angles-left"></i> Atrás  </button> }
                                
                                
                                    { (pasoActual <  (totalPaso - 1  )) && <button disabled={ ! (pasoActual <  (totalPaso - 1  )) }  onClick={() => setPasoActual( pasoActual + 1 )} className=' btn-xs  btn '> Siguiente <i class="fa-solid fa-angles-right"></i> </button> }              
                            </div>
                

                
        
                        
                    
                            {/* Categorias */}
                            { 
                            /** Si esta en el subpaso */
                            (SubPasopasoActual == 0) && 
                            (grupoCategorias.length >= 2 && CantidadproductosTipoplato > 4 ) 
                            &&  
                                <div class=" list-group">
                                    {grupoCategorias.map((data, index) => 
                                        <li  key={index}  onClick={ () => MostrarProductosByCategoria(data) }  class="list-group-item p-2 list-group-item-action">{devolverNombreReal(data)}</li>
                                    )}
                                </div>
                            || 
                                
                                <span> <ItemCompuesto 
                                filtroGrupoid={filtroGrupoid } 
                                filtroPlato={filtroPlato} 
                                agregardatos={agregardatos} 
                                itemActuales={itemActuales}  
                                itemAll={auxitem.COMPUESTO}    
                                /> </span>
                            } 

                        </div>
                    }
                </>
            }




           

            { switchComentario && <TagsCocina comentario={comentario}  auxitem={auxitem} Cargarcomentario={Cargarcomentario} /> }    



                

            { !switchComentario && <div className='row'> 

                <div className='col-12'>
                    { itemActuales && itemActuales.map((data,index) => 
                        <span key={index} class="mt-1 ms-1 badge text-bg-secondary">{data.nombre} <i onClick={ () => eliminarseleccion(data.id)}  class=" ms-1 link fa-solid fa-xmark"></i> </span>
                    ) }  


                    <div  onClick={setswitchComentario}      className='mt-3 comentario-div'>
                       
                        { (comentario  && comentario.length > 1)  &&  <i className='text-primary'>{comentario}</i> || <> <i class="fa-regular fa-comment"></i>  Hacer un comentario</> } 
                    </div>
                </div>
                <div className='col-12'>
                   
                    <button onClick={handleClick} disabled={totalPaso-1 != pasoActual} className='btn btn-principal mt-3 float-end'> <div className='text-principal-2 fw-bold'>$ { __app.formato_decimal( {total :nuevoPrecio    }) } </div></button>
                    <button onClick={desactivar} className='btn btn-light mt-3 me-3 float-end'> <div className='text-principal-2 fw-bold'> Cancelar</div></button> 
                </div>
            </div>}
               
            
             
        </div>
    );
};



/**** Esta Oc */
export default SeleccionProductosCompuesto;