import { fetchUSer , fetchLocalconfig} from "../Core/fetchLocalStoreData"

const localconfig = fetchLocalconfig();


const userInfo = fetchUSer();



export const initialState = {

    user : userInfo,

    infodatabase : {

        TIPO_DE_DOCUMENTO :  null
        
    },

    localconfig : localconfig



}