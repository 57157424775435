import React from 'react';
import { useState } from 'react';
import { useRef , useEffect} from 'react';


import {  reloadDatabases } from "../../../Core/Dixie"
import { NotificationManager} from 'react-notifications';
import {   resetearDocumentoGlobal } from "../../Document/controlador";


import { useContextDocumentValue , actionTypeDocument } from "../context/ContextDocument.js";


function GuardarDocumento({documento , accion , desactivar}) {

    const inputEl = useRef();

    const [model , setModel ] = useState();

    const [error , setError ] = useState(0);

    const [ {}  ,  dispatch ] = useContextDocumentValue();

    useEffect( () => {

        if (documento.nombre_documento){
            setModel(documento.nombre_documento) 
        }

        setTimeout(() => {
            inputEl.current.focus();
          }, 100);
    } , [] )


    const handleSubmit = (e) => {
        
        e.preventDefault();


        if  ( !(model.length  > 1)) {
            setError(1);
            inputEl.current.focus();
            setTimeout(() => {
                setError(0);
              }, 3000);
            return false;
        }

        if ( documento.PRODUCTOS.length  > 0 ){
            
            let copy = {...documento , "nombre_documento" : model}
            let documentoOBj  = [copy];
            reloadDatabases(documentoOBj     , "documentos");
            NotificationManager.success('Documento Guardado con Exito ', 'Guardado');
            
           resetearDocumentoGlobal(copy.KEYDOCUMENT , dispatch , actionTypeDocument )

            desactivar(false);
        }else{
            NotificationManager.warning('No hay productos para guardar', 'Error');
        }
      
        return false;
    }

    return (
        <div>
            
            <form onSubmit={handleSubmit}>
                <div className='mb-3'>
                    <label  className='form-label' htmlFor="">Nombre del Documento : {model} </label>
                    
                    <input placeholder='Ejemplo: factura de Camilia' defaultValue={model} ref={inputEl} onKeyUp={ (e) => setModel(e.target.value) } className='form-control' /> 
                    
                    {error == 1 && <label className='text-danger'> * Escribar un nombre para continuar</label> }
                </div>

                <div className="bd-callout bd-callout-info">
                    <strong>Aviso:</strong> Esta accion no guardara el documento en la base de datos, solo podras ver este documento espeficicamente en 
                    esta computadora y sera de manera temporal
                </div>

                <button type="submit" className="btn btn-principal float-end">Guardar Documento</button>
            </form>
        </div>
    );
}

export default GuardarDocumento;