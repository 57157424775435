import React , {useState  } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import {enviarPeticion} from "../../../Core/ActionDatabase";
import {getNit} from "../../../Core/fetchLocalStoreData";
import { __app } from "../../../utils/myApp";


import Select from 'react-select'


const NuevoItem = ({shitch,codigo,user,agregarItemPadre , fijo , establecerFocusPadre,productos}) => {

   

    const  $inputCheck = useRef();
    const Codigo = useRef();
    const Nombre = useRef();

 


    const initialProducto = {
        
        id_2 : codigo,
        nombre : "",
        PRECIO_PRINCIPAL : 0,
        COSTO : 0,
        inventariable : 1,
        tipo_producto : 1,
        stock : 1,
        

        shitchInventariable : true,
        compuesto_producto_id : "0",
        compuesto_cantidad : null
        
    }

   

    useEffect( () => {  
        if(shitch){
           
            
        }
        setinfoNewitme(initialProducto);
    } , [shitch] )



    useEffect( () => {
        setinfoNewitme(initialProducto);
    } , [ codigo] )


    const handleInputChange = (e) => {
       
        if( e.target.name != "shitchInventariable"  && e.target.name.length > 0  ){
            
            let copy =  {...infoNewitmen , [e.target.name] : e.target.value } ;
            console.log(copy)
            setinfoNewitme( copy)
        }
       
    }

    const handleSubmit = async (e) => {
        /*
        if (infoNewitmen.id_2.length <= 1   ){

            alert("Falta el Codigo")
            Codigo.current.focus();
            e.preventDefault();
            return false;
        }
        */

        if (infoNewitmen.nombre.length <= 2   ){
            Nombre.current.focus();
            e.preventDefault();
            return false;
        }

  


        enviarProdcuto();
        e.preventDefault();
        return false;
    }

    const enviarProdcuto = async (e) => {
        let nit = getNit();
        let resultado = null;
        
        
        let infoData = {
            user : user,
            Producto : infoNewitmen
        }
        __app.CARGANDO();
        resultado  =  await enviarPeticion("productos/guardarProducto"  , nit ,
            {
                body : infoData ,
            }  
        );

        if(resultado){
            __app.CARGANDO(0);
            console.log(resultado);
            if (resultado.respuesta){
                if  (resultado.data.resultado){

                    initialProducto.id_2 = '';
                    console.clear();
                    console.log(initialProducto);
                    setinfoNewitme(initialProducto);
                   


                    if(fijo){
              
                        Codigo.current.focus();

                    }else{
                        //*** Si entramos aqui estamos agregando un item directo apenas lo registremos */
                        establecerFocusPadre();
                        agregarItemPadre( resultado.data.producto  )

                    }
                }else{
                    alert("Error al Registrar PRoducto, Revise los campos");
                }
            }
        }
        
    }

    
    const [ infoNewitmen ,  setinfoNewitme ] = useState(initialProducto);

    const handleChangeProducto = (choise) => {
     
        setinfoNewitme( { ...infoNewitmen , 'compuesto_producto_id' :  choise.id }  ) 
    }
    
    const setShitchInventariable = () => {

       // setinfoNewitme( )
        let copy = { ...infoNewitmen , 'shitchInventariable' :  !infoNewitmen.shitchInventariable } ;
        console.log( copy.shitchInventariable)   ;
        setinfoNewitme(copy)
    }

    return (

        <form  onSubmit={handleSubmit} onChange={handleInputChange}   className='p-4 row' >
            <h3>Nuevo Producto</h3>
            
            <div className='mt-2'>
                Codigo
                <input   ref={Codigo} autoComplete='off' onChange ={() => {} } name="id_2" value={infoNewitmen.id_2} className="form-control"  type="text" />
            </div>

            

            <div className='mt-2'>
                Nombre
                <input  ref={Nombre} autoComplete='off'  onChange ={() => {} } name="nombre"  autoFocus value={infoNewitmen.nombre}  className="form-control"  type="text" />  
            </div>

            <div className='mt-2'>
                Precio de Venta
                <input  autoComplete='off' onChange ={() => {} } name="PRECIO_PRINCIPAL"  value={infoNewitmen.PRECIO_PRINCIPAL}  className="form-control"  type="text" /> 
            </div>

            <div className='mt-4 '>
                <div className="form-check form-switch">
                <input  ref={$inputCheck} onChange={ setShitchInventariable  } defaultChecked={infoNewitmen.shitchInventariable} value={infoNewitmen.shitchInventariable} name="shitchInventariable" className="form-check-input  form-check-input-buscar  " type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked" > 
                    { infoNewitmen.shitchInventariable && <div> Este producto es Inventariable </div> || <div> No inventariable  </div> } 
                    </label>
                </div>
            </div>

            <div className=''>
            
               {
                infoNewitmen.shitchInventariable   && 
                    <div className="">
                        Stock inventario
                        <input  autoComplete='off' onChange ={() => {} } name="stock"  value={infoNewitmen.stock} className="form-control"  type="text" />
                    </div>

                    ||

                    <div className='row'>
                        
                        <div className="col">
                            Este Contenedor(Caja o Display) Contiene 
                            <Select 
                            defaultValue={infoNewitmen.compuesto_producto_id}
                        
                            onChange={ (choise) => handleChangeProducto(choise) }
                            name="compuesto_producto_id"
                            getOptionLabel ={(productos)=>productos.nombre}
                            getOptionValue ={(productos)=>productos.id}
                            options={productos} />
                        </div>

                        

                        <div className="col">
                            Cantidad de unidades de la Caja o Display
                            <input   autoComplete='off' onChange ={() => {} } name="compuesto_cantidad"  value={infoNewitmen.compuesto_cantidad} className="form-control"  type="text" />
                        </div>
                    </div>
               }
               
            </div>
            
            <div className='mt-2'>
                Costo
                <input autoComplete='off' onChange ={() => {} } name="COSTO"  value={infoNewitmen.COSTO} className="form-control"  type="text" />  
            </div>   

            <input type="submit" value="Guardar Producto" className='btn btn-principal btn-lg mt-3  mx-auto ' />

        </form>
    );
}

export default NuevoItem;