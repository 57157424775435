import React, { useEffect, useState } from 'react';
import Documentos from './Documentos';
import DocumentosPendientesVenta from './DocumentosPendientesVenta';
import Abonos from './Abonos';
import DocumentosPendientesCompra from './DocumentosPendientesCompra';
import { useContextLocalValue } from './Core/ContextTercero';
import { __app } from '../../../utils/myApp';

const Menu = ({id , actualizarDatos}) => {

    const [menu , setMenu ] = useState();
    


    useEffect(() => {
        setMenu(1 )
    }, [])
    





    return (
        <div>
            <div className="pt-3 pb-4 px-0 sticky-top bg-body nav-underline-scrollspy z-5">
                <div className="nav-underline nav">
                    <div className="nav-item"><button onClick={() => setMenu(1)}  className={`pt-0 nav-link ` + (menu === 1 ? 'active' : '') } > Documentos </button></div>
                    <div className="nav-item"><button onClick={() => setMenu(2)}  className={`pt-0 nav-link ` + (menu === 2 ? 'active' : '') }>  Pendientes x Ventas  </button></div>
                    <div className="nav-item"><button onClick={() => setMenu(3)}  className={`pt-0 nav-link ` + (menu === 3 ? 'active' : '') }>  Pendientes x Compras </button></div>
                    <div className="nav-item"><button onClick={() => setMenu(4)}  className={`pt-0 nav-link ` + (menu === 4 ? 'active' : '') }>  Historico de pagos. </button></div>
                </div>
            </div>

            <div id="deals" className="lead-details-scrollspy mb-8">
                                    
                {menu == 1 && <Documentos terceroId={id}  actualizarDatos={actualizarDatos} /> }
                            
                {menu == 2 && <DocumentosPendientesVenta terceroId={id}  actualizarDatos={actualizarDatos} /> }


                {menu == 3 && <DocumentosPendientesCompra terceroId={id}  actualizarDatos={actualizarDatos} /> }
                

                {menu == 4 && <Abonos terceroId={id}  /> }
            </div>
        </div>

    );
};

export default Menu;