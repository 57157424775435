import React from 'react';
import { __app, __fecha } from '../../../utils/myApp';
import ImprimirPatio from './ImprimirPatio';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';

const Tablapatio = ({ documentos, camposMostrar , botones  , funciones_botones }) => {
    if (documentos === null) {
        return null; // Cambié 'false' por 'null' para que no renderice nada
    }

    const verificarMostrar = (campo) => {
        return camposMostrar.includes(campo); // Verifica si el campo está en camposMostrar
    };


    const verificarMostrarBotones = (campo) => {
        return botones.includes(campo); // Verifica si el campo está en camposMostrar
    };

    const user = fetchUSer();

    return (
        <div>
            
            <ImprimirPatio  documentos={documentos} />

            <table className='table'>
                <thead>
                    <tr>
                        {verificarMostrar('patio_entrada_fecha') && <th>Entrada</th>}
                        {verificarMostrar('placa') && <th>Placa</th>}
                        {verificarMostrar('descripcion') && <th>Descripcion</th>}
                        {verificarMostrar('categoria') && <th>Categoria</th>}
                        {verificarMostrar('tipo') && <th>Tipo</th>}
                        {verificarMostrar('estadoServidor') && <th>EstadoServidor</th>}
                        {verificarMostrar('cliente') && <th>Cliente</th>}
                        {verificarMostrar('total') && <th>Total</th>}
                         <th>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {documentos.sort((a, b) => b.fechaidFinal - a.fechaidFinal).map((docu, index) => (
                        <tr key={index}>
                            {verificarMostrar('patio_entrada_fecha') && <td>{__fecha.formatoFecha({ $fecha: docu.patio_entrada_fecha })}</td>}
                            {verificarMostrar('placa') && <td>{docu.placa}</td>}
                            {verificarMostrar('descripcion') && <td>{docu.OBJETO.nombre}</td>}
                            {verificarMostrar('categoria') && <td>{docu.OBJETO.nombreCategoria}</td>}
                            {verificarMostrar('tipo') && (
                                <td>
                                    <span className={'badge bg-' + docu.TIPO_DOCUMENTO.color_documento}>
                                        {docu.TIPO_DOCUMENTO.nombre}
                                    </span>
                                </td>
                            )}
                            {verificarMostrar('estadoServidor') && <td>{docu.TERCERO && docu.TERCERO.razonSocial}</td>}
                            {verificarMostrar('cliente') && <td>{docu.CLIENTE || 'N/A'}</td>} {/* Asume que hay un campo CLIENTE */}
                            {verificarMostrar('total') && <td>${__app.formato_decimal({ total: docu.MATEMATICAS.SUBTOTAL_3 })}</td>}
                            
                          
                            <td>
                                {verificarMostrarBotones('imprimirEntrada') && (<button onClick={() => funciones_botones.imprimirEntrada(docu)} >Imprimir Entrada</button> )}


                                {
                                    ( user.PERMISOS.EliminarReciboPatioParqueadero && user.PERMISOS.EliminarReciboPatioParqueadero ==  1 ) &&  <>
                                        {verificarMostrarBotones('imprimirEntrada') && (<button onClick={() => funciones_botones.eliminarPatio(docu.id)} > Eliminar del Patio </button> )}
                                    </>
                                }


                            </td>
                           
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Tablapatio;
