const TarifasForm = ({ tarifasbd, handleChange }) => {
    return (
        <div>
            <h2>Formulario de Tarifas</h2>
            <form className="row">
                {Object.keys(tarifasbd).map((key) => (
                    <div key={key} className="col-2 mb-2">
                        <label htmlFor={key} className="form-label">
                            {key}:
                        </label>
                        <input
                            type="number"
                            id={key}
                            name={key}
                            value={tarifasbd[key]}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                ))}
            </form>
        </div>
    );
};

export default TarifasForm;