import React, { useState, useRef, useEffect } from "react";
import Modalp7 from "../../../../Core/Html/Modalp7";

const CampoFullCantidad = ({ show, handleClose, accion , item }) => {
  const [cantidad, setCantidad] = useState("1"); 

  const [precio, setPrecio] = useState(item.PRECIO_PRINCIPAL); 

  const inputRef = useRef(null); // Referencia al input

  const inputPrecio = useRef(null); // Referencia al input

  
  
  const handleKeyDown = (e) => {


    if (e.keyCode === 13) {
      e.preventDefault(); 
      inputPrecio.current.focus();
      inputPrecio.current.select();
      return false;

    }


    if(e.key ===  'ArrowDown') {
     
      e.preventDefault();
      inputPrecio.current.focus();
      inputPrecio.current.select();
      return false;
    }




  };

  const handleKeyDownPrecio = (e) => {
    
   
     console.log( e.keyCode);
    
    if (e.keyCode === 13) {
      e.preventDefault();
      accion({cantidad, precio});
      handleClose();
      return false;
    }


    
    if(e.key ===  'ArrowUp') {
      e.preventDefault();
      inputRef.current.focus();
      inputRef.current.select();
      return false;
    }

   
  };


  const handleBlur = () => {

    
    // Verificar si la cantidad ingresada es válida
    const isValid = !isNaN(cantidad) && cantidad.trim() !== "";
    if (!isValid) {
      // Si la cantidad no es válida, establecer el foco en el input
      inputRef.current.focus();
    }
  };


  useEffect(() => {

    inputRef.current.select();

  } , [] )

  return (
    <Modalp7 show={show} Fcerrar={handleClose}>
      <div className="container mt-5">
        <label htmlFor="numericInput" className="form-label">
          Ingresa la Cantidad:
        </label>
        <input
          type="number"
          className="form-control fs-3"
          min="0"
          max="100"
          autoFocus
          ref={inputRef} // Asignar la referencia al input
          onKeyDownCapture={handleKeyDown}
          onBlur={handleBlur} // Manejar el evento onBlur
          value={cantidad}
          onChange={(e) => setCantidad(e.target.value)}
          placeholder="Ingrese la cantidad"
        />


    <label htmlFor="numericInput" className="form-label">
          Precio:
        </label>
        <input
          type="number"
          className="form-control fs-3"
          min="0"
          max="100"
          ref={inputPrecio} // Asignar la referencia al input
          onKeyDownCapture={handleKeyDownPrecio}
          value={precio}
          onChange={(e) => setPrecio(e.target.value)}
          placeholder="Ingrese la Precio"
        />
      </div>
    </Modalp7>
  );
};

export default CampoFullCantidad;
