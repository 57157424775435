import React from 'react';
import { Link, NavLink } from 'react-router-dom';


const LinkMenu1 = ({data}) => {
    return (
        <li  className="nav-item">
            <NavLink   className="nav-link p7-bkn-hiden-navar" to={data.path}>
                <div  className="d-flex align-items-center"><span  className="nav-link-text">{data.label}</span>
                </div>
            </NavLink >
        </li>
    );
};

export default LinkMenu1;