import React, { useState, useRef } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';

import { setLocalStore , getLocalstore} from "../../../Core/fetchLocalStoreData";
import {enviarPeticion} from "../../../Core/ActionDatabase";
import { NotificationManager} from 'react-notifications';
import { __app } from "../../../utils/myApp";
import { useEffect } from 'react';


const PinVendedor = ({guardarVendedor , desactivar , modalformadePago  , extra }) => {
  
    const [pin, setPin] = useState('');

    const inputRef = useRef();

    const [ estadoValidacion , SetestadoValidacion ] =  useState(null);

    const [   intentos , setIntentos  ] = useState(0);    



    const handleInputChange = (e) => {
        e.preventDefault();
        const value = e.target.value;


    

        // Permitir cualquier número de dígitos
        setPin(value);

        // Mostrar alerta cuando se ingresan exactamente 3 dígitos
        if (value.length === 4) {
        
            const resultado = operadores.find( (ope) => ope.pin_vendedor ==  value  );
            console.log(resultado);
            if(resultado === undefined){

                SetestadoValidacion(1);

                setIntentos((prevIntentos) => prevIntentos + 1);

            }else{

                if ( intentos >= 3){
                    
                    SetestadoValidacion(1);

                    setIntentos((prevIntentos) => prevIntentos + 1);


                }else{
                    SetestadoValidacion(null);
                    asignarVendedor(resultado);
                }


            }
        }
        
        return false;
        
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        e.preventDefault();

        return false;
    }
  };



  const asignarVendedor = (vendedor) => {

    let data  =  {
        renderizar_total : false,
        vendedor :  vendedor.id,
        nombre_vendedor : vendedor.nombre,

    };
    guardarVendedor ({ valor :  data , accion : "actualizar_vendedor" });

    desactivar(false);
    
    setTimeout( () => {
        modalformadePago(true)
     
    } , 100)
  }


  const [operadores , setOperadores ] = useState([]);





    const UpddareExtraerOperadoresServicio = async () => {
        let respuesta =  await enviarPeticion( "terceros/extraer_vendedores"  );

        if(respuesta){
            __app.CARGANDO(2);
            
            console.log(respuesta.data);
            
            
            if (respuesta.respuesta == 1 ){
                setLocalStore('vendedores' , respuesta.data);
                NotificationManager.success('Exito'  , 'Los Vendedores del sistema se cargaron con Exito');
                setOperadores( respuesta.data);
            }
        }else{
            __app.CARGANDO(2);
        }
    }

    useEffect( () => {
      let data = getLocalstore('vendedores');

      console.log(data);
      
      if(data){
          setOperadores(data);
      }else{
          UpddareExtraerOperadoresServicio(); 
      }
      
  },  [])



  return (
    <Container className="my-4">
      <Row className="justify-content-center">
        <Col md={4}>
          <Form>
            <Form.Group controlId="formPin">
              <Form.Label className="mb-2">Ingresa tu PIN.</Form.Label>
              <Form.Control
                ref={inputRef}
                type="password"
                maxLength="4"
                value={pin}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="text-center "
                autoFocus
              />
                
                { (estadoValidacion == 1 && pin.length == 4)  &&  <div className="text-danger">
                    PIN  no encontrado
                </div>}
                {intentos > 0 && <p>Intentos : {intentos}</p>}
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default PinVendedor;