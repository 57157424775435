import React from 'react';
import { __app } from '../../../../utils/myApp';

const FormaPagoCarta = ({documento , TotalPagado , fontSize , SaldoPendiente , user }) => {
    return (
        <>
          
            {/* Código PHP reemplazado con JSX */}
            { true  && (
                <div style={{ marginTop: '10px'}}>
                    
                    <table   style={{ width: '100%'}}>
                        <tr>
                            <td style={{width:'50%'}}>
                            <p style={{fontSize: fontSize.md + 'pt'}}>
                                <b >INFORMACIÓN BANCARIA </b> <br/>
                                { user.CONFIGURACIONES_TABLE.informacion_bancaria }
                            </p>
                            </td>
                        
                            <td style={{width:'50%'}}> 
                                <table  cellspacing="0" style={{ width: '100%', border: 'solid 1px black', background: '#F7F7F7', fontSize: fontSize.md + 'pt'}}>
                                    <colgroup>
                                        <col style={{ width: '12%', textAlign: 'center' }} />
                                        <col style={{ width: '52%', textAlign: 'center' }} />
                                        {/**  <col style={{ width: '13%', textAlign: 'center' }} /> */}
                                        <col style={{ width: '20%', textAlign: 'center' }} />
                                    </colgroup>
                                    <thead>
                                        <tr style={{ background: '#E7E7E7' }}>
                                            <th colSpan={3}>PAGOS RELALIZADOS</th>
                                        </tr>
                                        <tr >
                                            <th style={{ borderBottom: 'solid 1px black', textAlign: 'center' }}>Referencia</th>
                                            {/** <th style={{ borderBottom: 'solid 1px black', textAlign: 'center' }}>Fecha de pago</th>   */}                            
                                            <th style={{ borderBottom: 'solid 1px black', textAlign: 'center' }}>Metodo</th>
                                            <th style={{ borderBottom: 'solid 1px black', textAlign: 'center' }}>Pagado</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        
                                        { TotalPagado > 0 && 
                                        <>
                                            { (documento.FORMAS_DE_PAGO && documento.FORMAS_DE_PAGO.length > 0) && documento.FORMAS_DE_PAGO.map((pago, index) => (                                                                
                                                <tr key={index}>
                                                    {
                                                        pago.total !== 0   &&
                                                        <>
                                                            <td style={{ textAlign: 'center' }}>{pago.id}</td>
                                                            {/* <td style={{ textAlign: 'center' }}>xxxxxx</td> */}
                                                            <td style={{ textAlign: 'center' }}>{pago.nombre}</td>
                                                            <td style={{ textAlign: 'right' }}>$ {__app.formato_decimal({total:pago.total})}</td>
                                                        </>
                                                    }
                                                </tr>
                                            ))}
                                        </>
                                        ||
                                        <tr >
                                            <td colSpan={3} style={{ textAlign: 'center' }}> No encontramos pagos para este documento.</td>
                                        </tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr >
                                            <th colSpan={3} style={{ textAlign: 'right' , fontSize: fontSize.xl + 'pt'   }}> . </th>
                                            
                                        </tr>
                                        <tr >
                                            <th colSpan={2} style={{ textAlign: 'right' , fontSize: fontSize.xl + 'pt'   }}> ABONADO</th>
                                            <th  style={{ textAlign: 'right'  , fontSize: fontSize.xl + 'pt'  }}> $ {__app.formato_decimal({total:TotalPagado})}</th>
                                        </tr>

                                        <tr >
                                            <th colSpan={2} style={{ textAlign: 'right' , fontSize: fontSize.xl + 'pt' }}>SALDO PENDIENTE</th>
                                            <th  style={{ textAlign: 'right' , fontSize: fontSize.xl + 'pt' }}> $ {__app.formato_decimal({total:SaldoPendiente})}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </td>

                        </tr>
                    </table>
                </div>
            )}
        </>
    );
};

export default FormaPagoCarta;