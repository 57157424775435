import React from 'react';

import { useEffect } from 'react';
import {  onValue  } from "firebase/database";
import { useState } from 'react';
import { __app , __fecha } from '../../utils/myApp';
import { recentOpenFacRef } from '../../Core/FireBase';
import Pedidos from './Pedidos';
import prindiv from 'easyprintand';
import FacturaTirilla from '../Document/PlantillasImpresion/PlantillaComanda';
import Moment from 'react-moment'
import 'moment/locale/es'  // without this line it didn't work
import VistaComanda from './VistaComanda';
import { getLocalstore, setLocalStore } from '../../Core/fetchLocalStoreData';
import {actionType} from "../../context/reducer"; 
import {useNavigate , useParams } from "react-router-dom"

var   elemento_ID_  =   null;





const CocinaComanda = () => {


    
    let  { tipoCocinaComanda } = useParams();


    const [ cuentasAbiertas , setCuentasAbiertas ] = useState([]);
    const [ DocImprimirFactura , setDocImprimirFactura ] = useState(null);
    const [ AuxA , setAuxA ] = useState(null);
  

    const navigate = useNavigate();

    const [ user , setUser ] = useState(getLocalstore("user"));
    
    
   
   



    let auxcmi =  (user && user.TURNO.id) ? getLocalstore("comandasImpresasTurno_" + user.TURNO.id ) : 0;


    const [ comandasImpresas , setComandasImpresas ] = useState(auxcmi);
    
    useEffect( () => {
        if (!user) navigate('/');

        /*** 
         * Este Opservaor Esta pendiente si el utlimo ID de nuestra tabla a cambiado para llamarlo.
        */
        onValue(recentOpenFacRef(), (snapshot) => {
        
            const obj = snapshot.val();

            if(obj){
           
                var result = Object.keys(obj).map( (key2) => obj[key2]);
                result = result.map((data,index) => {
                    data.numberid = __app.numeroFecha( data.FORM.HoraComanda );
                    return data; 
                })
             
                
         
                result.sort( function(a, b){
                    
                    if (a.numberid <  b.numberid) {
                      return -1;
                    }

                    if (a.numberid > b.numberid)  {
                        return 1;
                    }
                    // a must be equal to b
                    return 0;
                })    



                result.map((dat) => {
                    console.log(dat.numberid );
                })
                
                setCuentasAbiertas(result);
             

                document.getElementById('__ULTIMA__COMANDA').classList.add("flash");
                //setultimaComanda()

            }
            
            
        })


        setTimeout(  () => {
            setAuxA(1)
        }  , 3000)

    }, [])

   
    const imprimir = () =>  { 
       
       
        let aux = null;
        let error = null;

        if (!cuentasAbiertas[cuentasAbiertas.length - 1]){
            console.log("no hay ultima comanda")
            return false;
        }  


        let comanda = cuentasAbiertas[cuentasAbiertas.length - 1];
        // Si es null guardamos
        if (!comandasImpresas){
            aux = 1;
            console.log("Guardaremos : Motivo : comanda es null" )
        }

        
        if( !aux){
            if(!comandasImpresas.includes(comanda.numberid) ){
                aux = 1;
                console.log("Guardaremos : Motivo : No  existe" )
            }else{
                error = "La comanda " + comanda.numberid + "ya existe en el array";
               
            }
        }

        
        
        if(aux){
            prindiv('EasyPrintAnd' ,  { extraCss : './tirilla.css' } );
            guardarComandaLocalstore(comanda);
        }else{
            console.log("no se Imprimo.")
            console.log(error);
        }
    }

    const guardarComandaLocalstore = (ultima) => {

        let auxLocalStore = comandasImpresas;

        if(!auxLocalStore){
            auxLocalStore = [];     
        }

        auxLocalStore = [ ...auxLocalStore , ultima.numberid];


        setLocalStore("comandasImpresasTurno_" + user.TURNO.id  , auxLocalStore);
        setComandasImpresas(auxLocalStore);
    }

    useEffect(() => {
        
        setTimeout(() => {
            document.getElementById('__ULTIMA__COMANDA').classList.remove("flash");
           
            if(AuxA == 1){
                imprimir();
                //guardarEnCacheQueSeImprimio
            }else{
                console.log('docuemtno no esta Listo: '  + AuxA)
            }
            
         
        },500)

    }, [cuentasAbiertas[cuentasAbiertas.length - 1]])



    const reimprimirComanda =  (doc) => {

        doc.modo = 'full';
        console.log(doc.modo)
        setDocImprimirFactura(doc)
        
        setTimeout(() => {
           
            prindiv('PrinterAnd2' ,  { extraCss : './tirilla.css' } );

            setTimeout(() => {
                setDocImprimirFactura(null)
            }, 500)

        } , 300)

 
   
    }




 



    if(!user.TURNO || !user.TURNO.id ){
        return (

            <div>
                <h5 className='text-danger'>Error: Para continuar primero debes abrir un turno.</h5>

                <a href='/nuevo-documento'>Abrir un turno</a>
            </div>
        )
    }


    return (
        <div className='row'>
            <span onClick={() => console.log(comandasImpresas)} >comandasImpresas</span>
              
              
            {cuentasAbiertas[cuentasAbiertas.length - 1]  && 
            
            <FacturaTirilla documento={cuentasAbiertas[cuentasAbiertas.length - 1]} imprimirProductosTipo={tipoCocinaComanda}   />}

            <div className='col-3'>
                
                
                <div  id='__ULTIMA__COMANDA' className='p-3 card  '>
                    {
                        cuentasAbiertas[cuentasAbiertas.length - 1] && 
                        
                        <VistaComanda reimprimirComanda={reimprimirComanda} ultimaComanda={cuentasAbiertas[cuentasAbiertas.length - 1]} />
                    }
                </div>
                
                
                <div className='p-3 mt-3 card'>
                    {
                        cuentasAbiertas[cuentasAbiertas.length - 2] && 
                        
                        <VistaComanda reimprimirComanda={reimprimirComanda} ultimaComanda={cuentasAbiertas[cuentasAbiertas.length - 2 ]} />
                    }
                </div>

            </div>


            <div className='col-5'>
                
                { cuentasAbiertas && cuentasAbiertas.map( (duc,keys) =>  

                    
                        

                        <div key={keys} className='card mt-3 p-3'>

                            { ((cuentasAbiertas.length - 1 !=  keys && cuentasAbiertas.length - 2 !=  keys))  && 
                                <div className="row">
                                    <button onClick={() => reimprimirComanda(duc) } className='btn btn-xs float-end'> Imprimir </button>
                                    <div className="col-6">  
                                        <h1>Mesa #{ duc.FORM.mesanombre }</h1>
                                        <div style={{marginTop:'-15px'}}>
                                            <span className='fs-5' style={{display:'block'}}>
                                            ⏱<Moment fromNow interval={3000}>{__app.formatoFechaGTM5(duc.FORM.HoraComanda)}</Moment>   
                                                              
                                            </span>
                                        </div>

                                       
                                        


                                        <span  style={{display:'block' , marginTop:'20px'}}>
                                            {__fecha.formatoFecha( { '$fecha' : duc.FORM.HoraComanda  } )}                            
                                        </span>

                                        <span style={{display:'block' }}>
                                            🎫  Comanda { duc.cuentAbiertaFirebaseId &&  <span>#{duc.cuentAbiertaFirebaseId}</span>  || <span>#{duc.id}</span>}                               
                                        </span>
                                        
                                        <span className='d-block'> 🤵🏻 Mesero : {duc.FORM.nombre_vendedor}</span>
                                        <span className='d-block fs-3'> 💵   { __app.formato_decimal({ total : duc.MATEMATICAS.SUBTOTAL_3})  } </span>
                                    </div>

                                    <div className="col-6 mt-3">
                                        {duc.PRODUCTOS.map( (pro,index) => 
                                            <div key={index} style={{borderTop:'solid 1px'}}>
                                                 <p> { pro.numberid == duc.numberid &&  <span className='badge bg-success color-principal'>✔️ Reciente </span> }</p>
                                                <span>{pro.nombre}</span>
                                                {pro.descripcion && <span style={{display:'block', marginLeft:'15px', fontFamily:'monospace', margintop:'3px'}}>{pro.descripcion}</span> }
                                            
                                                {pro.COMPUESTO &&
                                               
                                                <ul>
                                                        {pro.COMPUESTO.map( (com,index2) => 
                                                            <li key={index2}>{com.nombre}</li>
                                                        )}
                                                    </ul>
                                                }
                                            </div>

                                            
                                        )}
                                    </div>
                                </div>
                            }            
                           
                        </div>
                    

                )};
            </div>

           


          
            {  DocImprimirFactura  && <FacturaTirilla imprimirProductosTipo={tipoCocinaComanda} documento={DocImprimirFactura} idDocumento={'PrinterAnd2'} />   }          
          
        </div>
    );
};

export default CocinaComanda;