import React, { useEffect, useState } from 'react';
import { ExtraerTablabyIds } from '../../Core/Dixie';

const MaximosMinimos = ({alertaMaximoMinimos , volverAConsultarMAximosYminimos}) => {



    const [productosCombinados , SetproductosCombinados ] = useState([]);
    useEffect(() => {

        
        buscarProdutos();


    }, [])


    const buscarProdutos = async () => {
        
        console.log(alertaMaximoMinimos);

        const ids = [...new Set(alertaMaximoMinimos.map(data => data.producto_id))];

    
        const infoProductos = await ExtraerTablabyIds('productos' , ids);

        if(infoProductos){
           
            let combinacion = alertaMaximoMinimos.map((datamn) => {

                let data1 = infoProductos.find(data => data.id ==datamn.producto_id  );

                datamn.producto = data1;
                return datamn;
            })

           
            console.log(combinacion);
            SetproductosCombinados(combinacion);
        }



     

    }
    



    return (
        <div>
            <button className='btn btn-primary' onClick={volverAConsultarMAximosYminimos}> Consultar Nuevamente Agotados </button>
           <div className="card-body pt-2">
                <div className="table-responsive">
                    <table className="table table-centered table-nowrap table-hover mb-0">
                        <tbody>
                           
                           

                        {productosCombinados.map(data => 
                            <tr className=''>
                                <td>
                                    <h5 className="font-15 my-1">{data.producto.nombre} ({data.original} )   - {data.producto.id_2} </h5>
                                    <span className="text-muted font-13">Stock Actual  : {data.total_cantidad} </span> 
                    
                                </td>
                        
                                <td>
                                    <span className="text-muted font-13">Minimos</span> <br/>
                                    <span className="font-14 mt-1 fw-normal">{data.minimo_configurado}</span>
                                </td>
                                <td>
                                    <span className="text-muted font-13">Faltantes</span>
                                    <h5 className="font-14 mt-1 fw-normal text-danger text-bold ">{ parseFloat(data.minimo_configurado) - parseFloat(data.total_cantidad) } </h5>
                                </td>
                            
                        </tr>
                                
                        ) }
                                








                           
                                         
                        </tbody>
                    </table>
                </div> 

                </div>
        </div>
    );
};

export default MaximosMinimos;