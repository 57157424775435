
import  ListaAllDocument from "./ListaAllDocument";



import BarraEstado from "./BarraEstado";

import { useStateValue } from '../../context/StateProvider';
import { actionType } from '../../context/reducer';

import TurnoIndex from "../Turnos/TurnoIndex";
import { useEffect, useState } from "react";
import {enviarPeticion } from "../../Core/ActionDatabase";
import {useNavigate} from "react-router-dom"
import {fetchEmpresa, setLocalStore , getLocalstore} from "../../Core/fetchLocalStoreData";
import TurnoDetalleFinalizar from "../Turnos/TurnoDetalleFinalizar";


const  AddDocument = (props) =>  {

    const [ { user , infodatabase  } , dispatch ] = useStateValue();


    if( !user){
        window.location.href ='/';
    }

    const [turnocerrado , setTurnocerrado] = useState(null); 
    
    const navigate = useNavigate();

    useEffect( () => {

            consultarEstadoTurno();

            consultarDatosBasicos();

    }, []) 

    const consultarEstadoTurno =  async () => {
        console.log(user)
        if (!user.TURNO || !user.TURNO.id){
            console.log(false);
            setTurnocerrado("closed");
            return false;
        }

        const respuesta = await enviarPeticion("turnos/consultar_turno_estado",user.TURNO.id , {          
        } , true , 5000);

    
        if  (!respuesta.server_error){
            
            console.log(respuesta)
            
            ///Turno cerrado
            if(respuesta.data.resultado ){
             
                setTurnocerrado("closed");
                cerrarTurnoLocal();
            }else{
                
                setTurnocerrado("open");
            }
        }else{
            setTurnocerrado("open");
        }
        
    }
    

    const cerrarTurnoLocal = () => {
        console.log(user);

        const { TURNO , ...Newuser } = user;

     
        
        dispatch ({
            type : actionType.SET_USER,
            user: Newuser,
        })   

        
        localStorage.setItem('user', JSON.stringify(Newuser)); 
    }


    const cerrarSession = () => {     
        localStorage.removeItem('user');

        dispatch( {
            user : null,
            type : actionType.SET_USER 
        })
         window.location.href ='/';
    }



    const consultarDatosBasicos = () => {

        UpddareExtraerOperadoresServicio();
    } 


    const UpddareExtraerOperadoresServicio = async () => {

        let data = getLocalstore('operadores');
 
        if(data){
            return false;
        }else{
            let respuesta =  await enviarPeticion( "Terceros/extraer_operadores" );
            if(respuesta){ 
                if (respuesta.respuesta == 1 ){
                    setLocalStore('operadores' , respuesta.data);
                }
            }
        }

        
    }

    
    if(user.FINALIZAR_TURNO){

        return (<TurnoDetalleFinalizar user={user}  />)
    }




    return (

       

        <div>
            
            { !turnocerrado && <p>Cargando...</p>}
            {
                (user.TURNO &&  turnocerrado == "open")  && 
                <>  
                    {/* Pestañas del documento */}
                    <ListaAllDocument  dispatchGlobal = {dispatch} />  
                    <BarraEstado user={user} infodatabase={infodatabase} />  
                </> 
            }

            { turnocerrado == "closed" && 
                <div>
               
                    <TurnoIndex estadoTurno={setTurnocerrado} user={user}  infodatabase={infodatabase} cerrarSession={cerrarSession} />


                </div>    
            }


            
   
        </div>
    );
}

export default AddDocument;