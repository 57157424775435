import React, { useEffect, useRef, useState } from 'react';
import { reenviarDocuelectronico } from '../../controlador';
import StatusCodeWebService from './StatusCodeWebService'


const RenviarDian = ({ reenviarDian, documento }) => {
    // Respuesta Api
    const [logs, setLogs] = useState([]);
    // Respuesta P7
    const [logs2, setLogs2] = useState([]);
    // Estado del contador
    const [contador, setContador] = useState(0);
    // Estado de carga
    const [cargando, setCargando] = useState(null);
    // Estado para mostrar los logs con delay
    const [mostrarLogsConDelay, setMostrarLogsConDelay] = useState([]);
    
    const logsEndRef = useRef(null);


    /***Almacena la respuesta de la DIAN */
    const [respuestaDian , SetrespuestaDian ] =  useState({
        status_code_WEBSERVICE : -1,
    });

    /***Almacena la respuesta de la DIAN */
    const [respuestaDianQR , SetrespuestaDianQR ] =  useState(null);

    /*** Correo enviado  */
    const [respuestaCorreo , SetrespuestaCorreo ] =  useState(null);


    const [consola , setConsola ] = useState(true);


    const [errorHttp , SeterrorHttp ] = useState();

    const scrollToBottom = () => {
        if (logsEndRef.current) {
            logsEndRef.current.scrollTop = logsEndRef.current.scrollHeight;
        }
    };

    const iniciarPeticion = async () => {
        setCargando(1);
        console.log(documento.DOCUMENTO.CtDocumento_id);

        let resultado = await reenviarDocuelectronico(documento.DOCUMENTO.CtDocumento_id + '/' + reenviarDian );
        console.log(resultado)
        if (resultado !== -1) {
          
            stopInterval();

            let log_api = [];
            resultado.nivel1.map(data => {
                try {
                    let parsedData = JSON.parse(data); // Convierte la cadena a JSON
                    log_api.push(parsedData); // Agrega el JSON válido al arreglo
                } catch (e) {
                    log_api.push(data); // Agrega el JSON no válido al arreglo junto con el error
                    //console.error('Invalid JSON:', e); // Imprime un error si el JSON no es válido
                }
            });

            console.log( log_api[5] );

            if(typeof log_api[5] != 'object'){
                setCargando(0);
                return false;
                
            }
            

            let log_fep7 = [];
            log_api[5].map((data,index) => {

                if(data === "Respuesta_SendBillSync:"){
                    console.log( log_api[5][index + 1]);
                 
                    SetrespuestaDian( log_api[5][index + 1])
                }

                if(data === "RESPUESTA_CORREO:"){
                    SetrespuestaCorreo( log_api[5][index + 1])
                }
                
                if(data === "QRCode:"){
                    SetrespuestaDianQR( log_api[5][index + 1])
                }
                
                

                try {
                    let parsedData = JSON.parse(data); // Convierte la cadena a JSON
                    log_fep7.push(parsedData); // Agrega el JSON válido al arreglo
                } catch (e) {
                    log_fep7.push(data); // Agrega el JSON no válido al arreglo junto con el error
                    //console.error('Invalid JSON:', e); // Imprime un error si el JSON no es válido
                }
            });





           
            const filteredLogs = log_fep7.filter(item => (typeof item === 'string' || typeof item === 'number'));
            setLogs2(filteredLogs);
            setCargando(0);

            // Mostrar los logs con delay de 1 segundo por cada iteración
            filteredLogs.forEach((log, index) => {
                setTimeout(() => {
                    setMostrarLogsConDelay(prev => [...prev, log]);
                }, index * 250);
            });
        }else{
            SeterrorHttp(1);
            setCargando(0);
            stopInterval();
        }
    }

    const intervalRef = useRef(null);

    useEffect(() => {
        if (reenviarDian >=  1) {
          
          
           iniciarPeticion();
            
           intervalRef.current = setInterval(() => {
            setContador(prev => {
                if (prev < 200) {
                    return prev + 1;
                } else {
                    clearInterval(intervalRef.current);
                    return prev;
                }
            });
        }, 1000);

        // Cleanup function to clear interval when the component unmounts
        return () => clearInterval(intervalRef.current);
        }
        
    }, [reenviarDian]);


    const stopInterval = () => {
        clearInterval(intervalRef.current);
    };



    useEffect(() => {
        setTimeout(() => {
            scrollToBottom();
        }, 300);
    }, [mostrarLogsConDelay]);


   

    if (!reenviarDian) {
        return false;
    }
    const alerta = () => {

    }


    return (
        <p>
            {
                consola && 
                <div className="card mt-5 text-bg-dark mb-3" >
                    <div className="card-header d-flex justify-content-between align-items-center">Consola.
                        
                    </div>
                    <div className="">

                        <div ref={logsEndRef} style={{ fontFamily: 'monospace', width: '100%', height: '500px', overflow: 'auto' }}>
                            

                            <p className='text-success'>#Iniciando envio... <br/>
                            #Pluma7 esperara un maximo de 200 segundo una respuesta de la DIAN...<br/>
                            #Aveces la DIAN puede estar saturada y no responder...<br/>
                            #Tiempo de espera maximo 200 segundos ...<br/>
                                
                                {cargando === 1 && <p className='text-white'>Espere... {contador}/200 </p>}
                                {logs.map((data, index) => 
                                    <span>#{data} <br/></span>
                                )}
                                {mostrarLogsConDelay.map((data, index) => 
                                    <span className='ms-3'>#{data} <br/></span>
                                )}
                            </p>
                            
                            {cargando === 0 && mostrarLogsConDelay.length === logs2.length && <p className='text-success'><b>Finalizado.</b></p>}
                            
                            { errorHttp && <p className='text-danger'><b>Error de RED.</b></p>}
                            
                          
                        </div>
                    </div>
                    </div>
            }

    


            {cargando === 0 && mostrarLogsConDelay.length === logs2.length &&  !(respuestaDian.status_code_WEBSERVICE === -1 ) &&  <div className='mt-3'><StatusCodeWebService estado={respuestaDian.status_code_WEBSERVICE}  mensaje={respuestaDian.responseLimpioDian} /></div> }
            
            <div>
                {cargando === 0 && mostrarLogsConDelay.length === logs2.length && respuestaDian.status_code_WEBSERVICE  && respuestaDian.status_code_WEBSERVICE == 4  &&  <span> <a className='ms-2 mt-1 me-2' target="_blank"  href={respuestaDianQR}> Ver existencia en la DIAN  <i className="fa-solid fa-arrow-up-right-from-square"></i> </a>  </span> }        

                {cargando === 0 && mostrarLogsConDelay.length === logs2.length && respuestaDian.status_code_WEBSERVICE && respuestaDian.status_code_WEBSERVICE == 4  &&  
                    <span> 
                        {respuestaCorreo === '--1--' && <span className='badge badge-phoenix badge-phoenix-success'> <i class="fa-solid fa-paper-plane"></i>  Correo enviado<i class="fa-solid fa-check"></i> </span> || <span className='badge badge-phoenix badge-phoenix-warning'>Fallo el envio del Correo.  <i class="fa-solid fa-ban"></i> </span>  }
                    </span> 
                }  
            </div> 


            <hr />       
           
        </p>  
    );
};










export default RenviarDian;



