import React from 'react';
import Boton from '../ImprimirPago/Imprimir/Boton';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';

const InfoPago = ({pago , boton }) => {

    const user = fetchUSer();
    if (!pago) {
        return false;
    }

    // Extraer solo la fecha de 'CREADO' sin la hora
    const fechaSistema = pago.CREADO.split(' ')[0]; // "2024-09-16"
    const fechaManual = pago.fecha; // "2024-09-16"

    return (
        <div>
            <div className="TirillaTextCenter">     
                <h4 className="TirillaM0">{user.ESTACION.nombre}</h4>
                <b className="Tirilla11px"> NIT : {user.ESTACION.nit}</b>
                <p style={{marginTop:'-2px'}}>
                    {user.ESTACION.telefono} <br/> 
                    {user.ESTACION.direccion}<br/>
                    {user.ESTACION.municipio} <br/>
                </p>
            </div>

            <h3>Recibo de Pago #{pago.id}</h3>
            <table className='table' border={1}>
                <tbody>
                    <tr>
                        <th>Fecha del Sistema</th>
                        <td>{pago.CREADO}</td>
                    </tr>

                    {/* Solo mostrar la "Fecha Manual" si es diferente de la "Fecha del Sistema" */}
                    {fechaSistema !== fechaManual && (
                        <tr>
                            <th>Fecha Manual</th>
                            <td>{pago.fecha}</td>
                        </tr>
                    )}

                    <tr>
                        <th>Cliente</th>
                        <td>{pago.Cliente_identificacion} - {pago.Cliente_nombre}</td>
                    </tr>

                    <tr>
                        <th>Turno del Pago</th>
                        <td>#{pago.turno_id}</td>
                    </tr>

                    <tr>
                        <th>Forma de pago</th>
                        <td>{pago.name_formapago}</td>
                    </tr>

                    <tr>
                        <th>Cajero</th>
                        <td>{pago.Cajero_nombre}</td>
                    </tr>

                    <tr>
                        <th>Factura Aplicado</th>
                        <td>{pago.ct_documento_id}</td>
                    </tr>

                    {(pago.PAGO_ADELANTADO == 1 && pago.CONSUMO_estado == 0) && (
                        <tr>
                            <th>Tipo de pago</th>
                            <td>Saldo a favor del cliente</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <h1>{pago.total}</h1>

            {boton === true && <Boton pago={pago} />}
        </div>
    );
};

export default InfoPago;
