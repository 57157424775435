import React from 'react';

const Buscador = ({buscar , handleChange}) => {
    return (
        <div className="col-auto">
            <div className="search-box">
                <form className="position-relative">
                    <input onChange={handleChange} value={buscar} className="form-control search-input search" type="search" placeholder="Buscar tercero" aria-label="Search" />
                   
                </form>
            </div>
        </div>
    );
};

export default Buscador;