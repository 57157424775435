
import React, {  useState } from 'react';
import { __app , __fecha } from '../../../utils/myApp';
import {  getItemModificados } from '../Controlador';

import StandarModal from '../../Document/PartesDocument/StandarModal';
import {LabelEstadoPago } from "../../../Core/Html/ComponenteHtml"


const ItemsCtaEliminados = ({turno_id}) => {
    const [ itemsVendios , setItemVendios ] = useState([]);
    const [ carga , setCarga ] = useState(null);


    const [ totales , setTotales ] = useState({
        total : 0,
    });


    const [ showViewForm , SetshowViewForm] = useState();


    const cargarVentasItemsbyTurno = async () => {

        if( itemsVendios.length > 0  ){
            return false;
        }

        if ( carga == 1 ){
            return false;
        }

        setCarga(1);
        
        let data = await  getItemModificados(turno_id  );

        if  (data.length > 0){


            console.log(data);

            setCarga(null);
            setItemVendios(data);


            data.map((item, index) => {
                
                // Convierte las propiedades a números antes de sumarlas
                const total = parseFloat(item.total);

              
                // Acumula los valores en totales
                setTotales(prevTotales => ({
                    total: prevTotales.total + total,
                }));
            })

        } 

    }





    const index = 485454754544554;

    const [ documentoo , SetDocumento  ] = useState(null);

    const verDetalles = (documento) => {
        SetDocumento(documento)
        SetshowViewForm(true);
    }


    const fechaEliminacion =  ( inicio , fin  ) => {

        var fechaInicio = new Date(inicio);
        var fechaEliminacion = new Date(fin);


        // Calcular la diferencia en milisegundos
        var diferenciaEnMilisegundos = fechaEliminacion - fechaInicio;

        // Convertir la diferencia a segundos
        var diferenciaEnSegundos = diferenciaEnMilisegundos / (1000 * 60);

        // Mostrar el resultado
        diferenciaEnSegundos = diferenciaEnSegundos * -1;
        return  parseInt(diferenciaEnSegundos);
    }

    return (
        <div className="accordion" id="accordionPanelsStayOpenExample2" >
                
        
            <div key={index} className="accordion-item">
               
                    <h2 className="accordion-header">
                       
                        <button onClick={cargarVentasItemsbyTurno} className={'bg-opacity-10  accordion-button collapsed' + ' bg-warning'  } type="button" data-bs-toggle="collapse" data-bs-target={'#panelsStayOpen-collapse' + index } aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      
                        <span className={'fw-bold '}>  CUENTAS EDITADAS  { carga  == 1  &&  <i class="fa-solid fa-arrows-spin fa-spin fa-spin-reverse"></i> }   </span> 
                        
    
                        </button>

      

                    </h2>
                    <div id={'panelsStayOpen-collapse'  + index } className="accordion-collapse collapse"  style={{maxHeight: "500px", overflow : "auto"}}>
                        <div className="accordion-body">


                            { ( carga &&   carga == 1)  &&  <p>Cargando...</p> || 
                            
                            <table className='table  table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th> N°Unico</th>
                                    <th> Fecha </th>
                                    <th> N° Fact </th>
                                    <th> Tercero </th>
                                    <th> Vendedor </th>
                                    <th> Obeservaciones </th>
                                    <th> Estado </th>
                                    <th> Descuento </th>
                                    <th> Total  </th>
                                    <th> Detalles  </th>
                                    
                              
                                   
                                </tr>
                            </thead>
            
                            <tbody>
                                { itemsVendios.map( ( doc  , index  ) =>
            
                                    <tr>
                                        <td> {doc.CtDocumento_id}  </td>
                                        <td>{ __fecha.formatoFecha({$fecha : doc.CREADO } ) }</td>
                                        <td> {doc.CtDocumento_ct_numero_factura} </td>
                                        <td> { __app.TextoMinimo(doc.CtPersona_nombre, 15)}  </td>
                                        <td> { __app.TextoMinimo(doc.vendedor_nombre , 10)} </td>
                                        <td> {doc.CtDocumento_periodo_observacion}   </td>
                                        <td>  <LabelEstadoPago estado={doc.CtDocumento_ct_documento_estado_id} />   </td>
                                        <td>  <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal( {total : doc.CtDocumento_DESCUENTO , color : true })  }} /></td>
                                        <td>  <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal( {total : doc.CtDocumento_SUBTOTAL_3 , color : true })  }} /> </td>
                                        <td> <button  onClick={ () => verDetalles(doc) } className='btn btn-principal btn-xs'> Ver detalles </button></td>
                                    </tr>
                               )}
                            </tbody>
                        </table>
                            }
                        </div>
                    </div>
            </div>
         
          {/***
                 * Formulario Guardad Documento
            */}
            <StandarModal size="xl"  titulo="Guardar Documento"  desactivar={() => SetshowViewForm(!showViewForm) } mostrar={showViewForm}  > 
               

               <div className='row'>
    
                    <div className='col-6'>
                        <h3>Items Borrados</h3>
                        <table className='table'>
                            <tr>
                                <th>Borrado</th>
                                <th>Codigo</th>
                                <th>Nombre</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                            </tr>
                            {   
                                ( documentoo ) && 
                                <>
                                    {  documentoo.itemsBorrados.map((pro2) => 
                                        <tr>
                                            <td>{pro2.item.fecha_eliminacion} </td>
                                            <td>{pro2.item.id_2}</td>
                                            <td>{pro2.item.nombre}</td>
                                            <td>{pro2.item.MATEMATICAS.CANTIDAD}</td>
                                            <td>{pro2.item.MATEMATICAS.CANTIDAD}</td>
                                            <td>{pro2.item.MATEMATICAS.SUBTOTAL_3}</td>
                                        </tr>
                                    ) }
                                </>
                            }
                        </table>

                    </div>
               </div>
                
            </StandarModal> 

        </div>
    );
};


export default ItemsCtaEliminados;