import React, { useEffect, useState } from 'react';
import TablaDocumentos from '../../../Terceros/TerceroView/TablaDocumentos';
import { paginacionDocumentos } from '../../controlador';
import VerDocumento from '../../VerDocumento/VerDocumento';

const ListarDocumentos = ({ modulo , documentosaux}) => {
  

    const [documentos, setDocumentos] = useState(documentosaux);


    const [iniciaEn, setIniciaEn] = useState(0);

    const [cargando , setCargando] = useState(null);

    const campos = [
        'Fecha',
        'PuntoDeVenta',
        'Cliente',
        'Usuario',
        'Vendedor',
        '#Factura',
        'Tipo',
        'Descuento',
        'Estado',
        'Precio',
        'Pendiente',
        'Utilidad',
        'Opciones',
    ];


    /**** Esta funcion lista los 10 primeros documento de cada tipo documento existente. */
    const listarDocumentosL = async (limite, iniciar = 0) => {
        setCargando(1);
        const tipo = modulo.id;
        const documentos = await paginacionDocumentos({ tipo, iniciar, limite });

        if (documentos !== -1) {
            setCargando(null);
            setDocumentos(documentos);
        } else {
            setCargando(null);
            // Manejar el error aquí si es necesario
        }
    };

    useEffect(() => {
      
        console.log(modulo)
        if(modulo){
            
            listarDocumentosL(50, 0);
        }
     
    }, [modulo]);

    const paginar = (direccion) => {
        const limite = 50;
        let nuevoInicio = iniciaEn;

        if (direccion === "+") {
            nuevoInicio += limite;
        } else if (direccion === "-" && iniciaEn >= limite) {
            nuevoInicio -= limite;
        }

        listarDocumentosL(limite, nuevoInicio);
        setIniciaEn(nuevoInicio);
    };



    return (
        <div>
         
           
            { (documentosaux && documentosaux.length > 0)  && <TablaDocumentos cargando={cargando} actualizarDatos={() => {} } documentos={documentosaux} campos={campos} />}


            {!documentosaux && 
            
            
                <div>

                    <TablaDocumentos cargando={cargando} actualizarDatos={() => {} } documentos={documentos} campos={campos} />

                    <nav aria-label="Page navigation example" className='mt-4'>
                        <ul className="pagination justify-content-end">
                            <li className={`page-item ${iniciaEn === 0 ? 'disabled' : ''}`}>
                            
                                {iniciaEn > 0 && <button 
                                    onClick={() => paginar("-")} 
                                    className="page-link"
                                    disabled={iniciaEn === 0}
                                >
                                    {!cargando && <span>
                                        Anterior
                                    </span>}

                                    {cargando && <span>
                                        Espere..
                                    </span>}
                                </button>}
                            </li>
                            <li className="page-item">
                                <button 
                                    disabled={cargando}
                                    onClick={() => paginar("+")} 
                                    className="page-link"
                                >
                                    {!cargando && <span>
                                        Siguiente
                                    </span>}

                                    {cargando && <span>
                                        Espere..
                                    </span>}
                                </button>
                            </li>
                        </ul>
                    </nav>

                </div>
            }



        </div>
    );
};

export default ListarDocumentos;