import React from 'react';
import Links from './Links';
import BotonCollapsed from './BotonCollapsed';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';

const VerticalNav = () => {

	const user = fetchUSer();

	if(!user){
		return false;
	}
    const menuItems = [
		
 		{
			tipo: 'link',
			options: { icon: "📁", path: '/informe_documentos', label: 'Documentos' }
		},
		
	
		{
			tipo: 'link',
			options: { icon: "⏰", path: '/turnos', label: 'Turnos' }
		},
		{
			tipo: 'link',
			options: { icon: "👥", path: '/terceros', label: 'Contactos' }
		},
		

		/***
		 * 
		 * {
			tipo: 'link',
			options: { icon: "🛒", path: '/productos', label: 'Productos' }
		},

		{
			tipo: 'link',
			options: { icon: "🛒", path: '/Welcome', label: 'Text' }
		},

		 */

		{
			tipo: 'menu',
			label: 'Productos',
			id: "productos",
			icon: "🛒",
			options: [
				{ tipo: 'link', path: '/Productos', label: 'Lista de productos' },
				{ tipo: 'link', path: '/categoriasproductos', label: 'Lista de Categorías' }
			], 	
		},
		
		

		
		{
			tipo: 'menu',
			label: 'Restaurante',
			id: "restaurante",
			icon: "🍽️",
			options: [
				{ tipo: 'link', path: '/Comanda/1', label: 'Comanda Cocina' },
				{ tipo: 'link', path: '/ComandaBarra/2', label: 'Comanda Barra' }
			], 
			condiciones : [
				{
					restaurante : 1
				}
			]
			
		},
		
		
			
		{
			tipo: 'menu',
			label: 'Parqueadero',
			id: "parqueadero",
			icon: "🏍️",
			options: [
				{ tipo: 'link', path: '/parqueadero', label: 'Parqueadero' },
				{ tipo: 'link', path: '/probador/', label: 'Probar Tarifa' }
			], 
			
			condiciones : [
				{
					MODO_parqueadero : 1
				}
			]
		},	
		
		
		
			
		
		
		{
			tipo: 'menu',
			categoria: 'Configuracion',
			label: 'Configuración',
			id: "configuración",
			icon: "⚙️",
			options: [
				{
					label: 'Impresoras',
					tipo: 'menu',
					id: "impresoras",
					options: [
						{
							label: 'Diseño',
							tipo: 'menu',
							id: "disenodeimpresion",
							options: [
								{ tipo: 'link', path: '/disenoTirilla', label: 'Tirilla' },
								{ tipo: 'link', path: '/DisenoCarta', label: 'Carta' },
								{ tipo: 'link', path: '/DisenoComanda', label: 'Comanda' }
							]
						}
					]
				},
				{
					label: 'Punto de venta',
					tipo: 'menu',
					id: "puntodeventa",
					options: [
						{ tipo: 'link', path: '/CamposPuntoVenta', label: 'Campos Punto Venta' },
						{ tipo: 'link', path: '/Vendedores', label: 'Vendedores' },
						{ tipo: 'link', path: '/OperadoresServicio', label: 'Mecánicos' }
					]
				}
			]
		}
	];
	



	
	const validarExistencia = (data) => {
		
		if(data.condiciones){
			let resultado = false;
			data.condiciones.map((info) => {
				
				for (const clave in info) {
					
					let res =  compararClave(clave , info[clave]);
					if(res === true ){
						resultado= true;
						break;
					}
					
				}
			})
			return resultado;
		}
		return true;
	}



	const compararClave = (claveCheck , valor ) => {
		
		for(const claveDB in user.CONFIGURACIONES_TABLE){
			
			if(claveDB === claveCheck){
	
				if(user.CONFIGURACIONES_TABLE[claveDB] == valor){
					
					return true;

				}else{
					return false;
			
				}
			}
		}
	}
    return (
        <nav  className="navbar navbar-vertical navbar-expand-lg" data-navbar-appearance="darker">
            <div  className="collapse navbar-collapse" id="navbarVerticalCollapse">

                <div  className="navbar-vertical-content">
                    <ul  className="navbar-nav flex-column" id="navbarVerticalNav">


                        <li  className="nav-item">

							{menuItems.map( (data,index) => 
								
								<>
									{ validarExistencia(data) && <Links key={index} data={data} validarExistencia={validarExistencia} /> }
								</>
							)}

                        </li>
						
						<li  className="nav-item">	
							<p class="navbar-vertical-label">Otros Enlaces</p>
						

							<div class="nav-item-wrapper">
								<a class="nav-link label-1"  target="_blank" href="https://admin.nubep7.com/users/login_global" role="button" data-bs-toggle="" aria-expanded="false">
									<div class="d-flex align-items-center">
										
									<span class="nav-link-icon">
									<span class="nav-link-text">Administrativo</span></span></div>
								</a>
							</div>


							<div class="nav-item-wrapper">
								<a class="nav-link label-1" target="_blank"  href="https://facturaelectronica.nubep7.com/users/login_global" role="button" data-bs-toggle="" aria-expanded="false">
									<div class="d-flex align-items-center">
									<span class="nav-link-icon">
									<span class="nav-link-text">Factura electronica</span></span></div>
										
								</a>
							</div>

				  		</li>
                    </ul>
                </div>
            </div>


                <div  className="navbar-vertical-footer">
                    <BotonCollapsed />
                </div>

        </nav>
    );
};

export default VerticalNav;