import React from 'react';
import { __app } from '../../../utils/myApp';


const CuentasPorPagar = ({resumen_comprobantes}) => {
    return (
        <>

            <tr>
                <th colSpan="2" style={{textAlign : 'center'}} >
                    Cuentas por pagar.
                </th>
            </tr>
            
             
            { resumen_comprobantes['cuentas_por_pagar_proveedores']   && 
            <tr>
                <td>A Proveedores  </td>
                <td>
                    $ <span dangerouslySetInnerHTML={{ __html :  __app.formato_decimal( { total : resumen_comprobantes['cuentas_por_pagar_proveedores'] * -1  , color : false } )   }} /> 
                </td>
            </tr>}
            
            { resumen_comprobantes['cuentas_por_pagar_clientes']  && 
                
                <tr>
                    <td>A Clientes (Saldo  a favor) </td>
                    <td>
                        $ <span dangerouslySetInnerHTML={{ __html :  __app.formato_decimal( { total : resumen_comprobantes['cuentas_por_pagar_clientes'] * -1  , color : false } )   }} /> 
                    </td>
                </tr>
            }

            
            { ( !resumen_comprobantes['cuentas_por_pagar_clientes']  &&  !resumen_comprobantes['cuentas_por_pagar_proveedores'])  && 
                <tr>
                    <td  style={{textAlign:'center'}} colSpan={2}> No encontramos cuentas x pagar   </td>                  
                </tr>
            }

        </>
    );
};

export default CuentasPorPagar;