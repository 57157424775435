

import React, { useState } from 'react';
import { setLocalStore , getLocalstore} from "../../../Core/fetchLocalStoreData";
import {enviarPeticion} from "../../../Core/ActionDatabase";
import { NotificationManager} from 'react-notifications';
import { __app } from "../../../utils/myApp";
import { useEffect } from 'react';



function Vendedores({guardarVendedor ,  formDocument}) {

    const [vendedorid , serVendedor] = useState();
    
    useEffect(() => {
      
        let mesa_idayx = (formDocument && formDocument.vendedor_id ) ? formDocument.vendedor_id +"/"+  formDocument.nombre_vendedor : "0";
        serVendedor(mesa_idayx);
    }, [formDocument])

    const handleChange = (e) => {
        
        let aux = e.target.value;
        serVendedor(aux);
        var porciones = aux.split('/');
        let data  =  {
            renderizar_total : false,
            vendedor :  porciones[0],
            nombre_vendedor : porciones[1],

        };
        guardarVendedor ({ valor :  data , accion : "actualizar_vendedor" });
    }





    const [operadores , setOperadores ] = useState([]);




    const UpddareExtraerOperadoresServicio = async () => {
        let respuesta =  await enviarPeticion( "terceros/extraer_vendedores"  );

        if(respuesta){
            __app.CARGANDO(2);
            
            console.log(respuesta.data);
            
            
            if (respuesta.respuesta == 1 ){
                setLocalStore('vendedores' , respuesta.data);
                NotificationManager.success('Exito'  , 'Los Vendedores del sistema se cargaron con Exito');
                setOperadores( respuesta.data);
            }
        }else{
            __app.CARGANDO(2);
        }
    }

    useEffect( () => {
        let data = getLocalstore('vendedores');

        console.log(data);
        
        if(data){
            setOperadores(data);
        }else{
            UpddareExtraerOperadoresServicio(); 
        }
        
    },  [])





    return (
        <div className="form-group form-online " style={{minWidth:'180px'}}>

            <select value={vendedorid} onChange={ handleChange }  name="vendedor" className="form-select form-control" aria-label="Default select example">
                <option value="0/ninguno">Vendedor</option>
                { operadores.map((open , index) => 
                    <option  key={open.id} value={open.id +  '/' + open.nombre}>{open.nombre}</option>
                )}
            </select> 
        </div>
    );
}

export default Vendedores;