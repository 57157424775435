import React, { useEffect } from 'react';

const ListaPrecio = ({user , formData , setFormData, btnbmitLoad}) => {

    useEffect( () => {


        console.log(formData);

    } , [ formData] ) 

    
   
    const resturnSigno = ( ) => {
        if( user.CONFIGURACIONES_TABLE.switch_modo_precio && user.CONFIGURACIONES_TABLE.switch_modo_precio == 1 ){
            return "%";
        }
        if( user.CONFIGURACIONES_TABLE.switch_modo_precio && user.CONFIGURACIONES_TABLE.switch_modo_precio == 2 ){
            return "$";
        }  
    }
    


    const handleChangeLista = (index, key, value) => {
        setFormData((prevData) => {
          const nuevaLista = [...prevData.LISTA_PRECIOS];
          nuevaLista[index] = { ...nuevaLista[index], [key]: value };
          return { ...prevData, LISTA_PRECIOS: nuevaLista };

        });
      }



    return (
        <div>
            { (user.CONFIGURACIONES_TABLE.listadeprecio && user.CONFIGURACIONES_TABLE.listadeprecio == 1) && 
            
                <div className="form-group mb-3">
                    
                    <h5>Lista de Precios ({resturnSigno()}) </h5>

                    { (user.DATABASE.ct_lista_precios && user.DATABASE.ct_lista_precios.length > 0) && 

                        <>  
                            {console.log(formData.LISTA_PRECIOS)}
                            
                            {formData.LISTA_PRECIOS &&
                            formData.LISTA_PRECIOS.map((precio, index) => (
                            <div key={index}>
                                <label>{precio.nombre} - Sin impuestos (ojo)</label> <br/>
                                <input
                                disabled={btnbmitLoad}
                                type="number"
                                value={precio.precio}
                                onChange={(e) =>
                                    handleChangeLista(index, 'precio', parseFloat(e.target.value))
                                }
                                />
                            </div>
                            ))}                                  
                        

                        </>
                        
                    }
                </div>
            }
        </div>
    );
};

export default ListaPrecio;