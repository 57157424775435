export const HelpHttp = () => {




    const customFetch = (endpoint, options = {} , timeAbort = 60000) => {
      const defaultHeader = {
        accept: "application/json",
      };
  

      const controller = new AbortController();
      
      options.signal = controller.signal;
      

      // Por Defecti estabkecenis GET si no esta declarado
      options.method = options.method || "GET";
     
      
      //Mesclamos los Header
      options.headers = options.headers  ? { ...defaultHeader, ...options.headers } : defaultHeader;
      


      // Hay que parcuar el Body 
      // Si no viene Body es          lo pongo falso
      // Si el objeto es de tipo instanceof FormData no podemos usar JSON.stringify
      if(typeof options.body === 'object' && !(options.body instanceof FormData) ){  
        options.body = JSON.stringify(options.body);
      }
      

    

    // Si el Body esta falso lo borro con el metodo delete
    if (!options.body) delete options.body;
  


      console.log(options);
      console.log("a")
      
    setTimeout(() => controller.abort(), timeAbort);
      
      return fetch(endpoint, options)
        .then((res) =>
            res.ok
            ? res.json()
            
            : Promise.reject({
                err: true,
                status: res.status || "00",
                statusText: res.statusText || "Ocurrió un error",
              })
        )
        .catch( (err) => {
            
          let server_error = "desconectado"
          
          return {err,server_error }
        }  );
    };
  
    const get = (url, options = {} , timeAbort = 60000) => customFetch(url, options , timeAbort);
  

    const post = (url, options = {}, timeAbort = 60000) => { 
      options.method = "POST";
      return customFetch(url, options , timeAbort);
    };
  
    const put = (url, options = {}, timeAbort = 60000) => {
      options.method = "PUT";
      return customFetch(url, options,timeAbort);
    };
  
    const del = (url, options = {}, timeAbort = 60000) => {
      options.method = "DELETE";
      return customFetch(url, options,timeAbort);
    };
  
    return {
      get,
      post,
      put,
      del,
    };
  };