



export const validarForm  = ({validaciones, formulario}) => {


    let RESULTADOS = {};


  
    const iniciar = () => {
        console.clear();    
        //console.log(validaciones, formulario);     
        for ( let clave in validaciones ){
            console.log("INICIANDO DESDE A . " + clave)
           validarClave(clave);
        }
    }
   

  












    const validarClave = (clave) => {  
        let validacion = validaciones[clave];
        
        if( validacion !== undefined ){

            iniciarProcesodeValidacion(validacion , formulario[clave] , clave  );
        }
    }

    const iniciarProcesodeValidacion = (validacion , valor , clave ) => {

        console.log("iniciando proceso de validacion para : " + clave + " Su valor es: " + valor );
        console.log(validacion);



       if( valor !== undefined ){

            try {
                valor = valor.trim();    
            } catch (error) {  }       
       }

        /*** require  */
        let aux = true;
        let respuesta = {};

        if( validacion.required ){
            respuesta  = required( validacion.required , valor  );
            aux = respuesta.res;
        }
     

        if( aux === true &&  validacion.caracterMinimo ){
            respuesta   = caracterMinimo( validacion.caracterMinimo , valor  );
            aux = respuesta.res;
        }



        if( aux === true &&  validacion.numerico ){
            respuesta   = numerico( validacion.numerico , valor  );
            aux = respuesta.res;
        }

        

        if( aux === true &&  validacion.numeroMinimo ){
            respuesta   = numeroMinimo( validacion.numeroMinimo , valor  );
            aux = respuesta.res;
        }




        if( aux === true &&  validacion.maximoCaracteres ){
            respuesta   = maximoCaracteres( validacion.maximoCaracteres , valor  );
            aux = respuesta.res;
        }

        


        let data = {
            [clave] : respuesta
        }

        RESULTADOS = {...RESULTADOS , ...data}
       

        console.log("-------");
        console.log("-------");
        console.log("-------");
        console.log("-------");

    }

    const required = (required , valor) => {

        console.log(valor);

        console.log(valor);
        let res = true;

        if(  valor   ){
            if(valor !== undefined && valor !== null ){
                console.log(valor.length)
                if(valor.toString().length > 0){
                    console.log("TRUEE")
                    return   { res  };
                }else{
                    console.log("c")
                }
            }else{
                console.log("b")
            }
        }else{
            console.log("a")
        }
        console.log("Error no se cumplio las codinciones : "+valor+" !== undefined && "+valor+" && "+valor+".length > 0 ");
        res = false;
        let mensaje = required.mensaje
        return   { res , mensaje  };
           
    }

    const caracterMinimo = (caracterMinimo , valor) => {
        
        let res = true;
        if(!valor){
            return   { res  };
        }

        try {
            if(  valor.toString().length >=  caracterMinimo.valor ){
               
                return   { res  };
            }
        } catch (error) {}
            
        res = false;
        let mensaje = caracterMinimo.mensaje
        return   { res , mensaje  };
          
    }

    const numerico = (numerico , valor) => {
        
        let res = true;
        try {
           
            let aux  = parseFloat(valor);

            if(  typeof aux === 'number' && !isNaN(aux)  ){
                return   { res  };
            }
        } catch (error) {
            console.log(error);
        }

        res = false;
        let mensaje = numerico.mensaje
        return   { res , mensaje  };
    }   
    

    const numeroMinimo = (numerico , valor) => {
        
        let res = true;
        try {
           
            let aux  = parseFloat(valor);

            if(  typeof aux === 'number' && !isNaN(aux)  ){
                
                if (aux >= parseFloat(numerico.valor) ){
                    return   { res  };
                }
            }
        } catch (error) {
            console.log(error);
        }

        res = false;
        let mensaje = numerico.mensaje
        return   { res , mensaje  };
    } 




    const maximoCaracteres = (infomaximoCaracteres , valor) => {
        
        let res = true;
        let cadena  = valor.trim();
        let cantidaCaracteres = cadena.length;

        try {
           
           

            if( cantidaCaracteres < infomaximoCaracteres.valor ){
                return   { res  };
            }


        } catch (error) {
            console.log(error);
        }

        res = false;
        let mensaje = "El maximo de caracteres permitidos es de  " + infomaximoCaracteres.valor + " y tienes " + cantidaCaracteres
        return   { res , mensaje  };
    }
    

    
    iniciar();
    
    let estadoGlobalValidarionForm = estadoglobalresultados( RESULTADOS );


    return { RESULTADOS , estadoGlobalValidarionForm };
}


const estadoglobalresultados = (RESULTADOS) => {
    let resultado  = true;

    try {
        
        for ( let validacion in RESULTADOS ){

            if( RESULTADOS[validacion].res === false){
                return false;
            }
        }
        
        return resultado;

    } catch (error) {
        return false;
    }


    
}
