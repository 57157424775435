import React from 'react';
import {__app , __fecha } from "../../../utils/myApp"
import {BadgeCustom } from "../../../Core/Html/ComponenteHtml"
const CuerpoCtaPendientes = ({cuenta}) => {
    return (    
        <tr>
            <td>{cuenta.idpago}</td>
            <td>{ __fecha.formatoFecha({$fecha : cuenta.CREADO } ) }</td>
            <td>{cuenta.usernombre}</td>
            <td>{cuenta.clienteNombre}</td>
            <td>  <span className={'badge bg-' + cuenta.color_documento }> {cuenta.tipocomprobante} </span> </td>
            <td> <BadgeCustom colores={cuenta.color_tipoCredito} etiqueta={cuenta.tipocreditoNombre} />  </td>
            <td>{cuenta.numero_factura}/{cuenta.iddocumentos}</td>
            <td>
                <span dangerouslySetInnerHTML={{ __html: __app.formato_decimal( {total : cuenta.total , color : true })  }} />
            </td>
            
        </tr>
    );
};

export default CuerpoCtaPendientes;