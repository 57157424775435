import {  getDatabase, ref , query } from "firebase/database";
import { initializeApp } from "firebase/app";
import {fetchEmpresa , fetchNombreDispositivo, getLocalstore} from "../Core/fetchLocalStoreData";



const firebaseConfig = {
    apiKey: "AIzaSyAw4zl7nLyLGkFUhgrnbsIkbOEYkxxH2lc",
    authDomain: "pluma7-34f41.firebaseapp.com",
    databaseURL: "https://pluma7-34f41-default-rtdb.firebaseio.com",
    projectId: "pluma7-34f41",
    storageBucket: "pluma7-34f41.appspot.com",
    messagingSenderId: "624061040589",
    appId: "1:624061040589:web:dc3c1834e1567b6c702684",
};

export const app = initializeApp(firebaseConfig);


let  db = getDatabase();










export const getNit = () => {

    let ConfigEmpresa = fetchEmpresa();
    return  ConfigEmpresa.nit;
} 

export const recentPostsRef = () => {

    let ConfigEmpresa = fetchEmpresa();
    let nit = ConfigEmpresa.nit;
    return query(ref(db, 'cambiosdatabase/'+nit+'/productos/') ) 
} 



export const recentFacturaElectronicaRef = () => {
    let ConfigEmpresa = fetchEmpresa();
    let nit = ConfigEmpresa.nit;
    return query(ref(db, 'cambiosdatabase/'+nit+'/facturaelectronica/ultima_factura/'+fetchNombreDispositivo()+'/') ) 

}



export const recentOpenFacRef = () => {
    let ConfigEmpresa = fetchEmpresa();
    let user = getLocalstore('user');
    let nit = ConfigEmpresa.nit;
    if(user.ESTACION.id ){
        return query(ref(db, 'cambiosdatabase/'+nit+'/cuentas_abiertas/punto_venta_'+ user.ESTACION.id + '/'   ))
    }
    alert("no hay punto venta para cuentas abiertas")
    return query(ref(db, 'cambiosdatabase/'+nit+'/cuentas_abiertas/punto_venta_'+ 0 + '/'   )) 
}


export const estadoNet = () => {

    return  query(ref(db, 'estadonet') ) 
}





