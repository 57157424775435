import React, { useState } from 'react';
import { __app } from '../../../utils/myApp';
import {  getImtesVendidosByTurno } from '../Controlador';

const VentasItems = ( {turno_id} ) => {

    const [ itemsVendios , setItemVendios ] = useState([]);
    const [ carga , setCarga ] = useState(null);


    const [ totales , setTotales ] = useState({
        CANTIDAD : 0,
        UTILIDAD : 0,
        SUBTOTAL_3 : 0
    });


    const cargarVentasItemsbyTurno = async () => {

        if( itemsVendios.length > 0  ){
            return false;
        }

        if ( carga == 1 ){
            return false;
        }

        setCarga(1);
        
        let data = await  getImtesVendidosByTurno(turno_id , 1 );

        if  (data.length > 0){


            console.log(data);

            setCarga(null);
            setItemVendios(data);


            data.map((item, index) => {
                
                // Convierte las propiedades a números antes de sumarlas
                const cantidad = parseFloat(item.CANTIDAD);
                const utilidad = parseFloat(item.UTILIDAD);
                const subtotal = parseFloat(item.SUBTOTAL_3);
              
                // Acumula los valores en totales
                setTotales(prevTotales => ({
                    
                    CANTIDAD: prevTotales.CANTIDAD + cantidad,
                    UTILIDAD: prevTotales.UTILIDAD + utilidad,
                    SUBTOTAL_3: prevTotales.SUBTOTAL_3 + subtotal
                }));
            })

        } 

    }





    const index = 48545454754554;
    return (
        <div className="accordion" id="accordionPanelsStayOpenExample" >
                
        
            <div key={index} className="accordion-item">
               
                    <h2 className="accordion-header">
                       
                        <button onClick={cargarVentasItemsbyTurno} className={'bg-opacity-10  accordion-button collapsed' + ' bg-success'  } type="button" data-bs-toggle="collapse" data-bs-target={'#panelsStayOpen-collapse' + index } aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      
                        <span className={'fw-bold '}>  VENTAS POR ITEMS  
                        
                            { carga  == 1  &&  <span> Cargando..</span> }   
                        </span> 
                        
    
                        </button>

      

                    </h2>
                    <div id={'panelsStayOpen-collapse'  + index } className="accordion-collapse collapse"  style={{maxHeight: "500px", overflow : "auto"}}>
                        <div className="accordion-body">


                            { ( carga &&   carga == 1)  &&  <p>Cargando...</p> || 
                            
                                <table className='table '>
                                    <thead>
                                        <tr>
                                            <th>Codigo</th>
                                            <th>Nombre</th>
                                            <th>Cantidad</th>
                                            <th>Total</th>
                                            <th>Utilidad</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {( itemsVendios.map( (item, index) => 

                                        <tr>    
                                            <td>{item.id_2}</td>
                                            <td>{item.nombre}</td>
                                            <td>  { __app.formato_decimal( {total : item.CANTIDAD}) }</td>
                                            <td className='text-success'> $ { __app.formato_decimal( {total : item.SUBTOTAL_3})}</td>
                                            <td className='text-primary'> $ { __app.formato_decimal( {total : item.UTILIDAD})}</td>   
                                            
                                           
                                            
                                        </tr>   

                                    ))}
                                    </tbody>
                                    
                                    <tfoot>
                                        <tr>    
                                            <td></td>
                                            <td></td>
                                            <th>{ __app.formato_decimal( {total :totales.CANTIDAD})}</th>
                                            <th className='text-success'>$ { __app.formato_decimal( {total : totales.SUBTOTAL_3})}</th>
                                            <th className='text-primary'>$ {__app.formato_decimal( {total : totales.UTILIDAD})}</th>   
                                        </tr>
                                    </tfoot>

                                </table>
                            }
                        </div>
                    </div>
            </div>
         

        </div>
    );
};

export default VentasItems;