import userEvent from '@testing-library/user-event';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { fetchEmpresa } from '../../../../../Core/fetchLocalStoreData';

const EditarCuentacontable = ({
  fontSize,
  item,
  indexx,
  accionF,
  keyShow,
  accionName,
  descuento,
  user,
  clasesDefault,
  documento,
}) => {
  const [showinput, setShowInput] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  console.log(keyShow);

  useEffect(() => {
    const empresa = fetchEmpresa();
    const opciones = empresa.datos_precarga.gastos_contables ?? [];
    
    const defaultOption = opciones.find((option) => option.id == item.cuenta_contable);

    setSelectedOption(defaultOption);
  }, []);

  const handleChange = (e) => {
    setShowInput(false);
    let valor = e.target.value;
    let accion = accionName;
    accionF({ indexx, valor, accion });
  };

  const _handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleChange(e);
      e.preventDefault();
      return false;
    }
  };

  const empresa = fetchEmpresa();
  const opciones = empresa.datos_precarga.gastos_contables ?? [];

  const getLabel = (opcion) => {
    return `${opcion.id} - ${opcion.nombre}`;
  };

  const getValue = (opcion) => {
    return opcion.id;
  };

  const cambiosSelect = (data) => {
    console.log(data);
    setSelectedOption(data);
    let valor = data.id;
    let accion = accionName;
    accionF({ indexx, valor, accion });
  };

  return (
    <div>
      <Select
        getOptionLabel={(opcion) => getLabel(opcion)}
        getOptionValue={(opcion) => getValue(opcion)}
        options={opciones}
        onChange={cambiosSelect}
        value={selectedOption}
      />
    </div>
  );
};

export default EditarCuentacontable;
