import React from 'react';

const TagsIds = ( {ids, deleteCodigo} ) => {

    const arrayIds = ids.split(",");

    console.log("aaa")
    console.log(arrayIds);



    return (
        <div>
            {
                arrayIds.map( (data, index) => 
                    <>
                        {
                            data.length > 0 && <span title='Quitar Código' className="ms-1 badge text-bg-primary">{data} 
                            
                             {
                                (deleteCodigo) && 
                                <span onClick={() => deleteCodigo(index)} className='text-danger click link'>x</span>
                             }

                            </span>
                        }

                    
                    </>
                )
            }
        </div>
    );
};

export default TagsIds;