import React, { useState } from 'react';

const SelectPedidos = ({estadoFactura, handleChangeFormaPago}) => {




  return (
    <div className="cerrar-factura">
      <h3>Selecciona el estado de la factura</h3>

      {/* Select con las opciones */}
      <div className="form-group">
        <label htmlFor="estadoFactura">Estado de la factura</label>
        <select
          id="estadoFactura"
          className="form-control"
          value={estadoFactura}
          onChange={handleChangeFormaPago}
        >
          <option value="">Seleccionar...</option>
          <option value="cerrada-pagada">Cerrar Pagada</option>
          <option value="cerrada-pendiente">Cerrar Pendiente de Pago</option>
        </select>
      </div>

     

      {/* Mostrar el estado seleccionado */}
      <div className="mt-3">
        {estadoFactura && <p>Facturas marcada como: {estadoFactura}</p>}
      </div>
    </div>
  );
};

export default SelectPedidos;
