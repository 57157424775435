import React from 'react';
import { NavLink } from 'react-router-dom';

const LinksDirecto = ({data}) => {
    
    return (
        <div  className="nav-item-wrapper">
            <NavLink  className="nav-link label-1 p7-bkn-hiden-navar" to={data.path} role="button" data-bs-toggle="" aria-expanded="false">
                <div  className="d-flex align-items-center">
                    <span  className="nav-link-icon">
                        <span >{data.icon}</span>
                    </span>
                    <span  className="nav-link-text-wrapper">
                        <span  className="nav-link-text">{data.label}</span>
                    </span>
                </div>
            </NavLink> 
        </div>
    );
};


export default LinksDirecto;