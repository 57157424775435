


import React from 'react';
import TotalesDisplay from './Totales';

const TablaTiempos = ({resultado}) => {
    return (
        <div>
            {/* Columna para mostrar los resultados */}
            {resultado && <div className="col-md-6">

             

                    TOTAL DE HORAS <h1>{resultado.TIEMPO.totalHoras}</h1>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Concepto</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Días de 24horas </td>
                                <td>{resultado.TIEMPO.diasCompletos}</td>
                            </tr>
                           

                            <tr>
                                <td>Horas sobrantes </td>
                                <td>{resultado.TIEMPO.horas_sobrante_by24H}</td>
                            </tr>

                    

                            <tr>
                                <td>--</td>
                                <td>---------------</td>
                            </tr>



                            <tr>
                                <td>Dias de 12 horas </td>
                                <td>{resultado.TIEMPO.dias_de_12 }</td>
                            </tr>

                            

                            <tr>
                                <td>Noches </td>
                                <td>{resultado.TIEMPO.noches_de_12 }</td>
                            </tr>


                            <tr>
                                <td> Bloques de 12 Horas  </td>
                                <td>{resultado.TIEMPO.dias_y_noches_12 }</td>
                            </tr>


                            

                        
                      
            
                            <tr>
                                <th className='text-center' colSpan={2}>CALCULO DE HORAS  </th>
                            </tr>    
                            <tr>
                                <td>Horas sobrante Noches </td>
                                <td>{resultado.TIEMPO.horas_sobrante_nocturnas }</td>
                            </tr>

                            <tr>
                                <td>Horas sobrante dias </td>
                                <td>{resultado.TIEMPO.horas_sobrante_diurnas }</td>
                            </tr>

                            <tr>
                                <th>Horas sobrantes en 12H  </th>
                                <th>{resultado.TIEMPO.horas_sobrante_by12H}</th>
                            </tr>





                            <tr>
                                <td>minutos </td>
                                <td>{resultado.TIEMPO.minutos_sobrante}</td>
                            </tr>

                        
                            <tr>
                                <td>tiempo</td>
                                <td>{resultado.TIEMPO.textoTiempo}</td>
                            </tr>
                         
                        </tbody>
                    </table>


                    <TotalesDisplay  totales={resultado.TOTALES} />

                    <p>Logs de inventario</p>
                    {resultado.log.map((data,index) => 
                        <p key={index}>{data} </p>    
                    )} 


                    <div>
                        <div dangerouslySetInnerHTML={{ __html: resultado.explicacion }} />
                    </div>
            </div>}

       


        </div>
    );
};

export default TablaTiempos;