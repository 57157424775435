import Tiempos from "./Tiempos";

export default class Estandar_12h extends Tiempos {

    /***
     * Descripción del Cobro
     * 
     * Este cobro inicia cobrando por hora.
     * Cuando el valor de las horas supera el valor de 12 horas,
     * olvida el cobro por hora y aplica un valor fijo por 12 horas.
     * 
     */
    constructor({fecha1,fecha2,tarifasbd}) {
        // Llama al constructor de la clase padre (Tiempos)
        super();


        this.explicacion = `
            <p>Este algoritmo inicia cobrando por hora.</p>
            <p>Una vez el valor total de las horas supere el costo diario, se dejará de acumular el cobro por hora y se cobrará el costo del día.</p>
            
            <p>Las variables utilizadas son las siguientes:</p>
        
            <ul>
                <li><strong>aux1:</strong> Es el valor por cada 12 horas.</li>
                <li><strong>aux2:</strong> Valor por hora.</li>
                <li><strong>aux3:</strong> Tarifa mínima por entrar y sacar el vehículo.</li>
                <li><strong>aux4:</strong> Valor por cuarto de hora.</li>
                <li><strong>aux5:</strong> Es un tiempo. Escriba aquí después de cuántos minutos el sistema debe contarlo como una hora. 
                    <br>Ejemplo: Si quieres que después del minuto 10 el sistema cobre una hora completa, escribe el número 10.</li>

                <li><strong>aux6:</strong>El el Numero de horas de Gabela. Es decir una vez el cliente cumple 12 horas. Puede decidir no cobrarle las 2 horas siguientes y solo cobrarle 12
                En un ejemplo donde el cliente cumplio 12 horas.</li>

                <li><strong>aux7:</strong>El numero de minutos gratis de entrada sin cobrar.
                 Ejemplo si no queremos cobrar  5 minutos de la primera Hora para que el carro salga en 0. Escribimos el numero 5</li>


                <li><strong>aux8:</strong>Para efectos de IVA escriba aqui el porcentaje que quieres estar al precio Original.
                </li>
            </ul>
        `;



        this.fecha1 = fecha1;

        this.fecha2 = fecha2;

        // Definir las tarifas
        this.TARIFAS = {

            //aux1
            VALOR_12HORAS: parseFloat(tarifasbd.aux1),
            
            //aux2
            VALOR_HORA: parseFloat(tarifasbd.aux2),
            
            //aux3
            VALOR_MINIMO: parseFloat(tarifasbd.aux3),
            
            //aux4 
            VALOR_CUARTO_DE_HORA:  parseFloat( tarifasbd.aux4 ) ,
            

            //aux8
            PORCENTAJE_A_RESTAR:  parseFloat( tarifasbd.aux8 ) ,
        };

        // Opciones relacionadas con el tiempo
        this.OPCIONES_DE_TIEMPO = {
            /*** Se evalúa esta variable para transformar los minutos en una hora adicional */
            TRANSFORMAR_HORA_DESPUES_DE_X_MINUTOS:  parseFloat(tarifasbd.aux5),


            /**** Son las horas que se van arreglar depus de las 12 horas. El usuario tiene que tener 12 horas para aplicarle el descuneto */
            CANTIDAD_HORAS_DE_GABELA :  parseFloat(tarifasbd.aux6), 




            /**** El numero de Munutos gratis en la primera hora. Ideal cuando queremos que el sistema no cobre inmediatamente  **/
            MINUTOS_GRATIS_PRIMERA_HORA :  parseFloat(tarifasbd.aux7), 
        };

        // Valores de cobros inicializados en 0
        this.COBROS = {
            /**** El numero de horas que no fueron cobradas, Este valor on se suma */
            CANTIDAD_HORAS_DESCONTADAS :0 ,
            VALOR_12H: 0,
            VALOR_X_HORAS: 0,
            DESCUENTO_X_HORAS_EXTRAS : 0,
            VALOR_X_MINUTOS: 0,
            VALOR_MINIMO: 0,
            VALOR_A_PAGAR : 0
        };

        this.renderizar();


    }

    renderizar ( ){

        console.log(this);
    
        this.addLog("Algoritmo iniciado: Estandar_12h"  )
        
        this.index(this.fecha1 , this.fecha2);

        this.IniciarCobro();

    }

    IniciarCobro(){


        
        ///Valiadando minutos gratis en la primera hora.
        if(this.OPCIONES_DE_TIEMPO.MINUTOS_GRATIS_PRIMERA_HORA > 0 ){
            if(this.TIEMPO.totalHoras == 0 ){
                if(this.TIEMPO.minutos_sobrantes <= this.OPCIONES_DE_TIEMPO.MINUTOS_GRATIS_PRIMERA_HORA){
                    this.addLog("Se cumplio el tiempo de minutos gratis no cobraremos Tiempo"  )
                    this.COBROS.VALOR_A_PAGAR  = 1;
                    return false;
                }
            }
        }




        //Calculando 12horas
        this.COBROS.VALOR_12H = (  this.TIEMPO.dias_y_noches_12   ) * this.TARIFAS.VALOR_12HORAS;



        /// Si los minutos superan los minutos de gabela, entonces cobramos una hora completa por esos minutos
        if( this.TIEMPO.minutos_sobrantes  >   this.OPCIONES_DE_TIEMPO.TRANSFORMAR_HORA_DESPUES_DE_X_MINUTOS ){

            this.COBROS.VALOR_X_MINUTOS =  this.TARIFAS.VALOR_HORA;
        }else{

            if(this.TIEMPO.minutos_sobrantes  >  1 ){
                //En caso de que no se cumpa la condicion anteriro cobramos esos minutos como cuarto de hora.
                this.COBROS.VALOR_X_MINUTOS =  this.TARIFAS.VALOR_CUARTO_DE_HORA;
            }
        }




       
        

        /*** Cobramos horas. */
        this.COBROS.VALOR_X_HORAS =   this.TIEMPO.horas_sobrante_by12H *    this.TARIFAS.VALOR_HORA;




         
        //cobramos las horas.
        /**
         * Antes de combrar las horas debemos valifar que el cliente regale una horas estras.
         * PEro esto solo puede pasar de this.TIEMPO.dias_y_noches_12 > 0.
         * 
         */
        if(this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA > 0 ){

            this.addLog("Encontramos Gabela Activa , gabela es de  :" + this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA )
            /** Si hay descuento */
            if(this.TIEMPO.dias_y_noches_12 > 0){

                
                if(this.TIEMPO.horas_sobrante_by12H  > 0 ){
                    this.addLog("Encontramos horas sobrante de   :" + this.TIEMPO.horas_sobrante_by12H )
                   
                    let horas_sobrantes =   this.TIEMPO.horas_sobrante_by12H  -  this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA;
                    /*** Entonces si hay horas sobrante */

            
                    this.addLog("El sobrante es de :   :" +horas_sobrantes )


                    //Si entramo aqui, es por que el todal de horas se les hizo el descuento si sobrante se supone que quedara automantentico
                    if(horas_sobrantes >=   0 ){
                        this.addLog("Si Entramos aqui es por que las gabelas se consumieorn" )
                        this.COBROS.DESCUENTO_X_HORAS_EXTRAS = (this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA * this.TARIFAS.VALOR_HORA) * -1;
                        this.COBROS.CANTIDAD_HORAS_DESCONTADAS = this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA;
                    }


                    if(horas_sobrantes < 0){
                        this.addLog("hay sobrante de Gabela y podriamos anular minutos" )
                        //Sacamos las horas real
                        let aux_horas_de_gabela = this.OPCIONES_DE_TIEMPO.CANTIDAD_HORAS_DE_GABELA - (horas_sobrantes * -1)  ;
                        
                        
                        this.COBROS.DESCUENTO_X_HORAS_EXTRAS = (aux_horas_de_gabela * this.TARIFAS.VALOR_HORA) * -1;
                        this.COBROS.CANTIDAD_HORAS_DESCONTADAS = aux_horas_de_gabela;  


                        //Anulamos los minutos
                        this.COBROS.VALOR_X_MINUTOS = 0;
                    }

           
                }



            }
           
        }





        //Si la suma de las horas y munutos es Mayor a la Tarifa por dia, entonces ignoramos ese valor y le cobramos las horas al valor del dia
        if( (this.COBROS.VALOR_X_MINUTOS + this.COBROS.VALOR_X_HORAS ) >=     this.TARIFAS.VALOR_12HORAS   ){

            this.COBROS.VALOR_X_MINUTOS = 0;
            this.COBROS.VALOR_X_HORAS =   this.TARIFAS.VALOR_12HORAS;
        }





        this.COBROS.VALOR_A_PAGAR =  parseFloat(this.COBROS.VALOR_12H) +  parseFloat(this.COBROS.VALOR_X_HORAS) +  parseFloat(this.COBROS.VALOR_X_MINUTOS) +  parseFloat(this.COBROS.DESCUENTO_X_HORAS_EXTRAS);
            
        
        
        if(this.COBROS.VALOR_A_PAGAR <  this.TARIFAS.VALOR_MINIMO  ){
            
            
            this.COBROS = {
                DESCUENTO_X_HORAS_EXTRAS : 0,
                VALOR_12H: 0,
                VALOR_X_HORAS: 0,
                VALOR_X_MINUTOS: 0,
                VALOR_MINIMO: 0,
                VALOR_A_PAGAR : 0
            };

            this.COBROS.VALOR_MINIMO  =   this.TARIFAS.VALOR_MINIMO;
            this.COBROS.VALOR_A_PAGAR  =  this.TARIFAS.VALOR_MINIMO;
        }







        /**** Quitanto IVAS  */
        if(this.TARIFAS.PORCENTAJE_A_RESTAR > 0 ){
            this.COBROS.VALOR_A_PAGAR =  this.COBROS.VALOR_A_PAGAR / (1 + this.TARIFAS.PORCENTAJE_A_RESTAR / 100);
        }


    }

}