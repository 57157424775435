import React from 'react';
import PedidoIndividual from './PedidoIndividual';


const ModoTarjetas = ({pedidos , cargarDocumento , eliminarCuentaAbierta , user , imprimirPreFactura  }) => {
    return (
       
        <div className="row mt-1 row row-cols-1 row-cols-md-4 row-cols-xl-6  g-1">
            {/*** Modo tarjetas. */}
            { pedidos.sort((a, b) => a.FORM.mesa_id - b.FORM.mesa_id).map( (duc,keys) =>        
                <PedidoIndividual imprimirPreFactura={imprimirPreFactura} user={user} key={keys} duc={duc}  cargarDocumento={cargarDocumento}  eliminarCuentaAbierta={eliminarCuentaAbierta} />  
            )}
        </div>
   
    );
};

export default ModoTarjetas;