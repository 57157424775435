



import React, { useEffect, useState } from 'react';
import FacturaTirilla from '../../Document/PlantillasImpresion/FacturaTirilla';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';
import { Numpositivo } from '../../../Core/MatematicasGlobales';
import prindiv from 'easyprintand';

const ReimprimirTirilla = ({documento}) => {

    console.log(documento)
    const user = fetchUSer();




    const Auxproductos =   documento.ITEMS.map(( item ) => {

        return {
            
            id_2: item.id_2,
            nombre : item.nombre,


            MATEMATICAS: {

                PRECIO_3:  Numpositivo(item.SUBTOTAL_3 / item.CANTIDAD),
                CANTIDAD:  Numpositivo(item.CANTIDAD),
                SUBTOTAL_3:  Numpositivo(item.SUBTOTAL_3)

            }
        }

    })

    console.log(Auxproductos)
     

    const documentoAux = {

        
        id_server:  documento.DOCUMENTO.CtDocumento_id,

        fechaFinal : documento.DOCUMENTO.CREADO,
        
        TIPO_DOCUMENTO: {
            descripcion: documento.DOCUMENTO.CtTipoComprobanteFiscal_descripcion
        },


        FORM: {
            mesanombre: null,
            nombreCajero: documento.DOCUMENTO.Cajero,
            nombre_vendedor: documento.DOCUMENTO.vendedor,
            Operadores: null
        },
    


        RESPONSE_HTTP_FACTURA_ELECTRONICA: {
            numero_factura :  documento.DOCUMENTO.CtDocumento_ct_numero_factura ,
            CUFE :  documento.DOCUMENTO.DIAN_CUFE ,
            resolucion : documento.DOCUMENTO.DIAN_resolucionTexto ,
            codigoQr : documento.DOCUMENTO.DIAN__codigoQr,
        },



        EstadoServidor: 1,



        NUMERACION: {
            numero_completo:  documento.DOCUMENTO.CtDocumento_ct_numero_factura  ,
            texto_factura:   documento.DOCUMENTO.DIAN_resolucionTexto ,
        },


        OBJETO: null,


        patio_entrada_fecha:  null,

        patio_salida_fecha:  null,

        patio_tiempo:  null,

        cuentAbiertaFirebaseId:  null,


        placa: documento.DOCUMENTO.periodo_objeto_id,


        TERCERO: {
            razonSocial: documento.DOCUMENTO.razonSocial,
            identificacion: documento.DOCUMENTO.identificacion,
            telefono: documento.DOCUMENTO.telefono
        },




        PRODUCTOS : Auxproductos,



        MATEMATICAS: {

            SUBTOTAL_1 : Numpositivo(documento.DOCUMENTO.CtDocumento_SUBTOTAL_1) ,

           
            DESCUENTO_LINEA: Numpositivo(documento.DOCUMENTO.CtDocumento_DESCUENTO),


            IMPUESTO: Numpositivo(documento.DOCUMENTO.CtDocumento_IMPUESTOS),


            SUBTOTAL_3: Numpositivo(documento.DOCUMENTO.CtDocumento_SUBTOTAL_3),
        },
    };

    
    
    const [imprimirDocumento , SetimprimirDocumento ] = useState();

    const handleImprimirTirilla = () => {
        
        SetimprimirDocumento(documentoAux)
       
        setTimeout( () => {
            prindiv('EasyPrintAnd' ,  { extraCss : './tirilla.css' } )
        } , 300 )
    }




    return (
        <div>
           
            <button onClick={handleImprimirTirilla} className="btn btn-primary me-2 mt-2">
                <i className="fa fa-refresh"></i> Imprimir Tirilla
            </button>     


             {/*
            * Platilla de Reimpresion Solo para cuenta Abierta
            */}
           {imprimirDocumento && (

               <>
                    
                    <FacturaTirilla documento={imprimirDocumento} user={user}  />
               
               </>
      
            )}
            
            
        </div>
    );
};

export default ReimprimirTirilla;