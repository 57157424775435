

import React, { useState } from 'react';
import {enviarPeticion} from "../../../Core/ActionDatabase";



  const CargarCotizacion = ({desactivar , actualizarTab , LogicaDocumento , documento }) => {

    const [numero , setNumero ] = useState("");

    const consultarFactura = async () => {

        LogicaDocumento.cancelarCuentaActual();

        LogicaDocumento.despachador( "eliminar_todos_productos" )

        let respuesta =  await enviarPeticion( "ventas/consultar_cotizacion"  , numero  );

        if(respuesta.status && respuesta.respuesta == 1  ){

            if(respuesta.data.documento){


               desactivar();
    
                setTimeout(  () => {
                    
                    //actualizarTab(respuesta.data.info_documento );


                    //LogicaDocumento.despachador( "cambiar_tipo_documento" , {
                      //  valor  : {...respuesta.data.info_documento  , bloquear : true }      
                    //})   

                

                    

                    //let copy = LogicaDocumento.DOCUMENT;
                    
                    //copy.FORM = { numero_devolucion:  respuesta.data.documento.ct_numero_factura  };
                    
                 

        

                    //LogicaDocumento.DISPAC.dispatch({
                       // type :  LogicaDocumento.DISPAC.actionTypeDocument.SET_DOCUMENTO,
                        //documento : copy
                   //});
                   
                     
                   LogicaDocumento.agregarMultiItems( respuesta.data.infoProductosConsumidos  );

                } , 100)
                
            }else{
                alert("No encontramos este numero")
            }
            
        
        }else{
            alert("No tenemos conexion con el Servidor.");
        }


    }

    return (
        <div>
            <h1>Cargar una Cotización</h1>
            <p>Escriba el codigo unico interno</p>
            <input  onChange={ (e) =>  setNumero(e.target.value) } className='form-control' type="text" />

            <br/>

          

            <button onClick={desactivar} className='btn btn-lingh'> cancelar </button>
            <button onClick={consultarFactura} className='btn btn-principal'> Consultar </button>
        </div>
    );
};

export default CargarCotizacion;