import React from 'react';
import prindiv from 'easyprintand';
import PlantillaPago from './PlantillaPago';



const Boton = ({pago}) => {


    const handlePrint = () => {
        prindiv('EasyPrintAnd') 
    }




    return (
        <div>

            <button onClick={handlePrint}>Imprimir</button>

            <PlantillaPago pago={pago} />
        </div>
    );
};

export default Boton;