import React, { useEffect, useState } from 'react';

import { fetchUSer } from '../../../../Core/fetchLocalStoreData';
import { NotificationManager } from 'react-notifications';
import { cargarInventariobyProductoid , setCantidadInventario } from '../../Controlador';

const AjustarDirecto = ({item , desactivar}) => {

    
    const [stock , setStock] = useState(null);

    const [cantidad , setCantidad] = useState();
    const [observacion , setobservacion] = useState();
    
    const user  =  fetchUSer();

    useEffect( () => {
        console.log(item)
        abc();

    }, [])




    const abc = async () => {

        let cantidad = await cargarInventariobyProductoid(item.id + "/" + user.ESTACION.id);

        console.log(cantidad)
        if( typeof parseFloat(cantidad.cantidad) === 'number'){
            setStock(parseFloat(cantidad.cantidad))
        }

    }


    const realizarCambios = () => {
        if (observacion.length < 2) {
            NotificationManager.error("Escriba una Observación Válida");
            return false;
        }
    
        // Si estás verificando si la longitud de la cantidad es menor que 0, 
        // eso probablemente no sea lo que deseas. 
        // También, verifica si cantidad es null o undefined primero.
        if (cantidad === null || cantidad === undefined || cantidad.length < 1) {
            NotificationManager.error("Escriba una cantidad Válida");
            return false;
        }
        

       

        let  producto_id       =    item.id;
        let  estacione_id      =    user.ESTACION.id;
        let  cantidad_actual  =     stock; 
        let user_id           =     user.USER.id
        setCantidadInventario({producto_id,estacione_id,cantidad,observacion, cantidad_actual,user_id});

        desactivar();
        
       
    }
    return (
        <div>
           

          <h3>  Stock Actual :  <b>{stock}</b></h3>
            
            {
                stock !== null  &&
                <>

                    
                <div className='mt-2'>
                    <label htmlFor="">Escribe la nueva Cantidad</label>
                    <input value={cantidad} onChange={ (e) =>  setCantidad(e.target.value) } type='number' className='form-control'/>
                </div>


                <div className='mt-4'>
                    <label htmlFor="">Escribe una Observación  </label>
                    <input value={observacion} onChange={ (e) =>  setobservacion(e.target.value) } type='text' className='form-control'/>
                </div>


                <button onClick={realizarCambios}  className='mt-2 btn btn-success btn-lg'>Guardar STOCK </button>

                </>

                ||
                <p>Cargando..</p>
            }
        </div>
    );
};

export default AjustarDirecto;