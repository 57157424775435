import React from 'react';
import { __app , __fecha } from '../../utils/myApp';
import 'moment/locale/es'  // without this line it didn't work
import Moment from 'react-moment'
const VistaComanda = ({ultimaComanda , reimprimirComanda}) => {
    return (
        <div>
            <div>
            <button onClick={() => reimprimirComanda( ultimaComanda ) } className='btn btn-xs float-end'> Imprimir </button>
                <h1>Mesa #{ ultimaComanda.FORM.mesanombre }</h1>
              
                <div style={{marginTop:'-15px'}}>
                    <span className='fs-4' style={{display:'block'}}>
                        ⏱<Moment fromNow interval={3000}>{__app.formatoFechaGTM5(ultimaComanda.FORM.HoraComanda)}</Moment>                       
                    </span>
                </div>

                <span  style={{display:'block' , marginTop:'20px'}}>
                    {__fecha.formatoFecha( { '$fecha' : ultimaComanda.FORM.HoraComanda  } )}                            
                </span>
                <span style={{display:'block' , marginTop:'10px'}}>
                        🎫  Comanda { ultimaComanda.cuentAbiertaFirebaseId &&  <span>#{ultimaComanda.cuentAbiertaFirebaseId}</span>  || <span>#{ultimaComanda.id}</span>}                           
                </span>

               

                <span> 🤵🏻 Mesero : {ultimaComanda.FORM.nombreCajero}</span>
                
                <div style={{marginTop: 20}}>
                    {ultimaComanda.PRODUCTOS.map( (pro,index) => 
                        <div key={index} style={{borderTop:'solid 1px'}}>
                            <p> { pro.numberid == ultimaComanda.numberid &&  <span className='badge bg-success color-success'>✔️ Nuevo </span> }</p>
                            <span style={{'fontSize' : '16px'}}>{pro.MATEMATICAS.CANTIDAD})</span>  <span>{pro.nombre}</span>
                            {pro.descripcion && <span style={{display:'block', marginLeft:'15px', fontFamily:'monospace', marginTop:'3px'}}>{pro.descripcion}</span> }
                            <span style={{ fontSize: '16px' ,  display: 'block' , width: '100%', textAlign: 'end'}}> <span className='d-block fs-5'>   ${ __app.formato_decimal({ total : pro.MATEMATICAS.SUBTOTAL_3})  } </span></span>
                        
                            {pro.COMPUESTO &&
                                <ul>
                                    {pro.COMPUESTO.map( (com,index2) => 
                                        <li key={index2}>{com.nombre}</li>
                                    )}
                                </ul>
                            }
                        
                        </div>
                    )}
                </div>

                <div>
                    <span className='d-block fs-3 text-end'> 💵   { __app.formato_decimal({ total : ultimaComanda.MATEMATICAS.SUBTOTAL_3})  } </span>
                </div>
        
            </div>
        </div>
    );
};

export default VistaComanda;
