import React, { useState } from 'react';

const CheckListGrupos = ({data , listaName}) => {
 


        // State with list of all checked item
        const checkList = data;
        const [checked, setChecked] = useState([])
       
          
        // Add/Remove checked item from list
        const handleCheck = (event , item , index) => {
            var updatedList = [...checked];
            if (event.target.checked) {
                updatedList = [...checked, item];
                setChecked(updatedList);
            } else {
                //updatedList.splice(checked.indexOf(index), 1);        
                let data =  updatedList.filter((data) => data != item );
                setChecked(data);
            }
               
        };


        const handleCheckAll = (event) => {
            if (event.target.checked) {
                setChecked(checkList);
            }else{
                setChecked([]);
            }
        }


        // Return classes based on whether item is checked
        var isChecked = (item) => {

            let data = checked.filter( (check) => check == item ) 
       
            if(data.length > 0 ){

                return "checked";
            }
            return "";
        }

        
            
          
        return (
            <div className="">
                <div className="checkList">
                  <div className="title"></div>
                 
                  <div className="list-container d-flex flex-wrap" >
                    {checkList.map((item, index) => (
                      <div key={index} className='me-3 mt-2'>
                        <input className='form-check-input' id={index + "flexCheckChecked"} value={item.id} type="checkbox" onChange={(e) => handleCheck(e,item , index) } checked={isChecked(item)} />
                        <label class="form-check-label" htmlFor={index + "flexCheckChecked"}>
                            {item.nombre}
                        </label>
                      </div>
                    ))}

                    <div  className='me-3 mt-2'>
                        <input className='form-check-input' id={listaName + "flexCheckChecked"}  type="checkbox" onChange={(e) => handleCheckAll(e) }  />
                        <label class="form-check-label" htmlFor={listaName + "flexCheckChecked"}>
                            TODAS 
                        </label>
                      </div>
                  </div>
                </div>
          
               
            </div>
        );
};

export default CheckListGrupos;