import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { getReporteByTurno , getDocumentoByTipoTurno} from './Controlador';
import {__app} from "../../utils/myApp"

import Acordiones from './ViewTurnoid/Acordiones';
import AcordionCuentasPendientes from './ViewTurnoid/AcordionCuentasPendientes';
import AcordionConsolidado from './ViewTurnoid/AcordionConsolidado';
import VentasVendedor from './ViewTurnoid/VentasVendedor';
import VentasItems from './ViewTurnoid/VentasItems';
import CuentasAbiertasEliminadas from './ViewTurnoid/CuentasAbiertasEliminadas';
import Facturaseliminadas from './ViewTurnoid/Facturaseliminadas';
import ItemsCtaEliminados from './ViewTurnoid/ItemsCtaEliminados';
import { fetchUSer } from '../../Core/fetchLocalStoreData';


function TurnoView(props) {

    let  { id } = useParams();
    
    const [ tipoDocumentos  , setTipoDocumentos ] = useState([]);
    const [ tipoOperaciones , settipoOperaciones ] = useState([]);
    const [ consolidadoCaja , setConsolidadoCaja ] = useState([]);
    const [usoSaldoFavor , SetusoSaldoFavor]       = useState([]);

    const [ totalConsolidado , setTotalConsolidado] = useState();
    const [ totalSaldoFavorConsumido , settotalSaldoFavorConsumido] = useState(0);

    const [ aux  , setaux ] = useState([]);
    
    const [precarga , SetPrecarga ] = useState(1);
    
    
    const user = fetchUSer();

    
    useEffect( () => {

        optenerDetalles(id);
        
        
        





        // Limpiar el cambio cuando el componente se desmonta
        return () => {
            document.body.style.backgroundColor = '';
        };



    } , [] )




    const optenerDetalles = async () => {
        let data = await getReporteByTurno(id);

        if(data){
            SetPrecarga(null);
        }
        //setTipoDocumentos(data);
       // settipoOperaciones(data.operacionestipo)
       combinarDatos(data);

       

       organizarconsolidado(data.consolidado_de_caja , data.formas_de_pago)

       SetusoSaldoFavor({
            
            comprobante : data.uso_saldo_favor,
            nombre : "SALDO A FAVOR CONSUMIDO" 
        });
    
      
    }


    const organizarconsolidado = (consolidado_de_caja , formas_de_pago) => {

        let totalGlobal = 0;



       // Iterar sobre formasPago y agregar la propiedad "comprobante"
       formas_de_pago.forEach((formaPago) => {
            formaPago.comprobante = consolidado_de_caja.filter(
                (comprobante) => comprobante.forma_pago_id === formaPago.id
            );

            // Calcular la suma total
            formaPago.TOTAL = formaPago.comprobante.reduce(
                (total, comprobante) => total + parseFloat(comprobante.total),
                0
            );


            totalGlobal  +=   formaPago.TOTAL;
        });
        


 

        let TOTAL = 0;
        if( usoSaldoFavor.comprobante){

            TOTAL = usoSaldoFavor.comprobante.reduce(
                (total, comprobante) => total + parseFloat(comprobante.total),
                0
            );  
        }  
        TOTAL = TOTAL * -1;
        settotalSaldoFavorConsumido(TOTAL);

        setTotalConsolidado(totalGlobal );


        setConsolidadoCaja(formas_de_pago);
        
    }



    const combinarDatos = (data) => {
        
        data.operacionestipo.forEach(function(operacion) {
            
            operacion.DOCUMENTOS = data.tipodc.filter(function(documento) {
                return documento.ct_operacion_id === operacion.id;
            });

            operacion.CUENTASPENDIENTES = data.cuentas_pendientes.filter(function(docu) {
                return docu.ct_operacione_id === operacion.id;
            });


    
            operacion.TOTAL   = operacion.DOCUMENTOS.reduce(function(acumulador, objeto) {
                console.log(objeto);
                if(objeto.ct_master_id > 0 ){
                    return acumulador +  parseFloat(objeto.SUBTOTAL_3);
                }
            }, 0);


        });
        




        data.operacionestipo.forEach(function(operacion) {
            operacion.X_PAGAR  = sumarCuentasporPagar(operacion.CUENTASPENDIENTES) ;
            operacion.X_COBRAR = sumarCuentasporCobrar(operacion.CUENTASPENDIENTES);
        });


      

        
        settipoOperaciones(data.operacionestipo)
    }



    const sumarCuentasporPagar = (cuentasPendientes) => {
        let numero = 0;
        cuentasPendientes.forEach(objeto => {
            if (objeto.total < 0) {
                numero += parseFloat(objeto.total);
            } 
        });
        return numero;
    }


    const sumarCuentasporCobrar = (cuentasPendientes) => {
        let numero = 0;
        cuentasPendientes.forEach(objeto => {
            if (objeto.total > 0) {
                numero += parseFloat(objeto.total);
            } 
        });
        return numero; 
    }






    const cargarDocumentos = async (key) => {
        
      
        
        if (tipoDocumentos[key].DOCUMENTO ){
    
            return false
        }



        let data = await getDocumentoByTipoTurno(id ,tipoDocumentos[key].id );
        const aux =  [...tipoDocumentos];
        aux[key].DOCUMENTO = data


        console.log(aux);
        setTipoDocumentos(aux);

    
    }

    const cargarDocumentos2 = async (keyOperaciones,  index) => {


        if (tipoOperaciones[keyOperaciones].DOCUMENTOS[index].ESTADOHTTP &&  tipoOperaciones[keyOperaciones].DOCUMENTOS[index].ESTADOHTTP == 1 ){
            return false
        }
        
    

        if (tipoOperaciones[keyOperaciones].DOCUMENTOS[index].DOCUMENTOS ){
            return false
        }
        

        /*** Iniciamos una CARGA */
        const aux2 =  [...tipoOperaciones];
        aux2[keyOperaciones].DOCUMENTOS[index].ESTADOHTTP = 1;
        settipoOperaciones(aux2)



        let data = null;
        data = await getDocumentoByTipoTurno(id ,tipoOperaciones[keyOperaciones].DOCUMENTOS[index].id);
        if(data){

            const aux =  [...tipoOperaciones];
            aux[keyOperaciones].DOCUMENTOS[index].DOCUMENTOS = data;
            aux[keyOperaciones].DOCUMENTOS[index].ESTADOHTTP = null;
            settipoOperaciones(aux)
        }
    
    }
    



    
    if ( !(user.PERMISOS.verTurno && user.PERMISOS.verTurno == 1) ){

        return (<h3 className='text-white'>No tienes permisos para ver turno</h3>); 

    }else{
      
    }


    if(precarga == 1){
        return (<h1 className='text-white'>Cargando...</h1>);
    }
    
    return (
        <div>
            <h3 className='text-dark'>Informe de Turno #{id}</h3>

            { tipoOperaciones.map((ope , index)  => 
               
               
               <div  key={index}>
              
                {
                    (ope.DOCUMENTOS.length > 0 ) && 
                        <div   key={index} className='mt-5 card pt-5 pb-5 pe-3 ps-3' >
                            <h3>{ope.nombre}</h3>

                            <Acordiones tipoDocumentos={ope.DOCUMENTOS}  cargarDocumentos={cargarDocumentos2}  indexx={index}  />
                            
                            
                            { ( ope.TOTAL > 0 || ope.TOTAL < 0 ) && <h2 className='mt-2'> TOTAL  : $ {  __app.formato_decimal({total: ope.TOTAL })  }</h2> }




                           



                            {
                                ( ope.X_PAGAR != 0   ||  ope.X_COBRAR != 0   ) &&

                                <div>
                                    <h4 className='mt-5'>Cuentas pendientes</h4>
                                    <AcordionCuentasPendientes signo="+" cuentasPendientes={ope.CUENTASPENDIENTES} id={'ctpcc'} indexx={index}  titulo="Cuentas por Cobrar" operacion={ope} /> 
                                    <AcordionCuentasPendientes   signo="-"  cuentasPendientes={ope.CUENTASPENDIENTES}   id={'ctppc'} indexx={index}  titulo="Cuentas por Pagar " operacion={ope}  /> 
                                
                                </div>

                            }


                        </div>
                }
               </div>
            )}
            
            
            <div className='mt-5 card pt-5 pb-5 pe-3 ps-3' >

                <h3 className='mt-5' >OTROS DATOS  </h3>
            
                <VentasVendedor turno_id={id} />
                <VentasItems turno_id={id} />

                <CuentasAbiertasEliminadas turno_id={id} />
                <ItemsCtaEliminados turno_id={id} />
                {
                    /*
                    <Facturaseliminadas turno_id={id} />
                    <ItemsCtaEliminados turno_id={id} />
                    */
                }
            </div>
          
            






            <div className='mt-5 card pt-5 pb-5 pe-3 ps-3' >

                <h1>Consolidado de Caja</h1>    

                { consolidadoCaja.map( (consolidado , index2 ) => 

                    <div key={index2}>
                        {
                            ( consolidado.comprobante.length > 0 ) && 

                            <AcordionConsolidado   key={index2}  consolidado={consolidado} indexx={index2} />
                        }
                    </div>


                       

                )}

                

                <h2 className='mt-5 text-success text-center'>Total Consolidado <b> $ { __app.formato_decimal( { total : totalConsolidado})  }  </b></h2>
                
                
                <div className='mt-5'>
                    <AcordionConsolidado  saldoFavor={totalSaldoFavorConsumido}  switchSaldo="+" key={454545}  consolidado={usoSaldoFavor} indexx={454545} /> 
                </div>


            </div>   
            
        

        </div>
    );
}





export default TurnoView;