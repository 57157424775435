import React from 'react';
import CodigoQrfe from '../../../Documentos/Componentes/CodigoQrfe';


const DatosFacturaElectronica = ({documento}) => {

    if( !(documento?.RESPONSE_HTTP_FACTURA_ELECTRONICA?.CUFE)){

        return false;
    }
    return (
        <div>
 
            <p> 
                <b>Numero Factura:</b> {documento.RESPONSE_HTTP_FACTURA_ELECTRONICA.numero_factura}  <br />
                
                <div style={{ wordBreak: 'break-all', overflowWrap: 'break-word' }}>
                <b>CUFE:</b>{documento.RESPONSE_HTTP_FACTURA_ELECTRONICA.CUFE}     
                </div>     
                <br />
                <b>Resolucion:</b> {documento.RESPONSE_HTTP_FACTURA_ELECTRONICA.resolucion} 
            </p>
            
            <CodigoQrfe codigo={documento.RESPONSE_HTTP_FACTURA_ELECTRONICA.codigoQr} />
        </div>
    );
};

export default DatosFacturaElectronica;