import React , {useState} from 'react';
import { __app } from '../../../../../utils/myApp';
import { SumarImpuestosPrecio , obtenerValorAntesDeImpuestos} from '../../../../../Core/MatematicasGlobales';
import DisplayNumber from '../DisplayNumber';
const ActualizarPrecioVentaCompra = ({item,indexx,accionF,accionName , user , decimal , documento , fontSize }) => {

    const [showinput , setShowInput ] = useState(false);


    const handleChange = (e) => {
        setShowInput(false);

        let valor = e.target.value;
        valor = obtenerValorAntesDeImpuestos(valor , item.IMPUESTOS_RAIZ);
        let accion = accionName;
        accionF({indexx,valor, accion});
    }


    const _handleKeyDown =  (e)  => {
        if (e.keyCode === 13) {
            handleChange(e);
            e.preventDefault();
            return false;
        } 
    }

    const consultarPrecio = () => {
   
        if(item.COMPRA && item.COMPRA.precio_actual){

            
            let precio  = SumarImpuestosPrecio(item , item.COMPRA.precio_actual)


            return   __app.formato_decimal({"total" : precio});
        }
       
        return -1
    }

    /*bloquear_precio = 0     PReguntar si este usuario tiene permisos para alterar los precios de venta */
    if ( user.USER.type_user_id  >  1  && false  == 1 ){
        
        return (
            <>    
                       
                <DisplayNumber  number={consultarPrecio()} fontSize={fontSize}  />
                
                <i className="fa-sharp font-s-candado fa-solid fa-lock"></i>
            </>
        )
        
    }
    
    
    return (
        <>
           { !showinput && 
            <div onClick={ () =>  setShowInput(true) }>   
               { consultarPrecio()}
            </div> }
           {showinput && <input   onKeyDown={_handleKeyDown} className='max-50' autoFocus onBlurCapture={ handleChange}  defaultValue={consultarPrecio()} />}
        

        </>
    );
}


export default ActualizarPrecioVentaCompra;