import React, { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import { __app } from '../../../utils/myApp';

import Offcanvas from 'react-bootstrap/Offcanvas';

const  StandarOffCanvas = ({children , titulo , mostrar , desactivar , size , fullscreen = 0 , headerShow = true}) => {

  let sizee = size;

  if(!size){
    sizee = ""; 
  }

  if ( __app.TipoMovil() ) fullscreen = 1;

  const [show, setShow] = useState(mostrar);

  useEffect( () => {
    
    setShow(mostrar);

  } , [mostrar])
  
  const handleClose = () => {
    desactivar();
    setShow(false);
  } 
  const handleShow = () => setShow(true);


  return (
    <>
     

     <Offcanvas
        size={sizee}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen={Number(fullscreen)}
      >
        


        <Offcanvas.Header closeButton>
            <Offcanvas.Title  >{titulo}</Offcanvas.Title>

        </Offcanvas.Header>
        

          <Offcanvas.Body>
          {children}
          </Offcanvas.Body>

        </Offcanvas>
    </>
  );
}

export default StandarOffCanvas;
