import React from 'react';
import { __app } from '../../../../utils/myApp';
import DisplayNumber from './DisplayNumber';

const LineaImpuestos = ({fontSize , impuestos  , indexx , accionF , user}) => {

    if(! impuestos ){
        return false;
    }

    const _handleClick = (index) =>   {
        let valor  = index;
        let accion = "eliminar_impuesto_linea";
        accionF({indexx, accion , valor });
    }

    return (
            <table>
                <thead>
                 
                </thead>
                <tbody>
                { impuestos.map( (im , index) => 
                    <tr key={im.id}>
                        <th>{im.nombre}</th> 
                        <th> <DisplayNumber fontSize={fontSize} number={__app.formato_decimal({total: im.IMPUESTO_UNITARIO})} /> 
                            {user.USER.type_user_id  == 1 && <span onClick={ () => _handleClick(im.id) } className='badge bg-danger'><i title='Quitar impuesto' className="fa fa-trash" aria-hidden="true"></i> </span> }
                        
                        </th>
                    </tr> 
                )}
                </tbody>
            </table>
    );
}
export default LineaImpuestos;