import React from 'react';
import { Link } from 'react-router-dom';

const RutaPrivada = () => {
    return (
        <div>
            <p>
                Estas intentando entrar a una ruta privada.
                <br />
                Por favor inicia sesion para continuar.
            </p>
        
            <Link  to={"/"} className=" "  >
                Pagina Principal
            </Link>

        </div>
    );
};

export default RutaPrivada;