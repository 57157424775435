import React from 'react';

const TerceroCarta = ({documento , fontSize }) => {
    return (
        <>
                
            { documento.TERCERO &&      
              
                <table cellSpacing="0" style={{ paddingTop: '10px',  width: '100%', fontSize: fontSize.md + 'pt' }}>
                    <colgroup>
                        <col style={{ width: '65%', textAlign: 'left' }} />
                        <col style={{ width: '35%', textAlign: 'center' }} />
                    </colgroup>
                    <thead>
                        <tr style={{ background: '#E7E7E7' }}>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ maxWidth: '65% !important' }}>
                                <b>DATOS CLIENTE</b><br />
                                <b>Identificación:</b> {documento.TERCERO.identificacion} <br />
                                <b>Nombre:</b> {documento.TERCERO.razonSocial} <br />
                                <b>Teléfono:</b> {documento.TERCERO.telefono} <br />
                                <b>Dirección:</b> {documento.TERCERO.direccion} <br />
                            </td>
                            <td>
                                <br />
                                
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </>
    );
};

export default TerceroCarta;