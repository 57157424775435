import React from 'react';
import {enviarPeticion} from  "../../Core/ActionDatabase";
import { useState } from "react";
import { useEffect } from "react";

import {__app} from "../../utils/myApp";
import 'moment/locale/es'  // without this line it didn't work
import Moment from 'react-moment'
import { actionType } from '../../context/reducer';
import { useStateValue } from '../../context/StateProvider';


const TurnoAbiertosActual = ({estadoTurno}) => {


    const [ {user}  , dispatch ] = useStateValue();

    
    const [listaTurnos , SetlistaTurnos ] = useState();  

    const getTurnosOpen =  async () => {

        const resquestHttp = await enviarPeticion("turnos/listar_turnos_abiertos" , user.ESTACION.id );

        if(resquestHttp.respuesta) {

            SetlistaTurnos(resquestHttp.data.turnos);
        }

    }

    const EntrarAlTurno = async (turno_id) => {

        const resquestHttp = await enviarPeticion("turnos/extraer_turno_by_id" , turno_id );
        console.log(resquestHttp);
        
        if(resquestHttp.data.infoTurno.id > 0){

            let copi = user;
            copi = { ...user , 'TURNO' : resquestHttp.data.infoTurno }
            localStorage.setItem('user', JSON.stringify(copi));            
            dispatch ({
                type : actionType.SET_USER,
                user: copi,
            })  
            estadoTurno("open")
        }else{
            alert("error al entrar al turno.")
            getTurnosOpen();
        }
    
    }

    useEffect(() => {
        getTurnosOpen();
    } , [] ) 


    if( !user.ESTACION){
        return (<p>No hay punto de venta</p>)
    }
    
    return (
        <div className='mt-3'>
            <h3>Turno Abiertos Actualmente</h3>



            {  listaTurnos && listaTurnos.map( (data,index) => 

                <div key={index} className='card p-3' >
                    <h1>Turno #{data.id}</h1>

                    <div >
                        <span className='fs-4' style={{display:'block'}}> 
                           {/* ⏱<Moment fromNow interval={3000}>{__app.formatoFechaGTM5({ $fecha : data.inicio + " "})}</Moment>   */}                    
                        </span>
                    </div>

            



                    <span>  Usuario : {data.nombre_user_open}</span>

                    <span style={{display:'block' , marginTop:'10px'}}>
                              Punto de Venta :  {data.nombre_corto}                              
                    </span>

                    <span style={{display:'block' , marginTop:'10px'}}>
                              Caja :  {data.caja_nombre}                           
                    </span>

                    
                    <span style={{display:'block' , marginTop:'10px'}}>
                        Estado  <span className='badge bg-success'>Abierto</span>                          
                    </span>

                    <button onClick={() => EntrarAlTurno(data.id)} className='mt-3 btn btn-principal' >Entrar al Turno! </button>
                </div>
            )}





        </div>
    );
};

export default TurnoAbiertosActual;