import { NotificationManager } from "react-notifications";
import { enviarPeticion } from "../../../Core/ActionDatabase";




/** Esta funcion intenta extraer los clientes  desde indexDB, en caso tal no existe los trae directamente de la base de datos*/
export const extraerUsuarios = async () => {
   
    let httpresponse =   await enviarPeticion( "users/listaUsuarios" );
    if(httpresponse == -1){
        NotificationManager.error('Error'  , 'Error de internet');
        return -1;
    }else{
        return httpresponse.data
    }
    

}
