import React from 'react';
import {useNavigate} from "react-router-dom"
import {denominaciones} from './Controlador';
import { useState } from 'react';
import { __app } from '../../utils/myApp';
import { getLocalstore } from '../../Core/fetchLocalStoreData';
import {enviarPeticion} from "../../Core/ActionDatabase";
import {filtrarUserSendServer} from "../../Core/controlador";
import { useEffect } from 'react';
import { NotificationManager} from 'react-notifications';

import { actionType } from '../../context/reducer';
import { useStateValue } from '../../context/StateProvider';


const  TurnoCerrar = ({titulo}) =>  {

    const navigate = useNavigate();
    
    const [ {user , localconfig }  , dispatch ] = useStateValue();

    useEffect(() => {
        let user = getLocalstore("user");
        if (!user){   
            navigate('/error404');
        }
    }, [ ] )

    const [listaDenominaciones , setlistaDenominaciones ]  = useState( denominaciones())

    const [Total, setTotal] = useState(0);

    const [Base , setBase] = useState(0);

    const [total_seleccion , settotal_seleccion] = useState(0);

    const [load, setLoad] = useState(0);

    
    const [paso , setPaso]  = useState(1);
    const updateDemo = (e , index ) => {
        e.preventDefault();

        
        let copi = [   ...listaDenominaciones  ];

        copi[ index ].cantidad = Number(e.target.value);
        
        copi[ index ].valor_total = copi[ index ].cantidad * copi[ index ].valor;
        

        setlistaDenominaciones( copi );


        let total = copi.reduce(function(sum, current) {
            if (current.sumar == 1 ){
                return sum + current.valor_total;
            }
            return sum;
        }, 0); // 43 + 32 + 21 = 96


        setTotal(total);

        resumarAyudaBase();

    }

    const sumarBase = (event ,index) => {
     
        let copi = [   ...listaDenominaciones  ];
        copi[ index ].ayudaBase = event.target.checked;
        setlistaDenominaciones( copi );

        resumarAyudaBase();
    }

    const resumarAyudaBase = () => {
        let copi = [   ...listaDenominaciones  ];
        let total = copi.reduce(function(sum, current) {
            if (current.ayudaBase){
                return sum + current.valor_total;
            }
            return sum;
        }, 0); // 43 + 32 + 21 = 96

        settotal_seleccion(total);
    }

    const stylos = {

        textAlign: 'end',
        fontSize: '40px',
        color: 'red',
        marginLeft: '10px',
        paddingRight : "8px"        
    }


    const finalizarTurno = async () => {
        setLoad(1);
        let userFiltrer = filtrarUserSendServer(getLocalstore("user"));
        
        let total_reportado = Total - Base;
        
        const requestResponse  =  await enviarPeticion( "turnos/finalizar_turno/" , total_reportado  , {body : userFiltrer} ) 

        if ( requestResponse ){
            setLoad(0);
            console.log(requestResponse);
            if (requestResponse.status){

                if (requestResponse.data.respuesta_turno == 1 ){

                    cerrarTurnoLocal(requestResponse.data);

                }else if( requestResponse.data.respuesta_turno == 0){
                    
                    cerrarTurnoLocal(requestResponse.data);
                }

            }else{
                NotificationManager.error('No hay conexión con el servidor.  ', 'Deconectado' ); 
            }
        }

    } 

    const cerrarTurnoLocal = (respuesta) => {

        const { TURNO , ...Newuser } = user;

        let fiNewuser = {...Newuser , FINALIZAR_TURNO : respuesta }
        
        dispatch ({
            type : actionType.SET_USER,
            user: fiNewuser,
        })   

        
        localStorage.setItem('user', JSON.stringify(fiNewuser)); 
    }
    

    return (
        <div>

           {titulo && <h1>Cerrar Turno</h1> }

            <p>Cuenta cada denominación de Billete y escribe la cantidad. </p>


           {user.TURNO && 

           <>
           
           { paso == 1 && <div className='col bg-white '>



            <table className='table '>
                <thead>
                    <tr>
                        <th>Denominacion</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                    </tr>
                    </thead>

                    <tbody>
                    {listaDenominaciones.map( (demo,index) => 
                        
                        <tr key={index}>
                         
                            {
                                (demo.sumar == 1)  && 
                            
                                    
                                        <>
                                        <td>
                                            
                                                {/*<input  type='checkbox'  value={demo.ayudaBase} onChange={ (e) => sumarBase( e , index )  } />*/}

                                            {demo.nombre}
                                           
                                        </td>
                                        <td><input style={{maxWidth:80}} className='form-control' type="number" onChange={ (e) =>  updateDemo(e , index ) } /> </td>
                                        <td style={{textAlign: 'end'}}>{ __app.formato_decimal( {"total": demo.valor_total} ) }</td>
                                    </>
                                    

                            }
                         </tr>  

                    )}
                    <tr >
                        <th>Total en Caja</th>
                        <th></th>
                        <th style={{textAlign: 'end'}}> $ {  __app.formato_decimal({"total":Total}) }</th>
                    </tr>

                    <tr className='class="d-flex"'>
                        <th className='text-danger ' > <br/>BASE </th>
                        <td style={stylos}><span>-</span></td>
                        <th colSpan={1} className='d-flex justify-content-end'>  <p className=''> <br/>  <input style={{maxWidth:120, textAlign:'end'}} className=' form-control form-control-base ' type="number"  value={Base}  onChange={ (e) => setBase(e.target.value)} /></p>    </th>
                    </tr>
                    <tr>
                    <th>Total a  Reportar Turno</th>
                        <th></th>
                        <th style={{textAlign: 'end'}}> $ {  __app.formato_decimal({"total":Total - Base }) }</th>
                    </tr>

                    </tbody>
                    
                </table>
                <div className='d-flex justify-content-end'>
                    <button  className="btn btn-principal btn-lg" onClick={ () => setPaso(2) }> Siguiente Paso </button>        
                </div>
                           
                {/** <p>Seleccion Actual : {  __app.formato_decimal({"total":total_seleccion }) } </p>*/}
           </div> }

           {
            paso == 2 &&
            
                <div className='col-6 bg-white p-5'>
                
                <h3>Vamos a Entregar</h3>

                <h1 className='text-success'>$ {  __app.formato_decimal({"total":Total - Base }) } </h1>

                <p>¿Estas Seguro?</p>
                <button  className="btn btn-light btn-lg" onClick={ () => setPaso(1) }> No </button>       

                <button  className="btn btn-principal btn-lg" onClick={ () => setPaso(3) }> Si</button>       
                
                </div>


           }

           {
            paso == 3
            
            
            && <div className='col-6 bg-white p-5'>
                <h5 className='alert alert-warning'>Una vez Finalices el turno no podras modificar ni vender.</h5>
                 <button  className="btn btn-light btn-lg" onClick={ () => setPaso(2) } disabled={load} > Atras </button>   


                 <button onClick={ finalizarTurno } className="btn btn-principal" type="button" disabled={load} >

                    {load 
                    
                        && <>
                            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                            <span role="status"> Cargango ...</span>
                        </>

                        || 
                        <>
                            <span role="status"> Finalizar mi Turno</span> 
                        </>
                    }
                    
                </button>    

                
            </div>
           }
           </> ||

                <p>No hay turno para Cerrar.</p>
           }  
        </div>
    );
}

export default TurnoCerrar;