import React,{useState} from 'react';
import LoadView from './LoadView';
import Add from './Add';


const ViewProductos = ({item}) => {

    const [ menu , setMenu ] = useState(1);

    return (
        <div>
           
            { menu == 1 && <>
                <button onClick={ () => setMenu(2) } className='mb-5 btn btn-principal'> Editar </button>
            </>}

            { menu == 1 && <LoadView  item={item} /> }

            { menu == 2 && <Add editar={1} item={item} /> }

        </div>
    );
};

export default ViewProductos;