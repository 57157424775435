import React from 'react';

const ListaVariantes = ({variantes , eliminarVariante , totalStock}) => {

    if(!variantes){
        return ( <p>sin datos</p>)
    }

    return (
        <div>
             <table className="table table-striped">
                    <thead>
                        <tr>
                           
                            <th scope="col">Talla</th>
                            <th scope="col">Color</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {variantes.map((variante, index) => (
                            <tr key={index}>
                              
                                <td>{variante.talla}</td>
                                <td>{variante.color}</td>
                                <td>{variante.cantidad}</td>
                                <td>
                                    <button className="btn btn-danger btn-sm" onClick={() => eliminarVariante(index)}>Eliminar</button>
                                </td>
                            </tr>
                        ))}

                        <tr>
                           <th scope="col"></th>
                           <th scope="col"></th>
                           <th scope="col">{totalStock}</th>
                           <th scope="col"></th>
                       </tr>
                    </tbody>
                </table>
        </div>
    );
};

export default ListaVariantes;