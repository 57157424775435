import React from 'react';
import PropTypes from 'prop-types';
import { __app } from '../../../utils/myApp';




const Items = ({ document }) => {


  const {   ITEMS } = document;   
  
  let impuestos = 0;
  let subtotal3 = 0;
  let utilidad = 0;



  ITEMS.forEach((item) => {
    impuestos += parseFloat(item.IMPUESTOS);
    subtotal3 += parseFloat(item.SUBTOTAL_3);
    utilidad += parseFloat(item.UTILIDAD);
  })




  return (
    <div className="mt-4 col-xl-12 margin-15px col-md-12 mb-3">
      <div className="card border-left-primary shadow h-100 py-2">
        <div className="card-body table-responsive">
          <h5 className="text-center" >
            Productos / Servicios
          </h5>
          <br />
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Código</th>
                <th>Referencia</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th className="text-right">Valor U.</th>
                <th className="text-right">Cantidad</th>
                <th className="text-right">Impuesto</th>
                <th className="text-right">Total</th>
                <th className="text-right">Utilidad</th>
              </tr>
            </thead>
            <tbody>
              {ITEMS.map((item, key) => (
                <tr key={key}>
                  <td>{item.id_2}</td>
                  <td>{item.referencia}</td>
                  <td>
                    {item.nombre}
                  </td>
                  <td>{item.descripcion}</td>
                  <td className="text-right">${item.PRECIO}</td>
                  <td className="text-right">${item.CANTIDAD}</td>
                  <td className="text-right">${item.IMPUESTO}</td>
                  <td className="text-right">  <td>  <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  item.SUBTOTAL_3 , color : true , icono : false , numerosPositivos : true  } )} }></span> </td></td>
                  <td className="text-right">
                   
                  </td>
                </tr>
              ))}
  

              <tr>
                <th></th>
                <th></th>
                <th> </th>
                <th> </th>
                <th className="text-right"></th>
                <th className="text-right"></th>
                <th className="text-right"><span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  impuestos , color : true , icono : false , numerosPositivos : true  } )} }></span> </th>
                <th className="text-right">  <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  subtotal3 , color : true , icono : false , numerosPositivos : true  } )} }></span>  </th>
                <td className="text-right">  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};



export default Items;