import React from 'react';

const Welcome = () => {
    return (
        <div className="">

            <div className="mb-9">
               
               
               
                Cabezare



                navegador 

                <div id="products" >
                    <div className="mb-4">
                        <div className="row g-3">
                            
                            
                            buscador
                            
                            filtros 

                            opciones


                            table

                        </div>
                    </div>
                </div>
            </div>
            {/* Add the rest of the content here */}
        </div>
    );
}


export default Welcome;