import React from 'react';
import { Link } from 'react-router-dom';

function Error404(props) {


    let style = {
        marginTop : '15%',
        display: 'table',
        width: '100%',
        height: '100vh',
        textAlign: 'center'
        
    }
    return (
        <div style={style}>
            <span style={{fontSize : '5rem'}}>!Oops!  404 </span>
            <br/>
            Pagína no disponible.
            <span>Click <Link to={"/login"} >  Aquí </Link> para volver a la pagina de Inicio. </span>
        </div>
    );
}

export default Error404;