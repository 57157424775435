import React, { useState } from 'react';
import '../../estilos/modal-p7.css'
const Modalp7 = ({show  , Fcerrar , children }) => {
   
    let  x = show === 1 ? 'modalmask modalmask_target' : 'modalmask';
   
    const  [ modalClass , setmodalClass ] = useState(x);

    const cerrarModal = () => {
        setmodalClass('modalmask')
        if(Fcerrar){
            Fcerrar();
        }
    }

    return (
        <div>
            {modalClass}
            <div  className={modalClass}>
                <div className="modalbox movedown">
                    <button  onClick={cerrarModal} title="Close" className="close">X</button>
                    
                    {children}
                </div>
            </div>

        </div>



    );
};

export default Modalp7;