import React, { useEffect, useState } from 'react';

const BotonCollapsed = () => {

    const [ flechaDireccion , SetflechaDireccion] = useState(false);

    useEffect(() => {
            
        // Función para obtener un elemento del almacenamiento local
        const getItemFromStore = (key, defaultValue) => {
            const storedValue = localStorage.getItem(key);
            return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
        };

        // Función para establecer un elemento en el almacenamiento local
        const setItemToStore = (key, value) => {
            localStorage.setItem(key, JSON.stringify(value));
        };

        const resize = fn => window.addEventListener('resize', fn);


        // Define los selectores utilizados para identificar elementos en el DOM
        const Selector = {
            HTML: 'html',
            BODY: 'body',
            NAVBAR_VERTICAL: '.navbar-vertical',
            NAVBAR_VERTICAL_TOGGLE: '.navbar-vertical-toggle',
            NAVBAR_VERTICAL_COLLAPSE: '.navbar-vertical .navbar-collapse',
            ACTIVE_NAV_LINK: '.navbar-vertical .nav-link.active'
        };

        // Define los eventos utilizados en el script
        const Events = {
            CLICK: 'click',
            MOUSE_OVER: 'mouseover',
            MOUSE_LEAVE: 'mouseleave',
            NAVBAR_VERTICAL_TOGGLE: 'navbar.vertical.toggle'
        };

        // Define las clases de CSS utilizadas en el script
        const ClassNames = {
            NAVBAR_VERTICAL_COLLAPSED: 'navbar-vertical-collapsed'
        };

        // Selecciona los elementos del DOM utilizando los selectores definidos
        const navbarVerticalToggle = document.querySelector(Selector.NAVBAR_VERTICAL_TOGGLE);
        const navbarVerticalCollapse = document.querySelector(Selector.NAVBAR_VERTICAL_COLLAPSE);
        const activeNavLinkItem = document.querySelector(Selector.ACTIVE_NAV_LINK);

        // Agrega un event listener al botón de alternar la barra de navegación vertical
        if (navbarVerticalToggle) {
            navbarVerticalToggle.addEventListener(Events.CLICK, e => {
            
                // Obtiene el estado de colapso actual desde el almacenamiento local
                const isNavbarVerticalCollapsed = getItemFromStore('phoenixIsNavbarVerticalCollapsed', false);
                navbarVerticalToggle.blur(); // Quita el foco del botón

                // Alterna la clase de colapso en la etiqueta HTML
                document.documentElement.classList.toggle(ClassNames.NAVBAR_VERTICAL_COLLAPSED);

                // Guarda el estado de colapso en el almacenamiento local
                setItemToStore('phoenixIsNavbarVerticalCollapsed', !isNavbarVerticalCollapsed);

                // Despacha un evento personalizado
                const event = new CustomEvent(Events.NAVBAR_VERTICAL_TOGGLE);
                e.currentTarget?.dispatchEvent(event);
            });
        }

        // Si existe un enlace de navegación activo y la barra de navegación vertical no está colapsada, desplaza el enlace a la vista
        if (navbarVerticalCollapse) {
            const isNavbarVerticalCollapsed = getItemFromStore('phoenixIsNavbarVerticalCollapsed', false);
            if (activeNavLinkItem && !isNavbarVerticalCollapsed) {
                activeNavLinkItem.scrollIntoView({ behavior: 'smooth' });
            }
        }

        // Define una función para establecer la altura mínima del documento
        const setDocumentMinHeight = () => {
            const bodyHeight = document.querySelector(Selector.BODY).offsetHeight;
            const navbarVerticalHeight = document.querySelector(Selector.NAVBAR_VERTICAL)?.offsetHeight;

            if (
                document.documentElement.classList.contains(ClassNames.NAVBAR_VERTICAL_COLLAPSED) &&
                bodyHeight < navbarVerticalHeight
            ) {
     
                document.documentElement.style.minHeight = `${navbarVerticalHeight}px`;
            } else {
            
                document.documentElement.removeAttribute('style');
            }
        };

        // Establece la altura mínima del documento
        setDocumentMinHeight();

        // Agrega un listener de resize para ajustar la altura mínima del documento
        resize(() => {
            setDocumentMinHeight();
        });

        // Agrega un listener para el evento de alternar la barra de navegación vertical
        if (navbarVerticalToggle) {
            navbarVerticalToggle.addEventListener('navbar.vertical.toggle', () => {
                setDocumentMinHeight();
            });
        }

    },[])


    return (
        <button  onClick={() =>  SetflechaDireccion(!flechaDireccion)} className="btn navbar-vertical-toggle border-0 fw-semibold w-100 white-space-nowrap d-flex align-items-center">
            {!flechaDireccion && <span> <i className="fas fa-arrow-left"></i></span>}
        
            { flechaDireccion &&  <span> <i className="fa fa-arrow-right"> </i></span> }
         
            <span className="navbar-vertical-footer-text ms-2">Contraer Menu</span>
        </button>
    );
};

export default BotonCollapsed;