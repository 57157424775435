import React from 'react';
import { useState } from 'react';
import { NotificationManager} from 'react-notifications';
import {enviarPeticion} from '../../../../Core/ActionDatabase';
import { reloadDatabases, updateDixieOne } from '../../../../Core/Dixie';
const  ActualizarPrecioDeVenta = ({item ,user , ct_master_id , fontSize }) => {


    const [ statusRequestHttp , SetstatusRequestHttp ] = useState(null); 
    const [ statusResponse , SetstatusResponse ] = useState(null);  



    const ActualizarPreciobasedeDatos = async () =>{

        SetstatusRequestHttp(1);

        let Httpresponse =  await enviarPeticion( "productos/actualizar_producto_fast", "" , {
            body :{
            id : item.id,
            PRECIO_PRINCIPAL : item.MATEMATICAS.PRECIO,
        }});

        console.log(Httpresponse)
        if( Httpresponse.respuesta == 1 ){
            SetstatusRequestHttp(-1);
            SetstatusResponse( Httpresponse.respuesta)
            NotificationManager.success('Actualizado','El Precio está actualizo correctamente en la base de datos.' );

            let copy = {...item ,PRECIO_PRINCIPAL :  item.MATEMATICAS.PRECIO };
            updateDixieOne( item.id , copy, 'productos');
            
        }else{
            SetstatusRequestHttp(-1);
            SetstatusResponse( -1)
            NotificationManager.warning('Sin red'   , 'no hay Red. El Precio no se Cambio.' );
        }
        
       

    }
    if(  parseInt(item.PRECIO_PRINCIPAL) === parseInt(item.MATEMATICAS.PRECIO) )  return false;
    

    if (user.USER.type_user_id != 1 ) return false;
    
    if (ct_master_id != 1 ) return false;
    
    return (

        <span>

            {!statusResponse &&
               
               <button disabled={statusRequestHttp} onClick={ActualizarPreciobasedeDatos} title='El Precio Cambio. Presiona este botón si quieres Actualizarlo también en tu base de Datos. ' className="btn btn-warning btn-xxsss">         
                   
                    { statusRequestHttp &&  <i className="fa-solid fa-arrows-spin fa-spin fa-spin-reverse"></i> || <> ¿Actualizar?</>}
                    
                </button>
            }

            { (statusResponse  == 1)  && <span title='Este Precio fue cambiado en la Base de datos.'> ✔️</span> }
        
            { (statusResponse == -1 )  && <span title='Este Precio fue cambiado en la Base de datos.'> ❌ </span> }
                    
        </span>
    )















}

export default ActualizarPrecioDeVenta;