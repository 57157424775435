import React from 'react';
import { Link } from 'react-router-dom';
import { ListaPrecios,TipoEmpleado } from '../../../../Elementos/Etiquetas';
import { __fecha } from '../../../../../utils/myApp';

const Tbody = ({dataList , DATABASE}) => {

    console.log(DATABASE)
    return (
        <tbody class="list" id="leal-tables-body ">
                                        
            {dataList.slice(0, 50).map((item, index) =>   <tr class="hover-actions-trigger btn-reveal-trigger position-static">
      


                <td class="name align-middle  ps-0 p-3" style={{ maxWidth: "300px" , width: "40%" }}>
                    <div class="d-flex align-items-center">
                       {/*
                        <a href="#!">
                            <div class="avatar avatar-xl me-3">
                                {false && <img class="rounded-circle" src="../../assets/img/team/73.webp" alt="" />}
                            </div>
                        </a>
                       */}
                        <div>
                        <Link class="fs-9 fw-bold" to={"/TerceroPerfil/" + item.id }>{item.razonSocial} <span className='fs-9 text-dark' > <i class="fa-solid fa-eye"></i> </span></Link>
                            <div class="">
                                
                                
                    
                               <div className='d-flex align-items-center'>
                               <p class="mb-0 text-body-highlight fw-semibold fs-9 me-2">{item.identificacion}</p>
                                    <TipoEmpleado id={item.fe_tipopersona_id} valores={DATABASE.ct_lista_precios} />
        
                                    <ListaPrecios id={item.ct_lista_precio_id} valores={DATABASE.ct_lista_precios} />
                               </div>
                            </div>        
                        
                        </div>
                    </div>
                </td>
                
                <td class="email align-middle  fw-semibold ps-4 border-end border-translucent">
                    {item.correoelectronico}
                </td>
                
                <td class="phone align-middle  fw-semibold ps-4 border-end border-translucent">
                    <a class="text-body-highlight" href="tel:+611900654321">
                    {item.telefono}
                    </a>
                </td>
                
                <td class="contact align-middle  ps-4 border-end border-translucent fw-semibold text-body-highlight">
                {item.direccion}
                </td>

                
                <td class="date align-middle  text-body-tertiary text-opacity-85 ps-4 text-body-tertiary">
                    { __fecha.formatoFecha({$fecha : item.creado , $icon : false }) }
                </td>
                
            </tr>  )}
        </tbody>
    );
};

export default Tbody;