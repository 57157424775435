import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmarCambioPrecio = ({ show, respuesta }) => {
  return (
    <Modal show={show} >
     
      <Modal.Body>
        <h3>Hola Administrador</h3>
        Hemos detectado un cambio de cliente. con una lista de precio <br /> ¿Quieres mantener los precios?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => respuesta(false)}>
            Mantener Precios actuales
        </Button>
        <Button variant="success"   onClick={() => respuesta(true)}>
             
            Activar Lista de  Precios
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmarCambioPrecio;