import React from 'react';
import { __app } from '../../../utils/myApp';

const RecuperacionCartera = ({resumen_comprobantes}) => {

    const filtro = resumen_comprobantes.recuperacion_credito;



    return (
        <>
        
        <tr>
            <th colSpan="2" className="text-center">
            Recuperación de Creditos.
            </th>
        </tr>

        { (filtro.length > 0 ) && 
                <>
                    {filtro.map((value, key) => 
                    
                        <tr key={key}>
                            <th>{value['nombre_credito']}</th>
                            <th>
                                <span dangerouslySetInnerHTML={{ __html :  __app.formato_decimal( { total : value['total'] , color : true } )   }} /> 
                            </th>
                        </tr>
                    )}
                </> || 

                <tr >
                    <td  style={{textAlign:'center'}} colSpan={2}> No encontramos pagos   </td>                  
                </tr>

            }

        </>
    );
};

export default RecuperacionCartera;