import React, { useState, useEffect } from 'react';

const FiltroTipoDocumentos = ({ tipoDocumento , handleChangeFiltro }) => {
  // Inicializa filtroDocumento con todos los elementos de tipoDocumento
  const [filtroDocumento, setFiltroDocumento] = useState([]);

  // Función para verificar si un documento está marcado
  const comprobarCheck = (CtTipoComprobanteFiscal_id) => {
    console.log(CtTipoComprobanteFiscal_id)
    console.log(filtroDocumento)
    return  filtroDocumento.some((data) => data.CtTipoComprobanteFiscal_id == CtTipoComprobanteFiscal_id );

  };

  // Función para manejar cambios de checkbox
  const handleCheckboxChange = (CtTipoComprobanteFiscal_id) => {
   
    
    if(CtTipoComprobanteFiscal_id === 0){
   
        setFiltroDocumento(tipoDocumento)
        handleChangeFiltro(tipoDocumento);
        return false;
    }else{
        return handleCheckboxChange_unico(CtTipoComprobanteFiscal_id);
    }
    
    



    //Aqui para marcar varios

    setFiltroDocumento((prev) => {

      let filtro = null;
        // Si está en el filtro, se elimina (desmarcar), si no, se añade (marcar)
        if (comprobarCheck(CtTipoComprobanteFiscal_id)) {
            // Si ya está marcado, lo quitamos del filtro
            filtro =  prev.filter(
            (data) => data.CtTipoComprobanteFiscal_id !== CtTipoComprobanteFiscal_id
            );
        
        } else {
            // Si no está marcado, lo agregamos al filtro
            const nuevoItem = tipoDocumento.find(
            (doc) => doc.CtTipoComprobanteFiscal_id === CtTipoComprobanteFiscal_id
            );
            filtro =  [...prev, nuevoItem];
       }

      handleChangeFiltro(filtro)
      return filtro;
    });


  };


    // Función para manejar cambios de checkbox
    const handleCheckboxChange_unico = (CtTipoComprobanteFiscal_id) => {
        
        setFiltroDocumento() 
        const nuevoItem = tipoDocumento.find((doc) => doc.CtTipoComprobanteFiscal_id === CtTipoComprobanteFiscal_id  );

        console.log(nuevoItem);
        setFiltroDocumento([nuevoItem])   
        handleChangeFiltro([nuevoItem])
    
    };

  return (
    <ul class="nav nav-phoenix-pills mb-5  d-xl-flex col-md-5" id="contactListTab" data-chat-thread-tab="data-chat-thread-tab" role="tablist">


        {tipoDocumento.length > 2  &&   <li class="nav-item" role="presentation">
            <a  onClick={() => handleCheckboxChange(0)} class="active nav-link cursor-pointer" data-bs-toggle="tab" data-chat-thread-list="all" role="tab" aria-selected="false" tabindex="-1">
                Todos
            </a>
        </li> }

      {tipoDocumento.length > 1 &&  tipoDocumento.map((tipodocumento, index) => (
  

        <li class="nav-item" role="presentation">
            <a  onClick={() => handleCheckboxChange(tipodocumento.CtTipoComprobanteFiscal_id)} class="nav-link cursor-pointer" data-bs-toggle="tab" data-chat-thread-list="all" role="tab" aria-selected="false" tabindex="-1">
                {tipodocumento.nombre}
            </a>
        </li>

      ))}
   </ul>
  );
};

export default FiltroTipoDocumentos;


