import React from 'react';
import { useState ,  useRef} from 'react';

import Tercero  from "../Tercero.jsx";
import FormaPagoPaso1 from './FormaPagoPaso1.jsx';


import FormaPagoPaso3 from './FormaPagoPaso3.jsx';

import {enviarPeticion} from "../../../../Core/ActionDatabase.js";

import { useContextDocumentValue , actionTypeDocument } from "../../context/ContextDocument.js";


import { useStateValue } from '../../../../context/StateProvider.js';

import {   resetearDocumentoGlobal , eliminarCuentaAbiertaFireBase, actualizarProductos, setUltimosCambios } from "../../controlador.js";


import { NotificationManager} from 'react-notifications';

import FacturaTirilla from '../../PlantillasImpresion/FacturaTirilla.jsx';


import { __app , objetoATexto } from '../../../../utils/myApp.js';

import { reloadDatabases } from '../../../../Core/Dixie.js';

import { actionType } from '../../../../context/reducer.js';

import prindiv from 'easyprintand';

import { fetchNombreDispositivo, getLocalstore  } from "../../../../Core/fetchLocalStoreData.js";


import {guardarDocumentoLocal} from "../../controlador.js"



/***
 * 
 * ModoAbonar : Si el modo abonar es 1: 
 * El sistema no completara la venta, simplemente guardare un abono Al documento.
 * 
 * 
 */
const  FormasPago = ({tab , desactivar   , dispatchGlobal , asignarDevuelta  , ModoAbonar = 0} ) => {
    
    // Esta variable congela el reenvio de el formulario, evitamos que los usuario presionen el enter multiples veces.
    let CONGELAR_ENVIO = 0;

   
    
    const [ { user } ] = useStateValue();

    const [ {documento}  , dispatch ] = useContextDocumentValue();

    const [ pasoactual  , setPasoactual  ] = useState(1);

    const [ totalpagado , setTotalPagado ] = useState();
    

    const [respuesta , setRespuesta] = useState(null);

    const [estadocarga , setEstadocarga] =useState(null);

   
    const componentRef = useRef();   



    /*** Con esta variable el usuario podra controlar el estado de la aplicacion. 
     * 
     *  Si esta variable esta Activa.
     *  Esto sigfica que: no hay internet
     */
    let estado_server_manual  = getLocalstore("estado_server_manual");
    const [ estadoServer , SetEstadoServer] = useState(estado_server_manual);

    
    const cambiarPaso  =  (paso)  => {  
        paso = parseInt(paso);
        console.log(paso, estadopagodocumento)
        console.log(!documento.TERCERO)       
        paso = (paso === 2 &&  estadopagodocumento === 0 &&  !documento.TERCERO  ) ? 2  : 3 ;

        setPasoactual(paso) ;
        
    }

    const [facturaImprimir , SetfacturaImprimir] =useState(null);


    /*** 1 PAGADO , 0 PENDIENTE  */
    const [estadopagodocumento , setEstadopagodocumento] = useState(1);

    
    const changeEstadoPago = (estado) => setEstadopagodocumento(estado); 


    const setPagoDocumento = (forma_pagos , pagacon , propina , tipoCredito) => {
        

        let copiaDC = documento;
        copiaDC.FORMAS_DE_PAGO  = forma_pagos;
        copiaDC.ESTRAS.FORMA_PAGO_TOTAL_ACTUAL = copiaDC.MATEMATICAS.SUBTOTAL_3;
        copiaDC.ESTRAS.PAGO_CON = pagacon;
        copiaDC.MATEMATICAS.PROPINA = propina;
        copiaDC.MATEMATICAS.TIPO_CREDITO = tipoCredito; 


        dispatch({
            type : actionTypeDocument.SET_DOCUMENTO,
            documento:  copiaDC,
        })
        
    }

    const actualizartotalpagado = (total) => {
      
        setTotalPagado(total);
    }
    


    const guardarDatos  =  async () => {
        
        
        
        console.log("enviado.."  + CONGELAR_ENVIO );

        if(CONGELAR_ENVIO === 1 ){
            console.log("Calmate ventarron.")
            return false;
        }

        if(documento.PRODUCTOS.length <= 0 ){

        
            if(desactivar){
                desactivar();
            }
            return false;
        }
       
        
        CONGELAR_ENVIO = 1;


       setEstadocarga(1);
       
       let copi = setUltimosCambios(user , documento  );

       console.clear();
        console.log(copi);
        

        console.log("OKAY")

        
        /*  Si nuestra variable local se encuentra positiva*/
        /*  localStorage.getItem("statusServer") && localStorage.getItem("statusServer") == 1 */
        if(  estadoServer  ){
           
            let respuestauax =  await enviarPeticion( "adddocumentos/nuevo", "" ,  {body : copi});

            setRespuesta(respuestauax);
         
            if( respuestauax.respuesta){
                setEstadocarga(0);
                NotificationManager.success('Registro con Exito' , 'Documento #' +  respuestauax.data.id);
                finProcedimientoExitoso( respuestauax.data , copi);
                actualizarProductos(respuestauax.data);
              
            }else{
                setEstadocarga(0);

                if(respuestauax.server_error){
                    console.log(respuestauax)
                    console.log("No hay red")
                    localStorage.setItem('statusServer' , 0);
                    NotificationManager.warning('El servidor Principal no Respondio'   , 'Servidor' ); 
                    //fetchErrorSave(copi);
                }else{
                    
                    console.log("error de registro");
                    renderizarcodigoError(respuestauax.data);
                }
            }

        }else{
            setEstadocarga(0);
            NotificationManager.success('Sin red'   , 'Guardado Localmente' ); 
            fetchErrorSave(copi);
        }
        
      
       
    }


    const  validarVendedor = () => {
        if(  user.CONFIGURACIONES_TABLE.obligar_vendedor != "1") {
            return true;
        }   
        if (  documento.FORM  &&  parseInt(documento.FORM.vendedor_id) > 0 ) {
            return true;
        }
        return false;
    }




        
       


    const handlePrint = (copii = null) => {

         

        if( !user.CONFIGURACIONES.GENERALES ){
            setTimeout(() => {
         
                prindiv('EasyPrintAnd' ,  { extraCss : './tirilla.css' } )
                setTimeout( () => {
                   
                    desactivar()
                }  , 300)
                
            } , 500)

            return false;
        }
       
        /*** Imprimir desde el navegador habilitada. */
        if( user.CONFIGURACIONES.GENERALES &&  user.CONFIGURACIONES.GENERALES.habilitarImpresionNavagador &&  user.CONFIGURACIONES.GENERALES.habilitarImpresionNavagador == 1 ) {
      
            setTimeout(() => {   
                      
                prindiv('EasyPrintAnd' ,  { extraCss : './tirilla.css' } )
                setTimeout( () => {
                    desactivar()
                }  , 300)
                
            } , 500)

            return false;

        }

   
         /*** Imprimir de manera Local con el Soket */
         if( user.CONFIGURACIONES.GENERALES &&  user.CONFIGURACIONES.GENERALES.habilitarImpresionLocal &&  user.CONFIGURACIONES.GENERALES.habilitarImpresionLocal == 1 ) {
           
            let  facturaImprimir2 = copii
            let user2 = {
                ESTACION : user.ESTACION
            } ;
            console.log(facturaImprimir2);
            imprimirLocalmente( {facturaImprimir2 , user2}  );
            desactivar();
            return false;
        }


        desactivar();
       
    }
        

    const imprimirLocalmente = async (data) => {
        try {
          const response = await fetch('http://localhost:3001/imprimir', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
             
            },
            'body' :  JSON.stringify(data)
          });
      
        
          console.log(response); // Mensaje de la aplicación de escritorio

        } catch (error) {
          console.error('Error al imprimir:', error);
        }
    };



    /*** En caso de que la operacoion tenga error  */
    const fetchErrorSave = (copi) => {
        

        
        /*** Aplica  para el arqueadero  */
        let copy2 = { ...copi ,  
            estado_patio: 0, 
            patio_salida_fecha : __app.getFecha(),
        } ;

        let copii =   guardarDocumentoLocal(copy2);
        SetfacturaImprimir(copii);
        handlePrint(copii);
        reseterDocumento(copi);
    }


    /**
     * Procedimiento de registro de factura existoso
     * @respuesta = La data 
    */
    const finProcedimientoExitoso = (re , copydc) => {
        



        let copy = { ...copydc ,  
            
            EstadoServidor: 1 , 
            EstadoLocalFactura: 1 , 
            id_server : re.id , 
            NUMERACION :re.numeracion,
            estado_patio: 0, 
            patio_salida_fecha : __app.getFecha(),


        } ;

        
        let copyy = guardarDocumentoLocal(copy);



        //Estamos ancelando la impresion de factura electronica con datos locales, esperamos la respuesta del servidor
        if(copydc.TIPO_DOCUMENTO.factura_electronica_codigo ==  -1 ){
            console.log(copyy)
            handlePrint(copyy);
        
        }else{
            desactivar()
        }

       
        

        reseterDocumento(copy);
        eliminarCuentaAbiertaFireBase(copy.id , user);
        SetfacturaImprimir(  __app.clonarObjeto(copyy) )
        
 
        
        if(Number(copy.ESTRAS.PAGO_CON )  > 0 ){
            let cambio      =  Number(documento.ESTRAS.PAGO_CON ) - documento.FORMAS_DE_PAGO[0].total;
            if(cambio > 0){
               
                if(asignarDevuelta){
                    asignarDevuelta({
                        pagocon :copyy.ESTRAS.PAGO_CON ,
                        cambio :cambio 
                    })
                }
               
            }
        }

       
        
    }




    const reseterDocumento = (copy ) => {

        resetearDocumentoGlobal(copy.KEYDOCUMENT , dispatch , actionTypeDocument , copy.TIPO_DOCUMENTO )
    }


    /* analiza si el servidor envio un codigo de error para procesarlo y cambiar el comportamiento
    de la Aplicacion **/
    const renderizarcodigoError = (Resdata) => {

        let  ErrorCodigo = 0;

        try {
            if(Resdata.errorCodigo){
                ErrorCodigo  = Resdata.errorCodigo
            }
        } catch (error) {
            
        }

        console.log(ErrorCodigo);

        if(ErrorCodigo != 0){
                
            if('ERROR_TurnoCerrado' == ErrorCodigo) ERROR_TurnoCerrado()

        }else{
            console.log("no hay error");
        }
    }


    const ERROR_TurnoCerrado = () => {

        if(dispatchGlobal){
            const { TURNO , ...Newuser } = user;

     
        
            dispatchGlobal ({
                type : actionType.SET_USER,
                user: Newuser,
            })   
    
            
            localStorage.setItem('user', JSON.stringify(Newuser)); 
        }
      
    }



    
    if(!documento?.MATEMATICAS){

        return (<p>
            <button  onClick={() => console.log(documento)}>Documento log </button>
            No tenemos Matematicas </p>)
    }

    return (
        <div> 



            {/*   
             <p>
                Paso:  { pasoactual }  /  Estado : { estadopagodocumento }
            </p>
            <h1> Estado : {estadopagodocumento}</h1>
            <p>Total Pagado : {totalpagado}</p>
            <p>
                Paso:  { pasoactual }  /  Estado : { estadopagodocumento }
            </p>
            */}
      
            {/* documento.TERCERO && <span> Si ahy Tercer </span>}
            { !documento.TERCERO && <span>No hay tercero  </span>*/}
            { pasoactual === 1 &&  <FormaPagoPaso1    desactivar={desactivar}  ModoAbonar={ModoAbonar} actualizartotalpagado={actualizartotalpagado} pagoDocumento={setPagoDocumento} cambiarEstado={changeEstadoPago}   cambiarPaso={cambiarPaso}  documento={documento} tab={tab}  user={user} /> }


            { pasoactual === 2 && 
                <div> 
                    <p> <b>Alerta</b> No puedes dejar un documento pendiente sin un Tercero Asignado.  </p>
                    <Tercero  tab={tab}  />
                    <button onClick={() => setPasoactual(1) } >  Atras </button>
                    { documento.TERCERO && <button onClick={() => setPasoactual(3) }> Siguiente  </button> } 
                </div>
            }





   

            {/** (documento.FORM && documento.FORM.FechaHoraLocal)  */}
           {  facturaImprimir  &&  <FacturaTirilla  documento={facturaImprimir}  user={user}  />}

            

            {  pasoactual === 3 && 
                <span>

               
                    { validarVendedor() && 
                        <span className='badge bg-primary'>
                            {
                                (documento.FORM && documento.FORM.nombre_vendedor) && 
                                <span>
                                    Venedor: { documento.FORM.nombre_vendedor }
                                </span>
                            }
                        </span>
                        ||
                        <span>
                            <br/>Selecciona un vendedor para continuar
                        </span>
                    }



                    <FormaPagoPaso3 documento_actual={documento} totalpagado={totalpagado}  estadoServer={estadoServer} user={user}  />
                    
                    
                    {documento.TIPO_DOCUMENTO.signo_pago != 0 && 
                    
                        <>
                             { !estadocarga  && <button onClick={() => setPasoactual(1) } >  Atras </button> }
                        </>
                    }


                    { (!estadocarga && documento.FORMAS_DE_PAGO ) && <button autoFocus  onClick={ guardarDatos } className={'btn btn-' + documento.TIPO_DOCUMENTO.color_documento }> FINALIZAR  </button> }









                    { estadocarga  &&  <span  disabled={estadocarga}   className='btn btn-default'> Cargando...  </span> }


                    <div>
                        { respuesta &&  
                        
                            <div className="content" dangerouslySetInnerHTML={{__html: respuesta.mensaje}}></div> 
                        }
                    </div>
                </span>

                
            }      
        </div>
    );
}


export default FormasPago;