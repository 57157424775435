import React, { useEffect } from 'react';
import { getListaTurnobyId } from './Controlador';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { __pluma7 } from '../../Core/pluma7';
import { __app } from '../../utils/myApp';




const TurnoLista = () => {

    const [listaTurno , setListaTurno] = useState([]);

    useEffect( () => {

        consultarDatos();

    }, [])

    const consultarDatos = async () => {
        let lista = await getListaTurnobyId();
        setListaTurno(lista)
    }

    return (
        <div className=''>
            <h2 class="mb-0">Listado de turnos</h2>

            <div className='mx-n4  mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-5 position-relative top-1'>
          

            <table className='table table-bordered table fs-9 mb-0 border-top border-translucent'>
                <tr>
                    <th>Codigo</th>
                    <th>Responsable</th>
                    <th>Punto de venta</th>
                    <th>Fecha</th>
                    <th>Efectivo Turno</th>
                    <th>Reportado</th>
                    <th>Descuadre</th> 
       

                </tr>

                { listaTurno.map( (turno, index) => 
                    <tr>
                        <td  className='product align-middle p-4'>
                        # {turno.id} 
                        <Link to={"/TurnosVer/" + turno.id } title='ver información del turno' className="  badge p-1 text-bg-primary   rounded">Ver Turno </Link>
                            

                        { turno.estado == 1 && <> <br/> <span className='mt-1 text-center bg-success-subtle badge p-2 text-success-emphasis'>Abierto</span></> }
                        </td>
                        <td className='product align-middle ps-4'>{turno.nombre_user_open}</td>
                        <td  className='product align-middle ps-4' >{turno.Estacione_nombre}</td>
                        <td> {__pluma7.turnoDesdeHasta(turno.inicio , turno.fin )}</td>
                        <td>
                            <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  turno.efectivo_turno , color : false , icono : false , numerosPositivos : true  } )} }></span>
                        </td>
                        <td>  <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  turno.entrega , color : false , icono : false , numerosPositivos : true  } )} }></span> </td>
                       
                        <td> <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  turno.entrega -turno.efectivo_turno , color : true , icono : true , numerosPositivos : true  } )} }></span>   </td>
                
                    </tr>
                ) }
            </table>
        </div>

        </div>
      
    );
};

export default TurnoLista;