

import React , {useEffect , useState } from 'react';
import { fetchEmpresa, fetchUSer, getLocalstore , setLocalStore } from '../../../Core/fetchLocalStoreData';
import {enviarPeticion} from '../../../Core/ActionDatabase'
import OptionSelect  from '../../../Core/Html/OptionSelect';
import InputValidation from './InputValidation';


const Proporciones = ({formData, btnbmitLoad  , formDataValidations }) => {

    const empresa = fetchEmpresa();

    console.log(empresa)

    const initialstate =  (empresa.datos_precarga.proporciones) ? empresa.datos_precarga.proporciones : [];
    
    const filtro = initialstate.map((data) => data.nombre);


    console.log(filtro);

    const [datos, SetDatos] = useState(filtro);


    
    useEffect( () => {
        let data = getLocalstore("Listcategorias") 
    } , [] )




    return (
        <div className="form-group mb-3">
        
            <InputValidation formData={formData}  options={datos} TipoElementoForm={'select'} label={"Unidad de medida"}  formDataValidations={formDataValidations}   defaultValue={formData.proporcion}  btnbmitLoad={btnbmitLoad} id="proporcion" className="form-control" nameForm="proporcion"   />   
        </div>
    );
};

export default Proporciones;