import React from 'react';
import StandarModal from '../../Document/PartesDocument/StandarModal';
import Header from './subcomponents/Header';
import Tercero from './subcomponents/Tercero';
import Empresa from './subcomponents/Empresa';
import Pagos from './subcomponents/Pagos';
import Boton from './Imprimir/Boton';

const ImprimirPagos = ({infoPago , desactivarModal}) => {
    return (
        <div>
            
            <button onClick={desactivarModal} type="button" className="float-end btn-close " style={{marginTop: -30}}  data-bs-dismiss="alert" aria-label="Close"></button>
              
                <div>
                <div class="container mt-5">
                    <div class="alert alert-success d-flex align-items-center" role="alert">
                    <i class="fas fa-check-circle fa-2x me-2"></i>
                    <div>
                        <h4 class="alert-heading">¡Pago Exitoso!</h4>
                        <p>Tu pago ha sido procesado correctamente. </p>
                    </div>
                    </div>
                </div>

               

                <div class="container mt-5">

                
                    <div class="dropdown d-inline-block">
                    <button class="btn btn-subtle-primary me-1 mb-1 rounded-pill dropdown-toggle" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="fa-solid fa-print"></i> Imprimir</button><span class="caret"> </span>
                    <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="#">Tamaño Carta</a>
                        <a class="dropdown-item" href="#">Tamaño Tikect</a>
                        <Boton />

                    </div>
                    </div>


                    <button onClick={() => console.log(infoPago)} class="btn btn-subtle-primary me-1 mb-1 rounded-pill" type="button"> <i class="fa-solid fa-envelope"></i> Enviar por Correo</button>

                    <button class="btn btn-subtle-primary me-1 mb-1 rounded-pill" type="button"> <i  class="fa-brands fa-whatsapp"></i> Enviar por WhatsApp</button>

                    <div className='mt-5 p-4 card bg-body'>
                        
                        { false &&  <Empresa /> }
                        <Header infoPago={infoPago.serverInfoPago} />
                        <Tercero  infoPago={infoPago.serverInfoPago} />
                        <Pagos  infoPago={infoPago.serverInfoPago} />
                    </div>
                </div>
                      
                </div>

        </div>
    );
};

export default ImprimirPagos;