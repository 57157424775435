import React from 'react';
import { __app } from '../../../utils/myApp';



const ConsolidadoCaja = ({consolidado}) => {


    const totalConsultaConsolidado = consolidado.reduce((total, value) => {
        // Parsea el valor a número flotante solo si es una cadena que representa un número válido
        const valorNumerico = parseFloat(value['TOTAL']);
        if (!isNaN(valorNumerico)) {
            // Suma el valor numérico al total solo si es un número válido
            return total + valorNumerico;
        } else {
            // Si el valor no es un número válido, simplemente devuelve el total sin sumar nada
            return total;
        }
    }, 0);


    return (
        <>
             <tr>
                <th colSpan="2" className="text-center">
                    Consolidado de Caja.
                </th>
            </tr>

            {consolidado.map((value, key) => {
              
                return (
                <tr key={key}>
                    <td>{value['nombre']}</td>
                    <td>
                        <span dangerouslySetInnerHTML={{ __html :  __app.formato_decimal( { total : value['TOTAL'] , color : true } )   }} />
                    </td>
                </tr>
                );
            })}


            <tr>
                <th style={{ fontSize: "20px" }}>TOTAL NETO</th>
                <th style={{ fontSize: "20px !important" }}>
                    <span dangerouslySetInnerHTML={{ __html :  __app.formato_decimal( { total : totalConsultaConsolidado, color : true } )   }} />
                </th>
            </tr>
        </>
    );
};

export default ConsolidadoCaja;