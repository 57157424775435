import React, { useEffect, useState } from 'react';
import { cargarInventario } from './Controlador';
import { fetchUSer } from '../../Core/fetchLocalStoreData';
import { NotificationManager } from 'react-notifications';


const Inventario = () => {
    const [inventario, setInventario] = useState([]);
    const user = fetchUSer();

    const cargarInventarioo = async () => {
        try {
            const iinventario = await cargarInventario(user.ESTACION.id);
            console.log(iinventario);
            setInventario(iinventario);
        } catch (error) {
            NotificationManager.error("Error al cargar el inventario");
        }
    };

    useEffect(() => {

        cargarInventarioo();

    }, []);

    return (
        <div>
            
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                <h2>Alerta!</h2>
                Solo se mostrarán los productos que tengan <b>stock</b>. <br></br> Los que contengan <b>0</b> o menos no se mostrarán.<br></br>
                Solo se mostrará un máximo de <b>2.000</b> productos.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>

            {inventario.length > 0 ? (
                
                
                <div>

                    <button>Imprimir  </button>

                    <table className='table table-xs'>
                        <thead>
                            <tr>
                                <th>Codigo</th>
                                <th>Nombre</th>
                                <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                        {inventario.map((data, index) => 
                            <tr>
                                <td>{data.id_2}</td>
                                <td>{data.nombre}</td>
                                <td>{data.cantidad}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>    
                </div>
            ) : (
                <p>Cargando..</p>
            )}
        </div>
    );
};

export default Inventario;