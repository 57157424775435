import React, { useEffect, useState } from 'react';
import '../../../estilos/form-validacion.css'
import OptionSelect from '../../../Core/Html/OptionSelect';

const InputValidation = ({ listOptions , defaultChecked , options=[] , TipoElementoForm='input' , label , formData , btnbmitLoad , placeholder, type , id ,   className , nameForm , formDataValidations, defaultValue}) => {


  
    const dangerInput = { 
        'border' : 'solid #dc3545',
    }
    const textDanger = { 
        'color' : '#dc3545',
    }


    const successInput = { 
        'border' : 'solid #00d336',
    }
    const textSuccess = { 
        'color' : '#00d336',
    }


   



    const [ estilosInput , setEstilosInput ] = useState();
    const [ estilosText , setEstilosText ] = useState();

    const [ mensaje , setMensaje ] = useState();

    const [ estadoError , SetestadoError ] = useState(null);

    useEffect( () => {

       

        try {
            if(formDataValidations ){

                if( formDataValidations[nameForm] &&  formDataValidations[nameForm] && formDataValidations[nameForm].res === true  ){
    
                    setEstilosInput(successInput);
                    setEstilosText(textSuccess);
                }else{
      
                    if( formDataValidations[nameForm] ){
                
                        setEstilosInput(dangerInput);
                        setEstilosText(textDanger);
                        SetestadoError(true)
                    }
                    
                }
            }
            if ( formDataValidations[nameForm].mensaje   ){
                setMensaje( formDataValidations[nameForm].mensaje );
            }
        } catch (error) {console.log("No podemos validar")};





    } , [formDataValidations] )




    const quitarError = () => {
        setEstilosInput(null);
        setEstilosText(null);
        setMensaje(null);
        SetestadoError(null)
    }

   
    try {
         
        if( formData[nameForm] === undefined && formData[nameForm] ===  null  ){
            console.log( formData[nameForm]);
            return (<>"No exite"</>)
        }else{
            
        
        }

    } catch (error) {
        console.log( formData);
        console.log( nameForm );
    }

    
    
    if( TipoElementoForm == 'input'){
        return (
            <div className="form-group ">
                <label style={estilosText}   htmlFor=""><b>{label}</b></label>
                <input style={estilosInput} value={formData[nameForm]} onChange={ () => { quitarError() } } autoComplete='off' disabled={btnbmitLoad} type={type} id={id} className={ estadoError ? 'input-error ' + className  : className   } name={nameForm}  placeholder={placeholder}  />
               
                { mensaje   &&  <span style={estilosText} >  {mensaje}  </span>}
            </div>
        );
    }




    if( TipoElementoForm == 'select'){
        return (
            <div className="form-group ">
                
                <label style={estilosText}   htmlFor=""> <b>{label}</b></label>
                <select  style={estilosInput}  onChange={ () => quitarError() } value={defaultValue}   disabled={btnbmitLoad} id={id}  name={nameForm}  className={ estadoError ? 'input-error ' + className  : className   } >
                    <OptionSelect datos={options} />
                </select> 
                { mensaje   &&  <span style={estilosText} >  {mensaje}  </span>}
            </div>
        );
    }





    if( TipoElementoForm == 'radiobutton'){


        if ( listOptions && listOptions.length > 0  ){

            return (
                
                <div>
                    
                    { listOptions.map( (data , index)  =>   
                    
                       
                        <div className="form-check">    
                             {console.log(data)}  
                            <input  
                            onChange={ () => { quitarError() } }  
                            disabled={btnbmitLoad} 
                            type={"radio"} 
                            id={data.id} 
                            name={nameForm}  
                            checked={ data.defaultChecked }
                            className="form-check-input" 
                            value={data.value }
                        
                            />
            
                            <label   style={estilosText} className='form-check-label'      htmlFor={data.id}>
                               
                                {data.label} 
                            </label>
                            
            
                        </div>
                        
                    )}
                     { mensaje   &&  <span style={{...estilosText , marginTop : '-2px' }} >  {mensaje}  </span>}
                </div>
            );    
        }
   
        
    }

};

export default InputValidation;