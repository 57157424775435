import React, {useState} from 'react';


import Dropdown from 'react-bootstrap/Dropdown';

import { useStateValue } from '../../../context/StateProvider';
import { actionType } from "../../../context/reducer";
import { useEffect } from 'react';

import { useContextDocumentValue , actionTypeDocument } from "../context/ContextDocument.js";
import { resetearDocumentoGlobal } from "../../Document/controlador";

import { DocumentL  } from "../DocumentCore";

const  TipoDocumento = ({usuario , LogicaDocumento  , actualizarTab, TabIndexActual = null }) =>  {


    const [ {user}  ] = useStateValue();


    const [ tipodocumentos , setTipodocumentos  ] = useState(null);

   

    const [ {documento}  , dispatch ] = useContextDocumentValue();


    const [ default_value , setDefault_value ] = useState(1);





    useEffect( () => {

        setTipodocumentos(user.DATABASE.tipo_documento);

        if(  documento   ){
            if (!documento.TIPO_DOCUMENTO){    
                if ( tipodocumentos) {
                    let tipoDocumento = tipodocumentos[0];
                    dispatch({
                        type : actionType.SET_DOCUMENTO,
                        documento: {
                            ...documento , "TIPO_DOCUMENTO" : tipoDocumento
                        },  
                    })
                }      
            }else{
             setDefault_value(documento.TIPO_DOCUMENTO.id)
           }      
        }

    } , [documento] )





    const handleChange = (nuevo) => {

    
        if(actualizarTab){
            actualizarTab(nuevo);
        }

        LogicaDocumento.despachador( "cambiar_tipo_documento" , {
            valor  : nuevo       
        })   
    }

    const cancelar = () => {

       
        resetearDocumentoGlobal(TabIndexActual , dispatch , actionTypeDocument , tipodocumentos[0] );
        actualizarTab(tipodocumentos[0]);
    }

    if(!tipodocumentos){
        return false;
    }
    
    
    /*
    { documento.TIPO_DOCUMENTO &&  }
    */

    if(!documento.TIPO_DOCUMENTO){
        return false;
    }


    


    return (

        <div className='d-none d-sm-block'>    
            <Dropdown>
                <Dropdown.Toggle variant="null"    className={'tipo_de_documento  bg-' + documento.TIPO_DOCUMENTO.color_documento +  ' float-end'}  >
                    { documento.TIPO_DOCUMENTO.nombre}

                </Dropdown.Toggle>
                
                { (documento.TIPO_DOCUMENTO.bloquear && documento.TIPO_DOCUMENTO.bloquear == true ) && 

                    <Dropdown.Menu>
                                        
                    <Dropdown.Item onClick={ cancelar } > Cancelar  </Dropdown.Item>

                    </Dropdown.Menu>

                    ||
                    
                 

                    <Dropdown.Menu>
                    {tipodocumentos.map( (tpd , index ) => 
                        <Dropdown.Item onClick={ () => handleChange(tpd)} key={index} > {tpd.nombre}</Dropdown.Item>
                    )}     
                    </Dropdown.Menu>

                }
                


        
            </Dropdown>
        </div>
    );
}

export default TipoDocumento;