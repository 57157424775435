import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';

const TagsCocina = ({auxitem , Cargarcomentario , comentario}) => {


    const  eliminarUltimaComa = (texto) =>{
        
        if (texto.charAt(texto.length - 1) === ',') {
          return texto.slice(0, -1); // Elimina el último carácter (la coma)
        } else {
          return texto; // No hay coma al final, devuelve la cadena original
        }
    }

    let Auxcomentario =    comentario    ?   comentario :  "" ;


    let  data  = ( auxitem.auxRestauranteComentarios ) ? auxitem.auxRestauranteComentarios  : "";



    data = eliminarUltimaComa( data + " , " +  Auxcomentario) ;


    console.log(data);

    console.log(Auxcomentario);

    const itemList =  ( data.trim().length >  0) ? data.split(",") : [];
    
    const abc = itemList.filter((valor, indice, arr) => arr.indexOf(valor) === indice);

    const [filteredList, setFilteredList] =  useState(abc);
    
    
       
    Auxcomentario = ( Auxcomentario.trim().length >  0) ?Auxcomentario.split(",") : []; 
    const [selecion, Setselecion ] =  useState(Auxcomentario);

   
    const input = useRef(); 

    const [palabraBuscar , SetpalabraBuscar ] = useState(""); 

    const filterBySearch = (event) => {
        // Access input value
        const query = event.target.value;

        SetpalabraBuscar( query)

        // Create copy of item list
        var updatedList = [...itemList];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((item) => {
          return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
        // Trigger render with updated values
        //setFilteredList(updatedList);
    };

    const seleccionarData = (event , index) => {
        let newlist = null;
       
        if (event.target.checked) {
            newlist =  [...selecion , index];
        } else { 
            newlist = selecion.filter((lista) => lista != index  );
        }
        console.log(newlist)
        Setselecion(newlist);
        input.current.focus();
        

    }
    
    const meterFoco = () => {

       
        input.current.focus();

    }

    const Cargarcomentario2 = () => {
        let data = "";
        data =  selecion.join(',')
        Cargarcomentario(data);
    }

    const DispararkeyPress =  (e) => {
        

        if(e.key === 'Enter'){
            Setselecion([...selecion , e.target.value ]);
            SetpalabraBuscar('');
        }


        if(e.key === 'Backspace' && palabraBuscar.length === 0 ){
            
            let config = [...selecion];
            config.pop();
            Setselecion(config)
           
        }

        

        
    }

    const Comprobar = (item) => {
        let data = selecion.findIndex ( data => data == item);
        if(data >= 0 ) return true;
        return false;
    } 



    useEffect(() => {
        input.current.focus();
    } , [])


      return (
       
        <div className='row' >

            <div class="d-flex justify-content-between">


                    <div onClick={meterFoco} style={{border:'solid 1px' , height:'30vh' , width:'100%' }} className=" targetasd position-relative">

                        { selecion.map( (data , index) => 
                            <span key={index} class="badge rounded-pill text-bg-light me-2">{data}</span>                   
                        )}

                        <input  value={palabraBuscar} onKeyUpCapture={DispararkeyPress}   className="m-2 p-3 input-invisible" ref={input} style={{border:'none'}}   placeholder='Agregar Comentario '  id="search-box" onChange={filterBySearch} />

                    </div>  
            </div>
            
            <ul class="list-group mt-3 list-group-flush">
                        {filteredList.map((item, index) => (
                            <li key={index} class="list-group-item">
                                <input checked={ Comprobar(item) } onChange={(e) => seleccionarData(e, item) } class="form-check-input me-1" type="checkbox" value="" id={'aa131' + index} />
                                <label class="form-check-label stretched-link" htmlFor={'aa131' + index}>{item}</label>
                            </li>
                        ))}   
                    </ul>
        

            <div className='row'>
                <button  onClick={Cargarcomentario2} className='btn mt-5 btn-principal'>  <i class="fa-solid fa-angles-left"></i> Atras   </button> 
            </div>
         
        </div>
  
      );
    }

export default TagsCocina;