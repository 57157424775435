import React from 'react';
import { __app, __fecha } from '../../../../utils/myApp';
import Pendiente from '../../Componentes/Pendiente';
import { Numpositivo } from '../../../../Core/MatematicasGlobales';

const ListaPagos = ({document , setMenu }) => {

    const {   PAGOS  } = document; 
    const {   DOCUMENTO  } = document;  


    const totalDocumento = parseFloat(DOCUMENTO.CtDocumento_SUBTOTAL_3);
    const totalPagado = PAGOS
    .filter((item) => item.estado == 1) // Seleccionar elementos con estado igual a 1
    .reduce((acumulador, item) => acumulador + parseFloat(item.total), 0); // Sumar el valor de 'total'

    const pendiente = Numpositivo(totalDocumento) - Numpositivo(totalPagado);

    return (
        <div>
            <div className="d-flex justify-content-between align-items-end mb-4">     
                        <h5 className="text-center" >
                            Informacion de pagos  
                        </h5>

                        <div>

                            {pendiente > 0 && <button onClick={() =>setMenu(2)} className="btn btn-small btn-success me-2">
                                Registrar abono   
                            </button>}
                                
                  
                        </div>
                    </div>
                    
                    <br />
                    
                   <div className='table-responsive'>
                   <table className="table table-sm border-top border-translucent fs-8 mb-0 table-bordered">
                        <tr>
                            <th className="text-center">#Pago</th>
                            <th className="text-center">Fecha</th>
                            <th className="text-right">Responsable</th>
                            <th className="text-right">Forma de Pago</th>
                            <th className="text-right">Banco</th>
                            <th className="text-right">Sede</th>
                            <th className="text-right">Turno</th>
                            <th className="text-center">Total</th>
                     
                        </tr>
                   

                        <tbody>
                            {PAGOS.map((item, key) => (
                                <>
                                {
                                    item.estado == 1 &&
                                    <tr key={key}>
                                        <td>{item.id}</td>
                                        <td>{ __fecha.formatoFecha({$fecha:item.CREADO}) }</td>
                                        <td>{item.nombre_user}</td>
                                        <td>{item.forma_pagos_nombre}</td>
                                        <td>{item.banco_nombre}</td>
                                        <td>{item.estacione_nombre}</td>
                                        <td>#{item.turno_id}</td>
                                        <td>  <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total :  item.total , color : true , icono : false , numerosPositivos : true  } )} }></span> </td>
                                       
                                    </tr>
                                }
                                </>
                            ))}
                        </tbody>
                        <tfoot>
                          
                            
                            <tr >
                                <td colSpan={6}></td>
                                <td>Total Pagado</td>
                                <th>$  <span dangerouslySetInnerHTML={{__html : __app.formato_decimal( { total  : totalPagado , color : true , icono : false , numerosPositivos : true  } )} }></span> </th>
                            </tr>

                            <tr >
                                <td colSpan={6}></td>
                                <td>Saldo Pendiente </td>
                                <th> <Pendiente total={totalDocumento}   abonado={totalPagado} /> </th>
                            </tr>

                        </tfoot>
                    </table>
                   </div>
        </div>
    );
};

export default ListaPagos;