import React from 'react';

import { duracionTurno, estadoLabelTurno, formatoFecha } from '../Controlador';
import { __app, __fecha } from '../../../utils/myApp';


const DetalleTr = ({info}) => {
    return (

        <>
            <tr>
                <th style={{ border: "none" }}>Estado del turno</th>
                <th style={{ border: "none" }}>{estadoLabelTurno(info.Turno_estado)}</th>
            </tr>

            <tr>
                <th>Punto de Venta</th>
                <td>{info.Estacione_nombre}</td>
            </tr>

            <tr>
                <th>Turno iniciado por</th>
                <td>{info.Turno_open_name_user}</td>
            </tr>

           

            <tr>
                <th>Fecha de inicio</th>
                <td>{ __fecha.formatoFecha( { $fecha :  info.Turno_inicio }  )}</td>
            </tr>


            <tr>
                <th>Fecha Fín</th>
                <td>{ __fecha.formatoFecha( { $fecha :  info.Turno_fin} )}</td>
            </tr>

            <tr>
                <th>Tiempo de duración</th>
                <td><span dangerouslySetInnerHTML={{ __html:  duracionTurno(info.Turno_inicio, info.Turno_fin) }} />  </td>
            </tr>
          
        </>
        
    );
};

export default DetalleTr;