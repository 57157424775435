import React from 'react';
import DetalleTurno from './ViewTurnoid/DetalleTurno';
import CierresParcilales from './ViewTurnoid/CierresParcilales';
import ComprobantesDetalleView from './ViewTurnoid/ComprobantesDetalleView';
import ImprimirTurno from './ImprimirTurno/ImprimirTurno';
import prindiv from 'easyprintand';

const TurnoComponente = ({ user }) => {


    let infoTotales = {
        EFECTIVO_SISTEMA_ACTUAL : 0,
        efectivo_entrega : 0,
        efectivo_recibe : 0,
        TOTAL_CONSULTA_CONSOLIDADO : 0,
    }  
    const { FINALIZAR_TURNO : { info,consolidado, cierre_parcial , resumen_comprobantes } } = user;



    const Imprimir = () => {

        prindiv('TurnoDetalleImprimir' ,  { extraCss : './carta.css' })
    }

    const salir = () =>{

        localStorage.removeItem('user');
        window.location.href = '/';
    }

    return ( 

        <div className='d-flex justify-content-center '>

            {false && <div>
                <DetalleTurno info={info} /> 
                <CierresParcilales infoTotales={infoTotales} cierre_parcial={cierre_parcial} />
                <ComprobantesDetalleView  resumen_comprobantes={resumen_comprobantes} consolidado={consolidado} infoTotales={infoTotales} />
            </div>}
            
            <div className='mt-5'>
                <button onClick={() => Imprimir()} className='btn btn-principal'><i className="fa fa-print"></i> Imprimir </button>
                <button onClick={ () => salir() } className='ms-2 btn btn-danger'> <i className="fa fa-power-off"></i> Salir  </button>

                {
                    
                    <ImprimirTurno turno={user.FINALIZAR_TURNO}   />   
                }

            </div>

        </div>
    )

    };

export default TurnoComponente;
