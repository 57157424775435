import React from 'react';
import { __app, __fecha } from '../../../../utils/myApp';
import { __pluma7 } from '../../../../Core/pluma7';

const Header = ({infoPago}) => {
    const master = (infoPago && infoPago.pagos && infoPago.pagos[0].ct_master_id) ? infoPago.pagos[0].ct_master_id : 0;
    return (
        <div >
           <h3 className='mb-3'>COMPROBANTE DE {__pluma7.NombreMasterID({master_id:master})} </h3>
           Fecha de Impresion : { __fecha.formatoFecha({$fecha :  __app.getFecha() }) } <br />
    
        </div>
    );
};

export default Header;