


import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../../context/StateProvider';

import { useContextDocumentValue , actionTypeDocument } from "../context/ContextDocument.js";
import { DocumentL  } from "../../../components/Document/DocumentCore";



const ListaPrecio = ({tercero}) =>  {
   

    const [ {user} ] = useStateValue();
    const [ {documento}  , dispatch ] = useContextDocumentValue();
    const LogicaDocumento  =  new DocumentL(documento , null  , { dispatch, actionTypeDocument } , user  );
    



    /***
     *  1=> Activar porcentaje
     *  2=> Activar precio  
     */
    
    let SWITCH_LISTA_PRECIO = 1; 


    try {
        if(   user.CONFIGURACIONES_TABLE.switch_modo_precio &&  user.CONFIGURACIONES_TABLE.switch_modo_precio == 2 ){
            
            SWITCH_LISTA_PRECIO = 2;
        }
    } catch (error) {
        console.log(error);
    }


  
    const listaprecios = user.DATABASE.ct_lista_precios;



    let limitedescuento = 0;


   
    let aux = listaprecios.filter( (data) => data.id == tercero.ct_lista_precio_id );


    if(aux && aux.length > 0){
        limitedescuento = aux[0].valor ;
        console.log(aux[0]);
    }else{
        console.log(aux);
    }
    


    const handleChange = (e) => {

        LogicaDocumento.actualizar_lista_precio(e.target.value , SWITCH_LISTA_PRECIO ,  null ,   null ); 
    }

 

    const filtro = listaprecios.filter((data) =>  Number(limitedescuento) >=  Number(data.valor) );

    return (
        <div className='d-block'>
          

            { (filtro && filtro.length > 0)  &&  
               
               <>
                <label>Lista de Precios</label>
                <select defaultValue={documento.ESTRAS.LISTA_PRECIO_ACTIVA} onChange={handleChange} name="" id="">
                    <option  value={0} > Ninguna </option>
                    {filtro.map( (lista, index) => 
                        <option value={lista.id} key={index}>{lista.nombre}</option>  
                    )}
                </select>

               </>

            }

        
        
       
      

        </div>
    );
}

export default ListaPrecio;