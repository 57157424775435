import React, { useEffect, useState } from 'react';
import SelectPedidos from './SelectPedidos';
import { enviarPeticion } from '../../../Core/ActionDatabase';
import { fetchUSer } from '../../../Core/fetchLocalStoreData';
import { agregarFormaDepagoAutomatica, eliminarCuentaAbiertaFireBase, guardarDocumentoLocal, setUltimosCambios } from '../../Document/controlador';
import { __app } from '../../../utils/myApp';

const CerrarVentaEnMasa = ({ pedidosSeleccionados }) => {
    const user = fetchUSer();
    const [cuentasAbiertas, setCuentasAbiertas] = useState([]);
    const [estadoCarga, SetEstadoCarga] = useState(1);

    useEffect(() => {
        setCuentasAbiertas(pedidosSeleccionados);
    }, [pedidosSeleccionados]);

    const enviarDocumento = async (documento) => {

        


        let copi = setUltimosCambios(user, documento);
        
    
        if(estadoFactura == "cerrada-pagada"){
            let FORMAS_DE_PAGO  = agregarFormaDepagoAutomatica(copi.MATEMATICAS.SUBTOTAL_3);
            copi = {...copi ,  FORMAS_DE_PAGO : FORMAS_DE_PAGO};
           
        }


        let respuestauax = await enviarPeticion("adddocumentos/nuevo", "", { body: copi });

        let mensaje = null;
        let respuesta = null;
        if (respuestauax.respuesta) {
            finProcedimientoExitoso(respuestauax.data, copi);
            console.log("Guardado con Exito");

            respuesta = "Exitoso";
            return { mensaje, respuesta }
        } else {
            console.log("Error");
            respuesta = "Error";
            mensaje = respuestauax.mensaje;
            return { mensaje, respuesta };
        }
    }

    /**
     * Procedimiento de registro de factura exitoso
     * @respuesta = La data 
     */
    const finProcedimientoExitoso = (re, copydc) => {
        let copy = { ...copydc,
            EstadoServidor: 1,
            EstadoLocalFactura: 1,
            id_server: re.id,
            NUMERACION: re.numeracion,
            estado_patio: 0,
            patio_salida_fecha: __app.getFecha(),
        };

        let copyy = guardarDocumentoLocal(copy);
        eliminarCuentaAbiertaFireBase(copyy.id, user);
    }

    // Función para realizar una acción con la opción seleccionada
    const handleSubmit = () => {
        SetEstadoCarga(2);

        let copy = pedidosSeleccionados.map((data) => {
            data.AUX_SERVER = {
                estado: 'En espera...',
                respuestaHttp: null,
                mensajeError: '' // Añadimos un campo para el mensaje de error
            };
            return data;
        });

        setCuentasAbiertas(copy);
        iniciarRecorrido();
    };

    // Iniciar el recorrido de facturación
    const iniciarRecorrido = async () => {
     

        // Iterar sobre los pedidos seleccionados
        for (let i = 0; i < cuentasAbiertas.length; i++) {
            let data = cuentasAbiertas[i];

            // Modificar directamente el estado de cada factura
            data.AUX_SERVER = {
                estado: 'En Proceso',  // Marca el estado como "En Proceso"
                respuestaHttp: '',
                mensajeError: '' // Limpiamos el mensaje de error
            };

            // Actualizar el estado del componente para reflejar los cambios en la interfaz
            setCuentasAbiertas([...cuentasAbiertas]);

            // Enviar el documento y obtener la respuesta
            let respuesta = await enviarDocumento(data);

            // Después de enviar el documento, actualizar el estado de la factura
            data.AUX_SERVER.estado = respuesta.respuesta === "Exitoso" ? "Facturado" : "Error";
            data.AUX_SERVER.mensajeError = respuesta.respuesta === "Error" ? respuesta.mensaje : '';

            // Actualizar el estado del componente para reflejar los cambios en la interfaz
            setCuentasAbiertas([...cuentasAbiertas]); // Forzar re-renderizado
        }

        SetEstadoCarga(3);
    };

    // Estado para almacenar la opción seleccionada
    const [estadoFactura, setEstadoFactura] = useState(null);

    // Función para manejar el cambio en el select
    const handleChangeFormaPago = (e) => {
        setEstadoFactura(e.target.value);
    };



    return (
        <div className='mb-5'>
            <p>Cerrando ventas en masa</p>

            {/* Modo lista */}
            <div className='bg-white'>
               
               
                <SelectPedidos estadoFactura={estadoFactura} handleChangeFormaPago={handleChangeFormaPago}/>

      



                { (estadoCarga == 1 &&  estadoFactura ) &&  <button className="btn btn-primary mt-3" onClick={handleSubmit}>
                        Iniciar Facturación
                </button> }

                { estadoCarga == 2 &&  <p>Enviando, por favor espere...</p> }
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Codigo</th>
                            <th>Fecha</th>
                            <th>Vendedor</th>
                            <th>Cliente</th>
                            <th>Total</th>
                           
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {cuentasAbiertas.map((duc, index) => (
                            <tr key={duc.id}>
                                <td>{duc.id}</td>
                                <td>{duc.fechaid}</td>
                                <td>{duc.FORM.nombre_vendedor}</td>
                                <td>{duc?.TERCERO?.razonSocial}</td>
                                <td>${duc.MATEMATICAS.SUBTOTAL_3}</td>
                      
                                <td>
                                    {/* Si el estado es "En espera", mostrar el spinner */}
                                    {duc?.AUX_SERVER?.estado === 'En espera...' ? (
                                        <div className="spinner-border text-info" role="status">
                                            <span className="sr-only">Cargando...</span>
                                        </div>
                                    ) : (
                                        <span
                                            className={`badge ${
                                                duc?.AUX_SERVER?.estado === 'Facturado'
                                                    ? 'bg-success'
                                                    : duc?.AUX_SERVER?.estado === 'Error'
                                                    ? 'bg-danger'
                                                    : 'bg-warning'
                                            }`}>
                                            {duc?.AUX_SERVER?.estado}
                                        </span>
                                    )}
                                </td>
                                {/* Acordeón para mostrar el mensaje de error */}
                                {duc?.AUX_SERVER?.estado === 'Error' && duc?.AUX_SERVER?.mensajeError && (
                                    <tr>
                                        <td colSpan="7">
                                            <div className="accordion" id={`accordionError${index}`}>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={`headingError${index}`}>
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#collapseError${index}`}
                                                            aria-expanded="true"
                                                            aria-controls={`collapseError${index}`}>
                                                            Ver detalles del error
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={`collapseError${index}`}
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby={`headingError${index}`}
                                                        data-bs-parent={`#accordionError${index}`}>
                                                        <div className="accordion-body">
                                                            {duc?.AUX_SERVER?.mensajeError}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CerrarVentaEnMasa;
