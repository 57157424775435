import React from 'react';
import { calcularUtilidad } from '../../../../../Core/MatematicasGlobales';
import { __app } from '../../../../../utils/myApp';
const UtilidadPrecio = ({item}) => {
    let PRECIO_ACTUAL = (item.COMPRA && item.COMPRA.precio_actual) ? item.COMPRA.precio_actual : 0;

    let { ganancia, utilidadPorcentaje } = calcularUtilidad( PRECIO_ACTUAL ,  item.MATEMATICAS.PRECIO );


    const clase = ganancia < 0 ? 'text-danger' : 'text-primary';
    return (
        <span  style={{ cursor: 'help' }} className={'ms-1 ' +clase } title={ __app.formato_decimal_2({total:ganancia , decimal: 2 }) + ' Es el  valor que  ganaras por vender este producto'}>
            {
               __app.formato_decimal_2({total:ganancia }) 
            }
        </span>
    );
};

export default UtilidadPrecio;