import React, {  useState } from 'react';
import { __app } from '../../../utils/myApp';
import {  getVentasByVendedores } from '../Controlador';


const VentasVendedor = ({turno_id}) => {
    const [ itemsVendios , setItemVendios ] = useState([]);
    const [ carga , setCarga ] = useState(null);


    const [ totales , setTotales ] = useState({
        total : 0,
    });


    const cargarVentasItemsbyTurno = async () => {

        if( itemsVendios.length > 0  ){
            return false;
        }

        if ( carga == 1 ){
            return false;
        }

        setCarga(1);
        
        let data = await  getVentasByVendedores(turno_id , 1 );

        if  (data.length > 0){


            console.log(data);

            setCarga(null);
            setItemVendios(data);


            data.map((item, index) => {
                
                // Convierte las propiedades a números antes de sumarlas
                const total = parseFloat(item.total);

              
                // Acumula los valores en totales
                setTotales(prevTotales => ({
                    total: prevTotales.total + total,
                }));
            })

        } 

    }





    const index = 4854545454;
    return (
        <div className="accordion" id="accordionPanelsStayOpenExample" >
                
        
            <div key={index} className="accordion-item">
               
                    <h2 className="accordion-header">
                       
                        <button onClick={cargarVentasItemsbyTurno} className={'bg-opacity-10  accordion-button collapsed' + ' bg-success'  } type="button" data-bs-toggle="collapse" data-bs-target={'#panelsStayOpen-collapse' + index } aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      
                        <span className={'fw-bold '}>  VENTAS POR VENDEDOR  
                        
                            { carga  == 1  &&  <span> Cargando..</span> }    
                        </span> 
                        
    
                        </button>

      

                    </h2>
                    <div id={'panelsStayOpen-collapse'  + index } className="accordion-collapse collapse"  style={{maxHeight: "500px", overflow : "auto"}}>
                        <div className="accordion-body">


                            { ( carga &&   carga == 1)  &&  <p>Cargando...</p> || 
                            
                                <table className='table '>
                                    <thead>
                                        <tr>
                               
                                            <th>Nombre</th>
                                            <th>Total</th>
                                            <th>Comisión</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {( itemsVendios.map( (item, index) => 

                                        <tr>    
                                     
                                            <td>{item.nombre}</td>
                                            <td>  { __app.formato_decimal( {total : item.total}) }</td>
                                            <td>  { __app.formato_decimal( {total :   (item.total  *  0.005 )  }) }</td>  
                                            
                                           
                                            
                                        </tr>   

                                    ))}
                                    </tbody>
                                    
                                    <tfoot>
                                        <tr>    
                                            <th></th>
                                            <th className='text-success'>$ { __app.formato_decimal( {total : totales.total})}</th>
                                            <th className='text-primary'>$ {__app.formato_decimal( {total :  (totales.total  *  0.005 ) })}</th>   
                                        </tr>
                                    </tfoot>

                                </table>
                            }
                        </div>
                    </div>
            </div>
         

        </div>
    );
};

export default VentasVendedor;