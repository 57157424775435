import React from 'react';
import LinkMenu1 from './LinkMenu1';

const LinkMenu2 = ({data , id}) => {
    console.log(data)
    
    return (
        <li className="nav-item ">
            
            <a className="nav-link dropdown-indicator" href={"#nv-" + data.id} data-bs-toggle="collapse" aria-expanded="true" aria-controls={"nv-" + data.id}>
                <div className="d-flex align-items-center">
                    <div className="dropdown-indicator-icon"><span className="fas fa-caret-right"></span></div>
                    <span className="nav-link-text">{data.label}</span>
                </div>
            </a>
     
            <div className="parent-wrapper">
                <ul className="nav collapse parent " data-bs-parent={'#nv-' + id} id={"nv-" + data.id}>
                    
                    {data.options[0].tipo == "link" && <>{ data.options.map((item,index) => <LinkMenu1 key={index} data={item} /> )}</>}
                    
                    {data.options[0].tipo  == "menu" && <>{ data.options.map((item,index) => <LinkMenu2 key={index} data={item} id={data.id} /> )}</>} 

                </ul>
            </div>
        </li>
    );
};

export default LinkMenu2;