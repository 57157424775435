import { enviarPeticion } from "../../../Core/ActionDatabase"
import { fetchUSer } from "../../../Core/fetchLocalStoreData";


export const guardarPagoHttp = async  (formData) => {

    const user = fetchUSer();

    const {TURNO , ESTACION , USER  } = user;
    
    const userLogin   = {
        TURNO,
        ESTACION,
        USER
    };

    let responseHttp = await  enviarPeticion('pagos/addPago' , null , {
        body: {
            user : userLogin,
            POST : formData
        }
    });

    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }


}


export const listarPagosHttpByTercero = async  (tercero_id) => {


    let responseHttp = await  enviarPeticion('pagos/listar_pagos_by_tercero' , tercero_id ,);

    if(responseHttp.respuesta && responseHttp.respuesta == 1 ){
        return responseHttp.data
    }else{
        return -1;
    }


}