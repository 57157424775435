import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { NotificationManager } from 'react-notifications';


const Imagenes = ({setFormData , formData}) => {
    
  const [images, setImages] = useState(formData.IMAGENES);
  const maxNumber = 3;
  const maxSizeInBytes = 9900000; // 500 KB
  
  

  const onChange = (imageList) => {



    setImages(imageList);    
    setFormData({
        ...formData,
        IMAGENES: imageList
    });

    return false;
  };



  const subirImagenes = (e , onImageUpload) => {

    onImageUpload();
    e.preventDefault();
    return false
  }


  const eliminarImagene = (e , index , onImageRemove) => {

    onImageRemove(index)
    e.preventDefault();
    return false
  }

  const error  = (errors, files) => {
    console.log(errors)
    
    if(errors.maxFileSize === true ){
        NotificationManager.error("La imagen supera el tamaño.")
    }

    if(errors.maxNumber === true ){
        NotificationManager.error("Solo aceptamos un maximo de " + maxNumber + " Imagenes")
    }

    
  }
  
  return (
    <div className="container mt-5">
      <h2 className="mb-4">Subir Imágenes</h2>

      <p>Tamaño Maximo : { maxSizeInBytes / 1000} KB  </p>
      <p>Maximo de Fotos  : {maxNumber }   </p>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        maxFileSize={maxSizeInBytes}
        dataURLKey="data_url"
        onError={error}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemove,
        }) => (
          <div className="row">
            <div className="col-12 mb-3">
              <button className="btn btn-sm btn-subtle-primary me-2" onClick={ (e) => subirImagenes(e , onImageUpload ) }>
                Subir imágenes
              </button>
            </div>
            {imageList.map((image, index) => (
              <div key={index} className="col-md-4 mb-3">
                <div className="card">
                  <img src={image['data_url']} className="card-img-top" alt="" />
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
         
                      <button
                        className="btn btn-subtle-danger  btn-sm"
                        onClick={(e) => eliminarImagene(e, index , onImageRemove)}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>

    </div>
  );
};

export default Imagenes;
