import React, {createContext , useContext , useReducer } from "react";







export const actionTypeDocument = {

    SET_DATA : 'SET_DATA',
}

export const LocalContextReducer = ( state , action ) => {
   
   console.log("ajjaja")
   console.log(state)
   console.log(action.type)
    switch(action.type){       
        case actionTypeDocument.SET_DATA: 

         console.log("SIII")
            return {...state , 
                info : action.data
            };
        default :
            return state;
    }
    
}





export const ContextLocal = createContext();


/** Compoente */
export const LocalProvider = ( { reducer , initialState , children }  ) => (
        <ContextLocal.Provider value={  useReducer(reducer, initialState)  } >
            {children}
        </ContextLocal.Provider>
)


/*** Devulevel el context Creado */
export const useContextLocalValue = () =>  useContext(ContextLocal);