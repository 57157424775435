import React from 'react';
import { useState } from 'react';
import {Select} from  '../../../../ComponentsHtml/Elements';
const  EditarLinea = ({item , Accion , indexx , operadores })  => {


    

  



    const [ fomritems , setFomritems ] = useState(item);


    const handleInputChange = (event) => {   
        console.log("Disparado el  cambiador")
        let key   = event.target.getAttribute("data-key");
        let name   = event.target.name;
        let value  = event.target.value;
        let copia  = null; 

        

        console.log(key , name ,  value);


        /*** Solo para las Matematicas */
        if (key){
            
            let copia = {...fomritems , MATEMATICAS : { ...fomritems.MATEMATICAS , [name] : parseFloat(value) }   };
            setFomritems(copia);

        }else{

            
            /*** Para elresto del los items */
           

            if(name == 'operador'){
                let id = event.nativeEvent.target.selectedIndex;
                let nombre_operador = event.nativeEvent.target[id].text;

                copia = {...fomritems , [name] : value , 'nombre_operador' : nombre_operador };
                setFomritems(copia);

            }else{
                copia = {...fomritems , [name] : value};
                setFomritems(copia);
            }
        }

        console.log(copia);
       if ( item.nombre.trim()  !=   fomritems.nombre.trim() ||  item.descripcion.trim()  !=   fomritems.descripcion.trim()  ){       

            let copia2 = {...copia , DOCUMENTO : { ...fomritems.DOCUMENTO,  OTRO_PRODUCTO : true } };
            setFomritems(copia2);
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Desde handleSubmit de Editar Linea imprimo el item a enviar")
        console.log(fomritems);
         Accion(fomritems , indexx );
        return false;
    }

 

    return (
        <div>
            <h3 className='text-center'>Editar Item</h3>
            <form onChange={handleInputChange}  onSubmit={handleSubmit}  className="row g-3 mt-5">
                
                <div className='col-6'>
                    
                    <div className="col-md-12">
                        <label  className="form-label">Nombre</label>
                        <input onChange={ () => {}} name='nombre' value={fomritems.nombre}  className="form-control" />
                    </div>

                    <div className="mt-3">
                        <label  className="form-label">Descripcion</label>
                        <textarea  onChange={ () => {}} name='descripcion' value={fomritems.descripcion}    className="form-control" rows="3"></textarea>
                    </div>
                </div>

                <div className='col-6'>

                    <div className='row'>
                        <div className="col-6">
                            <label className="form-label">Precio de Venta</label>
                            <input data-key="1" onChange={ () => {}} name='PRECIO' value={fomritems.MATEMATICAS.PRECIO}  type="text" className="form-control"  placeholder="" />
                        </div>

                        <div className="col-6 ">
                            <label className="form-label">Cantidad</label>
                            <input data-key="1" onChange={ () => {}} name='CANTIDAD' value={fomritems.MATEMATICAS.CANTIDAD} type="text" className="form-control"  placeholder="" />
                        </div>
                    </div>


                    <div className='row  mt-3'>

                        { false && 
                        <div className="col-6">
                            <label  className="form-label">Descuento Manual</label>
                            <input type="text" className="form-control" placeholder="Apartment, studio, or floor" />
                        </div>
                        }   
                        
                        <div className="col-md-6">
                            <label  className="form-label">Descuento en porcentaje</label>
                            <input  data-key="1" onChange={ () => {}} name='DESCUENTO_UNITARIO_PORCENTUAL' value={fomritems.MATEMATICAS.DESCUENTO_UNITARIO_PORCENTUAL} type="text" className="form-control"  />
                        </div>

                    </div>

                </div>

                {  (item && item.tipo_producto == 3 && item.liquidable == 1 ) && 
                    <div className="col-12 mt-5 mb-3" >
                        <h3>Producto de tipo <span className='badge bg-primary'>Servicio</span></h3>
                       
                        <div className='row'>
                            <div className="col-md-6">
                                <label  className="form-label">Operador del Servicio</label>
                                <Select  value={fomritems.operador} className="form-control" name='operador'  options={operadores}  keys = {{ id : 'id' , nombre : 'razonSocial' }} />
                            </div>
                        
                    

                            <div className="col-md-6">
                                <label  className="form-label">Liqidacion en {item.switch_liquidacion == 1 &&  <span className='badge bg-primary'>Porcentaje</span>  ||  <span className='badge bg-success'>Valor Fijo </span>  }
                                </label>
                               
                                { (item.servicio_bloqueo_liquidacion == 1 )  && 
                                    <div>
                                        <input name="liquidacion" onChange={ () => {}}  value={fomritems.liquidacion} type="text"  className="form-control"  />
                                    </div>

                                    ||
                                    <div> 
                     
                                        {item.switch_liquidacion == 1 &&   <input  value={fomritems.liquidacion + " %"  }  readOnly={true} className="form-control bg-light"  /> ||   <input  value={ " $ " + fomritems.liquidacion  }  readOnly={true} className="form-control bg-light"  />  }

                                       
                                        
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                }            
                


                <div className="col-12 mt-5 mb-3" >
                    <center>
                        <button type="submit" className="btn btn-lg btn-primary">Guardar Cambios</button>
                    </center>
                </div>
            </form>
        </div>
    );
}

export default EditarLinea;