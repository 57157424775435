import { fetchUSer } from "../../../../Core/fetchLocalStoreData";

import Barcode from 'react-barcode';

const ImprimirEntradaPatio = ({documento }) => {

    const user = fetchUSer();
    if (!documento) {
        return false;
    }


    return (
        <div id=''>
            <div className="TirillaTextCenter">     
                <h4 className="TirillaM0">{user.ESTACION.nombre}</h4>
                <b className="Tirilla11px"> NIT : {user.ESTACION.nit}</b>
                <p style={{marginTop:'-2px'}}>
                    {user.ESTACION.telefono} <br/> 
                    {user.ESTACION.direccion}<br/>
                    {user.ESTACION.municipio} <br/>
                </p>
            </div>

            <h3>PLACAS  #{documento.placa}</h3>
            Descripcion : {documento.OBJETO.nombre}<br/>
            Categoria:  {documento.OBJETO.nombreCategoria}
            <table>
                <tr>
                    <th>Entrada: </th>
                    <td> {documento.patio_entrada_fecha}  </td>
                </tr>
                <tr>
                    <th> Recibido por:  </th>
                    <td> {documento.FORM.nombreCajero}  </td>
                </tr>
            </table>

            <Barcode 
                value={documento.placa} 
                width={2} 
                height={50} 
                displayValue={false} 
                background="#fff" 
                lineColor="#000" 
                />


            <br/>
            {user.CONFIGURACIONES_TABLE.parking_piepaginaEntrada && 
                <div dangerouslySetInnerHTML={{ __html: user.CONFIGURACIONES_TABLE.parking_piepaginaEntrada }}>
                </div>
            }

            <center>
                <p>Sistema: www.pluma7.com - (+57)3122946723 </p>
            </center>
        </div>
    );
};

export default ImprimirEntradaPatio;
