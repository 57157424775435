
import { getLocalstore } from '../../Core/fetchLocalStoreData';
import {enviarPeticion} from  "../../Core/ActionDatabase";

 export const SessionCheck = () => {

   
}

export const denominaciones = () => {
    

    return  [

    { 
        "nombre" : "$ 100,000",

        "valor"  : 100000,

        "cantidad"  : 0, 

        "valor_total" : 0
        , "sumar" : 1 ,

        "ayudaBase" : 0

    },


    { 

        "nombre" : "$ 50,000",

        "valor"  : 50000,

        "cantidad"  : 0, 

        "valor_total" : 0

        , "sumar" : 1 ,

        "ayudaBase" : 0
    },



    { 

        "nombre" : "$ 20,000",

        "valor"  : 20000,

        "cantidad"  : 0, 

        "valor_total" : 0

        , "sumar" : 1 ,

        "ayudaBase" : 0
    },




    { 

        "nombre" : "$ 10,000",

        "valor"  : 10000,

        "cantidad"  : 0, 

        "valor_total" : 0
        , "sumar" : 1 ,
        "ayudaBase" : 0

    },




    { 

        "nombre" : "$ 5,000",

        "valor"  : 5000,

        "cantidad"  : 0, 

        "valor_total" : 0
        , "sumar" : 1 ,
        "ayudaBase" : 0

    },




    { 

        "nombre" : "$ 2,000",

        "valor"  : 2000,

        "cantidad"  : 0, 

        "valor_total" : 0
        , "sumar" : 1 ,
        "ayudaBase" : 0

    },



    { 

        "nombre" : "$ 1,000",

        "valor"  : 1000,

        "cantidad"  : 0, 

        "valor_total" : 0

        , "sumar" : 1 ,
        "ayudaBase" : 0
    },



    { 

        "nombre" : "$ 500",

        "valor"  : 500,

        "cantidad"  : 0, 

        "valor_total" : 0

        , "sumar" : 1 ,
        "ayudaBase" : 0
    },



    { 

        "nombre" : "$ 200",

        "valor"  : 200,

        "cantidad"  : 0, 

        "valor_total" : 0

        , "sumar" : 1 ,
        "ayudaBase" : 0
    },


    { 

        "nombre" : "$ 100",

        "valor"  : 100,

        "cantidad"  : 0, 

        "valor_total" : 0
        , "sumar" : 1 ,
        "ayudaBase" : 0

    },


    { 

        "nombre" : "$ Otros",

        "valor"  : 1,

        "cantidad"  : 0, 

        "valor_total" : 0,

        "sumar" : 1 ,
        "ayudaBase" : 0


    },


    { 

        "nombre" : "$ Otros",

        "valor"  : 1,

        "cantidad"  : 0, 

        "valor_total" : 0,

        "sumar" : 0 ,
        "ayudaBase" : 0


    },


    ];

}


export const getListaTurnobyId = async( ) => {
    const respuesta = await enviarPeticion("turnos/listar_turno_by_usuario", 1);
    if(respuesta.status){
        return respuesta.data.resultado;
    }else{
        return  [];
    }
}

export const getReporteByTurno = async( turno_id ) => {
    const respuesta = await enviarPeticion("turnos/get_tipo_documentos_usados", turno_id);
    if(respuesta.status){
        return respuesta.data;
    }else{
        return  [];
    }
}

export const getDocumentoByTipoTurno = async( turno_id  , tipoDocmentoID ) => {
    const respuesta = await enviarPeticion("turnos/get_documentos_by_tipo_turno", turno_id  + "/" +  tipoDocmentoID);
    if(respuesta.status){
        return respuesta.data;
    }else{
        return  [];
    }
}


export const getImtesVendidosByTurno = async( turno_id  , tipoDocmentoID ) => {

    const respuesta = await enviarPeticion("turnos/get_items_by_turno", turno_id  + "/" +  tipoDocmentoID);
    if(respuesta.status){
        return respuesta.data;
    }else{
        return  [];
    }
}


export const getVentasByVendedores = async( turno_id  , tipoDocmentoID ) => {

    const respuesta = await enviarPeticion("turnos/get_ventas_vendedores_by_turno", turno_id  + "/" +  tipoDocmentoID);
    if(respuesta.status){
        return respuesta.data;
    }else{
        return  [];
    }
}

export const getCuentasEliminadas = async( turno_id  ) => {
    const respuesta = await enviarPeticion("turnos/get_cuentas_eliminadas_by_turno", turno_id  );
    if(respuesta.status){
        return respuesta.data;
    }else{
        return  [];
    }
}



export const getItemModificados = async( turno_id  ) => {
    const respuesta = await enviarPeticion("turnos/get_item_modificados", turno_id  );
    if(respuesta.status){
        return respuesta.data;
    }else{
        return  [];
    }
}


/****  */
export const estadoLabelTurno = (estado) => {

    return "Estado : 1";
}


export const duracionTurno = ( fecha1, fecha2 ) => {

    if (fecha2 === "0000-00-00 00:00:00") {
        return "--------";
    }

    const fechaInicio = new Date(fecha1);
    const fechaCierre = new Date(fecha2);
    const diferencia = fechaCierre - fechaInicio;

    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));

    if (dias > 0) {
        return `<b>${dias}</b> días + <b>${horas}</b> hrs <b>${minutos}</b> min`;
    }

    if (horas === 0) {
        return `<b>${minutos}</b> min`;
    }

    return `<b>${horas}</b> hrs <b>${minutos}</b> min`;
}

