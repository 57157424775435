import React from 'react';

const DisplayNumber = ({ number, fontSize = 12 }) => {
    // Convertir el número a una cadena y separarlo en parte entera y parte decimal

    if(!number  || number == undefined || number == null || number == ""  ){
        return number ;
    }
    const [parteEntera, parteDecimal] = number.toString().split('.');

    // Definir los estilos para las partes del número
    const enteroStyle = {
        fontSize: `${fontSize}px`,
    };

    const decimalStyle = {
        fontSize: `${fontSize * 0.69}px`,  // El tamaño de la fuente para la parte decimal es el 69% del tamaño de la parte entera
    };

    return (
        <>
            <span style={enteroStyle}>{parteEntera}</span>
            {parteDecimal && parteDecimal !== '00' && <span style={decimalStyle}>.{parteDecimal}</span>}
        </>
    );
};

export default DisplayNumber;