import React from 'react';

import ModoTarjetas from './ModoTarjetas';
import ModoLista from './ModoLista';
import { fetchUSer } from '../../Core/fetchLocalStoreData';



const Pedidos = ({pedidos , cargarDocumento , eliminarCuentaAbierta , user , imprimirPreFactura  }) => {

    console.log(pedidos)

    if(!pedidos) {
        return (<p>No hay Pedidos para mostrar</p>);
    }





    let modo_cuentas_abierta = user.CONFIGURACIONES_TABLE?.modo_cuentas_abierta  ? user.CONFIGURACIONES_TABLE?.modo_cuentas_abierta :  'tarjetas' ;

    
    return (
        <>
            

            {modo_cuentas_abierta === "tarjetas" && <ModoTarjetas pedidos={pedidos}  cargarDocumento={cargarDocumento} eliminarCuentaAbierta={eliminarCuentaAbierta}  user={user}  imprimirPreFactura={imprimirPreFactura} />}

            


            {modo_cuentas_abierta === "modoLista" && <ModoLista  pedidos={pedidos}  cargarDocumento={cargarDocumento} eliminarCuentaAbierta={eliminarCuentaAbierta}  user={user}  imprimirPreFactura={imprimirPreFactura}  /> }

   
        </>
    );
};

export default Pedidos;