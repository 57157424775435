import React , { useState } from 'react';
import { useEffect } from 'react';
import Documento from "./Documento";

import {   getExtraInitial, extraerUltimoDocumentoByCache, SetinitialStateDocumento } from "./controlador";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {DocumentProvider ,initialDocument , documentReducer} from "../Document/context/ContextDocument";

import { useStateValue } from '../../context/StateProvider';

import { nanoid } from 'nanoid'

import { __app } from '../../utils/myApp';

import {getLocalstore}  from '../../Core/fetchLocalStoreData'
    
import { useRef } from 'react';

import StandarModal from './PartesDocument/StandarModal';
import { NotificationManager } from 'react-notifications';


import { recentFacturaElectronicaRef, recentPostsRef} from "../../Core/FireBase";
import {  onValue  } from "firebase/database";
import audioFile from '../../recursos/sincronizado.mp3';
import { extraerCantidadDeProductosBD } from '../Productos/Controlador';
import { ExtraerTablabyIds, reloadDatabases } from '../../Core/Dixie';
import FacturaTirilla from './PlantillasImpresion/FacturaTirilla';
import prindiv from 'easyprintand';
import DatosFacturaElectronica from './PlantillasImpresion/Partes/DatosFacturaElectronica';


const  ListaAllDocument = ({dispatchGlobal})  => {
    
    
    
    

    let mostrar = 0;


   

    /** Initial StateStateIndex */
    let  initialStateDocumentLista = [
        {
            keyLocalStogere : "documente_0",
            precio : 0,
            tabKey : 0,
            
            color_documento : "principal",
            etiqueta_corta : "Factura POS"
        }
    ]

    
    
    /*** Initial State Activa TAB  */
    let initialActivaTab = {
        
        keyLocalStogere: "documente_0", 
        precio: 0, 
        tabKey: 0,
        color_documento : "principal",
        etiqueta_corta : "Factura POS"
    }





    const [ { infodatabase , user} , dispatch  ] = useStateValue();



    
    /* Este Estado Controla los Tab de los Documentos **/
    const [ documentList , setDocumentList ] = useState(initialStateDocumentLista);
    


    const [ TabIndexActual , setTabIndexActual ] = useState(0);



    const [ activeTab , setActiveTab ] = useState(initialActivaTab);



    const [ showModalMesas , SetshowModalMesas ] = useState(false);


    /***
     *  Cadavez que se sincronize un producto, el sistema generara un numero aletorio para avisar a items all una renovacion de los productos Actuales
     * Esta es una forma de avisarle al componente AllItems de un nuevo producto.
     *  */ 
    const [ CodigoSincronizacion , SetcodigoSincronizacion ] = useState(false);
    
    let x = (user.CONFIGURACIONES_TABLE.mostrar_pestaneo && user.CONFIGURACIONES_TABLE.mostrar_pestaneo != 0 ) ?1:2;

    const mostrar_pestaneo =  (x === 1 ) ? 1 :1;

    


    /**
     * Constructor para Asignar la LISTA de los Documentos
     */
    useEffect( () => {
        
        validarPrimeraKey();

        seleccionarListadeDocumentos();

        seleccionarTabActual();

        inicializarOservador();



        inicializarOservadorFacturasElectronicas();
        
        
    } , []  )


    
    const inicializarOservadorFacturasElectronicas = () => {

        onValue(recentFacturaElectronicaRef(), (snapshot) => {
            if(mostrar == 1 ){ 
                const obj = snapshot.val();  
                if(obj && obj.codigo_respuesta_dian  ){
                    
                    if(obj.codigo_respuesta_dian == 4 ){
                        imprimirUltimoDocumento(obj)         
                        NotificationManager.info("FACTURA DE ELECTRONICA" , obj.numero_factura)  
                    }else{
                        NotificationManager.error("FACTURA DE ELECTRONICA" , "RECHAZADA!!")
                            
                    }
                }
           }else{
                mostrar = 1;
           }    
        })
    }
    

    /** Guarda los datos de la reimpresion de la ultima factura */
    const [facturaImprimir , setFacturaImprimir ] = useState(null);

    

    /** Imprime el utlimo codumento generado */
    const imprimirUltimoDocumento = async (obj) => {
        
        if(obj.POST_ID){
            var   data = await  ExtraerTablabyIds("documentos" , [obj.POST_ID]  );

            if(data[0]){
                
                let new_document = {...data[0] , RESPONSE_HTTP_FACTURA_ELECTRONICA : obj };
               
                reloadDatabases([new_document]     , "documentos");
                
                if(data[0]){

                    
                    console.log(user.CONFIGURACIONES.GENERALES)
                    if( user.CONFIGURACIONES.GENERALES &&  user.CONFIGURACIONES.GENERALES.habilitarImpresionLocal &&  user.CONFIGURACIONES.GENERALES.habilitarImpresionLocal == 1 ) {

                        let  facturaImprimir2 = new_document
                        let user2 = {
                            ESTACION : user.ESTACION
                        } ;
                        console.log(facturaImprimir2);
                        imprimirLocalmente( {facturaImprimir2 , user2}  );
                        return false;

                    }else{

                        setFacturaImprimir(new_document);
        
                        setTimeout( () => {
                            prindiv('EasyPrintAnd' ,  { extraCss : './tirilla.css' } );
                        }, 500)
                
                        setTimeout(() => {
                            console.log("listo")
                            setFacturaImprimir(null);
                        }, 2000)
                        
                    }



        
                }
            }
        }   
    }

    const imprimirLocalmente = async (data) => {
        try {
            const response = await fetch('http://localhost:3001/imprimir', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                
            },
            'body' :  JSON.stringify(data)
            });
        
        
            console.log(response); // Mensaje de la aplicación de escritorio

        } catch (error) {
            console.error('Error al imprimir:', error);
        }
    };

    const   inicializarOservador = () => {
        
        /*** Ultimo Key */
        let lastIdactual = getLocalstore("firebaseLastKeyProducto");
        if ( !(Number(lastIdactual) > 0) ){
            NotificationManager.warning("No tenemos key para Sincronizar");
            return false;
        }
        
        onValue(recentPostsRef(), (snapshot) => {
            
            console.log("Observador instanciado" )
            const obj = snapshot.val();
            console.log(  Number(lastIdactual) +  ">" + obj.lastId )     
            if(  obj.lastId  >  lastIdactual ) {
                console.log("actualizar");
                reproducirSonido();
                /*** Genera un cofigo unico  */
                SetcodigoSincronizacion(`${Math.floor(Math.random() * 10000) + 1}${String.fromCharCode(Math.floor(Math.random() * 26) + 65)}`);

                
            }         
        })
  
    }


    const reproducirSonido = async () => {    
        try {
            const audio = new Audio(audioFile);
            await audio.play();
        } catch (error) {
            console.error('Error al reproducir el audio:', error);
        }
    };




    /**
     * Verifica los Tab en el local Store Luego los Asigna al InitialState de Lista Document
     */
    const seleccionarListadeDocumentos = () => {
        let  listdocument = localStorage.getItem('listdocument');


        if( listdocument ){
            initialStateDocumentLista =  JSON.parse(listdocument);   
            console.log(initialStateDocumentLista[initialStateDocumentLista.length-1].tabKey);
            setTabIndexActual( initialStateDocumentLista[initialStateDocumentLista.length-1].tabKey ) ; 

        }else{

            localStorage.setItem('listdocument' , JSON.stringify(initialStateDocumentLista) );
        }
        setDocumentList(initialStateDocumentLista);
    }



    /*** Actualizaremos la TAB <<Actual>> desde LocalStorage  */
    const seleccionarTabActual = () => {
        let  aux = localStorage.getItem('activeTab');
        if(aux){
            setActiveTab(JSON.parse(aux) );
        }else{
            setActiveTab(initialActivaTab);
        } 
    }



    
    
    /*** Agregar el tab si no Existe en la Key Store */
    const validarPrimeraKey  = () => {
        

        let keyPrimerDocumento = initialStateDocumentLista[0].keyLocalStogere;

  
        let document_1 = localStorage.getItem( keyPrimerDocumento );
        if( !document_1 ){
            ResetearTodo();
        }
        
       
    } 
    


       /*** Agregar una nueva Tab en el LocalStore */
       const addNewDocument = () => {
        
 
        let auxTab = TabIndexActual + 1 ;

        setTabIndexActual( auxTab );

        let aux = {
            keyLocalStogere : "documente_"  + auxTab,
            precio : 0,
            tabKey : auxTab,
            color_documento : "principal",
            etiqueta_corta : "Factura POS"
        }

        /*** Lista de TABS */
        let update = [...documentList , aux  ];

        console.log(update);

     
         

        iniciarDocumento( aux.keyLocalStogere );
        
        setDocumentList(update);
        updateLocalStore(update);
        setActiveTab(aux);
        localStorage.setItem('activeTab' , JSON.stringify(aux) );  
        
    }
    

    /***
     *  Inicia El primero Documento
    */
   const iniciarDocumento = (KEY_LOCALSTORE) => {

        let initial = getExtraInitial();
        console.clear();
        const aux = SetinitialStateDocumento();
        const copiaDC  = {...aux , ...initial}
        localStorage.setItem(KEY_LOCALSTORE , JSON.stringify(copiaDC) );
   }


    const resetListDocument = () => {

        confirmAlert({
            title: '¿Esta Seguro de Continuar?',
            message: '',
            buttons: [
              {
                label: 'Si',
                onClick: () => ResetearTodo()
              },
              {
                label: 'No',
                onClick: () => console.log("No")
              }
            ]
        });



        
    }


    /**
     * Resetea todos los active
     */
    const ResetearTodo = () => {
        
        setDocumentList(initialStateDocumentLista);
        updateLocalStore(initialStateDocumentLista);
        setTabIndexActual(0);

        
        /*** Inciciamos ACtive Tab State  */
        setActiveTab(initialActivaTab);

        /** Iniciamos El Active tab en Local Store al Inicial  */
        localStorage.setItem('activeTab' , JSON.stringify(initialActivaTab) );

        documentList.forEach( (data) => {
            localStorage.removeItem(data.keyLocalStogere);
        } )

        iniciarDocumento( initialStateDocumentLista[0].keyLocalStogere  )
      
    }

    /**
     * Elimina una pestaña Actual
     */
    const eliminarPestana =  (Tab) => {
      
        console.log(Tab);

        
        let  listdocument = getLocalstore(Tab.keyLocalStogere);

        console.log(listdocument.MATEMATICAS);


        const respuestaSi = () => {

            const tareasActualizadas = documentList.filter(tabLi => tabLi.tabKey !==  Tab.tabKey);
        
            setDocumentList(tareasActualizadas);
    
            updateLocalStore(tareasActualizadas);
            
            let ultimoTab = tareasActualizadas[ tareasActualizadas.length - 1  ];
            setActiveTab(ultimoTab);
    
            localStorage.removeItem(Tab.keyLocalStogere);
        }

        if(listdocument  && listdocument.MATEMATICAS &&  listdocument.MATEMATICAS.SUBTOTAL_3  > 0  ){
            
        }else{
            respuestaSi();
            return false;
        }

        confirmAlert({
            title: '¿Esta Seguro de Continuar?',
            message: '',
            buttons: [
              {
                label: 'Si',
                onClick: () => respuestaSi()
              },
              {
                label: 'No',
                onClick: () => console.log("No")
              }
            ]
    });
    


    }




    const updateLocalStore = (data) => {
        localStorage.setItem('listdocument' , JSON.stringify(data) );
    }



    const cargarDocumentByIndex = (document) => {
        Setshowinput(false);
        setActiveTab(document);
        /*** Se guardara en el Local Store el Tab Actual para cuando Exista un Refersh */
        localStorage.setItem('activeTab' , JSON.stringify(document) );
        console.clear();
        console.log("------- Se Disparo nueva TAB ---- ");
        console.log(document);
    }





   const  actualizarTab =  ({color_documento , etiqueta_corta}) => {

        let __colordocumento = (color_documento) ? color_documento : activeTab.color_documento;

        let copiaTab = {
            ...activeTab , 
            "color_documento" : __colordocumento , 
            "etiqueta_corta"  : etiqueta_corta
        }

        setActiveTab(copiaTab);
        
        const tabActualizada = documentList.map( tab => {

            if ( tab.tabKey ===  copiaTab.tabKey ){
                tab  = copiaTab;
            }
            return tab;
        });
        setDocumentList(tabActualizada)
        updateLocalStore(tabActualizada);

        /*** Se guardara en el Local Store el Tab Actual para cuando Exista un Refersh */
        localStorage.setItem('activeTab' , JSON.stringify(copiaTab) );
    }














    


 

    







     
    /*   
    return (
        <div className='mt-5'>
            <Ejemplo />
        </div>
     );
        */
    


    /***
     * 
     * Logica para el Renombrado de Pestaña 
     * 
     **/ 
    const [showinput , Setshowinput] = useState(false);

    const handleInput = (e) => {
        console.log(e.target.value);
        if(e.keyCode == 13){
            Setshowinput(false);

            if(e.target.value.length > 0){
                actualizarTab({etiqueta_corta:e.target.value})
            }
           

        }
    }    

    const refInput = useRef();
    const handleShoinput = () =>{

        Setshowinput(!showinput);
        setTimeout(() => {
            refInput.current.focus();
          }, 100);
        
    }


    
    /*** Esta Variable controla si se muestra los datos adicionales */
    const [ showmesa , setShowmesa ] = useState(null);
    
    const funshowmesa = (aux = null ) => {
     
        if ( aux == false || aux == true ){
           
            setShowmesa(aux);
            return false;
        }
        setShowmesa(!showmesa);
    }



    return (
        <>

         
            <div className={  'bg-' +  activeTab.color_documento  + '  contenedor-nav d-none d-sm-block' } >
                <ul className="nav nav-documento nav-tabs nav-tabs-documento">         
         
                    
                    <div className='pestanas_nav' >

                    { documentList.map( (document ) => 
                        <li id={document.id} key={document.keyLocalStogere}  className= {"nav-item nav-item-documento position-relative"}> 

                        { (  document.tabKey !==  0 &&  activeTab.tabKey ===  document.tabKey)   && 
                            <>
                                
                                { showinput && <input ref={refInput} onBlur={() => Setshowinput(false)} onKeyDownCapture={handleInput} style={{maxWidth:"50px" , position:"absolute"}} type="text" /> }
                                
                                { !showinput && <span onClick={handleShoinput} role='button'  className='bg-primary position-absolute  '><i className='fa fa-pencil'></i>  </span>}
                            
                            </>
                        }
                    
                        <button id={document.keyLocalStogere} onClick={() => cargarDocumentByIndex(document) } className={ "nav-link nav-link-documento" + (activeTab.tabKey === document.tabKey  ?  " active-" +  activeTab.color_documento   : "")   }   aria-current="page"> 
                            <span className='nav-link-titulo'>
                                {document.etiqueta_corta}
                            </span>  
                            <span className='nav-link-total' id={"TOTAL_" + document.keyLocalStogere } ></span>
                        </button>
                        
                            { (  document.tabKey !==  0 &&  activeTab.tabKey ===  document.tabKey)   &&  <button type="button" onClick={ () => eliminarPestana( document )} className="btn-close position-absolute top-0 end-0  btn-close-venta " title="Cerrar Documento" aria-label="Close"></button>  }
                    
                        </li>
                        )
                        }
                        
                        { mostrar_pestaneo === 1 && <>
                           
                            <span className="mt-1 link-nav-pt " onClick={addNewDocument} > <i className="fa-solid fa-plus"></i> </span>

                            <span className="mt-1 link-nav-pt" onClick={resetListDocument} > <b>Resetear</b> </span>


                        </>}
                   

                        {user.CONFIGURACIONES_TABLE.restaurante == 1  &&  <span  onClick={() => setShowmesa(!showmesa)}> 
                            {!showmesa && <span className='ms-3 btn btn-primary'>Mesas <i className="fa fa-cutlery" aria-hidden="true"></i></span> }
                            {showmesa && <span className='ms-3
                             btn btn-principal'>Factura <i className="fa fa-cutlery" aria-hidden="true"></i></span> }
                        </span>}

                    </div>    
                </ul>
            </div>
     

         


 
        
            <DocumentProvider  initialState={initialDocument} reducer={documentReducer}  >
                    <Documento showmesa={showmesa} funshowmesa={funshowmesa} actualizarTab={actualizarTab } TabIndexActual={activeTab} dispatchGlobal={dispatchGlobal} CodigoSincronizacion={CodigoSincronizacion}   />        
            </DocumentProvider>

                            
            

             {/** Reimprime la factura electronica con su Codigo QF */}
            {  facturaImprimir  &&  <FacturaTirilla  documento={facturaImprimir} user={user}  />}

       
       
        </>
    );
}

export default ListaAllDocument;