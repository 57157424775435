import React from 'react';

const SwitchProductos = ({formData}) => {
    return (
        <div className="mt-5">
            <div><b>Informacion a mostrar</b></div>

            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name="switch_mostrar"
                    id="flexRadioDefault1_"
                    value="1"
                    checked={formData.switch_mostrar === "1"}
                    onChange={() => {}}
                />
                <label className="form-check-label-success" htmlFor="flexRadioDefault1_">
                    Documentos. 
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input"
                    type="radio"
                    name="switch_mostrar"
                    id="flexRadioDefault2_"
                    value="0"
                    checked={formData.switch_mostrar === "0"}
                    onChange={() => {}}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2_">
                    Items.
                </label>
            </div>
        </div>
    );
};

export default SwitchProductos;