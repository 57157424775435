import React,{useState} from 'react';

const ThPrecioCompra = ({user, LogicaDocumento  , documento }) => {

    const  [ListaPrecioActualLiquidacion , SetListaPrecioActualLiquidacion] = useState(); 

    const  [showListaPre , SetshowListaPre] = useState();

    const  lista_precio_activa_compra = documento.ESTRAS.COMPRA && documento.ESTRAS.COMPRA.lista_precio_activa_compra ? documento.ESTRAS.COMPRA.lista_precio_activa_compra : 0;
    
   


    /*
    ESTRAS : {
        lista_precio_activa_compra : 0
        listaPrecio : [         
        ]
    */
    const handleChangeListPrecio = (id) => {
        const  infoPrecio = user.DATABASE.ct_lista_precios.find( data => data.id == id );
        
        if(infoPrecio){     
            LogicaDocumento.despachador('CambiarPrecioVentaShowCompra' , infoPrecio.id );
        }else{
            LogicaDocumento.despachador('CambiarPrecioVentaShowCompra' , "0" );
        }
    }

    const nombrePrecio = () => {

      
        const  infoPrecio = user.DATABASE.ct_lista_precios.find( data => data.id == lista_precio_activa_compra );
        if(infoPrecio && infoPrecio.nombre){
            return  infoPrecio.nombre;
        }
        return "Precio Principal"
    }
    return (
        <span>
                    
            Precio  
            {
                (  user.CONFIGURACIONES_TABLE.switch_modo_precio &&  user.CONFIGURACIONES_TABLE.switch_modo_precio ==  2   ) && <>

                                     
                        
                        <select defaultValue={lista_precio_activa_compra} onChange={(e) => handleChangeListPrecio(e.target.value)} className='form-select form-select-sm'>
                            
                            <option value={"PRECIO_PRINCIPAL"} >Precio Principal</option>
                            {user.DATABASE.ct_lista_precios.map((data, index) =>
                                <option value={data.id} key={index}>{data.nombre}</option>
                            )}
                        </select>
                        <span style={{fontSize:10}} className='fs-10 d-block text-dark'>{ nombrePrecio() }</span>         
                </>
            }

    
           

        </span>
    );
};

export default ThPrecioCompra;