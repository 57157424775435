import React from 'react';

import * as XLSX from 'xlsx'; // Librería para exportar a Excel
import prindiv from 'easyprintand';

// Componente que recibe los pedidos seleccionados y genera la factura unificada
const FacturaUnificada = ({ pedidosSeleccionados }) => {
  // Verifica si se pasó el array de pedidos
  if (!pedidosSeleccionados) {
    return null;
  }

  // Función para unificar los productos de los pedidos
  const unificarProductos = () => {
    let productosUnificados = [];

    // Iteramos sobre cada pedido
    pedidosSeleccionados.forEach(pedido => {
      pedido.PRODUCTOS.forEach(producto => {
        // Buscamos si ya existe el producto en el arreglo unificado
        const index = productosUnificados.findIndex(p => p.id === producto.id);

        if (index !== -1) {
          // Si ya existe, agregamos la cantidad
          productosUnificados[index].cantidad += producto.MATEMATICAS.CANTIDAD;
        } else {
          // Si no existe, lo agregamos como nuevo producto
          productosUnificados.push({
            ...producto,
            cantidad: producto.MATEMATICAS.CANTIDAD,
          });
        }
      });
    });

    return productosUnificados;
  };

  // Función para obtener los detalles del cliente
  const obtenerCliente = () => {
    const cliente = pedidosSeleccionados[0]?.TERCERO;
    return cliente || {};
  };

  // Función para obtener el vendedor
  const obtenerVendedor = () => {
    const vendedor = pedidosSeleccionados[0]?.FORM?.nombre_vendedor;
    return vendedor || 'Desconocido';
  };

  // Unificamos los productos de los pedidos
  const productosUnificados = unificarProductos();

  // Calculamos el total de la factura
  const totalFactura = productosUnificados.reduce((acc, producto) => acc + producto.total, 0);

  // Obtener datos del cliente y vendedor
  const cliente = obtenerCliente();
  const vendedor = obtenerVendedor();

  // Función para manejar la impresión
  const handlePrint = () => {
    prindiv('EasyPrintAnd_');
  };

  // Función para exportar a Excel
  const handleExportToExcel = () => {
    // Crear una hoja de Excel
    const ws = XLSX.utils.table_to_sheet(document.getElementById('factura-table'));
    
    // Crear un libro de Excel con la hoja
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Factura Unificada');
    
    // Exportar el libro a un archivo .xlsx
    XLSX.writeFile(wb, 'Factura_Unificada.xlsx');
  };

  return (
    <div className="factura mt-5">
      <div className="card p-3">
        <div>
          <button onClick={handlePrint} className="me-2 btn btn-primary">
            Imprimir
          </button>

          {/* Botón para exportar a Excel */}
          <button onClick={handleExportToExcel} className="btn btn-success">
           Copiar a Excel
          </button>
        </div>

        <h1>Factura Unificada</h1>

        {/* Contenido de la factura */}
        <div id="EasyPrintAnd_">
          <div className="vendedor">
            <p><strong>Vendedor:</strong> {vendedor}</p>
          </div>

          {/* Tabla de productos unificados */}
          <table id="factura-table" className="table">
            <thead>
              <tr>
                <th>Codigo</th>
                <th>Producto</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {productosUnificados.map((producto) => (
                <tr key={producto.id}>
                  <td>{producto.id_2}</td>
                  <td>{producto.nombre}</td>
                  <td>{producto.cantidad}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FacturaUnificada;
