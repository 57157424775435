import React from 'react';
import PedidoIndividual from './PedidoIndividual';
import { useState } from 'react';
import { useEffect } from 'react';

const PedidoByMesa = ({imprimirPreFactura , mesanombre , mesa_id, pedidos , getEstadoMesa, user, eliminarCuentaAbierta, cargarDocumento , seleccionarMesa}) => {
    

   

    const [estadoMesa, setEstadomesa ] = useState();

    useEffect( () => {

        let filter = pedidos.filter((pe,index) => pe.FORM.mesa_id == mesa_id );
        console.log(filter)
        if(filter.length  > 0){
            getEstadoMesa(1);
            setEstadomesa(1);
        }else{
            getEstadoMesa(0);
            setEstadomesa(0);
        }
       

        

    } , [])

    if(!pedidos) return (<p>LIBRE</p>)

    return (
        <>
            {pedidos.map((ped,index) => 
                <span key={index} className=''>
                    { ped.FORM.mesa_id == mesa_id && 
                    
                        <PedidoIndividual imprimirPreFactura={imprimirPreFactura} user={user} eliminarCuentaAbierta={eliminarCuentaAbierta} duc={ped}  cargarDocumento={cargarDocumento}/>
                        
                    }


                </span>    
            )}
            { estadoMesa == 0 &&   <button onClick={() => seleccionarMesa(mesa_id+ "/" + mesanombre) } className='btn btn-link'  >Seleccionar</button> }
        </>
    );
};

export default PedidoByMesa;