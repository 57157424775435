import React, { useEffect, useState } from 'react';
import '../../../estilos/form-validacion.css'
import OptionSelect from '../../../Core/Html/OptionSelect';
import TagsIds from './TagsIds';

const InputCodigo = ({  deleteCodigo , AsignarCodigo    , label , formData , btnbmitLoad , placeholder, type , id ,   className , nameForm , formDataValidations, defaultValue}) => {


  
    const dangerInput = { 
        'border' : 'solid #dc3545',
    }
    const textDanger = { 
        'color' : '#dc3545',
    }


    const successInput = { 
        'border' : 'solid #00d336',
    }
    const textSuccess = { 
        'color' : '#00d336',
    }


   



    const [ estilosInput , setEstilosInput ] = useState();
    const [ estilosText , setEstilosText ] = useState();

    const [ mensaje , setMensaje ] = useState();

    const [ estadoError , SetestadoError ] = useState(null);


    const [switchCodigo, setswitchCodigo] = useState(false);
    useEffect( () => {

       

        try {
            if(formDataValidations ){

                if( formDataValidations[nameForm] &&  formDataValidations[nameForm] && formDataValidations[nameForm].res === true  ){
    
                    setEstilosInput(successInput);
                    setEstilosText(textSuccess);
                }else{
      
                    if( formDataValidations[nameForm] ){
                
                        setEstilosInput(dangerInput);
                        setEstilosText(textDanger);
                        SetestadoError(true)
                    }
                    
                }
            }
            if ( formDataValidations[nameForm].mensaje   ){
                setMensaje( formDataValidations[nameForm].mensaje );
            }
        } catch (error) {console.log("No podemos validar")};





    } , [formDataValidations] )




    const quitarError = () => {
        setEstilosInput(null);
        setEstilosText(null);
        setMensaje(null);
        SetestadoError(null)
    }

    const [ input , SetInput ] =  useState("");

    const handlecapture = (event) => {

        
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            // Ejecutar la acción aquí
            console.log('Se presionó Enter o coma');
            // Limpia el valor del input
            if(input.length > 0){
                AsignarCodigo(input)
            }
            
            SetInput('')
            return false;
    
        }

        SetInput(event.target.value)
    }

    const disparadorCambio = (e) => {
        let valor = e.target.value;
        let signo = valor.slice(-1)

        if ( signo === ',' ) {
            console.log('Se presionó ,,');
            
          
        }else{
            SetInput(valor);
            
            
        }
         

    }

    try {
         
        if( formData[nameForm] === undefined && formData[nameForm] ===  null  ){
            console.log( formData[nameForm]);
            return (<>"No exite"</>)
        }else{
            
        
        }

    } catch (error) {
        console.log( formData);
        console.log( nameForm );
    }

    
    
    if( true){
        return (
            <div className="form-group ">

                <div className="form-check form-switch  ">  
                    <input onChange={() => setswitchCodigo(!switchCodigo) } value={switchCodigo} className="form-check-input " type="checkbox"  /> {!switchCodigo  && <>Multiples-Codigos</>} 
                </div>


                {switchCodigo &&
                    <>
                         <label style={estilosText}   htmlFor=""><b>Ingrese un código y presione Enter para agregarlo  </b></label>
                        <input className='form-control'  value={input} style={estilosInput} type="text" onChange={(e) => disparadorCambio(e)}  onKeyDownCapture={ (event) => handlecapture(event) }  />
                        <label htmlFor="" className='mb-2' style={{fontSize:10}} >Ingrese un Codigo y presione enter para agregar varios codigos. </label>
                        <TagsIds ids={ formData.id_2 } deleteCodigo={deleteCodigo} />
                    </> 
                }
                


                {!switchCodigo &&
                 <>
                    <label style={estilosText}   htmlFor=""><b>{label}</b></label>
                    <input style={estilosInput}   value={formData[nameForm]}    onChange={ () => { quitarError() } } autoComplete='off' disabled={btnbmitLoad} type={type} id={id} className={ estadoError ? 'input-error ' + className  : className   } name={nameForm}  placeholder={placeholder}  />
                </>
              
            }
                


               

                
                { mensaje   &&  <span style={estilosText} >  {mensaje}  </span>}
            </div>
        );
    }








};

export default InputCodigo;