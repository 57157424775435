import React, { useEffect, useState } from 'react';
import Header from './Components/Header';
import Navegador from './Components/Navegador';
import Buscador from './Components/Buscador';
import Filtros from './Components/Filtros';
import Opciones from './Components/Opciones';
import Table from './Components/ListTable/Table';
import { useStateValue } from '../../../context/StateProvider';
import { fetchEmpresa } from '../../../Core/fetchLocalStoreData';
import Buscaripi from '../../../utils/ripi';
import { NotificationManager } from 'react-notifications';
import { ExtraerTabla, reloadDatabases } from '../../../Core/Dixie';
import { HelpHttp } from '../../../Core/https';
import { HttplistarClientes, listarClientes } from '../Core';

let  Ripi_prIndex = null;

const Pages = () => {

    
    const [ {user , localconfig } ] = useStateValue();

    const ConfigEmpresa = fetchEmpresa();

    const [ terceros , setTerceros ] = useState([]);

    const [ tercerosfiltro , setTercerosfiltro ] = useState([]);

    const [ buscar , setBuscar ] = useState('');

    const handleChange = (e) => {
        console.log(e)
        setBuscar(e.target.value)
    }

    

    
    let Json = {
        "URL" :  localconfig.global.servidor,  
        "nit" :  ConfigEmpresa.nit      
    }

    
    useEffect( () => {
    


        /** Prepara los datos para el ripi.js  */
        const renderizar = (data) => {
            Ripi_prIndex  = new Buscaripi(data);
            setTercerosfiltro(data);
            Ripi_prIndex.fullResultado = true;
            setTerceros(data)
        }

        const getDatos = async () => {

            let tercerosDixie = await   ExtraerTabla('terceros');
            if(tercerosDixie.length > 0){
               
                console.log("...loading from localstore");
                renderizar(tercerosDixie);
            }else{
                obtenerDatosPHP();
            }
            
        }
        getDatos();

    } , [])


    const  [cargando , setCargando] = useState(null);

    const obtenerDatosPHP = async () => {
        
        setCargando(1);
        console.log("...loading from php");
   
        let Httpdata = await HttplistarClientes();
        if(Httpdata !== -1){
           
            setTimeout(() => {
                setCargando(null); 
                NotificationManager.success('Clientes en total: ' + Httpdata.length , 'Éxito Sincronizado');
            }, 3000);
            
            reloadDatabases(Httpdata , 'terceros');
            setTerceros(Httpdata)
            Ripi_prIndex  = new Buscaripi(Httpdata);  
            setTercerosfiltro([]);
            Ripi_prIndex.fullResultado = true;
        }   
    }

         
























    useEffect( () => {
        
        if (Ripi_prIndex){
    
            if(buscar.length > 0 ){

                let data = Ripi_prIndex.start(buscar , ["identificacion" , "nombrecomercial" , "razonSocial" , "telefono"   ] );
                setTercerosfiltro(data);
            
            }else{

                setTercerosfiltro(terceros);
            }
           
        }
        
    }  , [buscar ]);

    const actualizarTodo = ( ) => {
        
        obtenerDatosPHP();
    }




    return (
        <div className="">

            <div className="mb-9">
               
               
               
                <Header cantidad={terceros.length} />



               { false && <Navegador /> }

                <div id="products" >
                    <div className="mb-4">
                        <div className="row g-3">
                            
                            
                            <Buscador buscar={buscar} handleChange={handleChange}/>
                            
                            {false && <Filtros /> }

                            {false &&  <Opciones />}

                            {cargando === 1 && <p>Cargando...</p>}
                            <Table dataList={tercerosfiltro} user={user}/>

                        </div>
                    </div>
                </div>
            </div>
            {/* Add the rest of the content here */}
        </div>
    );
}


export default Pages;