import React , { useEffect , useState , useRef} from 'react';
import { ExtraerTabla , reloadDatabases } from '../../Core/Dixie';
import Buscaripi from "../../utils/ripi";
import { __app } from "../../utils/myApp";
import {HelpHttp} from "../../Core/https";

import { fetchLocalconfig, getLocalstore, setLocalStore } from "../../Core/fetchLocalStoreData";
import StandarModal from './PartesDocument/StandarModal';

import NuevoItem from "./PartesDocument/NuevoItem";
import {SincronizarNivelBajo , SincronizarTodo, SumarImpuestosPrecio} from  '../Productos/Controlador';
import { NotificationManager} from 'react-notifications';
import  Index   from '../Productos/Index';
import ListaCategorias from './PartesBuscadorItems/ListaCategorias';
import HistorialSincronizados from '../Productos/HistorialSincronizados';
import SincronizarProductos from '../Productos/SincronizarProductos';



import ErrorBeeb from '../../recursos/buzzer-4-183895.mp3';

import ProductContextMenu from './PartesDocument/ProductContextMenu';
import Add from '../Productos/Add';
import FiltroBuscador from './PartesBuscadorItems/FiltroBuscador';
import Inventario from '../Productos/Inventario';
import VerInventarioVariable from '../Productos/PartesFormAdd/Inventario/VerInventarioVariable';
import ErrorCodigoNoencontrado from './ItemAlls/ErrorCodigoNoencontrado';


let  Ripi  = null;




const  ItemsAll = ({ CodigoSincronizacion  , agregarItemPadre , user , hanldleComandos , focoTeclado , setFocoTeclado})  => {

    console.log("ABCDEF REdericaciones")

    const  activeTab = getLocalstore("activeTab");

    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const  $buscadorPrincipal = useRef();
    const  $inputCheck = useRef();
    

    const habilitar_sincronizacionrapida = ( user.CONFIGURACIONES_TABLE.habilitar_sincronizacionrapida ) ? user.CONFIGURACIONES_TABLE.habilitar_sincronizacionrapida : 1 ;
    const habilitar_sincronizacionFull = ( user.CONFIGURACIONES_TABLE.habilitar_sincronizacionFull ) ? user.CONFIGURACIONES_TABLE.habilitar_sincronizacionFull : 0 ;
    const agregarProducto = ( user.PERMISOS.agregarProducto ) ? user.PERMISOS.agregarProducto : 0 ;

    const desabilitarMasMenos = ( user.CONFIGURACIONES_TABLE.desabilitarMasMenosEnBuscador ) ? user.CONFIGURACIONES_TABLE.desabilitarMasMenosEnBuscador : 0 ;

    const teclaComponente = 'F1';

    const  [ productos , setProductos ] = useState([]);
    const  [ inputbuscar , setInputBuscar  ] = useState("");
    const  [ filtroProductos , setFiltroProductos  ] = useState(null);
    const  [ productosFavoritos , setProductosFavoritos] = useState([]);

    const  [ viewFormCategoria , SetviewFormCategoria] = useState(0);

    const [ cargaRipi , setCargaRipi  ] = useState();
    const [ cargandoBusqueda , SetcargandoBusqueda  ] = useState();

    const [ sinCodigo , SetSinCodigo  ] = useState();

    /*** Esta variable sirve para establecer si vamos a buscar el producto por nombre o por codigo, cuando se busca por codigo de una vez
     * se agrega a la lista de productos
     */

    
    
    const getLocalStoreSitch = () => {
        let data =  localStorage.getItem('swich_busqueda_item');
        let aux = true;
        if(data){
            if(data === "false"){
                aux = false;
            }
        }else{
            localStorage.setItem('swich_busqueda_item' , aux );
        }  
        console.log(aux)
        return aux;
    }

    const [ busquedaDirecta , setBusquedaDirecta ] = useState( getLocalStoreSitch() ); 


    const [ fila  , setFila ] = useState(-1);

    /*** Muestra el formulario de un nuevo producto */
    const [showFormProducto ,  setShowFormProducto] = useState(false);


    /*** Producto Accionado
     * Esta Variable Permite saber cuando se uso la tecla enter, para evitar mostrar 
     * LA palabra buscada a meno s de que se de enter
     */
    const [ accionBuscar , setAccionBuscar ] = useState(false);







    const [ showFullProductos , SetshowFullProductos] = useState(false);


    const [ showHistorialSincronizacion , SetShowHistorialSincronizacion] = useState(false);


    const [ showformSincronizarTodo  , SetshowformSincronizarTodo ] = useState(false);
    

    const [ showFormProductoNuevo  , setShowFormProductoNuevo ] = useState(false);

    
    const [ showFavoritos , SetshowFavoritos] = useState();


    const [ showInventario , setShowInventario] = useState();

    const [ showInventariovariable , setShowInventariovariable] = useState();
    

    /*** 
     *  Sincroniacion Automatica
     *  Si el observador esta Activo sincronizara todos los productos automaticamente, cuando derecte un cambio, */
    const [estadoObservaodor , setestadoObservaodor ] = useState(false);


    const [ filtroBusquedaRipi , SetfiltroBusquedaRipi ] = useState(getLocalstore("ConfiguracionBuscadorRipiPrincipal") );
    /**
     *  CONSTRUCTOR
     *  
     *  1)Llama los productos instanciados localmente 
     *     De no encontrarlos los traer de la base de fatos
     * 
     *  2) Un Observador con Google RealtimeDataBases, para agragarlo actuomaticamente cada vez que exista un cambio en la base de datps local
     * 
     * 
     *  
     */
    
    const getDatos = async () => {
        console.log("getDatos()")
        renderizarRipi([]);
        setCargaRipi(1);
        let productosDixie = await   ExtraerTabla('productos');
        if(productosDixie.length > 0){
            console.log("ESTOS SON LOS PRODUCTOS DEL DIXIEEE : " + productosDixie.length)
  
            renderizarRipi(productosDixie);
            
            setCargaRipi(null);    
            let favo = filtrarFavoritos (productosDixie)
            
           
            setFiltroProductos( favo);
            SetshowFavoritos(true);
            setProductosFavoritos(favo);
            
        }else{
            console.log("No se encontraron productos de manera DIXIEEE")
            setCargaRipi(null);
            //sincronizarTodoFromServer();
        }

        return productosDixie.length;
        
    }

    useEffect( () => {

     

        /*** Estore Swiych de buscador, para buscar por nombre o por Codigo */
        getLocalStoreSitch()

        
        /*** Carga los producto de manera LOCAL y los monta en el Ripi */
        getDatos();
     
    } , [])



    /*** Sereinicia el buscador cada vez que hay un nuevo producto */
    useEffect (() => {
        getLastUpdateProductos();
    }, [CodigoSincronizacion])




    const ErrorSonido = async () => {    
        try {
            const audio = new Audio(ErrorBeeb);
            await audio.play();
        } catch (error) {
            console.error('Error al reproducir el audio:', error);
        }
    };




    const filtrarFavoritos = (productos) => {
    
        return productos.filter( (prodc) => prodc.favoritos == 1 );
    }


    /*** Actualizar Estado del boton de busqueda */




    /*** Instancia la Clase Ripi */
    const renderizarRipi = (data) => {
        setProductos([]);
        
        /*if(Ripi != null){
            Ripi = null ;
        }*/
        Ripi  = new Buscaripi(data);
    }

    /* Sincronizar todos los Productos de la base de datos */
    const sincronizarTodoFromServer = async () => {
        setFiltroProductos( []);

        let productos  = await SincronizarTodo();
        
        if(productos ){
           
            NotificationManager.success("Sincronizados con Exito");
            setauxLoadingSincro(null);
            renderizarRipi(productos);
            reloadDatabases(productos , 'productos' , 1 );
            reloadDatabases([] , 'actualizaciones' , 1 );

            let favo = filtrarFavoritos (productos)
            setFiltroProductos( favo);

        }else{
            NotificationManager.error("No hay productos o internet");



        }
    }


    /* Sincronizar ultimas actualizaciones */
    const getLastUpdateProductos = async () => {  
        
        function siguientepaso(){
            if(infoProductos &&   infoProductos.productos && infoProductos.productos.length > 0){

                Ripi.addNewObjets(infoProductos.productos);
                NotificationManager.info("Un producto se ha sincronizado con éxito!");
                setFiltroProductos([]);

            } else{
                console.log("no encontramos productos")
            }
    
    
            if( infoProductos  &&   infoProductos.idsDelete && infoProductos.idsDelete.length > 0  ){
                console.log("Eliminando");
                Ripi.eliminarIds(infoProductos.idsDelete);
            }
        }

        console.log(Ripi);
        let infoProductos = await SincronizarNivelBajo();

        console.log(infoProductos);

        if(Ripi.ArrayPrincipal.length > 0){
            siguientepaso();
        }else{
            setTimeout( () => {
                siguientepaso();
            }, 5000)
        }

        
    }





    /*** En caso de que se haga una Ediccion y el Ripo no lo vea, se debe compronbar Si la variable de la seesion Store no esta modificada */
    const verificarReloadDatosDixe = async () => {

        let resul = getLocalstore('DixieItemsReload');
        if(resul && resul == 1){
         
            setLocalStore('DixieItemsReload' , "0");
            return  getDatos();
           
        }

        return 0;

    }




    /*** Opciones Directas del Documento */
    const establecerFocus = () => {

        
        setShowFormProducto(false);
        setAccionBuscar(false);
        $buscadorPrincipal.current.focus();
        $buscadorPrincipal.current.select();
        setFocoTeclado('F1');
        setFila(-1);
    }



    
    
    const disparadorCambio = (e) => {
        let valor = e.target.value;

        // Comprobar si el último carácter es "+" o "-"
        let signo = valor.slice(-1)
        if ( (signo === '+' || signo  === '-') && desabilitarMasMenos === 0  ) {
            // Eliminar el último carácter si es "+" o "-"
            valor = valor.slice(0, -1);
            
            
            if(signo === '+'){
                aumentarCantidadItem();
            }

            if(signo === '-'){
                reducirCantidadItem();
            }

        }
        SetSinCodigo(null);
        setFila(-1);
        setInputBuscar(valor); 
        console.log(valor);
    }
  

    const aumentarCantidadItem = () => {




    }


    const reducirCantidadItem = () => {

        
    }


    const [ aumemtar , setAumemtar] = useState(10);




    const DispararkeyPress =  (e) => {
        //SetshowFavoritos(false);
        
        e.preventDefault();
        SetSinCodigo(null);
        setShowFormProducto(false);
        setAccionBuscar(false);
        SetviewFormCategoria(false);
        


        if(e.key === 'Enter'){


            verificarReloadDatosDixe().then(()=>{

                if(inputbuscar === "" || inputbuscar.length === 0 ){
                    //SetshowFavoritos(true);
                    //setFiltroProductos(productosFavoritos);
                    return false;
                }


                    
                if(inputbuscar === "+" || inputbuscar === "-"  ){
                    let x = inputbuscar
                    setInputBuscar("");
                    
                    //SetshowFavoritos(true);
                    //setFiltroProductos(productosFavoritos);
                    hanldleComandos(x);
                    return false;
                }

        




                setShowFormProducto(false);
                setAccionBuscar(true);

                /** 
                * 
                */
            

                if(fila >= 0){ 

                    if(!filtroProductos){
                        return false;
                    }

                    if(  filtroProductos[fila] ){
                        
                        agregarItem( filtroProductos[fila] );
                        //setFila(-1);
                        $buscadorPrincipal.current.select();
                        return false;
                    }

                
                }




                if( busquedaDirecta ){

                    let item  = Ripi.startDirecto(inputbuscar);

                    console.log(item);
                    
                     

                    if(item){
                        

                        if (/,/.test(item.id_2)) {

                            console.log("El campo contiene comas.");
                            
                            agregarItem({...item , id_2 :  inputbuscar });

                        }else{
                            agregarItem(item);
                        } 

                        setFiltroProductos([item]);
                        
                        setInputBuscar("");
                        
                    }else{
                        $buscadorPrincipal.current.select();
                        ErrorSonido();
                        setFiltroProductos(null);
                        SetSinCodigo(1);
                        setTimeout(() => SetSinCodigo(null) , 4000 )
                        //proponerRegistrarProducto();
                        
                    }

                    setFila(-1);
                    return false;
                }else{

                
                    setFiltroProductos([]);
                    SetcargandoBusqueda(1);

                    setTimeout( () =>{
                   
                        console.log(filtroBusquedaRipi)
                        let data = Ripi.start(inputbuscar , filtroBusquedaRipi );
                        SetcargandoBusqueda(null);
                        
                        
                        SetshowFavoritos(false);                    
                        setFiltroProductos(data);
                        setFila(0);
                        SetSinCodigo(null);
                        return false;
                        
                    } , 300)
                  
                }       
           })   
        }   


        if ( e.key === 'ArrowDown'  ){   

            if( focoTeclado === 'F1' ){
               
                let data = fila;
                data++;
    
                  
                if(filtroProductos.length - 1 >= data ){
                
                    setFila(data); 

                }else{
                    console.log( filtroProductos.length - 1 + " Es menor que " + fila )
                }
                e.preventDefault();
                return false;
            }
           
        }

     

        if( e.key === 'ArrowUp'   ){
            
            if( focoTeclado === 'F1' ){
                let data = fila;
                data--;
                if( data >= 0){

                    setFila(data);
                }else{
                    setFila(0);
                }

                e.preventDefault();
                return false;
            } 
        }

        if( e.key === 'ArrowLeft'   ){
            
            if( focoTeclado === 'F1' ){
                e.preventDefault();
                return false;
            }
             
        }
        




        if(inputbuscar === "" || inputbuscar.length === 0 ){
            //SetshowFavoritos(true);
            //setFiltroProductos(productosFavoritos);
            setFiltroProductos([]);
            return false;
        }
    }






    const proponerRegistrarProducto = () => {
        setShowFormProducto(true);
    }


    const [formFijo , setFormFijo  ] = useState(false);

    const activarNuevoProducto = ()  => {

        setShowFormProducto(true);
        setFormFijo(true);
    }

    const agregarItem = ( item  ) => {
        


        agregarItemPadre(item , null ,  $buscadorPrincipal );
        
        
        
        
        setFila(-1);
        $buscadorPrincipal.current.focus();
        $buscadorPrincipal.current.select();
  
    }


    const handleClickInputBuscar = () =>{ 
        setFocoTeclado('F1');
        $buscadorPrincipal.current.select();
        setFila(-1);
        
        return false;
        setInputBuscar("") ;
        if (busquedaDirecta  ){
            setInputBuscar("") ;
        }
       
        setShowFormProducto(false);
        //setFiltroProductos(productosFavoritos);
    }


    const [auxLoadingSincro , setauxLoadingSincro] = useState(null);

    const handleClickSincronizacionLow = async () => {  
          
        //let productos = await SincronizarNivelBajo();

        
        setauxLoadingSincro(1);
        sincronizarTodoFromServer();

        return false;
        let productos = await  sincronizarTodoFromServer();
        if(productos && productos.length > 0){
            NotificationManager.success("Sincronizados con Exito")
            setauxLoadingSincro(null);
            let productosDixie = await   ExtraerTabla('productos');
            renderizarRipi(
                
            );
        }else{
            console.log(productos);
            NotificationManager.warning("No encontramos")
            setauxLoadingSincro(null);
            //sincronizarTodoFromServer();
        }
        
    }


    const setBusquedaMan  = () => {
        setFocoTeclado('F1');
        setBusquedaDirecta( !busquedaDirecta )
        console.log( !busquedaDirecta)
        localStorage.setItem('swich_busqueda_item' ,  !busquedaDirecta );
        $buscadorPrincipal.current.focus();
        $buscadorPrincipal.current.select();
    }

    const getItem = (item)  => {
      //  SetshowFullProductos(false);
        agregarItem(item);
    }


    const [ nombreCategoria , setnombreCategoria  ] = useState();

    const filtroCategoria  = ({id , nombre}) => {

        setnombreCategoria(nombre);

        if(Ripi.ArrayPrincipal[0].productogrupo_id){
            
            SetviewFormCategoria(false);
       
            console.log(Ripi.ArrayPrincipal);

            let filtrocategoria =  Ripi.ArrayPrincipal.filter( (prodc) => prodc.productogrupo_id == id );

            console.log(filtrocategoria);


            setFiltroProductos(filtrocategoria);
            return false;
        }

        alert("Vuelva a intentarlo")
        sincronizarTodoFromServer();
         
       
    }

    const  favoritos = (data) => {

        if( !(data === true)){
         
            SetshowFavoritos(true)
            SetviewFormCategoria(false);

            console.log(productosFavoritos)
            setFiltroProductos(productosFavoritos);
        }else{
          
            SetshowFavoritos(false);
        }
        
    }


    


    const [ showclickDerecho , setshowclickDerecho ] = useState(null);


    const [ itemClickDerecho , setitemClickDerecho ] = useState(null);

    const handleContextMenuClose = () => {
        setContextMenuPosition({ x: 0, y: 0, index: -500 });
        setshowclickDerecho(null);
    };


    const OpcionesClickDerecho = (e , item, index) => {

        e.preventDefault();
        console.log(item , index);   
        setContextMenuPosition({ x: e.pageX, y: e.pageY, index });
        setshowclickDerecho(true);
        setitemClickDerecho(item)
    }
    
    const handleClickCategoria = () => {
        setFiltroProductos([]);
        setInputBuscar("");
        SetviewFormCategoria(!viewFormCategoria);

        setnombreCategoria("");
    }
















    useEffect(() => {
        const handleKeyPress = (event) => {

   
            
            

          switch (event.key) {
            case 'F1':
              // Lógica para la tecla F1
              event.preventDefault();
              handleF1();
              break;

  
            case 'F12':
                  // Lógica para la tecla F1
                event.preventDefault();
                setFiltroProductos([]);
                setInputBuscar("");
                break;

            // Agrega más casos según sea necesario para otras teclas de función
            default:
              break;
          }
        };
    
    

    
        const handleF3 = () => {
          // Lógica para la tecla F3
          console.log('Tecla F3 presionada');
        };
    
        document.addEventListener('keydown', handleKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
      }, []); // El segundo parámetro del useEffect asegura que el efecto se ejecute solo una vez al montar el componente.

      

      const handleF1 = () => {
            
        

        setFila(-1);
        const isBuscadorFocused = $buscadorPrincipal.current === document.activeElement;

        if (isBuscadorFocused) {
            console.log('$buscadorPrincipal tiene el foco al presionar F1');
            $inputCheck.current.click()
            $buscadorPrincipal.current.select();
        } else {
          console.log('$buscadorPrincipal no tiene el foco al presionar F1');
          $buscadorPrincipal.current.focus();
          $buscadorPrincipal.current.select();
        }

        //
    };  

 



    return (
        <>  
            <ProductContextMenu user={user}  position={contextMenuPosition} onClose={handleContextMenuClose} show={showclickDerecho} item={itemClickDerecho} /> 

        

            { cargandoBusqueda && <span className='ms-5'> cargando resutados de busqueda espere...</span> || <span className='ms-5'>Ready!</span>}
            <div className='contenedor-input'>
                
                <div className="form-check form-switch  ">  
                    <button className={`kbc-button kbc-button-xxs ${focoTeclado === 'F1' ? 'active' : ''}`}>F1</button>
                    <input  ref={$inputCheck} onClick={  setBusquedaMan } defaultChecked={ getLocalStoreSitch() } value={busquedaDirecta} className="form-check-input  form-check-input-buscar  " type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                </div>

               <div>
                    <input autoComplete='off'  id="inputBuscador"  onClick={ handleClickInputBuscar } ref={$buscadorPrincipal} onKeyDownCapture={ () => setAccionBuscar(false)}  onKeyUpCapture={DispararkeyPress} value={inputbuscar} onChange={disparadorCambio}  placeholder='Buscar Producto...' className='inputBuscador' type="text" />
               </div>
            </div>


            { !busquedaDirecta && <FiltroBuscador filtroBusquedaRipi={filtroBusquedaRipi}  SetfiltroBusquedaRipi={SetfiltroBusquedaRipi} /> }

            <div className='position-relative mt-4'>         
                <div className="  top-50 start-50 translate-middle btn-group " role="group" aria-label="First group">
                    
                
            

                    <div className="dropdown me-4">
                    
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fas fa-bars"></i>
                    </button>
                    <ul className="dropdown-menu">


                        {agregarProducto == 1 && <li><a className="dropdown-item" href="#" onClick={() => setShowFormProductoNuevo(true)} >Nuevo Producto</a></li>}
                        
                        { habilitar_sincronizacionFull == 1 &&
                            <li><a className="dropdown-item" href="#" onClick={() => SetshowformSincronizarTodo(true)}>Sincronizacion forzada</a></li>
                        }
                        
                        { habilitar_sincronizacionrapida == 1 &&
                        <li>
                            <a onClick={handleClickSincronizacionLow} type="button" className="dropdown-item">
                                { !auxLoadingSincro &&  <> Sincronizar Rapido  </> || <> Cargando <i className="fa-solid fa-arrows-spin fa-spin fa-spin-reverse"></i>  </> } 
                            </a>
                        </li>}
                        
                        <li><a className="dropdown-item" href="#"   onClick={() => SetShowHistorialSincronizacion(true)} >historial</a></li>

                        <li><a className="dropdown-item" href="#"   onClick={() => setShowInventario(true)} >Ver Inventario</a></li>


                       
                        
                        
                        { false &&  <li><a className="dropdown-item" href="#">Busqueda Full</a></li> }
                    </ul>
                    </div>

                
                    

                    

                    
                    {user.CONFIGURACIONES_TABLE.restaurante != 1  && 
                    
                        <button onClick={() => SetshowFullProductos(true)} type="button" className="d-none btn btn-principal">BUSQUEDA FULL  </button>
                    }
                        
              


                    <button onClick={() => handleClickCategoria() } type="button" 
                    
                    className={(!viewFormCategoria) ? " btn btn-outline-primary " : "btn btn-principal  "  }>Categorias  </button>


                    <span title='Ver Lista de Favoritos' onClick={() => favoritos(showFavoritos) } type="button" > <i className={  (!showFavoritos) ? " fa-regular fa-star  " : "  text-warning fa-solid fa-star "  }> </i>  </span>

                </div>
            </div> 
            

            {
                sinCodigo && <div className="mensaje-error destello">¡Error! CODIGO NO ENCONTRADO </div>
            }


            <div style={{height:'75vh'}} className="list-group  list-group-productos-items-all  shadow-sm  bg-body rounded" id='GRUPOOO' >

                {
                    nombreCategoria && 
                    
                    <>
                        <h5 className='text-center mt-5'> *** CATEGORIA {nombreCategoria} ***  

                        <button className='ms-2 btn btn-principal btn-sm' onClick={ handleClickCategoria }>
                             Atras
                        </button>

                        </h5>

                       

                    </>
                }


                { showFavoritos && <h5 className='text-center mt-2'> *** Lista de Favoritos  *** </h5>} 





                {
                    cargaRipi && 
                    <p>Preparando el Buscandor...</p>
                }
                
                {  ( filtroProductos && filtroProductos.length == 0  && accionBuscar && cargandoBusqueda != 1 && inputbuscar.length > 0 )  &&
                    <span  className='mt-3 p-3' >
                        No encontramos la resultados para   <b className='fs-5'> {inputbuscar}  </b> 
                    </span>
                }


                {
                   cargandoBusqueda == 1 &&
                    <p> <i className="fa-solid mt-5 ms-5 fs-2 fa-arrows-spin fa-spin fa-spin-reverse"></i></p>
                }


                {
                    viewFormCategoria && <span><ListaCategorias  filtroCategoria={filtroCategoria} /></span>

                }

                {
                   ( filtroProductos && filtroProductos.length ===  0 && nombreCategoria )  &&  <p className='p-3 ms-5 me-5 text-center mt-5 alert alert-warning'>No hay productos en esta categoria</p>
                }   


                { (filtroProductos && filtroProductos.length > 0 && focoTeclado == 'F1' ) &&   
                <div>
                    <button className={`kbc-button kbc-button-xs` }><i className="fa fa-arrow-up" aria-hidden="true"></i></button>
                    <button className={`kbc-button kbc-button-xs` }><i className="fa fa-arrow-down" aria-hidden="true"></i></button>
                </div>}

                {
                    filtroProductos && 
                    <>
                        {filtroProductos.slice(0,50).map( (item, index) => 
                         
                         
                            <button  onContextMenu={ (e) =>  OpcionesClickDerecho(e, item ,index )   } onClick={ () => agregarItem(item) }    key={index}  id={"fila_" +index}  href="#"  className={  "list-group-item-list-producto   list-group-item-action "  + (index == fila &&  focoTeclado === 'F1' ?  "active" : "" )} aria-current="true">
                                
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1 producto-nombre"  >{item.nombre}  </h5>
                                    
                                    { (index == fila &&  focoTeclado === 'F1') && 
                                        <button className={`kbc-button kbc-button-xs` }> Enter</button>
                                    }
                                    <small> { item.favoritos == 1 && <i className='text-warning fa-solid fa-star'></i> ||  <i className='fa-regular fa-star'></i> } </small>
                                    
                                
                                </div>
                                <div className="d-flex w-100 justify-content-between">
                                    <small>{item.id_2}</small>
                                    <small className='precioList' > $   { __app.formato_decimal({"total" : SumarImpuestosPrecio(item) })} </small>
                                </div>
                            </button>
                        )}
                    </>
                }

                {  (!filtroProductos && !showFormProducto )  &&  
                    <>
                        <h4 className='mt-2 pl-4'> Buscaremos por 
                            {busquedaDirecta && <small className='badge badge-primary'> Codigo </small> || <small className='badge badge-danger'> Nombre </small> }
                        </h4>

                        <p  onClick={ () => { $inputCheck.current.click() } }  className=' click'> Toca <span className='text-white click'>Aqui</span> para Cambiar </p>
                    </>
                }




                {
                    showFormProducto  && 
                    <>
                       <NuevoItem  productos={productos} fijo={formFijo} shitch={showFormProducto}  codigo={inputbuscar} user={user} agregarItemPadre={agregarItemPadre} establecerFocusPadre={establecerFocus}  />
                    </>
                }
               
                <div style={{marginTop:50}} >
                    
                </div>
            </div>  
                


                {/***
                 * Formulario Formas de Pago
                */}
                <StandarModal fullscreen="1"  title="Formas de Pago"  desactivar={() => SetshowFullProductos(!showFullProductos)}  mostrar={showFullProductos}  > 
                    <Index getItem={getItem} /> 
                </StandarModal> 

                {/***
                 * Formulario Formas de Pago
                */}
                <StandarModal  size="xl" title="Historial de Sincronizacion"  desactivar={() => SetShowHistorialSincronizacion(!showHistorialSincronizacion)}  mostrar={showHistorialSincronizacion}  > 
                    <HistorialSincronizados  getLastUpdateProductos={getLastUpdateProductos} /> 
                </StandarModal> 


                 {/***
                 * Formualrio para Extraer todos los productos...
                */}
                <StandarModal  size="lg" title="Sincronizar Productos"  desactivar={() => SetshowformSincronizarTodo(!showformSincronizarTodo)}  mostrar={showformSincronizarTodo}  > 
                    <SincronizarProductos   getDatos={getDatos} desactivar={ () => SetshowformSincronizarTodo(false)}  getLastUpdateProductos={getLastUpdateProductos} /> 
                </StandarModal> 


                <StandarModal  size="lg" titulo="Nuevo Item"  desactivar={() => setShowFormProductoNuevo(!showFormProductoNuevo)}  mostrar={showFormProductoNuevo}  > 
                    <Add    desactivar={ () => setShowFormProductoNuevo(false)}  /> 
                </StandarModal> 

                

                 {/***
                 * Formulario Formas de Pago
                */}
                <StandarModal  size="xl" titulo="Inventario"  desactivar={() => setShowInventario(!showInventario)}  mostrar={showInventario}  > 
                    <Inventario   /> 
                </StandarModal>
                

                {/***
                 * Formulario Formas de Pago
                */}
                <StandarModal  size="xl" titulo="Inventario Variable"  desactivar={() => setShowInventariovariable(!showInventariovariable)}  mostrar={showInventariovariable}  > 
                    <VerInventarioVariable   /> 
                </StandarModal>
                

                sinCodigo

                {/***
                 * Formulario Formas de Pago
                */}
                <StandarModal  size="xl" titulo="Inventario"  desactivar={() => null }  mostrar={sinCodigo}  > 
                 
                    <ErrorCodigoNoencontrado    /> 
                </StandarModal>
        </>
    );
}

export default React.memo(ItemsAll);