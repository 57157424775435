import React, { useState } from 'react';

import { useContextDocumentValue , actionTypeDocument } from "../context/ContextDocument.js";

import { useStateValue } from '../../../context/StateProvider';
import { listaNegra } from '../controlador.js';

function LogsItems(props) {

    const [{ user} , dispatch ] =  useStateValue();          


    /**** DOCUMENTO  */
    const [ {documento}   ] = useContextDocumentValue();
     
    
    
    const actualizarUSer = (key, val) => {
        let copynew = {};
        if (key == 'type_user_id'){

            copynew = {...user ,USER :  { ...user.USER  ,   [key.trim()]:  val.trim()}  };
           
        }else{

            copynew = {...user ,CONFIGURACIONES_TABLE :  { ...user.CONFIGURACIONES_TABLE  ,   [key.trim()]:  val.trim()}  };
        }

 
        dispatch ({
            type : 'SET_USER',
            user: copynew,
        })   

        localStorage.setItem('user', JSON.stringify(copynew));
    }

    const actualizarUSer2 = ( key1 , key, val) => {
        let copynew = {};
      
        copynew = {...user , [key1] :  { ...user[key1]   ,   [key.trim()]:  val.trim()}  };
       

 
        dispatch ({
            type : 'SET_USER',
            user: copynew,
        })   

        localStorage.setItem('user', JSON.stringify(copynew));
    }


    
    const cambiarModoAplicacion = (val) => {

        let configguraciones_table = { ...user.CONFIGURACIONES_TABLE , estado_aplicacion : val};
     

 
        
        configguraciones_table = {...user ,CONFIGURACIONES_TABLE :  configguraciones_table   };
      

        console.log(configguraciones_table);
        dispatch ({
            type : 'SET_USER',
            user: configguraciones_table,
        })   

        localStorage.setItem('user', JSON.stringify(configguraciones_table));
        
    }

    const [ b , setb ] = useState();

    return (
        <div>
            <button className='btn btn-success' onClick={() => cambiarModoAplicacion(1)}>OCULTAR ESTE PANEL </button>
            <input placeholder='value'  onChange={(e) => setb(e.target.value) } style={{"width" : "80px"}} type="text" />
          
            <p><b>Configuracion de Usuario</b>o</p>     
            <span>Descuento Minimo aceptado %:  <span onClick={ () => actualizarUSer('descuento_minimo' , b )} className='badge bg-primary'>{user.CONFIGURACIONES_TABLE.descuento_minimo}%</span> </span>
            <br></br>
            <span>Precio permitido para realizar descuento  <span   onClick={ () => actualizarUSer('limite_descuento' , b )} className='badge bg-primary'> $ {user.CONFIGURACIONES_TABLE.limite_descuento} </span> </span>
            <br></br>


            <span>Obligar Vendedor  <span   onClick={ () => actualizarUSer('obligar_vendedor' , b )} className='badge bg-primary'> $ {user.CONFIGURACIONES_TABLE.obligar_vendedor} </span> </span>
            <br></br>

            

            <p><span>Tipo de usuario:  <span onClick={() => actualizarUSer('type_user_id' , b)} className='badge bg-danger'>{ user.USER.type_user_id}</span> </span></p>


            <p><span>Modo restaurante:  <span onClick={() => actualizarUSer2('CONFIGURACIONES_TABLE' , 'restaurante' , b)} className='badge bg-danger'>{  user.CONFIGURACIONES_TABLE.restaurante}</span> </span></p>


        <p><span>Tipo de precio:  <span onClick={() => actualizarUSer2('CONFIGURACIONES_TABLE' , 'switch_modo_precio' , b)} className='badge bg-danger'>{  user.CONFIGURACIONES_TABLE.switch_modo_precio}</span> </span></p>



            <p><b>Documento Actual </b></p>   
            <span>Porcentaje Global: <span className='badge bg-danger'>{documento.ESTRAS.PORCENTAJE_GLOBAL}%</span> </span>

            <br></br>
            <span>Imprimir Documento  <button className='badge bg-danger' onClick={ () => console.log(documento) }>Imprmir documento </button> </span>


            <p> documento.ESTRAS.SWITCH_LISTA_PRECIO : {documento.ESTRAS.SWITCH_LISTA_PRECIO} </p>


            <p>CODIGO LISTA DE PRECIO ACTIVGA : {documento.ESTRAS.LISTA_PRECIO_ACTIVA} </p>

            <p><b>LOG DOCUMENTO</b></p>
            {documento.LOGS &&  documento.LOGS.documento &&

            <span>
                {documento.LOGS.documento.map((prod, index) => 
                    <p key={index} className="list-group-item"> {prod} </p>
                )}
            </span>

            }

            <p><b>LOG ITEMS</b></p>

           
               { documento.LOGS && documento.LOGS.producto &&

                    <span>
                        {documento.LOGS.producto.map((prod, index) => 
                            <p key={index} className="list-group-item"> {prod.index} // {prod.notificacion} </p>
                        )}
                    </span>

               }

                
               
               <button onClick={ () => listaNegra(false) }> MATAR  </button>

               <button onClick={ () => console.log(user.CONFIGURACIONES_TABLE) }> Log configuracion  </button>

               <button onClick={ () => console.log(user.PERMISOS) }> Log Permisos  </button>
           
        </div>
    );
}

export default LogsItems;