import React, { useState } from 'react';
import { useEffect } from 'react';

import {HelpHttp} from "../../../Core/https";
import Buscaripi  from '../../../utils/ripi';
import { useStateValue } from '../../../context/StateProvider';
import {fetchEmpresa} from "../../../Core/fetchLocalStoreData";
import { ExtraerTabla , reloadDatabases } from '../../../Core/Dixie';
import { NotificationManager} from 'react-notifications';
import StandarModal from './StandarModal';
import TerceroView from './TerceroView';
import TerceroPerfil from '../../Terceros/TerceroView/TerceroPerfil';
import { HttplistarClientes } from '../../Terceros/Core';
let  Ripi_prIndex  = null;
const  BuscarTercero = ({seleccionarTercero , ocultarform}) =>  {



    const [ {user , localconfig } ] = useStateValue();
    const ConfigEmpresa = fetchEmpresa();

    const [ terceros , setTerceros ] = useState([]);
    const [ tercerosfiltro , setTercerosfiltro ] = useState([]);
    const [ buscar , setBuscar ] = useState('');


    const  [ showFormPerfilTercero , SetShowFormPerfilTercero  ]  = useState(null);

    const handleChange = (e) => {
        console.log(e)
    }


    
    let Json = {
        "URL" :  localconfig.global.servidor,  
        "nit" :  ConfigEmpresa.nit      
    }


    useEffect( () => {
    


        /** Prepara los datos para el ripi.js  */
        const renderizar = (data) => {
            Ripi_prIndex  = new Buscaripi(data);
            setTercerosfiltro([]);
            Ripi_prIndex.fullResultado = true;
            setTerceros(data)
        }

        const getDatos = async () => {

            let tercerosDixie = await   ExtraerTabla('terceros'  );
            if(tercerosDixie.length > 0){
                console.log("...loading from localstore");
                renderizar(tercerosDixie);
            }else{
                obtenerDatosPHP();
            }
            
        }
        
        getDatos();

    } , [])


    const  [cargando , setCargando] = useState(null);

    const obtenerDatosPHP = async () => {
        
        setCargando(1);
        console.log("...loading from php");
   
        let Httpdata = await HttplistarClientes();
        if(Httpdata !== -1){
           
            setTimeout(() => {
                setCargando(null); 
                NotificationManager.success('Clientes en total: ' + Httpdata.length , 'Éxito Sincronizado');
            }, 3000);
            
            reloadDatabases(Httpdata , 'terceros');
            setTerceros(Httpdata)
            Ripi_prIndex  = new Buscaripi(Httpdata);  
            setTercerosfiltro([]);
            Ripi_prIndex.fullResultado = true;
        }   
    }























    const handleChangeTercero = (tercero) => {
        setTercerosfiltro([]);
        
        seleccionarTercero(tercero);
        
    }

    const focusInput = () => {
        ocultarform(false);
    }



    useEffect( () => {
        
        if (Ripi_prIndex){
    
            if(buscar.length > 0 ){



                let data = Ripi_prIndex.start(buscar , ["identificacion" , "nombrecomercial" , "razonSocial"   ] );
                
                
                setTercerosfiltro(data);
            
            }else{

                setTercerosfiltro([]);
            }
           
        }
        
    }  , [buscar ]);

    const actualizarTodo = ( ) => {
        
        obtenerDatosPHP();
    }

    const [idTercero , SetidTercero] = useState();
    const verPerfil = (id) => {
        SetShowFormPerfilTercero(true);
        SetidTercero(id)

    }
    return (
        <div className='margin-5'>
            
            <div style={{display: 'flex'}}>
                <input   onFocus={focusInput}  autoComplete='off' value={buscar} onChange={ (e) => setBuscar(e.target.value) } className="min-width-400 form-control  " type="search" placeholder="Buscar un Tercero" id="example-search-input" />
             
                
                <button disabled={(cargando) ? "disabled": ""}  onClick={actualizarTodo}  className='btn btn-principal btn-small' title="Sincronizar Clientes"  data-bs-placement="bottom" data-bs-toggle="tooltip" > 
                  
                  { !cargando && <i className="fa-solid fa-arrows-rotate"></i> || <i className="fa-solid fa-arrows-spin fa-spin"></i>
                    }
                    
                </button>
            </div>

            <ul className="list-group resultado-clientes" style={{'zIndex': 1031}}>
                {tercerosfiltro.map((tercero, index) => (
                    <li
                        key={index}
                       
                        className="list-group-item d-flex justify-content-between align-items-center"
                        style={{ cursor: 'pointer', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                    >
                        <div  title='Click para Seleccionar' onClick={() => handleChangeTercero(tercero)} >
                            <strong>{tercero.razonSocial}</strong><br />
                            <small>NIT: {tercero.identificacion} - Tel: {tercero.telefono}</small>
                        </div>
                        
                        <button onClick={() => verPerfil(tercero.id)} title='Conoce el Perfil Completo de este Tercero' className='btn btn-link'>
                            <i className="fas fa-user"></i> Ver Perfil
                        </button>
                    </li>
                ))}
            </ul>


            {/***
             * Formulario Guardad Documento
            */}
            <StandarModal keyboard={true} headerShow={false} size="xl"  titulo="Guardar Documento"  desactivar={() => SetShowFormPerfilTercero(false) } mostrar={showFormPerfilTercero}  > 
                <TerceroPerfil id_directo={idTercero} modalClose={() => SetShowFormPerfilTercero(false) } />
            </StandarModal> 



        </div>

       
    );
}

export default BuscarTercero;