import React from 'react';
import { QRCode } from 'react-qr-code';

const CodigoQrfe = ({ codigo, size = 128, bgColor = "#ffffff", fgColor = "#000000", level = "L" }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
            <QRCode value={codigo} size={size} />
            <p style={{margin:10, fontSize:10, fontFamily:'monospace'}}>Generada por <br/> www.pluma7.com</p>
        </div>
    );
};

export default CodigoQrfe;