import { useEffect, useState } from "react";

import { disenoFactura } from '../../../Core/VariablesGlobales';
import {getLocalstore} from '../../../Core/fetchLocalStoreData'

import { __app , __fecha } from '../../../utils/myApp';


/** imprimirProductosTipo : Esta variable indica que solo vamos a imprimir lso productos
 * en el campo "cocina" que sean igual a esta variable.
 * 
 * ejemplo : cocina : 1 
 *           Barra : 2
 * 
 *  Asi poddemos abrir 2 pestañas en cada sitio sin problemas.
 */
const PlantillaComanda = ( {documento , modo="produccion" , imprimir , estilos = null , idDocumento = 'EasyPrintAnd' , imprimirProductosTipo = 1} ) => {


    
      
        let estiloGuardado = getLocalstore('disenoComandaInitialstate');
  
        if(!estilos){

            let estiloGuardado = getLocalstore('disenoComandaInitialstate');
            if(estiloGuardado){

                estilos = estiloGuardado;

            }else{

                estilos = disenoFactura;;
            }
          
        }
    

        

        estilos.LineaProductos.subtotal = { ...estilos.LineaProductos.subtotal , ...{ display: 'block' , width: '100%', textAlign: 'end'} } 
  
        
        

    

    /** Estilos */
    const [id, setID] = useState(idDocumento);


    const [ newproductos , SetNewProductos ] = useState();
    

    /*** Solo hay dos modos, MODO FULL o modo normal
     * 
     *  El modo Full imprimir todos los productos
     * El modo normal imprime solo los actuales
     */
    const  axuModo  = (documento.modo && documento.modo == "full" ) ?  "full" : "normal";

    
    useEffect(() => {
        
   

    },[])


      
    //** Antes de realizar una impresion debo comprobar si efectivamente hay algo para imprimir */
    const imprmir =  documento.PRODUCTOS.filter((pro) => ( (pro.numberid == documento.numberid )  || ( axuModo === "full" ) ) &&  imprimirProductosTipo == pro.cocina );
    if( !imprmir.length > 0){
        return false;
    }


    return (
        <div>
            { (estilos && documento ) && 
            
            
            <div style={estilos.EstilosPrincipales} id={id}>

                <div style={estilos.Tirillacontenedor }  >
                    
                { (documento.FORM && documento.FORM.mesanombre) &&  <h1>Mesa #   { documento.FORM.mesanombre }</h1> ||  <div>No hay mesa</div>}
                   
                    
                    { (documento.FORM && documento.FORM.mesanombre) && 
                    <div style={{marginTop:'-30px'}}>
                        <span style={{display:'block'}}>
                            Hora: {__fecha.formatoFecha( { '$fecha' : documento.FORM.HoraComanda  , $fecha_view : false, $icon : false } )}                
                        </span>
                    </div>
                    }



                    <span style={{display:'block' , marginTop:'10px'}}>
                              Comanda { documento.cuentAbiertaFirebaseId &&  <span>#{documento.cuentAbiertaFirebaseId}</span>  || <span>#{documento.id}</span>}                           
                    </span>

                
                    { (documento.FORM && documento.FORM.nombre_vendedor) && 
                    <span>  Mesero :  {documento.FORM.nombre_vendedor}</span>
                    }

                    { (documento.TERCERO && documento.TERCERO.nombrecomercial) && 
                        <div>
                            <br/><br/>
                            <span>  Cliente :  {documento.TERCERO.nombrecomercial}</span><br/>
                            <span>  Teléfono :  {documento.TERCERO.telefono}</span><br/>
                            <span>  Dirección :  {documento.TERCERO.direccion}</span><br/>
                        </div>
                    }












                    <div style={{marginTop: 20}}>
                        {documento.PRODUCTOS.map( (pro,index) => 
                            <div key={index}>
                                
                               
                                    { ( ( (pro.numberid == documento.numberid)  || ( axuModo === "full" ) ) &&  imprimirProductosTipo == pro.cocina  ) &&
                                    <div>
                                    <span style={estilos.LineaProductos.cantidad}>{pro.MATEMATICAS.CANTIDAD})</span>  
                                    
                                    
                                    { estilos.SWITCH_SHOW_FIELD && estilos.SWITCH_SHOW_FIELD.nombre == 1 &&  <span style={estilos.LineaProductos.nombre}>{pro.nombre}</span> } 
                                    
                                   
                                
                        
                                    { (pro.COMPUESTO && pro.COMPUESTO.length > 0)  &&
                                        <ul>
                                            
                                            {pro.COMPUESTO.map( (com,index2) => 
                                                
                                                <>
                                                {
                                                    (imprimirProductosTipo == com.cocina) &&

                                                    <li style={estilos.LineaProductos.nombre} key={index2}  >{com.nombre}</li>
                                                } 

                                                </>  
                                                    
                                            )}
                                        </ul>
                                    }

                                 
                                    { (pro.descripcion && pro.descripcion.trim().length > 2 ) && 

                                        <span style={ {...estilos.LineaProductos.nombre , ...{display:'block', marginLeft:'15px', fontFamily:'monospace', margintop:'3px'}}}>
                                            **************<br/>
                                            {pro.descripcion}<br/>
                                            ************** <br/>
                                        </span>
                                    }

                                    { estilos.SWITCH_SHOW_FIELD && estilos.SWITCH_SHOW_FIELD.subtotal == 1 && 
                                    <   span style={estilos.LineaProductos.subtotal}>$   { __app.formato_decimal({ total : pro.MATEMATICAS.SUBTOTAL_3})  }</span>
                                    }
                                
                                    {/*** Linea Separador Documento */}
                                    {documento.PRODUCTOS[index +1 ] && 
                                    <div style={{color : '#949ba3' , 'marginTop': '-5px','textalign': 'center','height' : '12px','overflow': 'hidden', marginBottom: '5px'}}>- - - - - - - - - - - - - - - - - - - - - - - - -- - - - - - - - - - - - - - - - - - - - - - - - - </div>
                                    } 
                                      
                                </div>}   
                               
                            </div>
                        )}
                    </div>

                    <h2 style={{textAlign:'center'}}>$ { __app.formato_decimal({total : documento.MATEMATICAS.SUBTOTAL_3})  } </h2>                 

                     { estilos.SWITCH_SHOW_FIELD && estilos.SWITCH_SHOW_FIELD.saltolinea == 1 && 
                     <>                   
                    <div style={{
                        'marginTop': '30px',
                        'textalign': 'center',
                        'height'  : '15px',
                        'overflow': 'hidden',
                    }}>
                    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -- - - - - - - - - - - - - - - - - - - -    
                    </div>

                    <div style={{
                        'marginTop': '50px',
                        'textAlign': 'center',
                        'height'  : '15px',
                        'overflow': 'hidden',
                    }}>
                    - - - - - - - - - - - - - - - - - - - - - - - - - - - - -- - - - - - - - - - - - - - - - - - - - -    
                    </div>
                    </> 
                    }
                </div>

            </div>}
        </div>
    )};

export default PlantillaComanda