import React, { useEffect, useRef } from 'react';

const Componente = () => {
  const buttonRef = useRef(null);

    useEffect(() => {

        const button = buttonRef.current;
        const ariaExpanded = button.getAttribute('aria-expanded');
        var navbarVerticalCollapse = document.getElementById('navbarVerticalCollapse');




        // Función para manejar los clics fuera del botón
        const Ocultar = (event) => {
           
            if(ariaExpanded == true || ariaExpanded == 'true' ){
                buttonRef.current.click();
            }     
        };

        const handleClickOutside = (event) => {
            var target = event.target;

           
            if (
                target !== navbarVerticalCollapse && 
                !navbarVerticalCollapse.contains(target) && 
                navbarVerticalCollapse.classList.contains('show')
            ) {         
                console.log("Por fuera");
                Ocultar();
            }
        }

        // Agrega el listener de clics al cuerpo del documento
        document.addEventListener('click', handleClickOutside);






        


        // Función para manejar los clics en los botones de tipo nav-link
        const handleNavLinkClick = (event) => {
            // Aquí puedes realizar la acción específica que deseas cuando se hace clic en un botón de tipo nav-link
            if(navbarVerticalCollapse.classList.contains('show')){
                Ocultar();
            }
            return false;
        };

        // Selecciona todos los botones de tipo nav-link dentro del elemento navbar-vertical
        const navLinks = document.querySelectorAll('.p7-bkn-hiden-navar');

        
        // Agrega un event listener a cada botón de tipo nav-link
        navLinks.forEach((navLink) => {
            navLink.addEventListener('click', handleNavLinkClick);
        });
        

        // Limpia el listener al desmontar el componente
        return () => {

            document.removeEventListener('click', handleClickOutside);

            navLinks.forEach((navLink) => {
                navLink.removeEventListener('click', handleNavLinkClick);
            });

        };

  }, []);

  return (
    <button
      ref={buttonRef}
      className="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarVerticalCollapse"
      aria-controls="navbarVerticalCollapse"
      aria-expanded="true"
      aria-label="Toggle Navigation"
    >
      <span className="navbar-toggle-icon"><span className="toggle-line"></span></span>
    </button>
  );
};

export default Componente;