import Rutas from './Rutas';

const Main = ({user}) => {
    return (
        <>
            <Rutas />  
        </>
       
   
    );
}

export default Main;