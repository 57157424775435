
import { useState } from "react";
import {  fetchEmpresa , fetchUSer  } from "../../Core/fetchLocalStoreData";

import {enviarPeticion} from  "../../Core/ActionDatabase";
import { actionType } from '../../context/reducer';
import { useStateValue } from '../../context/StateProvider';


const TurnoAbrir = ({ infodatabase , cerrarSession , estadoTurno}) => {


    const [ {user}  , dispatch ] = useStateValue();

 
    const initialEmpresa =  fetchEmpresa();
    
    const [ caja , setCaja  ]      = useState(0);
    const [cargando , setCargando] = useState(0);
    const [ statusresponse , setStatusresponse] = useState(null);
       
    const abrirTurno =  async ( ) => {
      
        setStatusresponse(null);
        setCargando(1);

        const respuesta = await enviarPeticion("turnos/abrir_turno",initialEmpresa.nit , {
            body : { user_id : user.USER.id , caja_id : caja ,  estacione_id : user.ESTACION.id  } 
        } );

        console.log(respuesta)
        if(respuesta.status) {

            console.log(respuesta)
            setCargando(0);

            if ( respuesta.respuesta){

                if(respuesta.data.id > 0){

                    let copi = user;
                    copi = { ...user , 'TURNO' : respuesta.data }
                    localStorage.setItem('user', JSON.stringify(copi));            
                    dispatch ({
                        type : actionType.SET_USER,
                        user: copi,
                    })  
                    estadoTurno("open")
                }

            }else{
                
          
                setStatusresponse(respuesta.data.mensaje); 
            }
        }else{
            alert("Error")
            setCargando(0);
            setStatusresponse("Error, No hay conexión con el Servidor, revisa tu conexión de internet.");    
        }
    }



    if( !user.ESTACION){
        return (<p>No hay punto de venta</p>)
    }

    if( !user ){

        return false;
    }else{
        
        console.log(user);
    }
    
    return (
        <div className="row p-5">
            <div className=" ">
            <h5 className="alert alert-warning p-5">No puedes entrar al punto de venta sin un turno Abierto</h5>
                <h3 className="mt-5" >PUNTO DE VENTA : { user.ESTACION.nombre }</h3>
                <p>Eres el Usuario : <b>{user.USER.nombre} </b> </p>
               
               
            
                { (user.USER.type_user_id == 1 || (user.PERMISOS  && user.PERMISOS.AbrirTurno == 1) ) &&  <div>

                    <div className="mt-5">

                            <h3>Para Abrir TURNO seleccione una Caja.</h3>

                            <div >Selecione una CAJA  para continuar </div>
                            <select  onChange={ (e) =>  setCaja( e.target.value ) } >
                                <option value={0} > --- Selelecione -- </option>
                                { user.CAJAS.map( (data, index)  => 
                                    <option key={index} value={data.id} > {data.nombre} </option>
                                )}
                            </select>
                        </div>

                        { (caja > 0 &&  !cargando)  && <button onClick={abrirTurno} className="mt-3 btn btn-principal"> Abrir Turno  </button>}

                        {  (cargando == 1 ) && <p>Cargando...</p>}     

                        { ( statusresponse == "error_5"  && !cargando)   &&  
                        <p className="mt-3 alert alert-danger"> 
                            <div className=""><b>Error Turno Abierto!</b></div>
                            <span className="mt-2">Ya hay un turno Abierto con este Usuario en esta Estación. Debe cerrar cession para cargar el turno Anterior.</span>
                            <div className="mt-2">Click aqui para <b roll="button" style={ {"cursor": "pointer"}} className=" btn-link " onClick={cerrarSession}>Cerrar Sesión</b></div>
                        </p>   }  
                    </div>

                    ||

                    <div className="alert text-white  bg-danger">
                        <p>Este Usuario no tiene Permisos para iniciar un nuevo Turno. <br />
                        Ingrese al <b>Administrativo</b> con una cuenta de usuario de tipo <b>ADMIN</b> y asigne permisos. </p>
                    </div>
                }




            </div>
        </div>
    )

}

export default TurnoAbrir;