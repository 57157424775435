import React from 'react';
import { __app } from '../../../utils/myApp';

const ListarItemsSum = ({ itemssum }) => {

    if(!itemssum){
        return false;
    }

    const copyTableToClipboard = () => {
        const table = document.querySelector('#items-table');
        let tableText = '';
        for (const row of table.rows) {
            const cells = row.cells;
            const rowText = [];
            for (const cell of cells) {
                rowText.push(cell.innerText);
            }
            tableText += rowText.join('\t') + '\n';
        }
        navigator.clipboard.writeText(tableText).then(() => {
            alert('Tabla copiada al portapapeles');
        }).catch(err => {
            console.error('Error al copiar la tabla: ', err);
        });
    };

    let totalCantidad = 0;
    let totalDescuento = 0;
    let totalTotal = 0;
    let totalUtilidad = 0;
    
    itemssum.forEach(item => {
      totalCantidad += parseFloat(item._CTD);
      totalDescuento += parseFloat(item._DTO);
      totalTotal += parseFloat(item._SUB3);
      totalUtilidad += parseFloat(item._UTD);
    })

    return (
        <div>
            <h3>Items Movidos</h3>
            <button className="btn btn-primary" onClick={copyTableToClipboard}>
               Copiar Tabla
            </button>
            <table id="items-table" className='table'>
                <thead>
                    <tr>
                        <th>Llave</th>
                        <th>Codigo</th>
                        <th>Nombre</th>
                        <th>Cantidad</th>
                        <th>Descuento</th>
                        <th>Total</th>
                        <th>Utilidad</th>
                    </tr>
                </thead>
                <tbody>
                    {itemssum && itemssum.map((item, index) => (
                        <tr key={index}>
                            <td>{item.pid}</td>
                            <td>{item.pid2}</td>
                            <td>{item.nm}</td>
                            <td>{ __app.formato_decimal({total: item._CTD }) }</td>
                            <td>{  __app.formato_decimal({total: item._DTO })  }</td>
                            <td>{  __app.formato_decimal({total: item._SUB3 }) }</td>
                            <td>{ __app.formato_decimal({total: item._UTD })  }</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>{__app.formato_decimal({ total: totalCantidad })}</th>
                        <th>{__app.formato_decimal({ total: totalDescuento })}</th>
                        <th>{__app.formato_decimal({ total: totalTotal })}</th>
                        <th>{__app.formato_decimal({ total: totalUtilidad })}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default ListarItemsSum;
