


import prindiv from 'easyprintand';
import React, { useEffect } from 'react';
import ImprimirEntradaPatio from '../../Document/PlantillasImpresion/Parqueadero/ImprimirEntradaPatio';

const ReciboPatio = ({documento}) => {




    useEffect(() => {
        handlePrint();
    } , [documento])
     
    if(!documento){
        return false;
    }

    const handlePrint = () => {
        
        prindiv('EasyPrintAnd') 
    }


    return (
        <div id='EasyPrintAnd'>
            <ImprimirEntradaPatio documento={documento} />
        </div>
    );
};

export default ReciboPatio;