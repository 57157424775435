import React, { useEffect, useState } from 'react';

import { __app, __pluma7 } from '../../../utils/myApp';
import { enviarPeticion } from '../../../Core/ActionDatabase';
import { fetchEmpresa, getNit  } from '../../../Core/fetchLocalStoreData';
import { NotificationManager } from 'react-notifications';
import { HelpHttp } from '../../../Core/https';
import RenviarDian from './Dian/RenviarDian';

import ReimprimirTirilla from './ReimprimirTirilla';
const Botones = ({document}) => {


    const nitEmpresa = getNit();
    
    const [ xxx , setXxx] = useState();

    useEffect(() => {
        setXxx(document)    
    }, [document])
    

    const enviarCorreoFacturaElectronica = async () => {
      
        let numeroFactura = (xxx && 
            xxx.DOCUMENTO.DIAN_factureroelectronico_id && 
            !isNaN(parseInt(xxx.DOCUMENTO.DIAN_factureroelectronico_id)) && 
            parseInt(xxx.DOCUMENTO.DIAN_factureroelectronico_id) > 0) 
          ? parseInt(xxx.DOCUMENTO.DIAN_factureroelectronico_id) 
          : 0;


        if(numeroFactura == 0 ){

            alert("Esta factura no esta Aceptada. No puedes enviarla");
            return false;
        }
        var correo = prompt("Por favor, ingresa el correo Electrónico:",  xxx.DOCUMENTO.correoelectronico);
        
        // Muestra el valor capturado en un alert
        if (correo !== null) {
          
            let httpResponse = await  HelpHttp().post('https://facturaelectronica.nubep7.com/apis/reenviar_correo_electronico/'+nitEmpresa+'/'+numeroFactura+'/' + correo );
     
            if(httpResponse.respuesta == 1){
                console.log(typeof httpResponse.data)
                
                let resultadoEmail = null;

                if(typeof httpResponse.data  == 'object'){
                    httpResponse.data.forEach((data,index) => {
                        if(data === 'RESPUESTA_CORREO:'){
                            resultadoEmail =  httpResponse.data[index+1];
                        }
                    });
                   
                }

                console.log(resultadoEmail)
                if(resultadoEmail === '--1--'){
                    NotificationManager.success("Email enviado con Éxito")
               }else{
                    NotificationManager.warning("Error al intentar enviar el correo." )
               }
            }else{
                NotificationManager.error("Error de red")
            }
        }
    }

    const [reenviarDian , SetreenviarDian] = useState();

    const HandlereenviarDian = () => {
        var numero = xxx.DOCUMENTO.CtDocumento_ct_numero_factura; // Número actual
        var mensaje = "La factura se enviará con el número: " + numero + 
                      ". Si deseas mantener este número presiona Aceptar. " +
                      "Si deseas el siguiente número presiona Cancelar.";
        
        if (window.confirm(mensaje)) {
            // El usuario quiere mantener el número actual
            alert("Se mantendrá el número: " + numero);
            SetreenviarDian(1);
        } else {
       
            alert("La factura se generará con un número nuevo.");
            SetreenviarDian(2);
        }
    
        // Continuar con la lógica después de la selección del número
       
        console.log("Número de factura seleccionado:", numero);
    
        return false;
    }

    return (
        <div>
      

        {(xxx && xxx.DOCUMENTO && xxx.DOCUMENTO.DIAN_repuesta_dian && xxx.DOCUMENTO.DIAN_repuesta_dian == 4) && 
            <button onClick={enviarCorreoFacturaElectronica} className="btn btn-secondary me-2">
                <i className="fa fa-envelope"></i> Enviar por Correo
            </button>
        }

        {(xxx && xxx.DOCUMENTO && xxx.DOCUMENTO.DIAN_documento_electornico && xxx.DOCUMENTO.DIAN_repuesta_dian != 4) && 
            <button onClick={HandlereenviarDian} className="btn btn-warning me-2">
                <i className="fa fa-refresh"></i> Re-enviar DIAN
            </button>
        }

        {(xxx && xxx.DOCUMENTO && xxx.DOCUMENTO.DIAN_documento_electornico && xxx.DOCUMENTO.DIAN_documento_electornico == 1) && 
            <a target='_blank' href={ 'https://facturaelectronica.nubep7.com/ventas/PDF_original/' + xxx.DOCUMENTO.DIAN_factureroelectronico_id + '/' + nitEmpresa } className="btn btn-primary me-2">
                <i class="fa-solid fa-file-pdf"></i> PDF Electronico
            </a>
        }

        {(xxx && xxx.DOCUMENTO && xxx.DOCUMENTO.DIAN_documento_electornico && xxx.DOCUMENTO.DIAN_documento_electornico == 1 && xxx.DOCUMENTO.DIAN__codigoQr) && 
            <a target='_blank' href={xxx.DOCUMENTO.DIAN__codigoQr} className="btn btn-success me-2">
                <i className="fa fa-qrcode"></i> Validación de la existencia en la DIAN
            </a>
        }




            { xxx  && <ReimprimirTirilla documento={xxx}   /> }

            <RenviarDian reenviarDian={reenviarDian}  documento={xxx} />
        </div>
    );
};

export default Botones;