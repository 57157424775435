import React, { useEffect, useState } from 'react';
import Seleccion from '../../../../Productos/PartesFormAdd/Inventario/Seleccion';

const VariantesIventario = ({auxitem , extraerVariantes}) => {
    

    let variante = null;
    try {
        variante = JSON.parse(auxitem.variantes);
    } catch (error) {
        
    }

    //997
    const [ formateadaVariante ,  setFormateadaVariante ] = useState(null);

    
    useEffect(() => {

        //Si existe item.VARIANTE: significa que  el cliente ya antes entro aqui a modificar esta variante.
        if(auxitem.VARIANTES && auxitem.VARIANTES.length > 0 ){
         
            console.log(auxitem.VARIANTES)
            setFormateadaVariante(auxitem.VARIANTES)
        }else{
            console.log(variante)
            if(variante){
                const  copy = variante.map(data => {
                    data.cantidad = 0;
                    return data
                }  )

                console.log(copy)
                setFormateadaVariante(copy)
            }
           
        }

 
    }, [])




    const getcambios  = (variantes , total) => {
        console.log(variantes , total)
        extraerVariantes(variantes , total)
    }
    return (
        <div>
            <h1>Variantes de inventario</h1>
            {formateadaVariante && <Seleccion  Auxvariantes={formateadaVariante}  getcambios={getcambios} />}
        </div>
    );
};

export default VariantesIventario;