import React, { useRef, useState } from 'react';
import { __app } from '../../../../../utils/myApp';
const StockProductos = ({item,indexx,accionF,accionName , user , decimal , ct_operacion_id}) => {
    
    const [showinput , setShowInput ] = useState(false);

    const input = useRef();

    const handleChange = (e) => {
        setShowInput(false);
        let valor = e.target.value;
        let accion = accionName;
        accionF({indexx,valor, accion});
    }


    const _handleKeyDown =  (e)  => {
        if (e.keyCode === 13) {
            handleChange(e);
            e.preventDefault();
            return false;
        } 
    }

    const clickCampos = () => {
       
        if( user.PERMISOS.ModificarInventario &&    user.PERMISOS.ModificarInventario != 0){
            setShowInput(true);
            setTimeout(() => {
                input.current.focus();
                input.current.select(); 
            }, 50);

            return false;
        } 
    }

    /*bloquear_precio = 0     es   bloqueado */
    if (!item.MATEMATICAS.STOCK && item.MATEMATICAS.STOCK !== 0) {
        return (
            <>
                ...
            </>
        );
    }
    
  

    

    return (
        <>
           { !showinput && 
            <span onClick={ clickCampos }>   
                {item.MATEMATICAS.STOCK}
            </span> }
           
            { !(user.PERMISOS.ModificarInventario &&    user.PERMISOS.ModificarInventario != 0 ) && <i className="fa-sharp font-s-candado  fa-solid fa-lock"></i>}

           { (showinput &&  user.PERMISOS.ModificarInventario && user.PERMISOS.ModificarInventario == 1)   && <input  ref={input}  onKeyDown={_handleKeyDown} className='max-50'  onBlurCapture={ handleChange}  defaultValue={item.MATEMATICAS.STOCK} />}
        

        </>
    );
}


export default StockProductos;