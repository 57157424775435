import React from 'react';

const ListaProductos = ({filtroProductos , cargarIguales, halarItem}) => {
        
    return (
        <div>
        <div className="table-responsive mt-3" id='' >
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Codigo</th>
                                <th>Referencia</th>
                                <th>Ref Ori</th>
                                <th>Nombre</th>
                                <th>Descripcion</th>
                                <th>Categoria</th>
                                <th>Marca</th>
                                <th>Precio</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                
                        <tbody>
                
                            {filtroProductos.slice(0,500).map( (item, index) =>   
                                <tr key={index}>
                                    <td>{item.id_2}</td>
                                    <td>{item.referencia}</td>
                                    <td>{item.referencia_2}</td>
                            
                                    <td>{item.nombre}</td>
                                    <td>{item.descripcion}</td>
                                    <td>Categoria</td>
                                    <td>Marca</td>
                                    <td>{item.PRECIO_PRINCIPAL}</td>
                                    <td>
                                        <span  onClick={() => cargarIguales(item) } className='btn btn-xs btn-principal'  >Cargar Iguales</span>
                                        <span  onClick={() => halarItem(item) } className='btn btn-xs btn-principal'  >Selccionar </span>
                                    </td>
                                </tr>         
                            )}
                        </tbody>
                    </table>
                </div>  
        </div>
    )
};

export default ListaProductos;