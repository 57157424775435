


import React, { useEffect, useState } from 'react';
import { deleteIdsDixie, ExtraerTabla } from '../../../Core/Dixie';
import Tablapatio from './Tablapatio';

const Patio = ({ reloadPatio  ,imprimirEntrada , resetearPanel }) => {

    const [documentos , setDocuementos ] = useState(null);

    
    const cargarDocumentosenPatio = async () => {

        let opciones =  {
            condiciones: {
                where: 'estado_patio',
                igual_a: 1,
            }
        };

        
        const  DocumentosDixie = await  ExtraerTabla('documentos', 1,  opciones );

        console.log(DocumentosDixie);
        setDocuementos(DocumentosDixie);
    }

    useEffect(() => {
        cargarDocumentosenPatio();
    }, [reloadPatio])


    const eliminarPatio = (id) => {

        deleteIdsDixie([id] , 'documentos');
        resetearPanel();
    }

    const camposMostrar = [

        'patio_entrada_fecha', 'placa', 'descripcion' ,  'categoria' , 'opciones'
    ]

    const botones = [

        "imprimirEntrada",
        "eliminarPatio" , 
        
    ]




    const funciones_botones = {

        imprimirEntrada : imprimirEntrada,
        eliminarPatio : eliminarPatio
    }


    return (
        <div>
            <h1>Vehiculos en Patio</h1>
            
           
            
            <Tablapatio documentos={documentos} camposMostrar={camposMostrar} botones={botones}  funciones_botones={funciones_botones} />
        </div>
    );
};

export default Patio;