
import Buscaripi from "../../utils/ripi";

import { NotificationManager} from 'react-notifications';

import {HelpHttp} from "../../Core/https";
import { ExtraerTabla , reloadDatabases } from '../../Core/Dixie';
import React , { useEffect , useState} from 'react';
import { useStateValue } from '../../context/StateProvider';
import {fetchEmpresa} from "../../Core/fetchLocalStoreData";
import {enviarPeticion} from "../../Core/ActionDatabase";
import { __app } from "../../utils/myApp";
import { Link } from "react-router-dom";

let  Ripi_prIndex  = null;

const Index = () =>  {

    const [ {user , localconfig } ] = useStateValue();
    const ConfigEmpresa = fetchEmpresa();

    const  [ inputbuscar , setInputBuscar  ] = useState("");
    const  [ terceros , setTerceros ] = useState([]);
    const  [ filtroTerceros , setFiltroTerceros ] = useState([]);
    const  [ fila  , setFila ] = useState(-1);


    let Json = {
        "URL" :  localconfig.global.servidor,  
        "nit" :  ConfigEmpresa.nit      
    }


    useEffect( () => {
        

        /** Prepara los datos para el ripi.js  */
        const renderizar = (data) => {
            Ripi_prIndex  = new Buscaripi(data);
            setFiltroTerceros(data);
            Ripi_prIndex.fullResultado = true;
            setTerceros(data)
        }

        const getDatos = async () => {

            let tercerosDixie = await   ExtraerTabla('terceros'  );
            if(tercerosDixie.length > 0){
                console.log("...loading from localstore");
                renderizar(tercerosDixie);
            }else{
                obtenerDatosPHP();
            }
            
        }
        
        getDatos();

    } , [])


    const obtenerDatosPHP = () => {

        console.log("...loading from php");
        let url = Json.URL + "terceros/listar/" + Json.nit ;
        let promise =  HelpHttp().post(url) ;    
        promise.then((data) => promesaResul(data));
     
        const promesaResul = (data) => {  

            if(data.respuesta){
                console.log(data)
                reloadDatabases(data.data , 'terceros');
                setTerceros(data.data)
                Ripi_prIndex  = new Buscaripi(data.data);  
                setFiltroTerceros(data.data);
                Ripi_prIndex.fullResultado = true;
            }
            
        }
    }



    
    const DispararkeyPress = (e) => {
       
        if(e.key === 'Enter'){
            let data = Ripi_prIndex.start(inputbuscar);
            setFila(-1);
            setFiltroTerceros(data);
            return false;
        }  
    }

    const disparadorCambio = (e) => {
        setInputBuscar(e.target.value)    
    }


    const ActivarDesactivarOperador = async (id) => {
        __app.CARGANDO(1);
        let respuesta =  await enviarPeticion( "Terceros/activar_desactivar_operador" , Json.nit , {
            body : {id:id},
        } );

        if(respuesta){
            __app.CARGANDO(2);
            NotificationManager.success('Exito'  , 'Editado');
            console.log(respuesta);
        }else{
            __app.CARGANDO(2);
        }
    }
    return (
        <div className='mt-3 container'>  

            <h2 className='' >Lista de Terceros </h2>
            <hr />
            <div className=''>
                <input   onKeyUpCapture={DispararkeyPress} value={inputbuscar} onChange={disparadorCambio}  placeholder='Buscar Terceo...' className='' type="text" />
            </div>

                 
            <div className="table-responsive" id='' >
                <table className='table'>
                    <thead>
                        <tr>
                            <th>CodSistema</th>
                            <th>Nombre</th>
                            <th>Telefono</th>
                            <th>Direccion</th>
                            <th>Operador</th>
    
                        </tr>
                    </thead>
               
                    <tbody>
              
                        {filtroTerceros.map( (item, index) =>   
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.razonSocial}</td>
                                <td>{item.telefono}</td>
                                <td>{item.direccion}</td>
                                <td> 
                                <div className="dropdown">
                                    <a className="btn btn-secondary dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Opciones
                                    </a>

                                    
                                    <ul className="dropdown-menu">
                                        <li><a onClick={ () => ActivarDesactivarOperador(item.id)} className="dropdown-item" >Desactivar/Activar Operador</a></li>
                                        <Link to={"/TerceroPerfil/" + __app.envolver(item.id) } className="  badge p-1 text-bg-primary   rounded">Ver </Link>
                                    </ul>
                                    </div>
                                </td>
                            </tr>         
                        )}
                    </tbody>
                </table>
            </div>  
       
        </div>
    )
}



























export default Index;