import React from 'react';
import { __app } from '../../../utils/myApp';


const ReportedeEfectivo = ({info}) => {

    const descuadre = info['Turno_entrega'] - info['Turno_efectivo_turno']


    return (
        <>  
            <span>INFORME DE LA CAJA EN EFECTIVO: </span>
            <table className="table table-lg">
                <tr>
                    <th>Reporto el usuario</th>
                    <th> $ {__app.formato_decimal( {total : info['Turno_entrega']}  ) } </th>
                </tr>

                
                <tr>
                    <th>Descuadre</th>
                    <th>
                    {descuadre > 0 ? '+' : null}
                    {__app.formato_decimal( {total : descuadre } ) }
                    </th>
                </tr>


                <tr>
                    <th><br/>Firma Entrega</th>
                    <td> <br/>  ______________ </td>
                </tr>

                <tr>
                    <th>Firma Recibe</th>
                    <td>  ______________ </td>
                </tr>

            </table>
        </>
    );
};

export default ReportedeEfectivo;