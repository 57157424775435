import React from 'react';
import { __app } from '../../../utils/myApp';

const CuentasPorCobrar = ({resumen_comprobantes}) => {

    const filtro = resumen_comprobantes.cuentas_por_cobrar;

    return (
        <>
            <th colSpan="2" className="text-center">
                Cuentas por Cobrar.
            </th>
        
 
          

            { (filtro.length > 0 ) && 
                <>
                      {filtro.map((value, key) => (
                        <tr key={key}>
                            <td>{value['nombre_credito']}</td>
                            <td>
                                $ <span dangerouslySetInnerHTML={{ __html :  __app.formato_decimal( { total :  value.total , color : false } )   }} /> 
                            </td>
                        </tr>
                    ))}
                </> 
                
                || 

                <tr>
                    <td  style={{textAlign:'center'}} colSpan={2}> No encontramos cuentas x cobrar   </td>                  
                </tr>

            }

        </>
    );
};

export default CuentasPorCobrar;