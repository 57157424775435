import React, { useState } from 'react';


const StarCheckbox = ({formData}) => {




  
  return (
    <label>
      
      <input
        type="checkbox"
        checked={ formData.favoritos == 1}   
        style={{ display: 'none' }}
        value={formData.favoritos}
        name='favoritos'
      />
      <i className="fa fa-star fs-2" aria-hidden="true"
      
        style={{ color : formData.favoritos == 1 ? '#ffc107' : '#ccc' }}>

        </i>
        Favorito
    </label>
  );
};

export default StarCheckbox;