import React , { useEffect , useState} from 'react';
import { ExtraerTabla , reloadDatabases } from '../../Core/Dixie';
import Buscaripi from "../../utils/ripi";
import {HelpHttp} from "../../Core/https";
import {fetchEmpresa, getLocalstore} from "../../Core/fetchLocalStoreData";
import { useStateValue } from '../../context/StateProvider';
import {SincronizarNivelBajo , SincronizarTodo , getTablaAuxProductos} from  './Controlador';
import ListaProductos from './ListaProductos';
import CheckListGrupos from './CheckListGrupos';

let  Ripi  = null;



const  Index = ({agregarItemPadre , getItem = null})  => {

 

    



    const  [ productos , setProductos ] = useState([]);
    const  [ iguales , setiguales ] = useState([]);


    const  [ auxproductos , setauxProductos ] = useState([]);

    
    const  [ inputbuscar , setInputBuscar  ] = useState("");
    const  [ filtroProductos , setFiltroProductos  ] = useState([]);
    const [ {user , localconfig } ] = useStateValue();
    const ConfigEmpresa = fetchEmpresa();

    

    let Json = {
        "URL" :  localconfig.global.servidor,  
        "nit" :  ConfigEmpresa.nit      
    }


    const [ fila  , setFila ] = useState(-1);


    useEffect( () => {
        const getDatos = async () => {
           
            let productosDixie = await   ExtraerTabla('productos');
            if(productosDixie.length > 0){
                renderizarRipi(productosDixie);
            }else{
                sincronizarTodoFromServer();
            }


            let auxProductos =    getLocalstore('auxProductos');
            if(auxProductos){
                setauxProductos(auxProductos);
            }else{
                auxProductos = await getTablaAuxProductos();
                setauxProductos(auxProductos);
                console.log(auxProductos);
            }
            
        }

        getDatos();


    
    } , [])

    
    
    const renderizarRipi = (data) => {
        setProductos([]);

        if(Ripi!= null){
            Ripi = null ;

        }
        console.log(data);
        Ripi  = new Buscaripi(data);
        setFiltroProductos(data);
        Ripi.fullResultado = true;
        setProductos(data);
    }


    const sincronizarTodoFromServer = async () => {
        let respuesta = await SincronizarTodo();
        if(respuesta.respuesta == 1 ){
            renderizarRipi(respuesta.data.productos);
            reloadDatabases(respuesta.data.productos , 'productos' , 1);
            localStorage.setItem('firebaseLastKeyProducto' , respuesta.data.lastKey);
        }
    }


    const disparadorCambio = (e) => {
        setInputBuscar(e.target.value)    
    }


    const DispararkeyPress = (e) => {
        if(e.key === 'Enter'){
            let data = Ripi.start(inputbuscar);
            setFila(-1);
            setFiltroProductos(data);
            return false;
        }  
    }





    const handleClickSincronizacionLow = async () => {    
        let productos = await SincronizarNivelBajo();
        console.log(productos);
        if(productos && productos.length > 0){
            let productosDixie = await   ExtraerTabla('productos');
            renderizarRipi(productosDixie);
        }else{
            //sincronizarTodoFromServer();
        }
    }


    const handleClickSincronizacionHigh = () => {
        sincronizarTodoFromServer()
    }



    const cargarIguales = (item) => {  
        if(item.referencia_2.length > 0){
            let filtros = productos.filter( (pro) => pro.referencia_2 == item.referencia_2 )
            console.log(filtros)
            setiguales(filtros);
        }else{
            setiguales([]);
        } 
    }

    const halarItem = (item) => {
        getItem(item);
    }

    return (
        <div className='mt-3'>  

            

                    
            <div className='row'>
               
               
                <div className='col-md-8'>
                
                    <div className='d-flex justify-content-between'>

                        <div className=''>
                            <input   onKeyUpCapture={DispararkeyPress} value={inputbuscar} onChange={disparadorCambio}  placeholder='Buscar Producto...' className='' type="text" />

                            <button className='ms-4'> Cargar inventario</button>
                        </div>

                        <div className=''>
                            <div className="btn-group drop">
                                <button onClick={handleClickSincronizacionLow} type="button" className="btn btn-secondary">
                                    Sincronizar
                                </button>

                                <button type="button" className="btn btn-secondary dropdown-toggle " data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="visually-hidden">Toggle Dropend</span>
                                </button>
                                <ul className="dropdown-menu">
                                    <li><button onClick={handleClickSincronizacionHigh} className="dropdown-item" type="button">Sincronizar Forzadamente </button></li>
                                </ul>
                            </div> 

                           
                        </div>    
                    </div>

                    {auxproductos.grupos && <CheckListGrupos data={auxproductos.grupos} listaName={'grupos'} /> } 
                    <ListaProductos filtroProductos={filtroProductos}  cargarIguales={cargarIguales} halarItem={halarItem} />

                </div> 

                <div className='col-md-4'>
                    <ListaProductos filtroProductos={iguales}  halarItem={halarItem}/>
                </div>
            </div>    
        </div>                 
        
    );
}

export default Index;