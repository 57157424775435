import React from 'react';
import LinksDirecto from './LinksDirecto';
import Menu1 from './Menu1';

const Links = ({data}) => {
    return (
        <div className="nav-item-wrapper">
            {data.tipo === "link" && <LinksDirecto data={data.options} />}
            {data.tipo === "menu" && <Menu1 data={data} />}
        </div>
    );
};

export default Links;