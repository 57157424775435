import React, { useState, useEffect } from 'react';
import { useContextDocumentValue, actionTypeDocument } from "../context/ContextDocument.js";

function FormDocumento(props) {
    const [{ documento }, dispatch] = useContextDocumentValue();

    const Initialform = {
        fecha_factura: "",
        plazo: "",
        fecha_vencimiento: "",
        observacion: "",
        numero_factura: "",
        vendedor: "",
        numero_devolucion: ""
    };

    const [form, setForm] = useState(Initialform);

    useEffect(() => {
        if (documento && documento.FORM) {
            setForm({ ...Initialform, ...documento.FORM });
        } else {
            setForm(Initialform);
        }
    }, [documento]);

    const handleBlur = (e) => {
        dispatch({
            type: actionTypeDocument.SET_DOCUMENTO,
            documento: {
                ...documento,
                FORM: form
            },
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value || ""
        }));
    };

    return (
        <form className=" " onChange={handleChange} onBlur={handleBlur}>
        
            <div className='row'>
                <div className="form-group col-4 form-online">
                    <label className='label-form'>Fecha de Factura</label>
                    <input type="date" autoComplete="off" value={form.fecha_factura} name="fecha_factura" className="form-control" placeholder="Fecha de Factura" />
                </div>
                <div className="form-group col-2 form-online">
                    <label className='label-form'>Plazo</label>
                    <input autoComplete="off" value={form.plazo} name="plazo" type="text" className="form-control max-50" placeholder="Plazo" />
                </div>
                <div className="form-group col-4 form-online">
                    <label className='label-form'>Fecha de Vencimiento</label>
                    <input autoComplete="off" value={form.fecha_vencimiento} name="fecha_vencimiento" type="date" className="form-control" placeholder="Vencimiento" />
                </div>
            </div>
          
            
            <div className="form-group form-online">
                <label className='label-form'>Numero de Factura(Referencia)</label>
                <input autoComplete="off" value={form.numero_factura} name="numero_factura" type="text" className="form-control max-80" placeholder="Numero de Factura" />
            </div>
            
            {(documento.TIPO_DOCUMENTO && documento.TIPO_DOCUMENTO.DEVOLUCION > 0) &&
                <div className="form-group form-online">
                    <label className='label-form badge bg-danger text-white'>Número Devolución</label>
                    <input autoComplete="off" value={form.numero_devolucion} name="numero_devolucion" type="text" className="form-control" placeholder="Numero de Devolucion." />
                </div>
            }
           
           <div className="form-group form-online">
                <label className='label-form'>Observación</label>
                <input autoComplete="off" value={form.observacion} name="observacion" type="text" className="form-control" placeholder="Observacion" />
            </div>
           
           
        </form>
    );
}

export default React.memo(FormDocumento, () => true);