import TurnoAbrir from "./TurnoAbrir";
import TurnoAbiertosActual from "./TurnoAbiertosActual";



const TurnoIndex = ({user , infodatabase , cerrarSession , estadoTurno }) => {



    return (

        <div className="row">
              
            <div className="col-md-6">
                <TurnoAbrir  estadoTurno={estadoTurno} infodatabase={infodatabase}  cerrarSession={cerrarSession} />
            </div>

            <div className="col-md-6">
                <TurnoAbiertosActual  estadoTurno={estadoTurno} infodatabase={infodatabase}  cerrarSession={cerrarSession} />
            </div>
        </div>
    )

}

export default TurnoIndex;