import React from 'react';
import LinkMenu1 from './LinkMenu1';
import LinkMenu2 from './LinkMenu2';

const Menu1 = ({data}) => {
   
    return (
      
        <div  className="nav-item-wrapper">
            <a  className="nav-link dropdown-indicator label-1" href={'#nv-' + data.id} role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls={'nv-'+data.id}>
                <div  className="d-flex align-items-center">
                    
                    {/* Icono indicado SAubnivel **/}
                    <div  className="dropdown-indicator-icon">
                        <span  className="fas fa-caret-right"></span>
                    </div>

                    {/* Icono **/}
                    <span  className="nav-link-icon">
                        <span style={{'fontSize': '15px'}} >{data.icon}</span>
                    </span>
                    
                    {/* Texto **/}
                    <span  className="nav-link-text">{data.label}</span>


                    {/* Indicacion de algo nuevo **/}
                    <span  className="fa-solid fa-circle text-info ms-1 new-page-indicator" style={{'fontSize': '6px'}}></span>
                        
                    
                </div>
            </a>
           
            <div  className="parent-wrapper label-1">
                
                <ul  className="nav collapse parent" data-bs-parent="#navbarVerticalCollapse" id={'nv-'+data.id}>
                    {/*** PAra Mobil */}
                    <li  className="collapsed-nav-item-title d-none">
                        {data.label} Mobil
                    </li>
                    
                    {data.options[0].tipo == "link" && <>{ data.options.map((item,index) => <LinkMenu1 key={index} data={item} /> )}</>}

                    {data.options[0].tipo  == "menu" && <>{ data.options.map((item,index) => <LinkMenu2 key={index} data={item} id={data.id} /> )}</>} 
                   

                </ul>

            </div>
        </div>
    );
};

export default Menu1;