import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ExtraerTabla } from '../../../Core/Dixie';
import { NotificationManager } from 'react-notifications';



const Composiciones = ({ayudas, setAyudas , setFormData , formData }) => {

    
    const initialState = {

        composiciones: null, 
        cantidad: '',
        PRECIO_COMPUESTO: ''
    };

    const [formDataLocal, setformDataLocal] = useState(initialState);

    // Manejar cambios en el campo de cantidad
    const handleChangeCantidad = (event) => {
        const value = event.target.value;
        setformDataLocal({
            ...formDataLocal,
            cantidad: value
        });
    };

    // Manejar cambios en el campo de precio individual
    const handleChangePrecioIndividual = (event) => {
        const value = event.target.value;
        setformDataLocal({
            ...formDataLocal,
            PRECIO_COMPUESTO: value
        });
    };

    // Manejar selección de producto
    const handleAddComposicione = (selectedOption) => {
        setformDataLocal({
            ...formDataLocal,
            composiciones: selectedOption
        });
    };

    const [productos , SetProductos ] = useState([]);


    useEffect(() => {
        
        SetProductos( cargar() );

    }, [])

    const cargar = async () =>  {

        let aux = await  ExtraerTabla('productos');
        SetProductos(aux)
    }
    


    const guardarCompocision = (e) => {
        e.preventDefault();
        console.log(formDataLocal);
    
        const id = formDataLocal.composiciones.id;
    
        // Verificar si ya existe un elemento con el mismo id en COMPOSICIONES
        const idExistente = formData.COMPOSICIONES.some(composicion => composicion.producto_id === id);
    
        if (idExistente) {
            NotificationManager.warning("Ya existe este producto")
            // Puedes manejar el caso de ID repetido aquí, como mostrar un mensaje de error al usuario
        } else {
            // Si el ID no está repetido, procede a insertarlo en la lista
            const insertar = {
                producto_id: id,
                nombre: formDataLocal.composiciones.nombre,
                cantidad: formDataLocal.cantidad,
                PRECIO_COMPUESTO: formDataLocal.PRECIO_COMPUESTO
            };
            
            const copyComposiciones = [...formData.COMPOSICIONES, insertar];
    
            setFormData({
                ...formData,
                COMPOSICIONES: copyComposiciones
            });

            setformDataLocal(initialState)
        }
    }

    const boorarComposicion = (e , item) => {
        e.preventDefault();
        const productoIdAEliminar = item.producto_id;
        const nuevasComposiciones = formData.COMPOSICIONES.filter(composicion => composicion.producto_id !== productoIdAEliminar);
        setFormData({
        ...formData,
        COMPOSICIONES: nuevasComposiciones
        });
    }

    return (
        <div className="">
           
            { ayudas.composiciones === 1 &&  <div className="alert alert-warning alert-dismissible fade show" role="alert">
                <strong>Crea combinaciones de productos.</strong> 
                <br/>
                <br/>
                    ¿Quieres ofrecer canastas, promociones, combos o platos especiales en tu restaurante? ¡Con nuestra herramienta de composición de productos, es muy fácil!
                    <br/>
                    <br/>   
                    <b>Productos:</b> Se refiere a la opción de seleccionar el producto que formará parte de la composición. Por ejemplo, si estás creando un combo de desayuno, podrías seleccionar café, pan, huevos, etc., como productos que componen ese combo.
                    <br/>
                   
                    <b>Cantidad:</b> Indica la cantidad de unidades de un producto que se descontarán cada vez que se venda el producto principal de la composición. Por ejemplo, si el producto principal es un combo de almuerzo y el producto secundario es una bebida, la cantidad podría representar cuántas unidades de la bebida se descontarán cada vez que se venda el combo de almuerzo.
                    <br/>
                    
                    <b>Precio individual:</b> Este es un costo adicional al producto principal. En el ejemplo dado, si el precio individual es de 1500 y el producto principal cuesta 20,000, el precio de venta final de la composición sería de 21,500. Es decir, se suma este costo adicional al precio del producto principal para determinar el precio final de la composición.


                <button type="button" onClick={() => setAyudas(prevState => ({...prevState,composiciones: 2 })) } className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>}


            <div className="row g-3">

                <div className='col'>
                    <div className="mb-3">
                        <label htmlFor="compuesto_producto_id" className="form-label">Producto</label>
                        <Select

                           getOptionLabel ={(productos)=>productos.nombre}
                           getOptionValue ={(productos)=>productos.id}
                           options={productos}
                           onChange={handleAddComposicione}
                           value={formDataLocal.COMPOSICIONES} // Establecer las opciones seleccionadas por defecto
                           placeholder="Selecciona un producto"
                        />
                    </div>
                </div>


                <div className='col-2'>           
                    <div className="mb-3">
                        <label htmlFor="cantidad" className="form-label">Cantidad</label>
                        <input value={formDataLocal.cantidad} onChange={handleChangeCantidad} type="text" className="form-control" id="cantidad" name="cantidad" />
                    </div>
                </div> 



                <div className='col-3 col-sm-4'>             
                    <div className="mb-3">
                        <label htmlFor="precio_aux" className="form-label">Precio individual</label>
                        <input value={formDataLocal.PRECIO_COMPUESTO} onChange={handleChangePrecioIndividual} type="text" className="form-control" id="precio_aux" name="PRECIO_COMPUESTO" />
                    </div>
                </div> 
      
                
                <div className="col-2">
                    <div className="mb-3">
                        <button  onClick={(e) => guardarCompocision(e)} type="submit" className="btn btn-principal mt-4"> <i class="fa fa-paper-plane"></i> </button>
                    </div>    
                </div>           
            </div>
         
            { formData.COMPOSICIONES  && formData.COMPOSICIONES.length > 0 &&  <div className='container-fluid row mt-5'>
                <table className='table table-striped  table-bordered'>

                    <tr>
                        <th>Llave</th>
                        <th>Nombre</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th></th>
        
                    </tr>
                { formData.COMPOSICIONES.map((data,index) => 

                    <tr key={index}>
                        <td>{data.producto_id}</td>
                        <td>{data.nombre}</td>
                        <td>{data.cantidad}</td>
                        <td> $ {data.PRECIO_COMPUESTO}</td>
                        <td> <button onClick={(e) => boorarComposicion(e , data) } className='btn  text-danger btn-xs'><i className='fa fa-trash'></i></button> </td>
                    </tr>
                    
                )}
                 </table>
            </div>}

            {!formData.COMPOSICIONES.length > 0 && <div className='alert  mt-5 alert-info'> No hay Composiciones para mostrar.</div> }
           
        </div>
    );
};

export default Composiciones;
