import React, { useEffect, useState } from 'react';
import PagesFiltro from './Componentes/Filtros/Pages.jsx';
import { extraerVendedores, listarCientesv2 } from '../../Terceros/Core.js';
import { extraerUsuarios } from '../../user/Core/Core.js';



const Pages = () => {


    const [datosFiltro, SetDatosfiltro] = useState(
        { 
        terceros: [], 
        vendedores: [], 
        usuarios: [] }
    );

    
    const getData = async () => {
        
        /// Extraer tercero
        const terceros = await listarCientesv2();



        //Extraer Vendedore
        const vendedores = await extraerVendedores ();
 


        
         //Extraer Vendedore
        const usuarios = await extraerUsuarios();
 
        
        SetDatosfiltro({terceros , vendedores  , usuarios})
        
        console.log({terceros , vendedores  , usuarios});
    }


    useEffect(() => {

        getData();


    }, [])

    
    return (
        <div>
      
        
            <h1>Documentos</h1>
            <PagesFiltro datosFiltro={datosFiltro} />
        </div>
    );
};

export default Pages;