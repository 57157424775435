import React, { useState, useEffect } from 'react';
import ListaVariantes from './ListaVariantes';

const Variantes = ({setVaribleForm , variantesAux , guardarInventarioEdiccion }) => {
   
    const [variantes, setVariantes] = useState( []);
    const [nuevoColor, setNuevoColor] = useState("");
    const [nuevaTalla, setNuevaTalla] = useState("");
    const [nuevaCantidad, setNuevaCantidad] = useState(0);
    const [totalStock, setTotalStock] = useState(0);

    const [ showBotonGuardar , setShowBotonguardar ] = useState(null);

    
    useEffect(() => {
       if(variantes){
        const total = variantes.reduce((acc, variante) => acc + variante.cantidad, 0);
        setTotalStock(total);
       }

    }, [variantes]);

    useEffect(() => {
        console.log(variantesAux)
        setVariantes( variantesAux ?? [] )


        if(guardarInventarioEdiccion){
            setShowBotonguardar(1);
        }
     }, []);


    const agregarVariante = (e) => {
        e.preventDefault();
        if (nuevoColor && nuevaTalla && nuevaCantidad > 0) {
            const nuevaVariante = { color: nuevoColor, talla: nuevaTalla, cantidad: parseInt(nuevaCantidad) };
            setVariantes([...variantes, nuevaVariante]);
            setNuevoColor("");
            setNuevaTalla("");
            setNuevaCantidad(0);

            if(typeof setVaribleForm == 'function'){
                setVaribleForm([...variantes, nuevaVariante]);
            }
            
        }
        return false;
    };

    const eliminarVariante = (index) => {
        const nuevasVariantes = [...variantes];
        nuevasVariantes.splice(index, 1);
        setVariantes(nuevasVariantes);
    };

    return (
        <div className="container mt-4">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Agregar Variante</h5>
                    <div className="form-row">
                    <div className="row">
                      
                        <div className="form-group col-md-4">
                            Talla:
                            <input
                            type="text"
                            className="form-control"
                            value={nuevaTalla}
                            onChange={(e) => setNuevaTalla(e.target.value)}
                            placeholder="Talla"
                            />
                        </div>

                        <div className="form-group col-md-4">
                            Color:
                            <input
                            type="text"
                            className="form-control"
                            value={nuevoColor}
                            onChange={(e) => setNuevoColor(e.target.value)}
                            placeholder="Color"
                            />
                        </div>

                        <div className="form-group col-md-4">
                            Cantidad:
                            <input
                            type="number"
                            className="form-control"
                            value={nuevaCantidad}
                            onChange={(e) => setNuevaCantidad(e.target.value)}
                            placeholder="Cantidad"
                            />
                        </div>
                        </div>
                    </div>
                    <button className="btn btn-primary mt-2" onClick={(e) => agregarVariante(e) }>Agregar Variante</button>
                </div>
            </div>
            <div className="mt-4">
                <h5>Variantes</h5>
                <ListaVariantes variantes={variantes} eliminarVariante={eliminarVariante} totalStock={totalStock} />

            </div>
            {showBotonGuardar && <button onClick={ () => guardarInventarioEdiccion(variantes)} className='btn btn-primary'> Guardar Cambios</button>}
        </div>
    );
};

export default Variantes;
