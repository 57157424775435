


/*
'Key' , 'key' ,  estadoApp


*/


import {getLocalstore} from '../../../../Core/fetchLocalStoreData.js';

export const  swtichNombreTr = (clave ) => {
    
 

    let  user  = getLocalstore('user');


    if(  user.CONFIGURACIONES_TABLE && user.CONFIGURACIONES_TABLE.estado_aplicacion && user.CONFIGURACIONES_TABLE.estado_aplicacion == 1){
    
        let x =  user.CONFIGURACIONES.CAMPOS_A_MOSTRAR_LISTA.filter( (infoList) => infoList.clave == clave   );
        return x[0].label;
    }else{
       
    
        let x =  user.CONFIGURACIONES.CAMPOS_A_MOSTRAR_LISTA.filter( (infoList) => infoList.clave == clave   );
        return x[0].clave
    }

}