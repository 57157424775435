import LayautDefault from "./components/Layaut/LayautDefault";
import AlertasInventario from "./components/Productos/AlertaMaximosMinimos/Pages";


/**import SimpleBar from 'simplebar-react'; */
/**import 'simplebar/dist/simplebar.min.css'; */



const  App  = () =>   {

   
        

    return (  
        <>  
            <AlertasInventario />
           <LayautDefault />

        </>
    );
}

export default App;